/** @format */

import React from 'react'
import { withRouter } from 'react-router-dom'

import Header from '../Common/Header'
//import FooterTop from '../Common/FooterTop'
import FooterMiddle from '../Common/FooterMiddle'
import FooterBottom from '../Common/FooterBottom'

function Layout(props) {
	window.scrollTo(0, 0)
	let location = props.location.pathname.split('/')
	return (
		<React.Fragment>
			<Header location={location} />
			{props.children}
			{/* 	<FooterTop /> */}
			<FooterMiddle />
			<FooterBottom />
		</React.Fragment>
	)
}

export default withRouter(Layout)
