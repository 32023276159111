/** @format */

import React, { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'

import Row from 'antd/lib/row'
import Col from 'antd/lib/col'

import Loading from '../../../components/Common/Loading'
import MainNavigation from '../../../components/Common/MainNavigation'

import Carrousel from '../detail/components/Carrousel'
import Aside from '../detail/components/Aside'
import Coments from '../detail/components/Coments'

import AdsDetail from './services'

import './style.css'

export default function DetailAd(props) {
	const { t } = useTranslation()
	const [isMobile, setMobile] = useState(false)
	const [isDetail, setDetail] = useState(false)
	const [isTraduce] = useState({
		service_global_description: t('global_service.service_global_description'),
	})
	useEffect(() => {
		if (window.innerWidth < 769) {
			setMobile(true)
		}

		AdsDetail(props.match.params.id, isTraduce).then((response) => {
			if (response) {
				setDetail(response)
			} else {
				setTimeout(() => {
					window.location.pathname = '/'
				}, 5000)
			}
		})
	}, [props.match.params.id, isTraduce])
	if (!isDetail) {
		return <Loading />
	} else {
		return (
			<>
				<MainNavigation
					title={t('publication.detail.main_navigation.title')}
					linkPrevTitle={'Home'}
					linkNextTitle={t('publication.detail.main_navigation.subtitle')}
					linkPrev={'/'}
					linkNext={''}
				/>

				{isMobile && (
					<div className='est-mobile-detail-global-container'>
						<div className='est-mobile-detail-main-container'>
							<Row>
								<Col span={24}>
									<div className='est-mobile-detail-text-global-container'>
										<div className='est-mobile-detail-title-container'>
											<h2 className='est-mobile-detail-title'>{isDetail.title}</h2>
										</div>
										<div className='est-mobile-detail-subtitle-container'>
											<h3 className='est-mobile-detail-subtitle-one'>
												{isDetail.completeAddress.country}
											</h3>
											<h3 className='est-mobile-detail-subtitle-two'>|</h3>
											<h3 className='est-mobile-detail-subtitle-three'>
												{isDetail.completeAddress.city}
											</h3>
										</div>
									</div>
								</Col>
								<Col span={24}>
									<div className='est-mobile-detail-col-container'>
										<Carrousel isDetailCarrousel={isDetail} />
									</div>
									<div className='est-mobile-detail-coments-container'>
										<Coments item={isDetail} />
									</div>
								</Col>
								<Col span={24}>
									<div className='est-mobile-detail-aside-container'>
										<Aside item={isDetail} />
									</div>
								</Col>
							</Row>
						</div>
					</div>
				)}

				<div className='est-detail-global-container'>
					<div className='est-detail-main-container'>
						<Row>
							<Col span={24}>
								<div className='est-detail-text-global-container'>
									<div className='est-detail-title-container'>
										<h2 className='est-detail-title'>{isDetail.title}</h2>
									</div>
									<div className='est-detail-subtitle-container'>
										<h3 className='est-detail-subtitle-one'>
											{isDetail.completeAddress.country}
										</h3>
										<h3 className='est-detail-subtitle-two'>|</h3>
										<h3 className='est-detail-subtitle-three'>
											{isDetail.completeAddress.city}
										</h3>
									</div>
								</div>
							</Col>
							<Col xs={24} sm={24} md={16} lg={16} xl={16}>
								<div className='est-detail-col-container'>
									<Carrousel isDetailCarrousel={isDetail} />
								</div>
								<div className='est-detail-coments-container'>
									<Coments item={isDetail} />
								</div>
							</Col>
							<Col xs={24} sm={24} md={8} lg={8} xl={8}>
								<div className='est-detail-aside-container'>
									<Aside item={isDetail} />
								</div>
							</Col>
						</Row>
					</div>
				</div>
			</>
		)
	}
}
