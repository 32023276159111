/** @format */

import axios from 'axios'

import notification from 'antd/lib/notification'

import { ENV_CORE } from '../../../../components/Common/Hooks/Variables/Enviroment'

export default async function Contact(item, traduce) {
	let returnResponse
	await axios({
		method: 'POST',
		url: `${ENV_CORE}/api/auth/send-email`,
		data: item,
	})
		.then((response) => {
			if (response.data.statusCode === 200) {
				notification['success']({
					message: `${traduce.success_message} ${item.regName}`,
					description: `${traduce.success_description}`,
				})
			} else {
				notification['warning']({
					message: 'Warning:',
					description: 'Service error: REACT_APP_SERVICE_CORE - User Reservations',
				})
			}
		})
		.catch(() => {
			notification['error']({
				message: `Error`,
				description: `${traduce.service_global_description}`,
			})
		})
	return returnResponse
}
