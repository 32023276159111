/** @format */

import React, { useEffect, useState } from 'react'

import HeadDescription from '../../components/Common/HeadDescription'
import CarouselBanners from '../../components/Common/CarouselBanners'
import LoadingBanner from '../../components/Common/LoadingBanner'

import Publication from './components/Publication'
import Blog from './components/Blog'
import Contact from './components/Contact'
import AboutUs from './components/AboutUs'
import Categories from './components/Categories'
import MainSearch from './components/MainSearch'

import { GetCategories } from '../../components/Common/Hooks/Functions/GetCategories'

import servicesHome from './services'

import './style.css'

export default function Home() {
	const [isMainBanners, setMainBanners] = useState(null)
	const [isCategories, setCategories] = useState(null)
	const [isAds, setAds] = useState(null)
	const [isAdsState, setAdsState] = useState(true)
	const [isBlogPosts, setBlogPosts] = useState(null)
	const [isBlogPostsState, setBlogPostsState] = useState(true)

	useEffect(() => {
		servicesHome.MainBanners().then((response) => {
			if (response) {
				setMainBanners(response)
			}
		})
		GetCategories().then((response) => {
			if (response) {
				setCategories(response)
			}
		})
		servicesHome.Ads().then((response) => {
			if (response) {
				setAds(response)
			} else {
				setAdsState(false)
			}
		})
		servicesHome.BlogLastestPosts().then((response) => {
			if (response) {
				setBlogPosts(response)
			} else {
				setBlogPostsState(false)
			}
		})
	}, [])

	return (
		<>
			<HeadDescription title={'Prujula - Home'} name={'description'} content={'Camping Site'} />
			{isMainBanners ? (
				<div className='est-home-banners-main-container'>
					<CarouselBanners
						slug={true}
						carouselItems={[1, 1, 1, 1, 1]}
						banners={isMainBanners}
						opacity={true}
					/>
					{isCategories && <MainSearch isCategories={isCategories} />}
				</div>
			) : (
				<LoadingBanner />
			)}
			<AboutUs />
			<div className='est-home-categories-global-container'>
				{isCategories && <Categories categories={isCategories} />}
			</div>
			{isAdsState ? (
				<>{isAds ? <Publication publication={isAds} /> : <LoadingBanner />}</>
			) : (
				<h2 className='est-home-ads-title-not-found'>No hay registros de Anuncios</h2>
			)}
			{isBlogPostsState ? (
				<>{isBlogPosts ? <Blog blog={isBlogPosts} /> : <LoadingBanner />}</>
			) : (
				<h2 className='est-home-ads-title-not-found'>No hay registros de Anuncios</h2>
			)}
			<Contact />
		</>
	)
}
