/** @format */

import React from 'react'

import { ArrowRightOutlined } from '@ant-design/icons'

import { ENV_CORE } from '../../../../../../components/Common/Hooks/Variables/Enviroment'

import Image from '../../../../../../components/Common/Image'

import './style.css'

export default function CardCategories(props) {
	const handleSearchLink = async (item) => {
		if (props.typeView) {
			window.location.href = `/views/${item}`
		} else {
			window.location.href = `/categories/${item}`
		}
	}

	return (
		<div
			className='est-categories-global-container'
			onClick={() => handleSearchLink(props.item.nombre)}>
			<div className='est-categories-main-container'>
				<div className='est-card-categories-img-container'>
					<Image classImg='est-card-categories-img' image={`${ENV_CORE}/${props.item.img}`} />
				</div>

				<div className='est-category-text-global-container'>
					<h2 className='category-name'>{props.item.nombre}</h2>
					<div className='est-category-text-main-container'></div>
					<div className='category-link'>
						<ArrowRightOutlined className='category-link-icon' />
					</div>
				</div>
			</div>
		</div>
	)
}
