/** @format */

import React, { useState, useEffect } from 'react'

import { useTranslation } from 'react-i18next'

import { useGlobal, setGlobal } from 'reactn'

import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import Button from 'antd/lib/button'
import Modal from 'antd/lib/modal'
import Form from 'antd/lib/form'
import Input from 'antd/lib/input'
//import Switch from 'antd/lib/switch'
//import Select from 'antd/lib/select'
//import Radio from 'antd/lib/radio'
import Tabs from 'antd/lib/tabs'
import Checkbox from 'antd/lib/checkbox'
import notification from 'antd/lib/notification'

import { EyeFilled, DeleteFilled } from '@ant-design/icons'

import AdsDetail from '../../../../../../components/Common/Hooks/Functions/PublicationDetail'
import { ENV_CORE } from '../../../../../../components/Common/Hooks//Variables/Enviroment'

import InputCustom from '../../../../../../components/Common/Inputs/Normal'
import InputMask from '../../../../../../components/Common/Inputs/InputMask'
import { GetCategories } from '../../../../../../components/Common/Hooks/Functions/GetCategories'
import { rulesValidation } from '../../../../../../components/Common/Inputs/Normal/rules'
import { rulesValidationMask } from '../../../../../../components/Common/Inputs/InputMask/rules'
import UploadImage from '../../../../../../components/Common/UploadImage'
import UploadGalleryImage from '../../../../../../components/Common/UploadGalleryImage'
import Address from '../../../../../../components/Common/Address'
import MapLeaft from '../../../../../../components/Common/MapLeaft'
import Image from '../../../../../../components/Common/Image'

import LoadingData from '../../../../components/LoadingData'

import servicesPublications from '../../services'

import EditPublicationService from './services'

import './style.css'

export default function EditPublication(props) {
	const { t } = useTranslation()
	const [edit_form] = Form.useForm()
	const [isVisible, setVisible] = useState(false)
	const [isDetail, setDetail] = useState(null)
	const [isDetailError, setDetailError] = useState(false)
	const [isDisabledOffer, setDisabledOffer] = useState(false)
	const [isCategories, setCategories] = useState(null)
	const [isLoadingEditPublication, setLoadingEditPublication] = useState(false)
	const [isAddress, setAddress] = useGlobal('SelectedAddress')
	const [isMainImage, setMainImage] = useState(null)
	const [isOfferImage, setOfferImage] = useState(null)
	const [isVisibleGalleryImage, setVisibleGalleryImage] = useState(false)
	const [isGalleryImage, setGalleryImage] = useState(null)
	const [isGalleryArrayImage] = useGlobal('GalleryArrayImages')

	const { TabPane } = Tabs
	const { TextArea } = Input
	//const { Option } = Select

	useEffect(() => {
		GetCategories().then((response) => {
			let info_categories = []
			let info_type_view = []
			response.category.forEach((item) => {
				info_categories.push({ label: item.nombre, value: item.nombre })
			})
			response.type_view.forEach((item) => {
				info_type_view.push({ label: item.nombre, value: item.nombre })
			})
			setCategories({ categories: info_categories, type_view: info_type_view })
		})
	}, [])

	const handleChangeTabs = () => {
		setTimeout(() => {
			window.dispatchEvent(new Event('resize'))
		}, 250)
	}

	/* 	const handleDiscountPrice = (item) => {
		setDisabledOffer(!item)
		if (item === false) {
			edit_form.setFieldsValue({ updPriceOffer: '0' })
			setOfferImage(null)
			const picture = isDetail.picture
			let newDetail
			newDetail = {
				...isDetail,
				picture: {
					picture_url: picture.picture_url,
					picture_url_offer: null,
					picture_galery: picture.picture_galery,
				},
			}
			setDetail(newDetail)
		}
	} */

	const handleModalEditPublication = (id, item) => {
		setVisible(item)
		AdsDetail(id).then((response) => {
			setDisabledOffer(false)
			let offer = true
			if (response) {
				setAddress({
					country: response.completeAddress.country,
					country_code: response.completeAddress.country_code,
					county: response.completeAddress.county,
					city: response.completeAddress.city,
					municipality: response.completeAddress.municipality,
					state: response.completeAddress.state,
					geo_location: {
						lat: parseFloat(response.completeAddress.lat),
						lng: parseFloat(response.completeAddress.lng),
					},
					complete_address: response.completeAddress.address,
				})
				let discount = parseInt(response.discount_amount)
				setDetail(response)
				setGlobal({
					GalleryArrayImages: response.picture.picture_galery,
				})
				if (response.offer === '0') {
					setDisabledOffer(true)
					offer = false
				}
				edit_form.setFieldsValue({
					updTitle: response.title,
					updPrice: response.price,
					updDescription: response.description,
					updPeople: response.people,
					updPriceOffer: response.price_offer,
					updOffer: offer,
					updDiscountAmount: discount,
					updIdCategory: response.id_category,
					updIdCategory2: response.id_category2,
					updAddressDescription: response.completeAddress.address_reference,
					updPhone: response.phone,
					agua: response.amenidades.agua === '1' ? true : false,
					luz: response.amenidades.luz === '1' ? true : false,
					tocador: response.amenidades.tocador === '1' ? true : false,
					cocinas: response.amenidades.cocinas === '1' ? true : false,
					bbq: response.amenidades.bbq === '1' ? true : false,
					fogata: response.amenidades.fogata === '1' ? true : false,
					historico: response.amenidades.historico === '1' ? true : false,
					ecologia: response.amenidades.ecologia === '1' ? true : false,
					agricola: response.amenidades.agricola === '1' ? true : false,
					reactivo_pasivo: response.amenidades.reactivo_pasivo === '1' ? true : false,
					reactivo_activo: response.amenidades.reactivo_activo === '1' ? true : false,
					recreacion_piscinas: response.amenidades.recreacion_piscinas === '1' ? true : false,
					recreacion_acuaticas:
						response.amenidades.recreacion_acuaticas === '1' ? true : false,
					recreacion_veredas: response.amenidades.recreacion_veredas === '1' ? true : false,
					recreacion_espeleologia:
						response.amenidades.recreacion_espeleologia === '1' ? true : false,
					recreacion_kayac_paddle_balsas:
						response.amenidades.recreacion_kayac_paddle_balsas === '1' ? true : false,
					recreacion_cocina: response.amenidades.recreacion_cocina === '1' ? true : false,
					recreacion_pajaros: response.amenidades.recreacion_pajaros === '1' ? true : false,
					recreacion_alpinismo:
						response.amenidades.recreacion_alpinismo === '1' ? true : false,
					recreacion_zipline: response.amenidades.recreacion_zipline === '1' ? true : false,
					paracaidas: response.amenidades.paracaidas === '1' ? true : false,
					recreacion_areas: response.amenidades.recreacion_areas === '1' ? true : false,
					recreacion_animales: response.amenidades.recreacion_animales === '1' ? true : false,
					equipos_mesas: response.amenidades.equipos_mesas === '1' ? true : false,
					equipos_sillas: response.amenidades.equipos_sillas === '1' ? true : false,
					equipos_estufas: response.amenidades.equipos_estufas === '1' ? true : false,
					casetas_acampar: response.amenidades.casetas_acampar === '1' ? true : false,
					toldos: response.amenidades.toldos === '1' ? true : false,
					estufas_gas: response.amenidades.estufas_gas === '1' ? true : false,
					tanques_gas: response.amenidades.tanques_gas === '1' ? true : false,
					lena: response.amenidades.lena === '1' ? true : false,
					carbon: response.amenidades.carbon === '1' ? true : false,
					se_admiten_mascotas: response.amenidades.se_admiten_mascotas === '1' ? true : false,
					perros_servicios: response.amenidades.perros_servicios === '1' ? true : false,
				})
			} else {
				setDetailError(true)
			}
		})
		setTimeout(() => {
			window.dispatchEvent(new Event('resize'))
		}, 350)
	}

	const handleAddProductImage = (item, data) => {
		item.file = `${ENV_CORE}/${item.file}`
		if (data === 'principal') {
			setMainImage(item)
		} else if (data === 'ofert') {
			setOfferImage(item)
		}
	}

	const handleDeteleImage = (item) => {
		if (item === 'principal') {
			setMainImage(null)
		} else if (item === 'ofert') {
			setOfferImage(null)
		}
	}

	const handleDeletePublicationImage = (item) => {
		const picture = isDetail.picture
		let newDetail
		if (item === 'principal') {
			setMainImage(null)
			newDetail = {
				...isDetail,
				picture: {
					picture_url: null,
					picture_url_offer: picture.picture_url_offer,
					picture_galery: picture.picture_galery,
				},
			}
		} else {
			setOfferImage(null)
			newDetail = {
				...isDetail,
				picture: {
					picture_url: picture.picture_url,
					picture_url_offer: null,
					picture_galery: picture.picture_galery,
				},
			}
		}
		setDetail(newDetail)
	}

	const handleEditPublication = async (item) => {
		let updMainImage
		let updDealImage
		const priceOffer = parseFloat(item.updPriceOffer)
		const priceMain = parseFloat(item.updPrice)
		if (priceMain <= 0) {
			notification['warning']({
				message: t('profile.user_publication.edit_publication.warning_title'),
				description: t('profile.user_publication.edit_publication.warning_one_description'),
			})
			return
		}
		if (item.updOffer) {
			if (priceOffer <= 0) {
				notification['warning']({
					message: t('profile.user_publication.edit_publication.warning_title'),
					description: t('profile.user_publication.edit_publication.warning_one_description'),
				})
				return
			}
		}
		if (priceMain <= priceOffer) {
			notification['warning']({
				message: t('profile.user_publication.edit_publication.warning_title'),
				description: t('profile.user_publication.edit_publication.warning_five_description'),
			})
			return
		}
		if (!isDetail.picture.picture_url) {
			if (!isMainImage) {
				notification['warning']({
					message: t('profile.user_publication.edit_publication.warning_title'),
					description: t('profile.user_publication.edit_publication.warning_two_description'),
				})
				return
			} else {
				updMainImage = isMainImage
			}
		} else {
			updMainImage = isDetail.picture.picture_url
		}
		if (!isDisabledOffer) {
			if (!isDetail.picture.picture_url_offer) {
				if (!isOfferImage) {
					notification['warning']({
						message: t('profile.user_publication.edit_publication.warning_title'),
						description: t(
							'profile.user_publication.edit_publication.warning_three_description'
						),
					})
					return
				} else {
					updDealImage = isOfferImage
				}
			} else {
				updDealImage = isDetail.picture.picture_url_offer
			}
		}
		if (isGalleryArrayImage.length === 0) {
			notification['warning']({
				message: t('profile.user_publication.edit_publication.warning_title'),
				description: t('profile.user_publication.edit_publication.warning_four_description'),
			})
			return
		}
		let updAddress = {
			completeAddress: isAddress.complete_address,
			country: isAddress.country,
			countryCode: isAddress.country_code,
			county: isAddress.county,
			city: isAddress.city,
			municipality: isAddress.municipality,
			state: isAddress.state,
			lat: isAddress.geo_location.lat,
			lng: isAddress.geo_location.lng,
		}

		setLoadingEditPublication(true)

		const data = {
			updId: isDetail.id,
			updIdUser: isDetail.id_user,
			updTitle: item.updTitle,
			updPrice: item.updPrice,
			updOffer: item.updOffer,
			updPriceOffer: item.updPriceOffer,
			updDescription: item.updDescription,
			updHalf: isDetail.half,
			updPeople: item.updPeople,
			updDiscountAmount: item.updDiscountAmount,
			updIdCategory: item.updIdCategory,
			updIdCategory2: item.updIdCategory2,
			updAddress: updAddress,
			updAddressDescription: item.updAddressDescription,
			updPhone: item.updPhone.replace(/[+()/\s/]/g, ''),
			updMainImage: updMainImage,
			updDealImage: updDealImage ? updDealImage : null,
			updImageGallery: isGalleryArrayImage,
			updAmenities: {
				agua: item.agua,
				luz: item.luz,
				tocador: item.tocador,
				cocinas: item.cocinas,
				bbq: item.bbq,
				fogata: item.fogata,
				historico: item.historico,
				ecologia: item.ecologia,
				agricola: item.agricola,
				reactivo_pasivo: item.reactivo_pasivo,
				reactivo_activo: item.reactivo_activo,
				recreacion_piscinas: item.recreacion_piscinas,
				recreacion_acuaticas: item.recreacion_acuaticas,
				recreacion_veredas: item.recreacion_veredas,
				recreacion_espeleologia: item.recreacion_espeleologia,
				recreacion_kayac_paddle_balsas: item.recreacion_kayac_paddle_balsas,
				recreacion_cocina: item.recreacion_cocina,
				recreacion_pajaros: item.recreacion_pajaros,
				recreacion_alpinismo: item.recreacion_alpinismo,
				recreacion_zipline: item.recreacion_zipline,
				paracaidas: item.paracaidas,
				recreacion_areas: item.recreacion_areas,
				recreacion_animales: item.recreacion_animales,
				equipos_mesas: item.equipos_mesas,
				equipos_sillas: item.equipos_sillas,
				equipos_estufas: item.equipos_estufas,
				casetas_acampar: item.casetas_acampar,
				toldos: item.toldos,
				estufas_gas: item.estufas_gas,
				tanques_gas: item.tanques_gas,
				lena: item.lena,
				carbon: item.carbon,
				se_admiten_mascotas: item.se_admiten_mascotas,
				perros_servicios: item.perros_servicios,
			},
		}
		/* 	TRADUCCION */
		const traduce = {
			service_success_title: t(
				'profile.user_publication.edit_publication.service_success_title'
			),
			service_success_description: t(
				'profile.user_publication.edit_publication.service_success_description'
			),
			service_warning_title: t(
				'profile.user_publication.edit_publication.service_warning_title'
			),
			service_warning_description: t(
				'profile.user_publication.edit_publication.service_warning_description'
			),
			service_error_description: t(
				'profile.user_publication.edit_publication.service_error_description'
			),
		}

		/* 	TRADUCCION */
		await EditPublicationService(data, traduce).then((response) => {
			if (response) {
				setVisible(false)
				servicesPublications.UserPublications(isDetail.id_user).then((responseDetail) => {
					if (responseDetail) {
						setGlobal({
							UserPublications: responseDetail,
						})
					} else {
						setGlobal({
							UserPublicationsState: true,
						})
					}
				})
			}
		})
		setLoadingEditPublication(false)
	}

	const handlePreviewGalleryImage = (item) => {
		setVisibleGalleryImage(true)
		setGalleryImage(item)
	}

	const handleDeleteGalleryImage = (item) => {
		const dataToFilter = [...isGalleryArrayImage]
		const index = dataToFilter.findIndex((data) => {
			return data.uid === item.uid
		})
		dataToFilter.splice(index, 1)
		setGlobal({
			GalleryArrayImages: dataToFilter,
		})
	}

	return (
		<>
			<Button
				disabled={props.item.approved.length > 0 || props.item.pending.length > 0}
				className='est-user-publications-edit-button'
				onClick={() => handleModalEditPublication(props.item.id_anuncio, true)}>
				{t('profile.user_publication.edit_publication.edit_main_button')}
			</Button>
			{(props.item.approved.length > 0 || props.item.pending.length > 0) && (
				<h4 className='est-user-publications-edit-title'>
					<span className='est-user-publications-edit-subtitle'>*</span>
					{t('profile.user_publication.edit_publication.edit_subtitle')}
				</h4>
			)}
			<Modal
				width={1024}
				wrapClassName='est-profile-publication-edit-modal-container'
				centered
				title={t('profile.user_publication.edit_publication.modal_title')}
				visible={isVisible}
				onCancel={() => setVisible(false)}
				cancelButtonProps={{ style: { display: 'none' } }}
				okButtonProps={{ style: { display: 'none' } }}
				okText=''
				cancelText=''
				footer={false}>
				<div className='est-profile-publication-edit-modal-title-container'>
					<p className='est-profile-publication-edit-modal-title'>
						{t('profile.user_publication.edit_publication.modal_subtitle_one')}
					</p>
					<p className='est-profile-publication-edit-modal-description'>
						{t('profile.user_publication.edit_publication.modal_subtitle_two')}
					</p>
				</div>

				{!isDetailError ? (
					<>
						{isDetail && isCategories ? (
							<>
								<Form
									onFinish={handleEditPublication}
									form={edit_form}
									/* 			initialValues={{
											updIdCategory: isDetail.id_category,
											updIdCategory2: isDetail.id_category2,
										}} */
								>
									<Tabs defaultActiveKey='1' onChange={handleChangeTabs}>
										<TabPane
											tab={t(
												'profile.user_publication.edit_publication.tabs.data_title'
											)}
											key='1'
											forceRender={true}>
											<Row>
												<Col
													className='est-publication-edit-info-container'
													xs={24}
													sm={24}
													md={12}
													lg={12}
													xl={12}>
													<h4 className='est-publication-edit-info-title'>
														{t(
															'profile.user_publication.edit_publication.tabs.input_name'
														)}
													</h4>
													<InputCustom
														className={'est-publication-edit-info-field-input'}
														inputName={'updTitle'}
														inputNameLabel={t(
															'profile.user_publication.edit_publication.tabs.placeholder_name'
														)}
														inputNameRule={true}
														inputNameMessage={' '}
														inputNameType={'text'}
														inputNameIcon={''}
														inputNameRules={t(
															'field_notifications.normal.rules_required'
														)}
													/>
												</Col>
												<Col
													className='est-publication-edit-info-container'
													xs={24}
													sm={24}
													md={12}
													lg={12}
													xl={12}>
													<h4 className='est-publication-edit-info-title'>
														{t(
															'profile.user_publication.edit_publication.tabs.input_price'
														)}
													</h4>
													<InputCustom
														className={'est-publication-edit-info-field-input'}
														inputName={'updPrice'}
														inputNameLabel={t(
															'profile.user_publication.edit_publication.tabs.placeholder_price'
														)}
														inputNameRule={true}
														inputNameMessage={' '}
														inputNameType={'number'}
														inputNameIcon={''}
														inputNameRules={t(
															'field_notifications.normal.rules_required'
														)}
													/>
												</Col>
												{/* 	<Col
													className='est-publication-edit-info-container'
													xs={24}
													sm={24}
													md={12}
													lg={12}
													xl={12}>
													<h4 className='est-publication-edit-info-title'>
														{t(
															'profile.user_publication.edit_publication.tabs.input_offer_price'
														)}
													</h4>
													<InputCustom
														disabled={isDisabledOffer}
														className={'est-publication-edit-info-field-input'}
														inputName={'updPriceOffer'}
														inputNameLabel={t(
															'profile.user_publication.edit_publication.tabs.placeholder_price'
														)}
														inputNameRule={true}
														inputNameMessage={' '}
														inputNameType={'number'}
														inputNameIcon={''}
														inputNameRules={t(
															'field_notifications.normal.rules_required'
														)}
													/>
												</Col> */}
												{/* <Col
													className='est-publication-edit-info-container'
													xs={24}
													sm={24}
													md={12}
													lg={12}
													xl={12}>
													<h4 className='est-publication-edit-info-title'>
														{t(
															'profile.user_publication.edit_publication.tabs.input_discount'
														)}
													</h4>
													<Form.Item name='updOffer' valuePropName='checked'>
														<Switch onClick={(data) => handleDiscountPrice(data)} />
													</Form.Item>
												</Col> */}
												<Col
													className='est-publication-edit-info-container'
													xs={24}
													sm={24}
													md={24}
													lg={24}
													xl={24}>
													<h4 className='est-publication-edit-info-title'>
														{t(
															'profile.user_publication.edit_publication.tabs.input_description'
														)}
													</h4>
													<Form.Item
														name={'updDescription'}
														rules={
															rulesValidation[
																t('field_notifications.normal.rules_required')
															]
														}>
														<TextArea
															className='est-publication-edit-info-field-input'
															size='large'
															type={'text'}
															placeholder={t(
																'profile.user_publication.edit_publication.tabs.placeholder_description'
															)}
															rows={4}
														/>
													</Form.Item>
												</Col>
												<Col
													className='est-publication-edit-info-container'
													xs={24}
													sm={24}
													md={8}
													lg={8}
													xl={8}>
													<h4 className='est-publication-edit-info-title'>
														{t(
															'profile.user_publication.edit_publication.tabs.input_people'
														)}
													</h4>
													<InputCustom
														className={'est-publication-edit-info-field-input'}
														inputName={'updPeople'}
														inputNameLabel={t(
															'profile.user_publication.edit_publication.tabs.placeholder_people'
														)}
														inputNameRule={true}
														inputNameMessage={' '}
														inputNameType={'number'}
														inputNameIcon={''}
														inputNameRules={t(
															'field_notifications.normal.rules_people'
														)}
													/>
												</Col>
												<Col
													className='est-publication-edit-info-container'
													xs={24}
													sm={24}
													md={8}
													lg={8}
													xl={8}>
													<h4 className='est-publication-edit-info-title'>
														{t(
															'profile.user_publication.edit_publication.tabs.input_category'
														)}
													</h4>
													<Form.Item
														name='updIdCategory'
														valuePropName='value'
														rules={[
															{
																required: true,
																message: t(
																	'publication.create.basic_info.category_message'
																),
															},
														]}>
														<Checkbox.Group options={isCategories.categories} />
													</Form.Item>
												</Col>
												<Col
													className='est-publication-edit-info-container'
													xs={24}
													sm={24}
													md={8}
													lg={8}
													xl={8}>
													<h4 className='est-publication-edit-info-title'>
														{t(
															'profile.user_publication.edit_publication.tabs.input_type_view'
														)}
													</h4>
													<Form.Item
														name='updIdCategory2'
														valuePropName='value'
														rules={[
															{
																required: true,
																message: t(
																	'publication.create.basic_info.type_view_message'
																),
															},
														]}>
														<Checkbox.Group options={isCategories.type_view} />
													</Form.Item>
												</Col>
												{/* 	<Col
													className='est-publication-edit-info-container'
													xs={24}
													sm={24}
													md={8}
													lg={8}
													xl={8}>
													<h4 className='est-publication-edit-info-title'>
														{t(
															'profile.user_publication.edit_publication.tabs.radio_reserv'
														)}
													</h4>
													<Form.Item
														name={'updDiscountAmount'}
														rules={rulesValidation.rulesRequired}
														className='est-publication-edit-info-value-container'>
														<Radio.Group>
															<Radio value={100}>100%</Radio>
															<Radio value={50}>50%</Radio>
															<Radio value={25}>25%</Radio>
														</Radio.Group>
													</Form.Item>
												</Col> */}
											</Row>
										</TabPane>
										<TabPane
											tab={t(
												'profile.user_publication.edit_publication.tabs.address_title'
											)}
											key='2'
											forceRender={true}>
											<Row>
												<Col
													className='est-publication-edit-info-container'
													xs={24}
													sm={24}
													md={12}
													lg={12}
													xl={12}>
													<div className='est-publication-edit-info-address-container'>
														<h4 className='est-publication-edit-info-title'>
															{t(
																'profile.user_publication.edit_publication.tabs.input_address'
															)}
														</h4>
														<Address />
													</div>

													<Form.Item
														name='updAddressDescription'
														rules={
															rulesValidation[
																t('field_notifications.normal.rules_required')
															]
														}>
														<TextArea
															className='est-publication-edit-info-field-input'
															size='large'
															type={'text'}
															placeholder={t(
																'profile.user_publication.edit_publication.tabs.placeholder_address'
															)}
															rows={4}
														/>
													</Form.Item>
													<h4 className='est-publication-edit-info-title'>
														{t(
															'profile.user_publication.edit_publication.tabs.input_phone'
														)}
													</h4>
													<Form.Item
														name={'updPhone'}
														rules={
															rulesValidationMask[
																t('field_notifications.normal.rules_mask_phone')
															]
														}>
														<InputMask
															maskstyle={'est-ads-location-input-main-phone'}
															mask='+(1) 999 999 9999'
															placeholder='+(1) 999 999 9999'
														/>
													</Form.Item>
												</Col>
												<Col
													className='est-publication-edit-info-container'
													xs={24}
													sm={24}
													md={12}
													lg={12}
													xl={12}>
													{isAddress && (
														<div className='est-publication-edit-info-address-container'>
															<h4 className='est-publication-edit-info-address-title'>
																{t(
																	'profile.user_publication.edit_publication.tabs.input_current_address'
																)}
															</h4>
															<h5 className='est-publication-edit-info-address-subtitle'>
																{isAddress.complete_address}
															</h5>
															<MapLeaft
																center={isAddress.geo_location}
																zoom={12}
																title={'User Current'}
																description={'Address.'}
															/>
														</div>
													)}
												</Col>
											</Row>
										</TabPane>
										<TabPane
											tab={t('profile.user_publication.edit_publication.tabs.img_title')}
											key='3'
											forceRender={true}>
											<Row className='est-publication-edit-info-main-container'>
												<Col
													className='est-publication-edit-info-container'
													xs={24}
													sm={24}
													md={12}
													lg={12}
													xl={12}>
													{isDetail.picture.picture_url ? (
														<>
															<h4 className='est-publication-edit-img-info-title'>
																{t(
																	'profile.user_publication.edit_publication.tabs.img_title_one'
																)}
															</h4>
															<div className='est-publication-edit-img-container'>
																<Image
																	classImg={'est-publication-edit-info-img'}
																	image={isDetail.picture.picture_url.file}
																	type={'Principal'}
																	title={'Principal'}
																/>
															</div>
															<Button
																onClick={() =>
																	handleDeletePublicationImage('principal')
																}
																className='est-user-publications-edit-image-button'>
																{t(
																	'profile.user_publication.edit_publication.tabs.img_delete_button'
																)}
															</Button>
															<h4 className='est-user-publications-edit-title'>
																<span className='est-user-publications-edit-subtitle'>
																	*
																</span>
																{t(
																	'profile.user_publication.edit_publication.tabs.img_subtitle_one'
																)}
															</h4>
														</>
													) : (
														<>
															<h4 className='est-publication-edit-img-info-title'>
																{t(
																	'profile.user_publication.edit_publication.tabs.img_title_two'
																)}
															</h4>
															<div className='est-publication-edit-img-container'>
																<UploadImage
																	disabled={true}
																	type={'principal'}
																	title={'Principal'}
																	addItemImage={(item, data) =>
																		handleAddProductImage(item, data)
																	}
																	imageDelete={(item) => handleDeteleImage(item)}
																/>
															</div>
														</>
													)}
												</Col>
												{!isDisabledOffer && (
													<Col
														className='est-publication-edit-info-container'
														xs={24}
														sm={24}
														md={12}
														lg={12}
														xl={12}>
														{isDetail.picture.picture_url_offer ? (
															<>
																<h4 className='est-publication-edit-img-info-title'>
																	{t(
																		'profile.user_publication.edit_publication.tabs.img_offer_title_one'
																	)}
																</h4>
																<div className='est-publication-edit-img-container'>
																	<Image
																		classImg={
																			'est-publication-edit-info-offer-img'
																		}
																		image={
																			isDetail.picture.picture_url_offer.file
																		}
																		type={'Offer photo'}
																		title={'Offer photo'}
																	/>
																</div>
																<Button
																	onClick={() =>
																		handleDeletePublicationImage('ofert')
																	}
																	className='est-user-publications-edit-image-button'>
																	{t(
																		'profile.user_publication.edit_publication.tabs.img_delete_button'
																	)}
																</Button>
																<h4 className='est-user-publications-edit-title'>
																	<span className='est-user-publications-edit-subtitle'>
																		*
																	</span>
																	{t(
																		'profile.user_publication.edit_publication.tabs.img_subtitle_one'
																	)}
																</h4>
															</>
														) : (
															<>
																<h4 className='est-publication-edit-img-info-title'>
																	{t(
																		'profile.user_publication.edit_publication.tabs.img_title_two'
																	)}
																</h4>
																<div className='est-publication-edit-img-container'>
																	<UploadImage
																		disabled={true}
																		type={'ofert'}
																		title={'Oferta'}
																		addItemImage={(item, data) =>
																			handleAddProductImage(item, data)
																		}
																		imageDelete={(item) =>
																			handleDeteleImage(item)
																		}
																	/>
																</div>
															</>
														)}
													</Col>
												)}
												<Col className='est-publication-edit-info-container' span={24}>
													<h4 className='est-publication-edit-img-info-title'>
														{t(
															'profile.user_publication.edit_publication.tabs.img_offer_title_three'
														)}
													</h4>
													<h4 className='est-user-publications-edit-title'>
														<span className='est-user-publications-edit-subtitle'>
															*
														</span>
														{t(
															'profile.user_publication.edit_publication.tabs.img_offer_subtitle_one'
														)}
													</h4>
													<div>
														{isGalleryArrayImage && (
															<>
																<Row className='est-user-publications-edit-gallery-image-container'>
																	{isGalleryArrayImage.length > 0 ? (
																		<>
																			{isGalleryArrayImage.map((item, index) => (
																				<Col
																					xs={24}
																					sm={12}
																					md={12}
																					lg={8}
																					xl={8}
																					className='est-user-publications-edit-gallery-image-inner-container'
																					key={index}>
																					<div className='est-publication-edit-img-gallery-container'>
																						<Image
																							classImg={
																								'est-publication-edit-img-gallery-image'
																							}
																							image={item.image}
																							alt={'gallery item'}
																							title={'gallery item'}
																						/>
																					</div>
																					<div className='est-user-publications-edit-gallery-button-container'>
																						<Button
																							className='est-user-publications-edit-gallery-button'
																							onClick={() =>
																								handlePreviewGalleryImage(
																									item.image
																								)
																							}>
																							<EyeFilled />
																						</Button>
																						<Button
																							className='est-user-publications-edit-gallery-button'
																							onClick={() =>
																								handleDeleteGalleryImage(
																									item
																								)
																							}>
																							<DeleteFilled />
																						</Button>
																					</div>
																				</Col>
																			))}
																		</>
																	) : (
																		<h3 className='est-user-publications-edit-gallery-title'>
																			{t(
																				'profile.user_publication.edit_publication.tabs.img_offer_title_four'
																			)}
																		</h3>
																	)}
																</Row>
																<div className='est-publication-edit-galery-img-container'>
																	{isGalleryArrayImage.length < 5 && (
																		<UploadGalleryImage />
																	)}
																</div>
															</>
														)}
													</div>
												</Col>
											</Row>
										</TabPane>
										<TabPane
											tab={t(
												'profile.user_publication.edit_publication.tabs.amenities_title'
											)}
											key='4'
											forceRender={true}>
											<Row className='est-edit-amenities-main-container'>
												<Col span={12}>
													<div className='est-edit-amenities-checkbox-container'>
														<h4 className='est-amenities-main-title'>
															{t(
																'profile.user_publication.edit_publication.tabs.title_services'
															)}
														</h4>
														<Row>
															<Col xs={24} sm={24} md={12} lg={12} xl={12}>
																<Form.Item name='agua' valuePropName='checked'>
																	<Checkbox>
																		{t(
																			'profile.user_publication.edit_publication.tabs.checkbox_services_water'
																		)}
																	</Checkbox>
																</Form.Item>
															</Col>
															<Col xs={24} sm={24} md={12} lg={12} xl={12}>
																<Form.Item name='luz' valuePropName='checked'>
																	<Checkbox>
																		{t(
																			'profile.user_publication.edit_publication.tabs.checkbox_services_light'
																		)}
																	</Checkbox>
																</Form.Item>
															</Col>
															<Col xs={24} sm={24} md={12} lg={12} xl={12}>
																<Form.Item name='tocador' valuePropName='checked'>
																	<Checkbox>
																		{t(
																			'profile.user_publication.edit_publication.tabs.checkbox_services_toilet'
																		)}
																	</Checkbox>
																</Form.Item>
															</Col>
															<Col xs={24} sm={24} md={12} lg={12} xl={12}>
																<Form.Item name='cocinas' valuePropName='checked'>
																	<Checkbox>
																		{t(
																			'profile.user_publication.edit_publication.tabs.checkbox_services_kitchen'
																		)}
																	</Checkbox>
																</Form.Item>
															</Col>
															<Col xs={24} sm={24} md={12} lg={12} xl={12}>
																<Form.Item name='bbq' valuePropName='checked'>
																	<Checkbox>BBQ</Checkbox>
																</Form.Item>
															</Col>
															<Col xs={24} sm={24} md={12} lg={12} xl={12}>
																<Form.Item name='fogata' valuePropName='checked'>
																	<Checkbox>
																		{t(
																			'profile.user_publication.edit_publication.tabs.checkbox_services_campfire'
																		)}
																	</Checkbox>
																</Form.Item>
															</Col>
														</Row>
													</div>
												</Col>
												<Col span={12}>
													<div className='est-edit-amenities-checkbox-container'>
														<h4 className='est-amenities-main-title'>
															{t(
																'profile.user_publication.edit_publication.tabs.title_site'
															)}
														</h4>
														<Row>
															<Col xs={24} sm={24} md={12} lg={12} xl={12}>
																<Form.Item name='historico' valuePropName='checked'>
																	<Checkbox>
																		{t(
																			'profile.user_publication.edit_publication.tabs.checkbox_site_history'
																		)}
																	</Checkbox>
																</Form.Item>
															</Col>
															<Col xs={24} sm={24} md={12} lg={12} xl={12}>
																<Form.Item name='ecologia' valuePropName='checked'>
																	<Checkbox>
																		{t(
																			'profile.user_publication.edit_publication.tabs.checkbox_site_reserve'
																		)}
																	</Checkbox>
																</Form.Item>
															</Col>
															<Col xs={24} sm={24} md={12} lg={12} xl={12}>
																<Form.Item name='agricola' valuePropName='checked'>
																	<Checkbox>
																		{t(
																			'profile.user_publication.edit_publication.tabs.checkbox_site_farming'
																		)}
																	</Checkbox>
																</Form.Item>
															</Col>
															<Col xs={24} sm={24} md={12} lg={12} xl={12}>
																<Form.Item
																	name='reactivo_pasivo'
																	valuePropName='checked'>
																	<Checkbox>
																		{t(
																			'profile.user_publication.edit_publication.tabs.checkbox_site_recess_pas'
																		)}
																	</Checkbox>
																</Form.Item>
															</Col>
															<Col xs={24} sm={24} md={12} lg={12} xl={12}>
																<Form.Item
																	name='reactivo_activo'
																	valuePropName='checked'>
																	<Checkbox>
																		{t(
																			'profile.user_publication.edit_publication.tabs.checkbox_site_recess_act'
																		)}
																	</Checkbox>
																</Form.Item>
															</Col>
														</Row>
													</div>
												</Col>
												<Col span={12}>
													<div className='est-edit-amenities-checkbox-container'>
														<h4 className='est-amenities-main-title'>
															{t(
																'profile.user_publication.edit_publication.tabs.title_activity'
															)}
														</h4>
														<Row>
															<Col xs={24} sm={24} md={12} lg={12} xl={12}>
																<Form.Item
																	name='recreacion_acuaticas'
																	valuePropName='checked'>
																	<Checkbox>
																		{t(
																			'profile.user_publication.edit_publication.tabs.checkbox_activity_aqua'
																		)}
																	</Checkbox>
																</Form.Item>
															</Col>
															<Col xs={24} sm={24} md={12} lg={12} xl={12}>
																<Form.Item
																	name='recreacion_alpinismo'
																	valuePropName='checked'>
																	<Checkbox>
																		{t(
																			'profile.user_publication.edit_publication.tabs.checkbox_activity_climb'
																		)}
																	</Checkbox>
																</Form.Item>
															</Col>
															<Col xs={24} sm={24} md={12} lg={12} xl={12}>
																<Form.Item
																	name='recreacion_areas'
																	valuePropName='checked'>
																	<Checkbox>
																		{t(
																			'profile.user_publication.edit_publication.tabs.checkbox_activity_meeting'
																		)}
																	</Checkbox>
																</Form.Item>
															</Col>
															<Col xs={24} sm={24} md={12} lg={12} xl={12}>
																<Form.Item
																	name='recreacion_animales'
																	valuePropName='checked'>
																	<Checkbox>
																		{t(
																			'profile.user_publication.edit_publication.tabs.checkbox_activity_endemic'
																		)}
																	</Checkbox>
																</Form.Item>
															</Col>
															<Col xs={24} sm={24} md={12} lg={12} xl={12}>
																<Form.Item
																	name='recreacion_pajaros'
																	valuePropName='checked'>
																	<Checkbox>
																		{t(
																			'profile.user_publication.edit_publication.tabs.checkbox_activity_birds'
																		)}
																	</Checkbox>
																</Form.Item>
															</Col>
															<Col xs={24} sm={24} md={12} lg={12} xl={12}>
																<Form.Item
																	name='recreacion_cocina'
																	valuePropName='checked'>
																	<Checkbox>
																		{t(
																			'profile.user_publication.edit_publication.tabs.checkbox_activity_cuisine'
																		)}
																	</Checkbox>
																</Form.Item>
															</Col>
															<Col xs={24} sm={24} md={12} lg={12} xl={12}>
																<Form.Item
																	name='recreacion_espeleologia'
																	valuePropName='checked'>
																	<Checkbox>
																		{t(
																			'profile.user_publication.edit_publication.tabs.checkbox_activity_potho'
																		)}
																	</Checkbox>
																</Form.Item>
															</Col>
															<Col xs={24} sm={24} md={12} lg={12} xl={12}>
																<Form.Item
																	name='recreacion_kayac_paddle_balsas'
																	valuePropName='checked'>
																	<Checkbox>
																		{t(
																			'profile.user_publication.edit_publication.tabs.checkbox_activity_kayak'
																		)}
																	</Checkbox>
																</Form.Item>
															</Col>
															<Col xs={24} sm={24} md={12} lg={12} xl={12}>
																<Form.Item
																	name='paracaidas'
																	valuePropName='checked'>
																	<Checkbox>
																		{t(
																			'profile.user_publication.edit_publication.tabs.checkbox_activity_para'
																		)}
																	</Checkbox>
																</Form.Item>
															</Col>
															<Col xs={24} sm={24} md={12} lg={12} xl={12}>
																<Form.Item
																	name='recreacion_piscinas'
																	valuePropName='checked'>
																	<Checkbox>
																		{t(
																			'profile.user_publication.edit_publication.tabs.checkbox_activity_pool'
																		)}
																	</Checkbox>
																</Form.Item>
															</Col>
															<Col xs={24} sm={24} md={12} lg={12} xl={12}>
																<Form.Item
																	name='recreacion_veredas'
																	valuePropName='checked'>
																	<Checkbox>
																		{t(
																			'profile.user_publication.edit_publication.tabs.checkbox_activity_trails'
																		)}
																	</Checkbox>
																</Form.Item>
															</Col>
															<Col xs={24} sm={24} md={12} lg={12} xl={12}>
																<Form.Item
																	name='recreacion_zipline'
																	valuePropName='checked'>
																	<Checkbox>Zip-line</Checkbox>
																</Form.Item>
															</Col>
														</Row>
													</div>
												</Col>
												<Col span={12}>
													<div className='est-edit-amenities-checkbox-container'>
														<h4 className='est-amenities-main-title'>
															{t(
																'profile.user_publication.edit_publication.tabs.title_equipment'
															)}
														</h4>
														<Row>
															<Col xs={24} sm={24} md={12} lg={12} xl={12}>
																<Form.Item
																	name='equipos_mesas'
																	valuePropName='checked'>
																	<Checkbox>
																		{t(
																			'profile.user_publication.edit_publication.tabs.checkbox_equipment_table'
																		)}
																	</Checkbox>
																</Form.Item>
															</Col>
															<Col xs={24} sm={24} md={12} lg={12} xl={12}>
																<Form.Item
																	name='equipos_sillas'
																	valuePropName='checked'>
																	<Checkbox>
																		{t(
																			'profile.user_publication.edit_publication.tabs.checkbox_equipment_chair'
																		)}
																	</Checkbox>
																</Form.Item>
															</Col>
															<Col xs={24} sm={24} md={12} lg={12} xl={12}>
																<Form.Item
																	name='equipos_estufas'
																	valuePropName='checked'>
																	<Checkbox>
																		{t(
																			'profile.user_publication.edit_publication.tabs.checkbox_equipment_stove'
																		)}
																	</Checkbox>
																</Form.Item>
															</Col>
															<Col xs={24} sm={24} md={12} lg={12} xl={12}>
																<Form.Item
																	name='casetas_acampar'
																	valuePropName='checked'>
																	<Checkbox>
																		{t(
																			'profile.user_publication.edit_publication.tabs.checkbox_equipment_camping'
																		)}
																	</Checkbox>
																</Form.Item>
															</Col>
															<Col xs={24} sm={24} md={12} lg={12} xl={12}>
																<Form.Item name='toldos' valuePropName='checked'>
																	<Checkbox>
																		{t(
																			'profile.user_publication.edit_publication.tabs.checkbox_equipment_awning'
																		)}
																	</Checkbox>
																</Form.Item>
															</Col>
															<Col xs={24} sm={24} md={12} lg={12} xl={12}>
																<Form.Item
																	name='estufas_gas'
																	valuePropName='checked'>
																	<Checkbox>
																		{t(
																			'profile.user_publication.edit_publication.tabs.checkbox_equipment_gas_stoves'
																		)}
																	</Checkbox>
																</Form.Item>
															</Col>
															<Col xs={24} sm={24} md={12} lg={12} xl={12}>
																<Form.Item
																	name='tanques_gas'
																	valuePropName='checked'>
																	<Checkbox>
																		{t(
																			'profile.user_publication.edit_publication.tabs.checkbox_equipment_gas_tank'
																		)}
																	</Checkbox>
																</Form.Item>
															</Col>
															<Col xs={24} sm={24} md={12} lg={12} xl={12}>
																<Form.Item name='lena' valuePropName='checked'>
																	<Checkbox>
																		{t(
																			'profile.user_publication.edit_publication.tabs.checkbox_equipment_firewood'
																		)}
																	</Checkbox>
																</Form.Item>
															</Col>
															<Col xs={24} sm={24} md={12} lg={12} xl={12}>
																<Form.Item name='carbon' valuePropName='checked'>
																	<Checkbox>
																		{t(
																			'profile.user_publication.edit_publication.tabs.checkbox_equipment_coal'
																		)}
																	</Checkbox>
																</Form.Item>
															</Col>
														</Row>
													</div>
												</Col>
												<Col span={12}>
													<div className='est-edit-amenities-checkbox-container'>
														<h4 className='est-amenities-main-title'>
															{t(
																'profile.user_publication.edit_publication.tabs.title_others'
															)}
														</h4>
														<Row>
															<Col xs={24} sm={24} md={12} lg={12} xl={12}>
																<Form.Item
																	name='se_admiten_mascotas'
																	valuePropName='checked'>
																	<Checkbox>
																		{t(
																			'profile.user_publication.edit_publication.tabs.checkbox_others_pets'
																		)}
																	</Checkbox>
																</Form.Item>
															</Col>
															<Col xs={24} sm={24} md={12} lg={12} xl={12}>
																<Form.Item
																	name='perros_servicios'
																	valuePropName='checked'>
																	<Checkbox>
																		{t(
																			'profile.user_publication.edit_publication.tabs.checkbox_others_service'
																		)}
																	</Checkbox>
																</Form.Item>
															</Col>
														</Row>
													</div>
												</Col>
											</Row>
										</TabPane>
									</Tabs>
									<Col span={24}>
										<div className='est-profile-publication-edit-modal-button-container'>
											<Button
												className='est-profile-publication-edit-modal-button'
												htmlType={'submit'}
												loading={isLoadingEditPublication}>
												{t(
													'profile.user_publication.edit_publication.tabs.accept_button'
												)}
											</Button>
										</div>
									</Col>
								</Form>
							</>
						) : (
							<LoadingData />
						)}
					</>
				) : (
					<h3>{t('profile.user_publication.edit_publication.tabs.error_message')}</h3>
				)}
			</Modal>
			<Modal
				width={768}
				wrapClassName='est-profile-publication-edit-modal-photo-container'
				centered
				title={t('profile.user_publication.edit_publication.tabs.modal_preview_title')}
				visible={isVisibleGalleryImage}
				onCancel={() => setVisibleGalleryImage(false)}
				cancelButtonProps={{ style: { display: 'none' } }}
				okButtonProps={{ style: { display: 'none' } }}
				okText=''
				cancelText=''
				footer={false}>
				{isGalleryImage && (
					<Image
						classImg={'est-profile-publication-edit-modal-photo'}
						image={isGalleryImage}
						alt={'Preview'}
						title={'Preview'}
					/>
				)}
			</Modal>
		</>
	)
}
