/** @format */

import React from 'react'

import { setGlobal } from 'reactn'

import Autocomplete from 'react-google-autocomplete'
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete'

import { ENV_API_GOOGLE } from '../Hooks/Variables/Enviroment'

import './style.css'

export default function Address() {
	const handleSelect = async (item) => {
		let location = {}
		item.address_components.forEach((data) => {
			data.types.forEach((element) => {
				if (element === 'country') {
					location.country = data.long_name
					location.country_code = data.short_name
				}
				if (element === 'route') {
					location.county = data.short_name
				}
				if (element === 'street_number') {
					location.street_number = data.long_name
				}
				if (element === 'postal_code') {
					location.postal_code = data.long_name
				}
				if (element === 'locality') {
					location.city = data.long_name
				}
				if (element === 'sublocality') {
					location.municipality = data.long_name
				}
				if (element === 'sublocality_level_1') {
					location.state = data.long_name
				}
				if (element === 'administrative_area_level_1') {
					location.administrative_area_level_1 = data.long_name
				}
			})
		})
		if (!location.country) {
			location.country = ''
			location.country_code = ''
		}
		if (!location.county) {
			location.county = ''
		}
		if (!location.street_number) {
			location.street_number = ''
		}
		if (!location.postal_code) {
			location.postal_code = ''
		}
		if (!location.city) {
			location.city = ''
		}
		if (!location.municipality) {
			location.municipality = ''
		}
		if (!location.state) {
			location.state = ''
		}
		if (!location.administrative_area_level_1) {
			location.administrative_area_level_1 = ''
		}
		geocodeByAddress(item.formatted_address)
			.then((results) => getLatLng(results[0]))
			.then((latLng) => {
				location.geo_location = { lat: latLng.lat, lng: latLng.lng }
				location.complete_address = item.formatted_address
				setGlobal({
					SelectedAddress: location,
				})
			})
			.catch((error) => console.error('Error', error))
	}
	return (
		<Autocomplete
			className='est-ads-location-address-input'
			apiKey={ENV_API_GOOGLE}
			style={{ width: '100%' }}
			onPlaceSelected={(item) => handleSelect(item)}
			options={{
				types: ['address'],
				componentRestrictions: { country: 'pr' },
			}}
		/>
	)
}
