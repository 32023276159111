/** @format */

import React, { useState } from 'react'
import AwesomeSwiper from 'react-awesome-swiper'

import Image from '../Image'

import { ENV_CORE } from '../Hooks/Variables/Enviroment'

import './style.css'

export default function Carouselbanners(props) {
	const config = {
		spaceBetween: 0,
		loop: true,
		autoplay: {
			delay: 3000,
			stopOnLastSlide: false,
			disableOnInteraction: false,
		},
		effect: 'fade',
		preloadImages: false,
		lazy: true,
		speed: 500,
		navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev',
		},
		breakpoints: {
			1024: {
				slidesPerView: props.carouselItems[0],
				spaceBetween: 0,
			},
			768: {
				slidesPerView: props.carouselItems[1],
				spaceBetween: 10,
			},
			640: {
				slidesPerView: props.carouselItems[2],
				spaceBetween: 0,
			},
			320: {
				slidesPerView: props.carouselItems[3],
				spaceBetween: 0,
			},
			250: {
				slidesPerView: props.carouselItems[4],
				spaceBetween: 0,
			},
		},
	}

	const [isConfig] = useState(config)

	return (
		<AwesomeSwiper config={isConfig}>
			<div className='swiper-wrapper'>
				{props.banners.map((item, i) => (
					<div className='swiper-slide' key={i}>
						<Image
							classImg={'est-banner-image'}
							image={`${props.slug ? `${ENV_CORE}/${item.image}` : `${item.image}`}`}
							alt={item.tipo}
							title={item.tipo}
						/>
					</div>
				))}
			</div>
			<div
				className={`${
					props.opacity && 'est-arrow-display-none'
				} swiper-button-prev ph-carousel-prev`}></div>
			<div
				className={`${
					props.opacity && 'est-arrow-display-none'
				} swiper-button-next ph-carousel-next`}></div>
		</AwesomeSwiper>
	)
}
