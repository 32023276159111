/** @format */

import React from 'react'

import { useTranslation } from 'react-i18next'

import { MailOutlined } from '@ant-design/icons'

import MainNavigation from '../../components/Common/MainNavigation'

import './style.css'

export default function RegisterVerify() {
	const { t } = useTranslation()
	return (
		<>
			<MainNavigation
				title={t('register_verify.main_navigation.title')}
				linkPrevTitle={'Home'}
				linkNextTitle={t('register_verify.main_navigation.title')}
				linkPrev={'/'}
				linkNext={'/register_verify'}
			/>
			<div className='est-register-verify-global-container'>
				<div className='est-register-verify-main-container'>
					<h3 className='est-register-verify-icon'>
						<MailOutlined />
					</h3>
					<h3 className='est-register-verify-title'>{t('register_verify.title')}</h3>
					<h3 className='est-register-verify-subtitle'>{t('register_verify.subtitle')}</h3>
				</div>
			</div>
		</>
	)
}
