/** @format */

import React, { useState } from 'react'

import { useTranslation } from 'react-i18next'

import { setGlobal } from 'reactn'

import { useLocation, Link } from 'react-router-dom'

import ModalUser from '../../../Header/components/ModalUser'
import ModalLogOut from '../../../Header/components/ModalLogOut'

import Menu from 'antd/lib/menu'

import './style.css'

export default function DropMenu(props) {
	const { t } = useTranslation()
	const [isVisible, setVisible] = useState(false)
	let location = useLocation()
	const handleModalUser = (item) => {
		setGlobal({
			ModalUser: true,
		})
		props.CloseMenu(false)
		if (item === 'login') {
			setGlobal({
				UserLogin: true,
				UserRegister: false,
				UserRecover: false,
			})
		} else if (item === 'register') {
			setGlobal({
				UserLogin: false,
				UserRegister: true,
				UserRecover: false,
			})
		} else if (item === 'recover') {
			setGlobal({
				UserLogin: false,
				UserRegister: false,
				UserRecover: true,
			})
		}
	}
	const handleModalLogOut = (item) => {
		props.CloseMenu(false)
		setVisible(item)
	}

	return (
		<>
			<Menu
				className={`${
					location.pathname === '/'
						? 'est-dropdown-menu-container'
						: 'est-dropdown-menu-container-estandar'
				} est-dropdown-menu-main-container`}>
				{props.email ? (
					<>
						<Menu.Item onClick={() => props.CloseMenu(false)}>
							<Link to='/profile/user-data'>{t('header.drop_menu.profile')}</Link>
						</Menu.Item>
						{/* 						{props.modeLogin.modo === 'directo' && ( */}
						<Menu.Item onClick={() => props.CloseMenu(false)}>
							<Link to='/publication/create/basic-info'>{t('header.drop_menu.post')}</Link>
						</Menu.Item>
						{/* 		)} */}
						<Menu.Item onClick={() => handleModalLogOut(true)}>
							<span>{t('header.drop_menu.logout')}</span>
						</Menu.Item>
					</>
				) : (
					<>
						<Menu.Item onClick={() => handleModalUser('login')}>
							<span>{t('header.drop_menu.login')}</span>
						</Menu.Item>
						<Menu.Item onClick={() => handleModalUser('register')}>
							<span>{t('header.drop_menu.register')}</span>
						</Menu.Item>
						<Menu.Item onClick={() => handleModalUser('recover')}>
							<span>{t('header.drop_menu.password')}</span>
						</Menu.Item>
					</>
				)}
			</Menu>
			<ModalLogOut showModalLogOut={handleModalLogOut} visible={isVisible} />
			<ModalUser />
		</>
	)
}
