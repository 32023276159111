/** @format */

import React, { useEffect } from 'react'

import { useTranslation } from 'react-i18next'

import { Link } from 'react-router-dom'

import MainNavigation from '../../../../../components/Common/MainNavigation'

import Row from 'antd/lib/row'
import Col from 'antd/lib/col'

import { CloseCircleOutlined } from '@ant-design/icons'

import Spacer from '../../../../../components/Common/Spacer'

import './style.css'

export default function ErrorAd() {
	const { t } = useTranslation()
	useEffect(() => {
		localStorage.removeItem('info')
		localStorage.removeItem('info-location')
		localStorage.removeItem('info-photos')
		localStorage.removeItem('success')
	}, [])
	return (
		<>
			<MainNavigation
				title={t('publication.create.error.main_navigation.title')}
				linkPrevTitle={'Home'}
				linkNextTitle={t('publication.create.error.main_navigation.title')}
				linkPrev={'/'}
				linkNext={'/publication/create/error'}
			/>
			<div className='est-ads-error-global-container'>
				<div className='est-ads-error-main-container'>
					<div className='est-ads-error-text-global-container'>
						<h2 className='est-ads-error-text-icon'>
							<CloseCircleOutlined />
						</h2>
						<h2 className='est-ads-error-text-title'>
							{t('publication.create.error.title')}
						</h2>
						<h3 className='est-ads-error-text-subtitle'>
							{t('publication.create.error.subtitle_one')}
						</h3>
						<h3 className='est-ads-error-text-subtitle'>
							{t('publication.create.error.subtitle_two')}
						</h3>
					</div>

					<Row>
						<Col span={24} className='est-ads-error-button-container '>
							<Link className='est-ads-error-button' to='/publication/create/basic-info'>
								{t('publication.create.error.retry_button')}
							</Link>
							<Spacer />
							<Link className='est-ads-error-button' to='/'>
								{t('publication.create.error.contact_button')}
							</Link>
							<Spacer />
							<Link className='est-ads-error-button' to='/'>
								{t('publication.create.error.home_button')}
							</Link>
						</Col>
					</Row>
				</div>
			</div>
		</>
	)
}
