/** @format */

import React, { useState, useEffect } from 'react'

import { useTranslation } from 'react-i18next'

import { Link } from 'react-router-dom'

import MainNavigation from '../../../../components/Common/MainNavigation'

import { setGlobal } from 'reactn'

import Form from 'antd/lib/form'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import Button from 'antd/lib/button'
import Select from 'antd/lib/select'
import Spin from 'antd/lib/spin'
import Modal from 'antd/lib/modal'

import { LoadingOutlined } from '@ant-design/icons'

import HeadDescription from '../../../../components/Common/HeadDescription'
import Loading from '../../../../components/Common/Loading'
import Image from '../../../../components/Common/Image'
import InputCustom from '../../../../components/Common/Inputs/Normal'
import InputMask from '../../../../components/Common/Inputs/InputMask'

import MainLogoImg from '../../../../img/footer-top/main-logo-colorfull.png'

import { rulesValidationMask } from '../../../../components/Common/Inputs/InputMask/rules'

import { CheckReservation } from '../../../../components/Common/Hooks/Functions/CheckReservation'

import servicesPayment from './services'

import './style.css'

export default function PaymentForm(props) {
	const { t } = useTranslation()
	const [date] = useState(new Date())
	const [isUserSession] = useState(JSON.parse(localStorage.getItem('userSession')))
	const [isValidData, setValidData] = useState(null)
	const [isPaymentResponse, setPaymentResponse] = useState(true)
	const [isButtonLoading, setButtonLoading] = useState(false)
	const [isVisible, setVisible] = useState(false)
	const [isPayment, setPayment] = useState(false)
	const [isModalConfirmation, setModalConfirmation] = useState(false)
	const [isConfirmation, setConfirmation] = useState(false)
	const [isConfirmationText, setConfirmationText] = useState(null)
	const { Option } = Select
	const [isFunctionAge] = useState(t('field_notifications.normal.rules_mask_age_date'))

	const handleProcessPayment = async (item) => {
		setVisible(true)
		setButtonLoading(true)
		let info = {
			credentials: {
				Company: 'acceptatest',
				Username: 'AshbelRoldanVT',
				Password: '123456Ab',
			},
			data: item,
		}
		info.data.CardPresent = 'Y'
		info.data.CustomerPresentIndicator = '0'
		info.data.TerminalCapabilities = '3'
		info.data.EntryMode = '1'

		const traduceOne = {
			service_error_description_one: t('profile.payment.service_error_description_one'),
			service_error_description_two: t('profile.payment.service_error_description_two'),
		}

		const traduceTwo = {
			service_warning_title: t('profile.payment.service_warning_title'),
			service_warning_description: t('profile.payment.service_warning_description'),
		}

		await servicesPayment.SendPayment(info, traduceOne).then((response) => {
			if (response) {
				setPayment(true)
				servicesPayment
					.ConfirmPayment(isValidData.id, response.ConfirmationNumber, traduceTwo)
					.then((responseConfirmation) => {
						setVisible(false)
						setButtonLoading(false)
						setModalConfirmation(true)
						if (responseConfirmation) {
							setConfirmation(true)
							setConfirmationText({
								idReservation: isValidData.id,
								confirmationNumber: response.ConfirmationNumber,
							})
						}
					})
			} else {
				setVisible(false)
				setButtonLoading(false)
			}
		})
	}

	useEffect(() => {
		CheckReservation(props.match.params.id).then((response) => {
			if (response) {
				if (response.pagado === '0') {
					if (!isUserSession) {
						let session = {
							nombre: response.nombre,
							apellido: response.apellido,
							id: response.id_user,
							modo: 'directo',
							email: response.email,
						}
						localStorage.setItem('userSession', JSON.stringify(session))
						setGlobal(() => ({
							userEmail: `${session.nombre} ${session.apellido}`,
							userData: session,
						}))
					}
					setValidData(response)
				} else {
					window.location.href = '/'
				}
			} else {
				setPaymentResponse(false)
			}
		})
	}, [props.match.params.id, isUserSession])
	if (isPaymentResponse) {
		if (!isValidData) {
			return <Loading />
		} else {
			return (
				<>
					<HeadDescription
						title={`${t('profile.payment.head_description.title')}`}
						name={'description'}
						content={'Camping Site'}
					/>
					<MainNavigation
						title={t('profile.payment.main_navigation.title')}
						linkPrevTitle={'Home'}
						linkNextTitle={t('profile.payment.main_navigation.subtitle')}
						linkPrev={'/'}
						linkNext={'/profile/user-reservations'}
					/>
					<div className='est-profile-payment-global-container'>
						<h3 className='est-profile-payment-global-title'>{t('profile.payment.title')}</h3>
						<Form
							name='reservation_payment'
							onFinish={handleProcessPayment}
							initialValues={{
								InvoiceNumber: isValidData.id,
								AmountOfTransaction: isValidData.total,
								AccountNumber: '4111111111111111',
							}}>
							<h3 className='est-profile-payment-main-title'>
								{t('profile.payment.form.title')}
							</h3>
							<Row>
								<Col
									xs={24}
									sm={12}
									md={12}
									lg={8}
									xl={8}
									className='est-profile-payment-info-container'>
									<h5 className='est-profile-payment-info-title'>
										{t('profile.payment.form.reservation_number')}
									</h5>
									<InputCustom
										className={''}
										inputName={'InvoiceNumber'}
										inputNameLabel={'Invoice Number'}
										inputNameMessage={'Campo obligatorio'}
										inputNameType={'text'}
										inputNameRule={false}
										inputNameIcon={''}
										inputNameRules={'rulesRequired'}
										disabled={true}
									/>
								</Col>
								<Col
									xs={24}
									sm={12}
									md={12}
									lg={8}
									xl={8}
									className='est-profile-payment-info-container'>
									<h5 className='est-profile-payment-info-title'>
										{t('profile.payment.form.amount')}
									</h5>
									<InputCustom
										className={''}
										inputName={'AmountOfTransaction'}
										inputNameLabel={t('profile.payment.form.placeholder_amount')}
										inputNameMessage={'Campo obligatorio'}
										inputNameType={'text'}
										inputNameRule={true}
										inputNameIcon={''}
										inputNameRules={t('field_notifications.normal.rules_required')}
										//disabled={true}
									/>
								</Col>
								<Col
									xs={24}
									sm={12}
									md={12}
									lg={8}
									xl={8}
									className='est-profile-payment-info-container'>
									<h5 className='est-profile-payment-info-title'>
										{t('profile.payment.form.card_type')}
									</h5>
									<Form.Item
										name='CardType'
										rules={[
											{
												required: true,
												message: t('profile.payment.form.card_type_message'),
											},
										]}>
										<Select
											placeholder={t('profile.payment.form.placeholder_card_type')}
											size='large'
											style={{ width: '100%' }}>
											<Option value={`VISA`}>VISA</Option>
											<Option value={`MASTERCARD`}>MASTERCARD</Option>
										</Select>
									</Form.Item>
								</Col>
								<Col
									xs={24}
									sm={12}
									md={12}
									lg={8}
									xl={8}
									className='est-profile-payment-info-container'>
									<h5 className='est-profile-payment-info-title'>
										{t('profile.payment.form.card_number')}
									</h5>
									<InputCustom
										className={''}
										inputName={'AccountNumber'}
										inputNameLabel={t('profile.payment.form.placeholder_card_number')}
										inputNameMessage={'La cuenta es obligatoria'}
										inputNameType={'text'}
										inputNameRule={true}
										inputNameIcon={''}
										inputNameRules={t('field_notifications.normal.rules_number')}
									/>
								</Col>
								<Col
									xs={24}
									sm={12}
									md={12}
									lg={8}
									xl={8}
									className='est-profile-payment-info-container'>
									<h5 className='est-profile-payment-info-title'>
										{t('profile.payment.form.exp_date')}
									</h5>
									<Form.Item
										name='ExpirationDate'
										rules={rulesValidationMask[isFunctionAge](date)}>
										<InputMask
											maskstyle='est-profile-payment-info-field-input'
											mask='99/9999'
											placeholder='12/2021'
										/>
									</Form.Item>
								</Col>
								<Col
									xs={24}
									sm={12}
									md={12}
									lg={8}
									xl={8}
									className='est-profile-payment-info-container'>
									<h5 className='est-profile-payment-info-title'>
										{t('profile.payment.form.input_name')}
									</h5>
									<InputCustom
										className={''}
										inputName={'Name'}
										inputNameLabel={t('profile.payment.form.placeholder_name')}
										inputNameMessage={'Campo obligatorio'}
										inputNameType={'text'}
										inputNameRule={true}
										inputNameIcon={''}
										inputNameRules={t('field_notifications.normal.rules_name_required')}
									/>
								</Col>

								<Col
									xs={24}
									sm={12}
									md={12}
									lg={8}
									xl={8}
									className='est-profile-payment-info-container'>
									<h5 className='est-profile-payment-info-title'>
										{t('profile.payment.form.address')}
									</h5>
									<InputCustom
										className={''}
										inputName={'Address'}
										inputNameLabel={t('profile.payment.form.placeholder_address')}
										inputNameMessage={'Campo obligatorio'}
										inputNameType={'text'}
										inputNameRule={true}
										inputNameIcon={''}
										inputNameRules={t(
											'field_notifications.normal.rules_address_required'
										)}
									/>
								</Col>
								<Col
									xs={24}
									sm={12}
									md={12}
									lg={8}
									xl={8}
									className='est-profile-payment-info-container'>
									<h5 className='est-profile-payment-info-title'>
										{t('profile.payment.form.city')}
									</h5>
									<InputCustom
										className={''}
										inputName={'City'}
										inputNameLabel={t('profile.payment.form.placeholder_city')}
										inputNameMessage={'Campo obligatorio'}
										inputNameType={'text'}
										inputNameRule={true}
										inputNameIcon={''}
										inputNameRules={t('field_notifications.normal.rules_name_required')}
									/>
								</Col>
								<Col
									xs={24}
									sm={12}
									md={12}
									lg={8}
									xl={8}
									className='est-profile-payment-info-container'>
									<h5 className='est-profile-payment-info-title'>
										{t('profile.payment.form.trans_type')}
									</h5>
									<Form.Item
										name='TypeTransaction'
										rules={[
											{
												required: true,
												message: t('profile.payment.form.trans_type_message'),
											},
										]}>
										<Select
											placeholder={t('profile.payment.form.placeholder_trans_type')}
											size='large'
											style={{ width: '100%' }}>
											<Option value={`DEBIT`}>
												{t('profile.payment.form.trans_op_one')}
											</Option>
											<Option value={`CREDIT`}>
												{t('profile.payment.form.trans_op_two')}
											</Option>
										</Select>
									</Form.Item>
								</Col>
							</Row>
							<div className='est-profile-payment-info-button-container'>
								<Button
									loading={isButtonLoading}
									className='est-profile-payment-info-button'
									htmlType={'submit'}>
									{t('profile.payment.form.button')}
								</Button>
							</div>
						</Form>
					</div>
					<Modal
						wrapClassName='est-payment-modal'
						centered
						title={t('profile.payment.modal.title_one')}
						visible={isVisible}
						cancelButtonProps={{ style: { display: 'none' } }}
						okButtonProps={{ style: { display: 'none' } }}
						okText=''
						cancelText=''
						width={270}
						header={false}
						footer={false}>
						<Image
							classImg={'est-payment-modal-image'}
							image={MainLogoImg}
							alt={'Prujula Logo'}
							title={'Prujula Logo'}
						/>

						<Spin
							className='est-payment-modal-spin'
							indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
						/>
						{isPayment ? (
							<h4 className='est-payment-modal-title'>
								{t('profile.payment.modal.pay_process_one')}
							</h4>
						) : (
							<h4 className='est-payment-modal-title'>
								{t('profile.payment.modal.pay_process_two')}
							</h4>
						)}

						<h4 className='est-payment-modal-subtitle'>
							{t('profile.payment.modal.subtitle_one')}
						</h4>
					</Modal>
					<Modal
						wrapClassName='est-payment-modal'
						centered
						title={t('profile.payment.modal.title_two')}
						visible={isModalConfirmation}
						cancelButtonProps={{ style: { display: 'none' } }}
						okButtonProps={{ style: { display: 'none' } }}
						okText=''
						cancelText=''
						width={350}
						header={false}
						footer={false}>
						<Image
							classImg={'est-payment-modal-image'}
							image={MainLogoImg}
							alt={'Prujula Logo'}
							title={'Prujula Logo'}
						/>
						{isConfirmation ? (
							<h4 className='est-payment-modal-title-2'>
								{t('profile.payment.modal.pay_success')}
							</h4>
						) : (
							<>
								<h4 className='est-payment-modal-title-2'>
									{t('profile.payment.modal.pay_fail_one')}
								</h4>
							</>
						)}
						{isConfirmationText && (
							<>
								<h3 className='est-payment-modal-error-title-1'>
									{t('profile.payment.modal.pay_confirmed_id')}{' '}
									{isConfirmationText.idReservation}
								</h3>
								<h3 className='est-payment-modal-error-title-2'>
									{t('profile.payment.modal.pay_confirmed_number')} {'  '}
									{isConfirmationText.confirmationNumber}
								</h3>
							</>
						)}
						<Link to='/' className='est-profile-payment-modal-button'>
							{t('profile.payment.modal.button')}
						</Link>
					</Modal>
				</>
			)
		}
	} else {
		return <h3>{t('profile.payment.modal.pay_fail_two')}</h3>
	}
}
