/** @format */

import React, { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'

import moment from 'moment/min/moment-with-locales'

import Moment from 'react-moment'

import { useCookies } from 'react-cookie'

import { Link } from 'react-router-dom'

import HeadDescription from '../../components/Common/HeadDescription'

import MainNavigation from '../../components/Common/MainNavigation'
import Loading from '../../components/Common/Loading'

import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
//import Spacer from '../../components/Common/Spacer'

//import Input from 'antd/lib/input'

/* import {
	FolderOutlined,
	WechatOutlined,
	HeartOutlined,
	LeftOutlined,
	RightOutlined,
	SearchOutlined,
} from '@ant-design/icons' */

//import RightSearch from '../blog-detail/components/RightSearch'
import Image from '../../components/Common/Image'
//import ArticleOneMain from '../../img/blog/articulo1/principal.jpg'
//import ArticleTwoMain from '../../img/blog/articulo2.jpg'
//import ArticleThreeMain from '../../img/blog/articulo3.jpg'
//import ArticleFourMain from '../../img/blog/articulo4.jpg'
//import ArticleFiveMain from '../../img/blog/articulo5.jpg'

import { BlogAllPosts } from './services'

import './style.css'

export default function BlogDetail() {
	const { t } = useTranslation()
	Moment.globalMoment = moment
	const [cookies] = useCookies(['i18next'])
	const [isLanguaje, setLanguaje] = useState(null)
	//const [isMobile, setMobile] = useState(false)
	const [isAllpost, setAllPost] = useState(null)

	useEffect(() => {
		/* if (window.innerWidth < 769) {
			setMobile(true)
		} */
		BlogAllPosts().then((response) => {
			if (response) {
				setAllPost(response)
				if (cookies.i18next === 'es') {
					setLanguaje('info_es')
				} else if (cookies.i18next === 'en') {
					setLanguaje('info_en')
				}
			}
		})
	}, [cookies.i18next])
	if (!isAllpost) {
		return <Loading />
	} else {
		return (
			<>
				<HeadDescription
					title={'Prujula - Blog'}
					name={'description'}
					content={'Camping Site'}
				/>
				<div className='est-blog-menu-global-container'>
					<MainNavigation
						title={'BLOG'}
						linkPrevTitle={'Home'}
						linkNextTitle={'Blog'}
						linkPrev={'/'}
						linkNext={'/blog'}
					/>
					<div className='est-blog-menu-main-container'>
						{/* 						{isMobile && (
							<>
								<Row className='est-blog-menu-responsive-global-container'>
									<Col span={10}>
										<div className='est-blog-menu-responsive-search-title-input-container'>
											<h3 className='est-blog-menu-responsive-search-title'>
												{t('blog_detail.right_search.search.search_title')}
											</h3>
											<hr className='est-blog-menu-responsive-search-spacer'></hr>
											<div className='est-blog-menu-responsive-search-input-container'>
												<Input
													size='large'
													placeholder={t(
														'blog_detail.right_search.search.search_placeholder'
													)}
													prefix={
														<SearchOutlined className='est-blog-menu-responsive-search-input-icon' />
													}
												/>
											</div>
										</div>
									</Col>
									<Col span={14}>
										<div className='est-blog-menu-responsive-search-categories-global-container'>
											<h3 className='est-blog-menu-responsive-search-categories-main-title'>
												{t('blog_detail.right_search.category.title')}
											</h3>
											<hr className='est-blog-menu-responsive-search-spacer'></hr>
											<div className='est-blog-menu-responsive-search-categories-container'>
												<div className='est-blog-menu-responsive-search-categories-title-container'>
													<h3 className='est-blog-menu-responsive-search-categories-title'>
														Gampling
													</h3>
													<h3 className='est-blog-menu-responsive-search-categories-title'>
														Camper
													</h3>
												</div>
												<div className='est-blog-menu-responsive-search-categories-title-container'>
													<h3 className='est-blog-menu-responsive-search-categories-title'>
														{t('blog_detail.right_search.category.backpack')}
													</h3>
													<h3 className='est-blog-menu-responsive-search-categories-title'>
														{t('blog_detail.right_search.category.trunk')}
													</h3>
												</div>
											</div>
										</div>
									</Col>
								</Row>
								<div className='est-blog-menu-left-global-container'>
									<div className='est-blog-menu-img-title-container'>
										<Link to='/blog/article-one'>
											<div className='est-blog-menu-img-container'>
												<Image
													classImg={'est-blog-main-img'}
													image={ArticleOneMain}
													alt={'Profile photo'}
													title={'Profile photo'}
												/>

												<div className='est-blog-menu-img-date'>
													<h3 className='est-blog-menu-img-date-title'>16</h3>
													<h3 className='est-blog-menu-img-date-subtitle'>07</h3>
												</div>
											</div>
										</Link>

										<div className='est-blog-menu-content-container'>
											<Link to='/blog/article-one'>
												<h3 className='est-blog-menu-title'>
													SENDERISMO AL ALCANCE DE TODOS
												</h3>
											</Link>

											<p className='est-blog-menu-text'>
												Más que nunca me convenzo de lo importante y necesario que es
												para nuestra salud mental y física separar tiempos de
												esparcimiento en la naturaleza. Entiendo que hay una relación
												clara entre las actividades al aire libre y menos visitas al
												médico. En ese sentido, el senderismo es la mejor "pastilla". Y
												no se diga el efecto positivo en la formación personal
												balanceada de lo niños cuando lo practican con sus padres y
												parientes.
											</p>
											<hr className='est-blog-search-spacer'></hr>
											<div className='est-blog-menu-subtitle-container'>
												<h4 className='est-blog-menu-category-text'>
													<FolderOutlined /> {t('blog.article_category')}
												</h4>
												<h4 className='est-blog-menu-category-icon'>
													<WechatOutlined /> 12
												</h4>
												<h4 className='est-blog-menu-category-icon'>
													<HeartOutlined /> 6
												</h4>
											</div>
										</div>
									</div>
									<div className='est-blog-menu-img-title-container'>
										<Link to='/blog/article-two'>
											<div className='est-blog-menu-img-container'>
												<Image
													classImg={'est-blog-main-img'}
													image={ArticleTwoMain}
													alt={'Profile photo'}
													title={'Profile photo'}
												/>

												<div className='est-blog-menu-img-date'>
													<h3 className='est-blog-menu-img-date-title'>17</h3>
													<h3 className='est-blog-menu-img-date-subtitle'>07</h3>
												</div>
											</div>
										</Link>

										<div className='est-blog-menu-content-container'>
											<Link to='/blog/article-two'>
												<h3 className='est-blog-menu-title'>
													Puerto Rico: un paraíso para proteger y disfrutar
												</h3>
											</Link>

											<p className='est-blog-menu-text'>
												Desde los años de la administración de Luis Muñoz Marín, Puerto
												Rico se ha vislumbrado como un destino turístico sin igual.
												Ciertamente, nuestro clima es propicio para realizar actividades
												de exterior durante todo el año. A ello se le añade la variedad
												de ecosistemas, flora y fauna que coexisten en un solo
												archipiélago.
											</p>
											<hr className='est-blog-search-spacer'></hr>
											<div className='est-blog-menu-subtitle-container'>
												<h4 className='est-blog-menu-category-text'>
													<FolderOutlined /> {t('blog.article_category')}
												</h4>
												<h4 className='est-blog-menu-category-icon'>
													<WechatOutlined /> 12
												</h4>
												<h4 className='est-blog-menu-category-icon'>
													<HeartOutlined /> 6
												</h4>
											</div>
										</div>
									</div>
									<div className='est-blog-menu-img-title-container'>
										<Link to='/blog/article-three'>
											<div className='est-blog-menu-img-container'>
												<Image
													classImg={'est-blog-main-img'}
													image={ArticleThreeMain}
													alt={'Profile photo'}
													title={'Profile photo'}
												/>

												<div className='est-blog-menu-img-date'>
													<h3 className='est-blog-menu-img-date-title'>18</h3>
													<h3 className='est-blog-menu-img-date-subtitle'>07</h3>
												</div>
											</div>
										</Link>

										<div className='est-blog-menu-content-container'>
											<Link to='/blog/article-three'>
												<h3 className='est-blog-menu-title'>La semilla de PRUJULA</h3>
											</Link>
											<p className='est-blog-menu-text'>
												Desde que era una pioja, y gracias a mi papá, aprendí a amar mi
												país disfrutándolo, acampando en cada monte o playa que nos
												encontrábamos. De hecho, en los últimos año incluso comparto con
												una colectiva de madres y padres interesad@s en compartir con
												sus crías más allá de los espacios educativos, domésticos o
												tecnológicos. Esa colectiva nos hacemos llamar Camping Crew.
											</p>
											<hr className='est-blog-search-spacer'></hr>
											<div className='est-blog-menu-subtitle-container'>
												<h4 className='est-blog-menu-category-text'>
													<FolderOutlined /> {t('blog.article_category')}
												</h4>
												<h4 className='est-blog-menu-category-icon'>
													<WechatOutlined /> 12
												</h4>
												<h4 className='est-blog-menu-category-icon'>
													<HeartOutlined /> 6
												</h4>
											</div>
										</div>
									</div>
									<div className='est-blog-menu-img-title-container'>
										<Link to='/blog/article-four'>
											<div className='est-blog-menu-img-container'>
												<Image
													classImg={'est-blog-main-img'}
													image={ArticleFourMain}
													alt={'Profile photo'}
													title={'Profile photo'}
												/>

												<div className='est-blog-menu-img-date'>
													<h3 className='est-blog-menu-img-date-title'>19</h3>
													<h3 className='est-blog-menu-img-date-subtitle'>07</h3>
												</div>
											</div>
										</Link>

										<div className='est-blog-menu-content-container'>
											<Link to='/blog/article-four'>
												<h3 className='est-blog-menu-title'>
													¿Camping de Mochila o Baúl?
												</h3>
											</Link>

											<p className='est-blog-menu-text'>
												Creo que había acampado más de veinte veces ocasiones cuando
												hablaba de nuestra próxima aventura con mis amigos Carlos y
												Diana, campistas de a verduras, cuando carlos me pregunta - ¿es
												de mochila o de baúl?- . Acto seguido le pregunto a Carlos a qué
												se refería y me explica que existen dos categorías mínimas para
												definir el tipo de campismo que se realiza.
											</p>
											<hr className='est-blog-search-spacer'></hr>
											<div className='est-blog-menu-subtitle-container'>
												<h4 className='est-blog-menu-category-text'>
													<FolderOutlined /> {t('blog.article_category')}
												</h4>
												<h4 className='est-blog-menu-category-icon'>
													<WechatOutlined /> 12
												</h4>
												<h4 className='est-blog-menu-category-icon'>
													<HeartOutlined /> 6
												</h4>
											</div>
										</div>
									</div>

									<div className='est-blog-menu-img-title-container'>
										<Link to='/blog/article-five'>
											<div className='est-blog-menu-img-container'>
												<Image
													classImg={'est-blog-main-img'}
													image={ArticleFiveMain}
													alt={'Profile photo'}
													title={'Profile photo'}
												/>

												<div className='est-blog-menu-img-date'>
													<h3 className='est-blog-menu-img-date-title'>20</h3>
													<h3 className='est-blog-menu-img-date-subtitle'>07</h3>
												</div>
											</div>
										</Link>

										<div className='est-blog-menu-content-container'>
											<Link to='/blog/article-five'>
												<h3 className='est-blog-menu-title'>
													Para campistas, camperos y fanáticos
												</h3>
											</Link>

											<p className='est-blog-menu-text'>
												Más que un artículo o blog esto es mi “cheat sheet” para
												acampar...
											</p>
											<hr className='est-blog-search-spacer'></hr>
											<div className='est-blog-menu-subtitle-container'>
												<h4 className='est-blog-menu-category-text'>
													<FolderOutlined /> {t('blog.article_category')}
												</h4>
												<h4 className='est-blog-menu-category-icon'>
													<WechatOutlined /> 12
												</h4>
												<h4 className='est-blog-menu-category-icon'>
													<HeartOutlined /> 6
												</h4>
											</div>
										</div>
									</div>

									<div className='est-blog-menu-button-container'>
										<Link className='est-blog-menu-button' to='/blog'>
											<LeftOutlined className='est-blog-menu-button-icon' />
											{t('blog.menu_navigation.button_prev')}
										</Link>
										<Spacer />
										<Link className='est-blog-menu-button' to='/blog'>
											{t('blog.menu_navigation.button_next')}
											<RightOutlined className='est-blog-menu-button-icon' />
										</Link>
									</div>
								</div>
							</>
						)} */}
						<Row className='est-blog-menu-responsive-row-container'>
							<Col span={24} className='est-blog-menu-left-global-container'>
								{isAllpost.map((item, index) => (
									<div className='est-blog-menu-img-title-container' key={index}>
										<Link to={`/blog-detail/${item.id}`}>
											<div className='est-blog-menu-img-container'>
												<Image
													classImg={'est-blog-main-img'}
													image={item.image}
													alt={'Profile photo'}
													title={'Profile photo'}
												/>
											</div>
										</Link>

										<div className='est-blog-menu-img-date'>
											<h3 className='est-blog-menu-img-date-title'>
												<Moment format='DD'>{item.date_create}</Moment>
											</h3>
											<h3 className='est-blog-menu-img-date-subtitle'>
												<Moment format='MM'>{item.date_create}</Moment>
											</h3>
										</div>

										<div className='est-blog-menu-content-container'>
											<Link to={`/blog-detail/${item.id}`}>
												<h3 className='est-blog-menu-title'>
													{item[isLanguaje] ? item[isLanguaje].title : ''}
												</h3>
											</Link>

											<p className='est-blog-menu-text'>
												{item[isLanguaje] ? item[isLanguaje].content : ''}
											</p>
											<hr className='est-blog-search-spacer'></hr>
											<div className='est-blog-menu-subtitle-container'>
												<h4 className='est-blog-menu-category-text'>
													{t('blog.author_title')} {item.name} {item.last_name},{' '}
													<Moment locale={cookies.i18next} format='LL'>
														{item.date_create}
													</Moment>
												</h4>
											</div>
										</div>
									</div>
								))}

								{/* 								<div className='est-blog-menu-img-title-container'>
									<Link to='/blog/article-two'>
										<div className='est-blog-menu-img-container'>
											<Image
												classImg={'est-blog-main-img'}
												image={ArticleTwoMain}
												alt={'Profile photo'}
												title={'Profile photo'}
											/>

											<div className='est-blog-menu-img-date'>
												<h3 className='est-blog-menu-img-date-title'>17</h3>
												<h3 className='est-blog-menu-img-date-subtitle'>07</h3>
											</div>
										</div>
									</Link>

									<div className='est-blog-menu-content-container'>
										<Link to='/blog/article-two'>
											<h3 className='est-blog-menu-title'>
												Puerto Rico: un paraíso para proteger y disfrutar
											</h3>
										</Link>

										<p className='est-blog-menu-text'>
											Desde los años de la administración de Luis Muñoz Marín, Puerto
											Rico se ha vislumbrado como un destino turístico sin igual.
											Ciertamente, nuestro clima es propicio para realizar actividades de
											exterior durante todo el año. A ello se le añade la variedad de
											ecosistemas, flora y fauna que coexisten en un solo archipiélago.
										</p>
										<hr className='est-blog-search-spacer'></hr>
										<div className='est-blog-menu-subtitle-container'>
											<h4 className='est-blog-menu-category-text'>
												<FolderOutlined /> {t('blog.article_category')}
											</h4>
											<h4 className='est-blog-menu-category-icon'>
												<WechatOutlined /> 12
											</h4>
											<h4 className='est-blog-menu-category-icon'>
												<HeartOutlined /> 6
											</h4>
										</div>
									</div>
								</div>
								<div className='est-blog-menu-img-title-container'>
									<Link to='/blog/article-three'>
										<div className='est-blog-menu-img-container'>
											<Image
												classImg={'est-blog-main-img'}
												image={ArticleThreeMain}
												alt={'Profile photo'}
												title={'Profile photo'}
											/>

											<div className='est-blog-menu-img-date'>
												<h3 className='est-blog-menu-img-date-title'>18</h3>
												<h3 className='est-blog-menu-img-date-subtitle'>07</h3>
											</div>
										</div>
									</Link>

									<div className='est-blog-menu-content-container'>
										<Link to='/blog/article-three'>
											<h3 className='est-blog-menu-title'>La semilla de PRUJULA</h3>
										</Link>
										<p className='est-blog-menu-text'>
											Desde que era una pioja, y gracias a mi papá, aprendí a amar mi
											país disfrutándolo, acampando en cada monte o playa que nos
											encontrábamos. De hecho, en los últimos año incluso comparto con
											una colectiva de madres y padres interesad@s en compartir con sus
											crías más allá de los espacios educativos, domésticos o
											tecnológicos. Esa colectiva nos hacemos llamar Camping Crew.
										</p>
										<hr className='est-blog-search-spacer'></hr>
										<div className='est-blog-menu-subtitle-container'>
											<h4 className='est-blog-menu-category-text'>
												<FolderOutlined /> {t('blog.article_category')}
											</h4>
											<h4 className='est-blog-menu-category-icon'>
												<WechatOutlined /> 12
											</h4>
											<h4 className='est-blog-menu-category-icon'>
												<HeartOutlined /> 6
											</h4>
										</div>
									</div>
								</div>
								<div className='est-blog-menu-img-title-container'>
									<Link to='/blog/article-four'>
										<div className='est-blog-menu-img-container'>
											<Image
												classImg={'est-blog-main-img'}
												image={ArticleFourMain}
												alt={'Profile photo'}
												title={'Profile photo'}
											/>

											<div className='est-blog-menu-img-date'>
												<h3 className='est-blog-menu-img-date-title'>19</h3>
												<h3 className='est-blog-menu-img-date-subtitle'>07</h3>
											</div>
										</div>
									</Link>

									<div className='est-blog-menu-content-container'>
										<Link to='/blog/article-four'>
											<h3 className='est-blog-menu-title'>
												¿Camping de Mochila o Baúl?
											</h3>
										</Link>

										<p className='est-blog-menu-text'>
											Creo que había acampado más de veinte veces ocasiones cuando
											hablaba de nuestra próxima aventura con mis amigos Carlos y Diana,
											campistas de a verduras, cuando carlos me pregunta - ¿es de mochila
											o de baúl?- . Acto seguido le pregunto a Carlos a qué se refería y
											me explica que existen dos categorías mínimas para definir el tipo
											de campismo que se realiza.
										</p>
										<hr className='est-blog-search-spacer'></hr>
										<div className='est-blog-menu-subtitle-container'>
											<h4 className='est-blog-menu-category-text'>
												<FolderOutlined /> {t('blog.article_category')}
											</h4>
											<h4 className='est-blog-menu-category-icon'>
												<WechatOutlined /> 12
											</h4>
											<h4 className='est-blog-menu-category-icon'>
												<HeartOutlined /> 6
											</h4>
										</div>
									</div>
								</div>
								<div className='est-blog-menu-img-title-container'>
									<Link to='/blog/article-five'>
										<div className='est-blog-menu-img-container'>
											<Image
												classImg={'est-blog-main-img'}
												image={ArticleFiveMain}
												alt={'Profile photo'}
												title={'Profile photo'}
											/>

											<div className='est-blog-menu-img-date'>
												<h3 className='est-blog-menu-img-date-title'>20</h3>
												<h3 className='est-blog-menu-img-date-subtitle'>07</h3>
											</div>
										</div>
									</Link>

									<div className='est-blog-menu-content-container'>
										<Link to='/blog/article-five'>
											<h3 className='est-blog-menu-title'>
												Para campistas, camperos y fanáticos
											</h3>
										</Link>

										<p className='est-blog-menu-text'>
											Más que un artículo o blog esto es mi “cheat sheet” para acampar...
										</p>
										<hr className='est-blog-search-spacer'></hr>
										<div className='est-blog-menu-subtitle-container'>
											<h4 className='est-blog-menu-category-text'>
												<FolderOutlined /> {t('blog.article_category')}
											</h4>
											<h4 className='est-blog-menu-category-icon'>
												<WechatOutlined /> 12
											</h4>
											<h4 className='est-blog-menu-category-icon'>
												<HeartOutlined /> 6
											</h4>
										</div>
									</div>
								</div>
 */}
								{/* 							<div className='est-blog-menu-button-container'>
								<Link className='est-blog-menu-button' to='/blog'>
									<LeftOutlined className='est-blog-menu-button-icon' />
									{t('blog.menu_navigation.button_prev')}
								</Link>
								<Spacer />
								<Link className='est-blog-menu-button' to='/blog'>
									{t('blog.menu_navigation.button_next')}
									<RightOutlined className='est-blog-menu-button-icon' />
								</Link>
							</div> */}
							</Col>

							{/* <Col span={6} className='est-blog-detail-right-global-container'>
							<RightSearch />
						</Col> */}
						</Row>
					</div>
				</div>
			</>
		)
	}
}
