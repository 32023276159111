/** @format */

import React, { useState } from 'react'

import { Link } from 'react-router-dom'

import { ArrowRightOutlined } from '@ant-design/icons'

import Image from '../../../../../../../../components/Common/Image'

import './style.css'

export default function Articles(props) {
	const [isShow, setShow] = useState(false)
	return (
		<div
			className={`${
				isShow ? '' : 'est-articles-global-container-hover'
			} est-articles-global-container`}
			onMouseEnter={() => setShow(true)}
			onMouseLeave={() => setShow(false)}>
			<Image
				classImg={`${
					isShow ? 'est-articles-img-color' : 'est-articles-img-bw'
				} est-articles-img`}
				image={props.item.picture.picture_url.file}
				alt={props.item.title}
				title={props.item.title}
			/>
			<div
				className={`${
					isShow ? 'est-article-text-bg-green' : 'est-article-text-bg-transparent'
				} est-article-text-global-container`}>
				<h2 className='article-name'>{props.item.title}</h2>
				<div
					className={`${
						isShow
							? 'est-article-text-main-container-shown'
							: 'est-article-text-main-container-unshown'
					} est-article-text-main-container`}>
					<h3 className='article-subtitle'>{props.item.description}</h3>
					<div className='article-link'>
						<Link to={`/publication/detail/${props.item.id}`}>
							<ArrowRightOutlined className='article-link-icon' />
						</Link>
					</div>
				</div>
			</div>
		</div>
	)
}
