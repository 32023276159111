/** @format */

import React, { useState } from 'react'

import { useTranslation } from 'react-i18next'

import { useGlobal, setGlobal } from 'reactn'

import axios from 'axios'

import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import message from 'antd/lib/message'
import Upload from 'antd/lib/upload'
import Spin from 'antd/lib/spin'

import { CloudUploadOutlined, LoadingOutlined } from '@ant-design/icons'

import { ENV_CORE } from '../Hooks/Variables/Enviroment'

import './style.css'

export default function AdsPhotos() {
	const { t } = useTranslation()
	const [isGalleryArrayImage] = useGlobal('GalleryArrayImages')
	const [isProgress, setProgress] = useState(false)
	const { Dragger } = Upload

	const handleImageGalleryDelete = async (item) => {
		let data = isGalleryArrayImage
		data = data.filter(function (data) {
			return data.uid !== item.uid
		})
		setGlobal({
			GalleryArrayImages: data,
		})
	}

	const handleImageGalleryUpload = async (options) => {
		setProgress(true)
		let galleryOrigin = isGalleryArrayImage
		const { onSuccess, onError, file, onProgress } = options
		const data = new FormData()
		data.append('imagen[]', file)
		const config = {
			headers: { 'Content-Type': 'multipart/form-data' },
			onUploadProgress: (event) => {
				onProgress({ percent: (event.loaded / event.total) * 100 })
			},
		}
		if (isGalleryArrayImage.length <= 4) {
			try {
				const response = await axios.post(`${ENV_CORE}/api/upload/upload-image`, data, config)
				if (response) {
					if (response.data.statusCode === 200) {
						let info = {
							uid: file.uid,
							image: `${ENV_CORE}/${response.data.imageInfo[0].file}`,
							name: response.data.imageInfo[0].name,
							old_name: response.data.imageInfo[0].old_name,
							old_title: response.data.imageInfo[0].old_title,
						}

						galleryOrigin.push(info)
						onSuccess('Ok')
						message.success(t('upload_gallery_image.message_success'))
					} else {
						message.error(t('upload_gallery_image.message_fail'))
					}
				}
			} catch (err) {
				onError({ err })
				message.error(t('upload_gallery_image.message_fail'))
			}
		} else {
			onError()
			message.error(t('upload_gallery_image.message_max_img'))
		}
		if (galleryOrigin.length > 5) {
			let images = galleryOrigin.slice(0, 5)
			galleryOrigin = images
		}
		setGlobal({
			GalleryArrayImages: galleryOrigin,
		})
		setProgress(false)
	}

	return (
		<div>
			<Row>
				<Col span={24}>
					<h4 className='est-loading-edit-publication-main-title'>
						{t('upload_gallery_image.main_title')}
					</h4>
					<div>
						<div className='est-loading-edit-publication-main-container'>
							{isProgress && (
								<div className='est-loading-edit-publication-container'>
									<Spin indicator={<LoadingOutlined className='' />} />
								</div>
							)}
							<Dragger
								className=''
								maxCount={5}
								accept='image/*'
								customRequest={handleImageGalleryUpload}
								onRemove={handleImageGalleryDelete}
								multiple={true}>
								<p className='est-upload-drag-title'>
									{t('upload_gallery_image.placeholder_title')}
								</p>
								<p className='est-upload-drag-subtitle'>
									{t('upload_gallery_image.placeholder_subtitle')}
								</p>
								<div className='est-upload-drag-icon-container'>
									<p>
										<CloudUploadOutlined />
									</p>
								</div>
							</Dragger>
						</div>
					</div>
				</Col>
			</Row>
		</div>
	)
}
