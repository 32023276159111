/** @format */

import React, { useState, useEffect } from 'react'

import { useTranslation } from 'react-i18next'

import { useGlobal, setGlobal } from 'reactn'

import MainNavigation from '../../../../components/Common/MainNavigation'

import moment from 'moment'

import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import Button from 'antd/lib/button'
import Collapse from 'antd/lib/collapse'
import Tabs from 'antd/lib/tabs'

import { StarFilled, CaretRightOutlined } from '@ant-design/icons'

import HeadDescription from '../../../../components/Common/HeadDescription'
import Loading from '../../../../components/Common/Loading'

import { GetUserInfo } from '../../../../components/Common/Hooks/Functions/GetUserInfo'

import MainMenu from '../../components/MainMenu'
import LoadingData from '../../components/LoadingData'

import EditPublication from './components/EditPublication'

import servicesPublications from './services'

import './style.css'

export default function UserListPublications() {
	const { t } = useTranslation()
	const [isUser] = useState(JSON.parse(localStorage.getItem('userSession')))
	const [isDataProfile] = useGlobal('DataProfile')
	const [isUpdateLoading, setUpdateLoading] = useState(false)
	const [isUserPublications, setUserPublications] = useGlobal('UserPublications')
	const [isUserPublicationsLoading, setUserPublicationsLoading] =
		useGlobal('UserPublicationsState')
	const { Panel } = Collapse
	const { TabPane } = Tabs

	const [isUserPublicationsTraduce] = useState({
		service_global_description: t('global_service.service_global_description'),
	})

	const handleUpdateReservation = async (item, data) => {
		setUpdateLoading(true)

		const traduce = {
			service_success_title: t('profile.user_publication.service_success_title'),
			service_success_description: t('profile.user_publication.service_success_description'),
			service_warning_title: t('profile.user_publication.service_warning_title'),
			service_warning_description: t('profile.user_publication.service_warning_description'),
			service_error_description: t('profile.user_publication.service_error_description'),
		}

		await servicesPublications.UpdatePublication(item, data, traduce).then((response) => {
			if (response) {
				servicesPublications
					.UserPublications(isUser.id, isUserPublicationsTraduce)
					.then((response) => {
						if (response) {
							setUserPublications(response)
						} else {
							setUserPublicationsLoading(true)
						}
					})
			}
		})
		setUpdateLoading(false)
	}

	const handleCancelationDate = (item, data) => {
		let days
		if (item) {
			let todayDate = new Date()
			todayDate =
				todayDate.getFullYear() +
				'-' +
				0 +
				(todayDate.getMonth() + 1) +
				'-' +
				todayDate.getDate()
			todayDate = moment(todayDate)
			let cancellationDate = moment(item)
			const totalCancellationDate = cancellationDate.diff(todayDate, 'days')
			if (totalCancellationDate <= 0) {
				days = t('profile.user_publication.data_day_three')
			} else {
				days =
					'*(' +
					cancellationDate.diff(todayDate, 'days') +
					')' +
					` ${
						data
							? t('profile.user_publication.data_day_one')
							: t('profile.user_publication.data_day_two')
					}`
			}
		}
		return days
	}

	useEffect(() => {
		servicesPublications
			.UserPublications(isUser.id, isUserPublicationsTraduce)
			.then((response) => {
				if (response) {
					setUserPublications(response)
				} else {
					setUserPublicationsLoading(true)
				}
			})
		GetUserInfo(isUser.id).then((response) => {
			if (response) {
				setGlobal({
					DataProfile: response,
				})
			} else {
				setTimeout(() => {
					window.location.pathname = '/'
				}, 5000)
			}
		})
	}, [isUser.id, setUserPublications, setUserPublicationsLoading, isUserPublicationsTraduce])
	if (!isDataProfile) {
		return <Loading />
	} else {
		return (
			<>
				<HeadDescription
					title={`${t('profile.user_publication.head_description.title')} - ${isUser.id}`}
					name={'description'}
					content={'Camping Site'}
				/>

				<MainNavigation
					title={t('profile.user_publication.main_navigation.title')}
					linkPrevTitle={'Home'}
					linkNextTitle={t('profile.user_publication.main_navigation.subtitle')}
					linkPrev={'/'}
					linkNext={'/'}
				/>

				<div className='est-user-publications-global-container'>
					<div className='est-user-publications-main-container'>
						<div className='est-user-publications-main-title-container'>
							<h2 className='est-user-publications-main-title'>
								{t('profile.user_publication.main_title')}
							</h2>
						</div>

						<div className='est-profile-user-publications-global-container'>
							<div className='est-profile-user-publications-aside-container'>
								<MainMenu user={isDataProfile} />
							</div>
							<div className='est-profile-publications-list-global-container'>
								<div className='est-profile-publications-list-main-container'>
									<div className='est-profile-publications-list-header-global-container'>
										<div className='est-profile-publications-responsive-button-container'>
											<Button
												className='est-profile-publications-responsive-button'
												type='primary'
												onClick={() =>
													setGlobal({
														useDrawer: true,
													})
												}>
												{t('profile.user_publication.menu_resp_button')}
											</Button>
										</div>
										<div className='est-profile-publications-list-title-button-container'>
											<h2 className='est-profile-publications-list-title'>
												{t('profile.user_publication.title')}
											</h2>
										</div>
									</div>
									<div className='est-profile-publications-list-collapse-container'>
										{!isUserPublicationsLoading ? (
											<>
												{isUserPublications ? (
													<Collapse
														accordion
														expandIconPosition={'right'}
														expandIcon={({ isActive }) => (
															<CaretRightOutlined rotate={isActive ? 90 : 0} />
														)}>
														{isUserPublications.map((item, index) => (
															<Panel
																className='est-profile-publications-list-collapse-item-container'
																header={
																	<Row>
																		<Col xs={24} sm={9} md={2} lg={3} xl={3}>
																			<h3 className='est-list-collapse-item-title-one'>
																				{item.id_anuncio}
																			</h3>
																		</Col>
																		<Col xs={24} sm={15} md={6} lg={6} xl={6}>
																			<h3 className='est-list-collapse-item-title-two'>
																				{item.title}
																			</h3>
																		</Col>
																		<Col xs={24} sm={9} md={4} lg={5} xl={5}>
																			<h3 className='est-list-collapse-item-title-three'>
																				${item.price}
																			</h3>
																		</Col>
																		<Col xs={24} sm={15} md={12} lg={10} xl={10}>
																			{item.pending.length > 0 && (
																				<>
																					<h3 className='est-list-collapse-item-title-four'>
																						<span className='est-list-collapse-item-title-icon'>
																							<StarFilled />
																						</span>{' '}
																						{t(
																							'profile.user_publication.publication_allow'
																						)}
																					</h3>
																				</>
																			)}
																		</Col>
																	</Row>
																}
																key={index}>
																<Row>
																	<Col xs={24} sm={12} md={8} lg={8} xl={8}>
																		<h4 className='est-list-collapse-item-content'>
																			<span className='est-list-collapse-item-span-title'>
																				{t(
																					'profile.user_publication.publication_name'
																				)}{' '}
																			</span>
																			{item.title}
																		</h4>
																	</Col>
																	{/* <Col xs={24} sm={12} md={8} lg={8} xl={8}>
																		<h4 className='est-list-collapse-item-content'>
																			<span className='est-list-collapse-item-span-title'>
																				{t(
																					'profile.user_publication.publication_discount'
																				)}{' '}
																			</span>
																			${item.discount_amount}
																		</h4>
																	</Col> */}
																	<Col xs={24} sm={12} md={8} lg={8} xl={8}>
																		<h4 className='est-list-collapse-item-content'>
																			<span className='est-list-collapse-item-span-title'>
																				{t(
																					'profile.user_publication.publication_category'
																				)}{' '}
																			</span>
																			{item.categorias.map((c) => `${c},`)}
																			{item.nombre_categoria}
																		</h4>
																	</Col>
																	<Col xs={24} sm={12} md={8} lg={8} xl={8}>
																		<h4 className='est-list-collapse-item-content'>
																			<span className='est-list-collapse-item-span-title'>
																				{t(
																					'profile.user_publication.publication_view'
																				)}{' '}
																			</span>
																			{item.vistas.map((c) => `${c},`)}
																			{item.nombre_categoria}
																		</h4>
																	</Col>
																	<Col xs={24} sm={12} md={8} lg={8} xl={8}>
																		<h4 className='est-list-collapse-item-content'>
																			<span className='est-list-collapse-item-span-title'>
																				{t(
																					'profile.user_publication.publication_price'
																				)}{' '}
																			</span>
																			${item.price}
																		</h4>
																	</Col>
																	<Col xs={24} sm={12} md={8} lg={8} xl={8}>
																		<h4 className='est-list-collapse-item-content'>
																			<span className='est-list-collapse-item-span-title'>
																				{t(
																					'profile.user_publication.publication_price_offer'
																				)}{' '}
																			</span>
																			${item.price_offer}
																		</h4>
																	</Col>
																	<Col xs={24} sm={12} md={8} lg={8} xl={8}>
																		<h4 className='est-list-collapse-item-content'>
																			<span className='est-list-collapse-item-span-title'>
																				{t(
																					'profile.user_publication.publication_people'
																				)}{' '}
																			</span>
																			{item.people}
																		</h4>
																	</Col>
																	<Col xs={24} sm={12} md={24} lg={24} xl={24}>
																		<br />
																		<div className='est-list-collapse-item-content'>
																			<EditPublication item={item} />
																		</div>
																	</Col>
																</Row>
																<div className='est-user-publications-list-tab-main-container'>
																	<h3 className='est-user-publications-list-tab-main-title'>
																		{t(
																			'profile.user_publication.publication_status'
																		)}
																	</h3>
																	{item.pending.length > 1 && (
																		<h3 className='est-list-collapse-item-span-subtitle'>
																			({' '}
																			{t(
																				'profile.user_publication.publication_pending'
																			)}{' '}
																			{item.pending.length} )
																		</h3>
																	)}
																</div>

																<div className='est-user-publications-list-reservations-global-container'>
																	<Tabs defaultActiveKey='1'>
																		<TabPane
																			tab={t(
																				'profile.user_publication.tabs.pending_title'
																			)}
																			key='1'>
																			{item.pending.length > 0 ? (
																				<>
																					{item.pending.map((element, i) => (
																						<div
																							key={i}
																							className='est-user-publications-list-reservations-main-container'>
																							<Row>
																								<Col
																									xs={24}
																									sm={14}
																									md={7}
																									lg={7}
																									xl={7}>
																									<h4 className='est-user-reservations-list-main-title'>
																										<span className='est-user-reservations-list-main-span-title'>
																											{t(
																												'profile.user_publication.tabs.id'
																											)}{' '}
																										</span>
																										{element.id_anuncio}
																									</h4>
																								</Col>
																								<Col
																									xs={24}
																									sm={10}
																									md={7}
																									lg={7}
																									xl={7}>
																									<h4 className='est-user-reservations-list-main-title'>
																										<span className='est-user-reservations-list-main-span-title'>
																											{t(
																												'profile.user_publication.tabs.title'
																											)}{' '}
																										</span>
																										{element.title}
																									</h4>
																								</Col>
																								<Col
																									xs={24}
																									sm={14}
																									md={10}
																									lg={10}
																									xl={10}>
																									<h4 className='est-user-publications-list-reservations-main-title'>
																										<span className='est-user-publications-list-reservations-main-span-title'>
																											{t(
																												'profile.user_publication.tabs.applicant'
																											)}{' '}
																										</span>
																										{element.nombre}{' '}
																										{element.apellido}
																									</h4>
																								</Col>
																								<Col
																									xs={24}
																									sm={10}
																									md={7}
																									lg={7}
																									xl={7}>
																									<h4 className='est-user-publications-list-reservations-main-title'>
																										<span className='est-user-publications-list-reservations-main-span-title'>
																											{t(
																												'profile.user_publication.tabs.number_days'
																											)}{' '}
																										</span>
																										{
																											element.cantidad_dias
																										}
																									</h4>
																								</Col>
																								<Col
																									xs={24}
																									sm={14}
																									md={7}
																									lg={7}
																									xl={7}>
																									<h4 className='est-user-reservations-list-main-title'>
																										<span className='est-user-reservations-list-main-span-title'>
																											{t(
																												'profile.user_publication.tabs.amount_people'
																											)}{' '}
																										</span>
																										{
																											element.cantidad_personas
																										}
																									</h4>
																								</Col>
																								<Col
																									xs={24}
																									sm={10}
																									md={10}
																									lg={10}
																									xl={10}>
																									<h4 className='est-user-publications-list-reservations-main-title'>
																										<span className='est-user-publications-list-reservations-main-span-title'>
																											{t(
																												'profile.user_publication.tabs.start_date'
																											)}{' '}
																										</span>
																										{element.fecha_desde}
																									</h4>
																								</Col>
																								<Col
																									xs={24}
																									sm={14}
																									md={7}
																									lg={7}
																									xl={7}>
																									<h4 className='est-user-publications-list-reservations-main-title'>
																										<span className='est-user-publications-list-reservations-main-span-title'>
																											{t(
																												'profile.user_publication.tabs.end_date'
																											)}{' '}
																										</span>
																										{element.fecha_hasta}
																									</h4>
																								</Col>
																								<Col
																									xs={24}
																									sm={10}
																									md={7}
																									lg={7}
																									xl={7}>
																									<h4 className='est-user-publications-list-reservations-main-title'>
																										<span className='est-user-publications-list-reservations-main-span-title'>
																											Subtotal:{' '}
																										</span>
																										${element.precio}
																									</h4>
																								</Col>
																								<Col
																									xs={24}
																									sm={14}
																									md={10}
																									lg={10}
																									xl={10}>
																									<h4 className='est-user-publications-list-reservations-main-title'>
																										<span className='est-user-publications-list-reservations-main-span-title'>
																											{t(
																												'profile.user_publication.tabs.taxes'
																											)}{' '}
																										</span>
																										${element.impuesto}
																									</h4>
																								</Col>
																								<Col
																									xs={24}
																									sm={10}
																									md={7}
																									lg={7}
																									xl={7}>
																									<h4 className='est-user-publications-list-reservations-main-title'>
																										<span className='est-user-publications-list-reservations-main-span-title'>
																											Total:{' '}
																										</span>
																										${element.total}
																									</h4>
																								</Col>
																							</Row>
																							<Col span={24}>
																								<div className='est-user-publications-date-left'>
																									{handleCancelationDate(
																										element.fecha_vencimiento
																									)}
																								</div>
																							</Col>
																							<Col
																								span={24}
																								className='est-user-publications-list-reservations-button-container'>
																								<Button
																									className='est-user-publications-list-reservations-button'
																									loading={isUpdateLoading}
																									onClick={() =>
																										handleUpdateReservation(
																											'1',
																											element.id
																										)
																									}>
																									{t(
																										'profile.user_publication.tabs.pass_button'
																									)}
																								</Button>
																								<Button
																									className='est-user-publications-list-reservations-button'
																									loading={isUpdateLoading}
																									onClick={() =>
																										handleUpdateReservation(
																											'2',
																											element.id
																										)
																									}>
																									{t(
																										'profile.user_publication.tabs.cancel_button'
																									)}
																								</Button>
																							</Col>
																						</div>
																					))}
																				</>
																			) : (
																				<h3 className='est-user-publications-list-tab-main-subtitle'>
																					{t(
																						'profile.user_publication.tabs.subtitle'
																					)}
																				</h3>
																			)}
																		</TabPane>
																		<TabPane
																			tab={t(
																				'profile.user_publication.tabs.pass_title'
																			)}
																			key='2'>
																			{item.approved.length > 0 ? (
																				<>
																					{item.approved.map((element, i) => (
																						<div
																							key={i}
																							className='est-user-publications-list-reservations-main-container'>
																							<Row>
																								<Col
																									xs={24}
																									sm={14}
																									md={7}
																									lg={7}
																									xl={7}>
																									<h4 className='est-user-reservations-list-main-title'>
																										<span className='est-user-reservations-list-main-span-title'>
																											{t(
																												'profile.user_publication.tabs.id'
																											)}{' '}
																										</span>
																										{element.id_anuncio}
																									</h4>
																								</Col>
																								<Col
																									xs={24}
																									sm={10}
																									md={7}
																									lg={7}
																									xl={7}>
																									<h4 className='est-user-reservations-list-main-title'>
																										<span className='est-user-reservations-list-main-span-title'>
																											{t(
																												'profile.user_publication.tabs.title'
																											)}{' '}
																										</span>
																										{element.title}
																									</h4>
																								</Col>
																								<Col
																									xs={24}
																									sm={14}
																									md={10}
																									lg={10}
																									xl={10}>
																									<h4 className='est-user-publications-list-reservations-main-title'>
																										<span className='est-user-publications-list-reservations-main-span-title'>
																											{t(
																												'profile.user_publication.tabs.applicant'
																											)}{' '}
																										</span>
																										{element.nombre}{' '}
																										{element.apellido}
																									</h4>
																								</Col>
																								<Col
																									xs={24}
																									sm={10}
																									md={7}
																									lg={7}
																									xl={7}>
																									<h4 className='est-user-publications-list-reservations-main-title'>
																										<span className='est-user-publications-list-reservations-main-span-title'>
																											{t(
																												'profile.user_publication.tabs.number_days'
																											)}{' '}
																										</span>
																										{
																											element.cantidad_dias
																										}
																									</h4>
																								</Col>
																								<Col
																									xs={24}
																									sm={14}
																									md={7}
																									lg={7}
																									xl={7}>
																									<h4 className='est-user-reservations-list-main-title'>
																										<span className='est-user-reservations-list-main-span-title'>
																											{t(
																												'profile.user_publication.tabs.amount_people'
																											)}{' '}
																										</span>
																										{
																											element.cantidad_personas
																										}
																									</h4>
																								</Col>
																								<Col
																									xs={24}
																									sm={10}
																									md={10}
																									lg={10}
																									xl={10}>
																									<h4 className='est-user-publications-list-reservations-main-title'>
																										<span className='est-user-publications-list-reservations-main-span-title'>
																											{t(
																												'profile.user_publication.tabs.start_date'
																											)}{' '}
																										</span>
																										{element.fecha_desde}
																									</h4>
																								</Col>
																								<Col
																									xs={24}
																									sm={14}
																									md={7}
																									lg={7}
																									xl={7}>
																									<h4 className='est-user-publications-list-reservations-main-title'>
																										<span className='est-user-publications-list-reservations-main-span-title'>
																											{t(
																												'profile.user_publication.tabs.end_date'
																											)}{' '}
																										</span>
																										{element.fecha_hasta}
																									</h4>
																								</Col>
																								<Col
																									xs={24}
																									sm={10}
																									md={7}
																									lg={7}
																									xl={7}>
																									<h4 className='est-user-publications-list-reservations-main-title'>
																										<span className='est-user-publications-list-reservations-main-span-title'>
																											Subtotal:{' '}
																										</span>{' '}
																										${element.precio}
																									</h4>
																								</Col>
																								<Col
																									xs={24}
																									sm={14}
																									md={10}
																									lg={10}
																									xl={10}>
																									<h4 className='est-user-publications-list-reservations-main-title'>
																										<span className='est-user-publications-list-reservations-main-span-title'>
																											{t(
																												'profile.user_publication.tabs.taxes'
																											)}{' '}
																										</span>{' '}
																										${element.impuesto}
																									</h4>
																								</Col>
																								<Col
																									xs={24}
																									sm={10}
																									md={7}
																									lg={7}
																									xl={7}>
																									<h4 className='est-user-publications-list-reservations-main-title'>
																										<span className='est-user-publications-list-reservations-main-span-title'>
																											Total:{' '}
																										</span>{' '}
																										${element.total}
																									</h4>
																								</Col>
																								<Col span={24}>
																									<div className='est-user-publications-date-left'>
																										{handleCancelationDate(
																											element.fecha_vencimiento,
																											true
																										)}
																									</div>
																								</Col>
																								<Col span={24}>
																									<Button
																										className='est-user-publications-list-reservations-button'
																										loading={
																											isUpdateLoading
																										}
																										onClick={() =>
																											handleUpdateReservation(
																												'2',
																												element.id
																											)
																										}>
																										{t(
																											'profile.user_publication.tabs.cancel_button'
																										)}
																									</Button>
																								</Col>
																							</Row>
																						</div>
																					))}
																				</>
																			) : (
																				<h3 className='est-user-publications-list-tab-main-subtitle'>
																					{t(
																						'profile.user_publication.tabs.subtitle'
																					)}
																				</h3>
																			)}
																		</TabPane>
																		<TabPane
																			tab={t(
																				'profile.user_publication.tabs.cancel_title'
																			)}
																			key='3'>
																			{item.canceled.length > 0 ? (
																				<>
																					{item.canceled.map((element, i) => (
																						<div
																							key={i}
																							className='est-user-publications-list-reservations-main-container'>
																							<Row>
																								<Col
																									xs={24}
																									sm={14}
																									md={7}
																									lg={7}
																									xl={7}>
																									<h4 className='est-user-reservations-list-main-title'>
																										<span className='est-user-reservations-list-main-span-title'>
																											{t(
																												'profile.user_publication.tabs.id'
																											)}{' '}
																										</span>
																										{element.id_anuncio}
																									</h4>
																								</Col>
																								<Col
																									xs={24}
																									sm={10}
																									md={7}
																									lg={7}
																									xl={7}>
																									<h4 className='est-user-reservations-list-main-title'>
																										<span className='est-user-reservations-list-main-span-title'>
																											{t(
																												'profile.user_publication.tabs.title'
																											)}{' '}
																										</span>
																										{element.title}
																									</h4>
																								</Col>
																								<Col
																									xs={24}
																									sm={14}
																									md={10}
																									lg={10}
																									xl={10}>
																									<h4 className='est-user-publications-list-reservations-main-title'>
																										<span className='est-user-publications-list-reservations-main-span-title'>
																											{t(
																												'profile.user_publication.tabs.applicant'
																											)}{' '}
																										</span>
																										{element.nombre}{' '}
																										{element.apellido}
																									</h4>
																								</Col>
																								<Col
																									xs={24}
																									sm={10}
																									md={7}
																									lg={7}
																									xl={7}>
																									<h4 className='est-user-publications-list-reservations-main-title'>
																										<span className='est-user-publications-list-reservations-main-span-title'>
																											{t(
																												'profile.user_publication.tabs.number_days'
																											)}{' '}
																										</span>
																										{
																											element.cantidad_dias
																										}
																									</h4>
																								</Col>
																								<Col
																									xs={24}
																									sm={14}
																									md={7}
																									lg={7}
																									xl={7}>
																									<h4 className='est-user-reservations-list-main-title'>
																										<span className='est-user-reservations-list-main-span-title'>
																											{t(
																												'profile.user_publication.tabs.number_days'
																											)}{' '}
																										</span>
																										{
																											element.cantidad_personas
																										}
																									</h4>
																								</Col>
																								<Col
																									xs={24}
																									sm={10}
																									md={10}
																									lg={10}
																									xl={10}>
																									<h4 className='est-user-publications-list-reservations-main-title'>
																										<span className='est-user-publications-list-reservations-main-span-title'>
																											{t(
																												'profile.user_publication.tabs.start_date'
																											)}{' '}
																										</span>
																										{element.fecha_desde}
																									</h4>
																								</Col>
																								<Col
																									xs={24}
																									sm={14}
																									md={7}
																									lg={7}
																									xl={7}>
																									<h4 className='est-user-publications-list-reservations-main-title'>
																										<span className='est-user-publications-list-reservations-main-span-title'>
																											{t(
																												'profile.user_publication.tabs.end_date'
																											)}{' '}
																										</span>
																										{element.fecha_hasta}
																									</h4>
																								</Col>
																								<Col
																									xs={24}
																									sm={10}
																									md={7}
																									lg={7}
																									xl={7}>
																									<h4 className='est-user-publications-list-reservations-main-title'>
																										<span className='est-user-publications-list-reservations-main-span-title'>
																											Subtotal:{' '}
																										</span>
																										${element.precio}
																									</h4>
																								</Col>
																								<Col
																									xs={24}
																									sm={14}
																									md={10}
																									lg={10}
																									xl={10}>
																									<h4 className='est-user-publications-list-reservations-main-title'>
																										<span className='est-user-publications-list-reservations-main-span-title'>
																											{t(
																												'profile.user_publication.tabs.taxes'
																											)}{' '}
																										</span>{' '}
																										${element.impuesto}
																									</h4>
																								</Col>
																								<Col
																									xs={24}
																									sm={10}
																									md={7}
																									lg={7}
																									xl={7}>
																									<h4 className='est-user-publications-list-reservations-main-title'>
																										<span className='est-user-publications-list-reservations-main-span-title'>
																											Total:{' '}
																										</span>{' '}
																										${element.total}
																									</h4>
																								</Col>
																							</Row>
																						</div>
																					))}
																				</>
																			) : (
																				<h3 className='est-user-publications-list-tab-main-subtitle'>
																					{t(
																						'profile.user_publication.tabs.subtitle'
																					)}
																				</h3>
																			)}
																		</TabPane>
																	</Tabs>
																</div>
															</Panel>
														))}
													</Collapse>
												) : (
													<LoadingData />
												)}
											</>
										) : (
											<h2 className='est-user-profile-publications-list-main-title'>
												{t('profile.user_publication.tabs.no_post_title')}
											</h2>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		)
	}
}
