/** @format */

import axios from 'axios'

import { notification } from 'antd'

import { ENV_CORE } from '../../../../../../components/Common/Hooks/Variables/Enviroment'

export const GetMetricsReservationsPay = async (item, traduce) => {
	let returnResponse
	await axios({
		method: 'POST',
		url: `${ENV_CORE}/api/metrics/reservations-pay`,
		data: {
			idioma: item,
		},
	})
		.then((response) => {
			if (response.data.statusCode === 200) {
				returnResponse = response.data.metricPay
			} else {
				notification['warning']({
					message: 'Warning:',
					description: `Service error: REACT_APP_SERVICE_CORE - Data Info`,
				})
			}
		})
		.catch(() => {
			notification['error']({
				message: `Error`,
				description: `${traduce.service_global_description}`,
			})
		})
	return returnResponse
}

export const GetMetricsReservationsAproved = async (item, traduce) => {
	let returnResponse
	await axios({
		method: 'POST',
		url: `${ENV_CORE}/api/metrics/reservations-mes`,
		data: {
			idioma: item,
		},
	})
		.then((response) => {
			if (response.data.statusCode === 200) {
				returnResponse = response.data.metricReserv
			} else {
				notification['warning']({
					message: 'Warning:',
					description: `Service error: REACT_APP_SERVICE_CORE - Data Info`,
				})
			}
		})
		.catch(() => {
			notification['error']({
				message: `Error`,
				description: `${traduce.service_global_description}`,
			})
		})
	return returnResponse
}
