/** @format */

import React from 'react'
import { Route, Redirect } from 'react-router-dom'

class PrivateLogin extends React.Component {
	render() {
		let isLogin = JSON.parse(localStorage.getItem('userSession'))
		if (isLogin) {
			return <Route {...this.props} />
		}
		return <Redirect to='/' />
	}
}
export default PrivateLogin
