/** @format */

import React, { useState, useEffect } from 'react'

import { useTranslation } from 'react-i18next'

import axios from 'axios'

import { Link } from 'react-router-dom'

import MainNavigation from '../../../../../components/Common/MainNavigation'

import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import Button from 'antd/lib/button'
import Form from 'antd/lib/form'
import message from 'antd/lib/message'
import Upload from 'antd/lib/upload'
import Modal from 'antd/lib/modal'
import notification from 'antd/lib/notification'

import { CloudUploadOutlined } from '@ant-design/icons'

import { ENV_CORE } from '../../../../../components/Common/Hooks/Variables/Enviroment'

import UploadImage from './components/UploadImage'

import Spacer from '../../../../../components/Common/Spacer'

import Loading from '../../../../../components/Common/Loading'

import './style.css'

export default function AdsPhotos() {
	const { t } = useTranslation()
	const [isInfoPhoto] = useState(JSON.parse(localStorage.getItem('info-location')))
	const [isPreviewModal, setPreviewModal] = useState(false)
	const [isPreviewImg, setPreviewImg] = useState('')
	const { Dragger } = Upload
	const handleImageGalleryDelete = async (item) => {
		let data = isInfoPhoto.regImageGallery
		data = data.filter(function (data) {
			return data.uid !== item.uid
		})
		isInfoPhoto.regImageGallery = data
	}
	const handleImageGalleryUpload = async (options) => {
		const { onSuccess, onError, file, onProgress } = options
		const data = new FormData()
		data.append('imagen[]', file)
		const config = {
			headers: { 'Content-Type': 'multipart/form-data' },
			onUploadProgress: (event) => {
				onProgress({ percent: (event.loaded / event.total) * 100 })
			},
		}
		if (isInfoPhoto.regImageGallery.length <= 4) {
			try {
				const response = await axios.post(`${ENV_CORE}/api/upload/upload-image`, data, config)
				if (response) {
					if (response.data.statusCode === 200) {
						let info = {
							uid: file.uid,
							image: `${ENV_CORE}/${response.data.imageInfo[0].file}`,
							name: response.data.imageInfo[0].name,
							old_name: response.data.imageInfo[0].old_name,
							old_title: response.data.imageInfo[0].old_title,
						}
						isInfoPhoto.regImageGallery.push(info)
						onSuccess('Ok')
						message.success(t('publication.create.photos.message_success'))
					} else {
						message.error(t('publication.create.photos.message_error_one'))
					}
				}
			} catch (err) {
				onError({ err })
				message.error(t('publication.create.photos.message_error_one'))
			}
		} else {
			onError()
			message.error(t('publication.create.photos.message_error_two'))
		}
		if (isInfoPhoto.regImageGallery.length > 5) {
			isInfoPhoto.regImageGallery.forEach((item) => {
				let a = item.uid.split('-')
				item.position = a[3]
			})
			let gallery = (isInfoPhoto.regImageGallery = isInfoPhoto.regImageGallery.sort(
				(item, data) => item.position - data.position
			))

			isInfoPhoto.regImageGallery = gallery
			let images = isInfoPhoto.regImageGallery.slice(0, 5)
			isInfoPhoto.regImageGallery = images
		}
	}
	const handlePhotosInfo = async () => {
		if (isInfoPhoto.regImageGallery.length <= 0) {
			notification['warning']({
				message: t('publication.create.photos.warning_message'),
				description: t('publication.create.photos.warning_description_one'),
			})
			return
		}
		if (!isInfoPhoto.regMainImage) {
			notification['warning']({
				message: t('publication.create.photos.warning_message'),
				description: t('publication.create.photos.warning_description_two'),
			})
			return
		}
		if (isInfoPhoto.regOferta) {
			if (!isInfoPhoto.regDealImage) {
				notification['warning']({
					message: t('publication.create.photos.warning_message'),
					description: t('publication.create.photos.warning_description_three'),
				})
				return
			}
		}
		localStorage.setItem('info-photos', JSON.stringify(isInfoPhoto))
		window.location.pathname = 'publication/create/amenities'
	}
	const handlePreview = async (item) => {
		setPreviewModal(true)
		setPreviewImg(item.thumbUrl)
	}
	const handleAddProductImage = (item, data) => {
		item.file = `${ENV_CORE}/${item.file}`
		if (data === 'principal') {
			isInfoPhoto.regMainImage = item
		} else if (data === 'ofert') {
			isInfoPhoto.regDealImage = item
		}
	}
	const handleDeteleImage = (item) => {
		if (item === 'principal') {
			isInfoPhoto.regMainImage = null
		} else if (item === 'ofert') {
			isInfoPhoto.regDealImage = null
		}
	}
	useEffect(() => {
		if (!isInfoPhoto) {
			window.location.pathname = '/'
		}
	}, [isInfoPhoto])
	if (!isInfoPhoto) {
		return <Loading />
	} else {
		return (
			<>
				<MainNavigation
					title={t('publication.create.photos.main_navigation.title')}
					linkPrevTitle={'Home'}
					linkNextTitle={t('publication.create.photos.main_navigation.title')}
					linkPrev={'/'}
					linkNext={'/publication/create/photos'}
				/>
				<div className='est-ads-photos-global-container'>
					<div className='est-ads-photos-main-container'>
						<h2 className='est-ads-photos-global-title'>
							{t('publication.create.photos.title')}
						</h2>
						<h3 className='est-ads-photos-global-subtitle'>
							{t('publication.create.photos.subtitle')}
						</h3>
						<Form name='location' onFinish={handlePhotosInfo}>
							<Row className='est-ads-photos-map-global-container'>
								<Col
									xs={24}
									sm={24}
									md={isInfoPhoto.regOffer ? 12 : 24}
									lg={isInfoPhoto.regOffer ? 12 : 24}
									xl={isInfoPhoto.regOffer ? 12 : 24}
									className='est-ads-photos-map-main-container'>
									<h4 className='est-ads-photos-map-title'>
										{t('publication.create.photos.main_photo')}
									</h4>
									<UploadImage
										type={'principal'}
										title={'Principal'}
										addItemImage={(item, data) => handleAddProductImage(item, data)}
										imageDelete={(item) => handleDeteleImage(item)}
									/>
								</Col>
								{isInfoPhoto.regOffer && (
									<Col
										xs={24}
										sm={24}
										md={12}
										lg={12}
										xl={12}
										className='est-ads-photos-map-main-container'>
										<h4 className='est-ads-photos-map-title'>
											{t('publication.create.photos.offer_photo')}
										</h4>
										<UploadImage
											type={'ofert'}
											title={'Oferta'}
											addItemImage={(item, data) => handleAddProductImage(item, data)}
											imageDelete={(item) => handleDeteleImage(item)}
										/>
									</Col>
								)}
							</Row>
							<Row className='est-ads-photos-map-global-container'>
								<Col span={24} className='est-ads-photos-map-main-container'>
									<h4 className='est-ads-photos-map-title'>
										{t('publication.create.photos.gallery_photo')}
									</h4>
									<div className='est-ads-photos-dragger-container'>
										<Dragger
											className='est-ads-photos-dragger'
											maxCount={5}
											accept='image/*'
											customRequest={handleImageGalleryUpload}
											onRemove={handleImageGalleryDelete}
											multiple={true}
											onPreview={handlePreview}
											listType='picture-card'>
											<p className='est-upload-drag-title'>
												{t('publication.create.photos.gallery_placeholder_one')}
											</p>
											<p className='est-upload-drag-subtitle'>
												{t('publication.create.photos.gallery_placeholder_two')}
											</p>
											<div className='est-upload-drag-icon-container'>
												<p>
													<CloudUploadOutlined />
												</p>
											</div>
										</Dragger>
									</div>
								</Col>
							</Row>
							<Row>
								<Col span={24} className='est-ads-photos-button-main-container'>
									<Link
										className='est-ads-photos-button'
										to='/publication/create/location'>
										{t('publication.create.photos.prev_button')}
									</Link>
									<Spacer />
									<Button className='est-ads-photos-button' htmlType={'submit'}>
										{t('publication.create.photos.next_button')}
									</Button>
								</Col>
							</Row>
						</Form>
						<Modal
							wrapClassName='est-upload-photo-modal-container'
							visible={isPreviewModal}
							title={t('publication.create.photos.modal_title')}
							footer={null}
							onCancel={() => setPreviewModal(false)}>
							{isPreviewImg && (
								<img alt='example' style={{ width: '100%' }} src={isPreviewImg} />
							)}
						</Modal>
					</div>
				</div>
			</>
		)
	}
}
