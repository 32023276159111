/** @format */

import React from 'react'

import Row from 'antd/lib/row'
import Col from 'antd/lib/col'

import Image from '../../../../../../src/components/Common/Image'
import CarouselGallery from '../../../../../components/Common/CarouselGallery'

import './style.css'

export default function Carrousel(props) {
	return (
		<Row className='est-carrousel-img-global-container'>
			<Col span={24} className='est-carrousel-img-main-container'>
				<Image
					classImg={'est-carrousel-img-principal'}
					image={props.isDetailCarrousel.picture.picture_url.file}
					alt={props.isDetailCarrousel.titulo}
					title={props.isDetailCarrousel.titulo}
				/>
			</Col>

			{/* <h4>Imagen de oferta:</h4> */}

			{/* <Col span={24}>
				<Image
					classImg={'est-carrousel-img-offer'}
					image={`${ENV_CORE}/${props.isDetailCarrousel.imagen_oferta.file}`}
					alt={props.isDetailCarrousel.titulo}
					title={props.isDetailCarrousel.titulo}
				/>
			</Col> */}

			{/* <h4>Galeria de imagenes:</h4> */}

			<Col span={24}>
				<div className='carrousel-gallery-global-container'>
					{props.isDetailCarrousel.picture.picture_galery.length >= 4 && (
						<CarouselGallery
							carouselItems={[4, 4, 3, 2, 1, 1]}
							slug={true}
							banners={props.isDetailCarrousel.picture.picture_galery}
							opacity={true}
						/>
					)}
					{props.isDetailCarrousel.picture.picture_galery.length === 3 && (
						<CarouselGallery
							carouselItems={[3, 3, 3, 2, 1, 1]}
							slug={true}
							banners={props.isDetailCarrousel.picture.picture_galery}
							opacity={true}
						/>
					)}
					{props.isDetailCarrousel.picture.picture_galery.length === 2 && (
						<CarouselGallery
							carouselItems={[2, 2, 2, 2, 1, 1]}
							slug={true}
							banners={props.isDetailCarrousel.picture.picture_galery}
							opacity={true}
						/>
					)}
					{props.isDetailCarrousel.picture.picture_galery.length === 1 && (
						<CarouselGallery
							carouselItems={[1, 1, 1, 1, 1, 1]}
							slug={true}
							banners={props.isDetailCarrousel.picture.picture_galery}
							opacity={true}
						/>
					)}
				</div>
			</Col>
		</Row>
	)
}
