/** @format */

import React, { useEffect, useState } from 'react'

import { useCookies } from 'react-cookie'

import { useTranslation } from 'react-i18next'

import Row from 'antd/lib/row'
import Col from 'antd/lib/col'

import { Link } from 'react-router-dom'

import Image from '../../../../components/Common/Image'
//import ArticleOneMain from '../../../../img/blog/articulo1/principal.jpg'
//import ArticleTwoMain from '../../../../img/blog/articulo2.jpg'
//import ArticleThreeMain from '../../../../img/blog/articulo3.jpg'

import { PlusCircleFilled } from '@ant-design/icons'

import './style.css'

export default function Blog(props) {
	const [cookies] = useCookies(['i18next'])
	const [isLanguaje, setLanguaje] = useState(null)
	const { t } = useTranslation()

	useEffect(() => {
		if (cookies.i18next === 'es') {
			setLanguaje('info_es')
		} else if (cookies.i18next === 'en') {
			setLanguaje('info_en')
		}
	}, [cookies.i18next])

	if (isLanguaje) {
		return (
			<div className='est-blog-global-container'>
				<div className='est-blog-main-container'>
					<div className='est-blog-container'>
						<Link to='/blog'>
							<h2 className='est-blog-title-primary'>{t('home.blog.title')}</h2>
						</Link>
						<h2 className='est-blog-title-secondary'>{t('home.blog.subtitle')}</h2>
					</div>
				</div>
				<div className='est-blog-map-main-container'>
					<Row className='est-blog-map-container'>
						{props.blog.map((item, index) => (
							<Col xs={24} sm={24} md={8} lg={8} xl={8} key={index}>
								<div className='est-card-blog-global-container'>
									<div className='est-card-blog-main-container'>
										<Link to={`/blog-detail/${item.id}`}>
											<div className='est-card-blog-img-main-container'>
												<Image
													classImg={'est-card-blog-img'}
													image={item.image}
													alt={item[isLanguaje] ? item[isLanguaje].title : ''}
													title={item[isLanguaje] ? item[isLanguaje].title : ''}
												/>
											</div>
											<h3 className='card-blog-name'>
												{item[isLanguaje] ? item[isLanguaje].title : ''}
											</h3>
											<h3 className='card-blog-subtitle'>
												{t('blog.author_title')} {item.name} {item.last_name}
											</h3>
											<h3 className='card-blog-description'>
												{item[isLanguaje] ? item[isLanguaje].content : ''}
											</h3>
											<h3 className='card-blog-link'>
												<PlusCircleFilled className='card-blog-link-icon' />
												{t('blog.see_more_button')}
											</h3>
										</Link>
									</div>
								</div>
							</Col>
						))}

						{/* <Col xs={24} sm={24} md={8} lg={8} xl={8}>
							<div className='est-card-blog-global-container'>
								<div className='est-card-blog-main-container'>
									<Link to='/blog/article-two'>
										<Image
											classImg={'est-card-blog-img'}
											image={ArticleTwoMain}
											alt={t('home.blog.article.name')}
											title={t('home.blog.article.name')}
										/>
										<h3 className='card-blog-name'>
											Puerto Rico: un paraíso para proteger y disfrutar
										</h3>
										<h3 className='card-blog-subtitle'>Por Estelí Capote</h3>
										<h3 className='card-blog-description'>
											Desde los años de la administración de Luis Muñoz Marín, Puerto
											Rico se ha vislumbrado como...
										</h3>

										<h3 className='card-blog-link'>
											<PlusCircleFilled className='card-blog-link-icon' />
											Ver más
										</h3>
									</Link>
								</div>
							</div>
						</Col>
						<Col xs={24} sm={24} md={8} lg={8} xl={8}>
							<div className='est-card-blog-global-container'>
								<div className='est-card-blog-main-container'>
									<Link to='/blog/article-three'>
										<Image
											classImg={'est-card-blog-img'}
											image={ArticleThreeMain}
											alt={t('home.blog.article.name')}
											title={t('home.blog.article.name')}
										/>
										<h3 className='card-blog-name'>La semilla de PRUJULA</h3>
										<h3 className='card-blog-subtitle'>La semilla de PRUJULA</h3>
										<h3 className='card-blog-description'>
											Desde que era una pioja, y gracias a mi papá, aprendí a amar mi
											país disfrutándolo, acampando en cada monte o playa que nos
											encontrábamos.
										</h3>
										<h3 className='card-blog-link'>
											<PlusCircleFilled className='card-blog-link-icon' />
											Ver más
										</h3>
									</Link>
								</div>
							</div>
						</Col> */}
					</Row>
				</div>
			</div>
		)
	} else {
		return ''
	}
}
