/** @format */
import React, { useEffect, useState } from 'react'
import { RefinementList } from 'react-instantsearch-dom'
import { useTranslation } from 'react-i18next'

import { useHistory } from 'react-router-dom'

import algoliasearch from 'algoliasearch/lite'
import {
	InstantSearch,
	SearchBox,
	Configure,
	Pagination,
	ClearRefinements,
} from 'react-instantsearch-dom'

import { GoogleMapsLoader, GeoSearch, Marker } from 'react-instantsearch-dom-maps'

import Filters from './components/Filters'
import Results from './components/Results'

import { Dropdown, Row, Col, Button } from 'antd'

import { ENV_ALGOLIA_KEY, ENV_ALGOLIA_ID, ENV_API_GOOGLE } from '../Hooks/Variables/Enviroment'

import withURLSync from './services'
import './style.css'

function AlgoliaSearch(props) {
	const { t } = useTranslation()
	const history = useHistory()
	const [isMobile, setMobile] = useState(false)
	const searchClient = algoliasearch(ENV_ALGOLIA_KEY, ENV_ALGOLIA_ID)

	const handleFilter = (props) => {
		let { category, views } = props
		const filters = []
		if (props.params.categories) {
			category = props.params.categories
		}
		if (props.params.views) {
			views = props.params.views
		}
		if (category) {
			filters.push(`categorias:${category}`)
		}

		if (views) {
			filters.push(`vistas:${views}`)
		}
		return filters.join(' OR ')
	}

	const handleSimilarQuerys = (props) => {
		let { anuncios } = props
		const filters = []
		if (props.params.id) {
			anuncios = props.params.id
			anuncios = anuncios.replaceAll('-', ' ')
		}
		filters.push(`'${anuncios}'`)
		return anuncios
	}

	const handleHitMarker = (item) => {
		history.push(`/publication/detail/${item.objectID}`)
	}

	useEffect(() => {
		if (window.innerWidth < 769) {
			setMobile(true)
		}
	}, [])

	return (
		<>
			<div className='est-algolia-global-container'>
				<div className='est-algolia-main-container'>
					<InstantSearch
						searchClient={searchClient}
						indexName='publications'
						searchState={props.searchState}
						createURL={props.createURL}
						routing={true}
						onSearchStateChange={props.onSearchStateChange}>
						<Configure
							filters={handleFilter(props)}
							similarQuery={handleSimilarQuerys(props)}
						/>
						{isMobile && (
							<div className='est-mobile-algolia-global-contrainer'>
								<div className='est-mobile-googlemap-global-contrainer'>
									<GoogleMapsLoader apiKey={ENV_API_GOOGLE}>
										{(google) => (
											<GeoSearch google={google} minZoom={2}>
												{({ hits }) => (
													<div>
														{hits.map((hit) => (
															<Marker
																key={hit.objectID}
																hit={hit}
																onClick={() => handleHitMarker(hit)}
															/>
														))}
														<ClearRefinements
															className='ClearGeoRefinement'
															transformItems={(items) =>
																items.filter((item) => item.id === 'boundingBox')
															}
															translations={{
																reset: 'Clear the map refinement',
															}}
														/>
													</div>
												)}
											</GeoSearch>
										)}
									</GoogleMapsLoader>
								</div>
								<Row>
									<Col xs={10} sm={10} md={10} lg={10} xl={10}>
										<h3 className='est-mobile-algolia-search-filters-title'>
											{t('algolia.mobile_menu.search_title')}
										</h3>
										{/* <hr className='est-mobile-algolia-search-filters-spacer'></hr> */}
									</Col>
									<Col xs={14} sm={14} md={14} lg={14} xl={14}>
										<h2 className='est-mobile-results-menu-filter-title'>
											{t('algolia.mobile_menu.category_title')}
										</h2>
										{/* <hr className='est-results-menu-filter-spacer'></hr> */}
									</Col>
									<Col
										xs={10}
										sm={10}
										md={10}
										lg={10}
										xl={10}
										className='est-mobile-algolia-search-filters-container'>
										<header className='header search-box-main-container'>
											<SearchBox
												translations={{
													placeholder: `${t(
														'algolia.mobile_menu.searchbox_placeholder'
													)}`,
												}}
											/>
										</header>
									</Col>
									<Col xs={14} sm={14} md={14} lg={14} xl={14}>
										<Row className='est-mobile-search-filters-global-container'>
											<Col
												xs={12}
												sm={12}
												md={8}
												lg={8}
												xl={8}
												className='est-mobile-search-filters-main-container'>
												<Dropdown
													trigger={['click']}
													overlay={
														<RefinementList withSearchBox attribute='amenidades' />
													}
													placement='bottomCenter'>
													<Button className='est-mobile-categories-button-filter'>
														{t('algolia.mobile_menu.amenities_button')}
													</Button>
												</Dropdown>
											</Col>
											<Col
												xs={12}
												sm={12}
												md={8}
												lg={8}
												xl={8}
												className='est-mobile-search-filters-main-container'>
												<Dropdown
													trigger={['click']}
													overlay={
														<RefinementList attribute='completeAddress.state' />
													}
													placement='bottomCenter'>
													<Button className='est-mobile-categories-button-filter'>
														{t('algolia.mobile_menu.states_button')}
													</Button>
												</Dropdown>
											</Col>
											<Col
												xs={24}
												sm={24}
												md={8}
												lg={8}
												xl={8}
												className='est-mobile-search-filters-main-container-three'>
												<Dropdown
													trigger={['click']}
													overlay={<RefinementList attribute='category' />}
													placement='bottomCenter'>
													<Button className='est-mobile-categories-button-filter-three'>
														{t('algolia.mobile_menu.category_button')}
													</Button>
												</Dropdown>
											</Col>
										</Row>

										{/* <Row>
											<Col span={10} className='est-mobile-left-container'>
												<h3 className='est-mobile-left-container-title'>Amenidades</h3>
											</Col>
											<Col span={7} className='est-mobile-middle-container'>
												<h3 className='est-mobile-middle-container-title'>Estados</h3>
											</Col>
											<Col span={7} className='est-mobile-right-container'>
												<h3 className='est-mobile-right-container-title'>Categorias</h3>
											</Col>
										</Row> */}
									</Col>
									<Col span={24} className='est-mobile-algolia-results-container'>
										<Results />
									</Col>
								</Row>
							</div>
						)}
						<Row>
							<Col span={18} className='est-algolia-results-container'>
								<Results />
							</Col>
							<Col span={6} className='est-algolia-search-filters-container'>
								<div className='est-algolia-search-filters-main-container'>
									<h3 className='est-algolia-search-filters-title'>
										{t('algolia.mobile_menu.search_title')}
									</h3>
									<hr className='est-algolia-search-filters-spacer'></hr>
									<header className='header search-box-main-container'>
										<SearchBox
											translations={{
												placeholder: `${t(
													'algolia.mobile_menu.searchbox_placeholder'
												)}`,
											}}
										/>
									</header>
								</div>
								<Filters isMobile={isMobile} />
							</Col>
							<Col span={24} className='est-algolia-pagination-global-container'>
								<div className='est-algolia-pagination-main-container'>
									<Pagination />
								</div>
							</Col>
						</Row>
					</InstantSearch>
				</div>
			</div>
		</>
	)
}
export default withURLSync(AlgoliaSearch)
