/** @format */

import React from 'react'

import Row from 'antd/lib/row'
import Col from 'antd/lib/col'

import CarouselGallery from '../../../../../../components/Common/CarouselGallery'

import CarrouselImgOne from '../../../../../../img/blog/articulo1/image.jpg'
import CarrouselImgTwo from '../../../../../../img/blog/articulo1/image_1.jpg'
import CarrouselImgThree from '../../../../../../img/blog/articulo1/image_2.jpg'
import CarrouselImgFour from '../../../../../../img/blog/articulo1/image_3.jpg'
import CarrouselImgFive from '../../../../../../img/blog/articulo1/image_4.jpg'
import CarrouselImgSix from '../../../../../../img/blog/articulo1/image_5.jpg'
import CarrouselImgSeven from '../../../../../../img/blog/articulo1/image_6.jpg'
import CarrouselImgEight from '../../../../../../img/blog/articulo1/image_7.jpg'
import CarrouselImgNine from '../../../../../../img/blog/articulo1/image_8.jpg'
import CarrouselImgTen from '../../../../../../img/blog/articulo1/image_9.jpg'
import CarrouselImgEleven from '../../../../../../img/blog/articulo1/image_10.jpg'
import CarrouselImgTwelve from '../../../../../../img/blog/articulo1/image_11.jpg'
import CarrouselImgThriteen from '../../../../../../img/blog/articulo1/image_12.jpg'

import './style.css'

export default function Carrousel() {
	return (
		<Row className='est-carrousel-article-one-global-container'>
			<Col span={24}>
				<div className='carrousel-article-one-gallery-container'>
					<CarouselGallery
						carouselItems={[4, 4, 3, 2, 1, 1]}
						slug={true}
						banners={[
							{ image: CarrouselImgOne },
							{ image: CarrouselImgTwo },
							{ image: CarrouselImgThree },
							{ image: CarrouselImgFour },
							{ image: CarrouselImgFive },
							{ image: CarrouselImgSix },
							{ image: CarrouselImgSeven },
							{ image: CarrouselImgEight },
							{ image: CarrouselImgNine },
							{ image: CarrouselImgTen },
							{ image: CarrouselImgEleven },
							{ image: CarrouselImgTwelve },
							{ image: CarrouselImgThriteen },
						]}
						opacity={true}
					/>
				</div>
			</Col>
		</Row>
	)
}
