/** @format */

import React, { useState } from 'react'

import { useTranslation } from 'react-i18next'

import { Line } from 'react-chartjs-2'

import './style.css'

export default function DataLine(props) {
	const { t } = useTranslation()
	const [isDataLine] = useState({
		labels: props.data.labels,
		datasets: [
			{
				label: `${t('profile.data_metrics.metric_bar.label_title')}`,
				fill: false,
				lineTension: 0.1,
				backgroundColor: 'rgba(10,189,89,0.4)',
				borderColor: 'rgba(61,150,102,1)',
				borderCapStyle: 'butt',
				borderDash: [],
				borderDashOffset: 0.0,
				borderJoinStyle: 'miter',
				pointBorderColor: 'rgba(61,150,102,1)',
				pointBackgroundColor: '#fff',
				pointBorderWidth: 1,
				pointHoverRadius: 5,
				pointHoverBackgroundColor: 'rgba(10,189,89,0.4)',
				pointHoverBorderColor: 'rgba(61,150,102,1)',
				pointHoverBorderWidth: 2,
				pointRadius: 1,
				pointHitRadius: 10,
				data: props.data.data,
			},
		],
	})
	return (
		<>
			<h2 className='est-data-metrics-line-title'>
				{t('profile.data_metrics.metric_bar.main_title')}
			</h2>
			<Line
				data={isDataLine}
				height={props.mobile ? 200 : 70}
				options={{
					responsive: true,
					maintainAspectRatio: true,
				}}
			/>
		</>
	)
}
