/** @format */

import React, { useState } from 'react'
import AwesomeSwiper from 'react-awesome-swiper'

import CardPublication from './components/CardPublication'

import './style.css'

export default function CarouselAds(props) {
	const [isConfig] = useState({
		spaceBetween: 0,
		loop: false,
		/* 		autoplay: {
			delay: 3000,
			stopOnLastSlide: false,
			disableOnInteraction: true,
		}, */
		autoplay: false,
		preloadImages: false,
		lazy: true,
		speed: 500,
		navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev',
		},
		breakpoints: {
			1024: {
				slidesPerView: props.carouselItems[0],
				spaceBetween: 0,
			},
			992: {
				slidesPerView: props.carouselItems[1],
				spaceBetween: 0,
			},
			768: {
				slidesPerView: props.carouselItems[2],
				spaceBetween: 0,
			},
			640: {
				slidesPerView: props.carouselItems[3],
				spaceBetween: 0,
			},
			320: {
				slidesPerView: props.carouselItems[4],
				spaceBetween: 0,
			},
			250: {
				slidesPerView: props.carouselItems[5],
				spaceBetween: 0,
			},
		},
	})
	return (
		<>
			<AwesomeSwiper ref={(ref) => ref} config={isConfig}>
				<div className='swiper-wrapper'>
					{props.item.map(function (item, index) {
						return (
							<div className='swiper-slide' key={index}>
								<CardPublication item={item} />
							</div>
						)
					})}
				</div>
				<div className='swiper-button-prev ph-carousel-prev'></div>
				<div className='swiper-button-next ph-carousel-next'></div>
			</AwesomeSwiper>
		</>
	)
}
