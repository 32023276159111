/** @format */

import React, { useState } from 'react'

import { setGlobal } from 'reactn'

import { Row, Col, Button, Modal, Form, Select } from 'antd'

import { useTranslation } from 'react-i18next'

import Input from '../../../../../../../../components/Common/Inputs/Normal'

import { rulesValidation } from '../../../../../../../../components/Common/Inputs/Normal/rules'

import { GetAllUsers } from '../../services'

import CreateUserService from './services'

import './style.css'

const CreateUser = () => {
	const { t } = useTranslation()
	const [form] = Form.useForm()
	const { Option } = Select
	const [isVisible, setVisible] = useState(false)
	const [isLoading, setLoading] = useState(false)

	const handleCreateUser = async (item) => {
		delete item.confirm
		item.regModo = 'directo'
		item.regVerificacion = 0
		setLoading(true)

		const traduce = {
			service_success_message: t(
				'profile.general_users.service_create_user.service_success_message'
			),
			service_success_description: t(
				'profile.general_users.service_create_user.service_success_description'
			),
			service_warning_message: t(
				'profile.general_users.service_create_user.service_warning_message'
			),
			service_warning_description: t(
				'profile.general_users.service_create_user.service_warning_description'
			),
			service_error_description: t(
				'profile.general_users.service_create_user.service_error_description'
			)
		}
		await CreateUserService(item,traduce).then((response) => {
			if (response) {
				GetAllUsers().then((responseUsers) => {
					setGlobal({
						allUsers: responseUsers,
					})
					form.resetFields()
				})
			}
		})
		setLoading(false)
		setVisible(false)
	}

	return (
		<>
			<Button
				className='est-general-list-create-users-button'
				type='primary'
				onClick={() => setVisible(true)}>
				{t('profile.general_users.menu_create_user')}
			</Button>
			<Modal
				forceRender
				wrapClassName='est-user-modal-container'
				maskClosable={false}
				width='700px'
				centered
				visible={isVisible}
				onCancel={() => setVisible()}
				okText='Confirmar'
				cancelButtonProps={{ style: { display: 'none' } }}
				okButtonProps={{ style: { display: 'none' } }}>
				<h3 className='est-auth-edit-profile-modal-title'>
					{t('profile.general_users.users_info.modal_create_user_title')}
				</h3>

				<Form form={form} name='user_create' onFinish={handleCreateUser}>
					<div className='est-auth-login-form-container'>
						<Row>
							<Col span={12} className='est-login-form-text-container'>
								<h4 className='est-login-form-text'>
									{t('profile.user_data.modal_edit_user.input_name')}
								</h4>
								<Input
									className={'est-auth-login-field-input'}
									inputName={'regName'}
									inputNameLabel={t('profile.user_data.modal_edit_user.placerholder_name')}
									inputNameRule={true}
									inputNameMessage={'Nombre es obligatorio.'}
									inputNameType={'text'}
									inputNameIcon={''}
									inputNameRules={t('field_notifications.normal.rules_first_name')}
									disabled={false}
								/>
							</Col>
							<Col span={12} className='est-login-form-text-container'>
								<h4 className='est-login-form-text'>
									{t('profile.user_data.modal_edit_user.input_last_name')}
								</h4>
								<Input
									className={'est-auth-login-field-input'}
									inputName={'regLast'}
									inputNameLabel={t(
										'profile.user_data.modal_edit_user.placerholder_last_name'
									)}
									inputNameRule={true}
									inputNameMessage={'Nombre es obligatorio.'}
									inputNameType={'text'}
									inputNameIcon={''}
									inputNameRules={t('field_notifications.normal.rules_last_name')}
								/>
							</Col>
							<Col span={24} className='est-login-form-text-container'>
								<h4 className='est-login-form-text'>
									{t('profile.user_data.modal_edit_user.input_email')}
								</h4>
								<Input
									className={'est-auth-login-field-input'}
									inputName={'regEmail'}
									inputNameLabel={t('profile.user_data.modal_edit_user.input_email')}
									inputNameRule={true}
									inputNameMessage={'E-mail es obligatorio'}
									inputNameType={'text'}
									inputNameIcon={''}
									inputNameRules={t('field_notifications.normal.rules_email')}
									disabled={false}
								/>
							</Col>
							<Col span={24} className='est-login-form-text-container'>
								<h4 className='est-login-form-text'>
									{t('profile.user_data.modal_edit_user.input_profile_type')}
								</h4>
								<div className='est-create-user-modal-selector'>
									<Form.Item name={'regPerfil'} rules={rulesValidation.rulesRequiredES}>
										<Select>
											<Option value='0'>
												{t('profile.user_data.modal_edit_user.profile_type_option_one')}
											</Option>
											<Option value='1'>
												{t('profile.user_data.modal_edit_user.profile_type_option_two')}
											</Option>
										</Select>
									</Form.Item>
								</div>
							</Col>
							<Col span={24} className='est-login-form-text-container'>
								<h4 className='est-login-form-text'>
									{t('profile.general_users.users_info.modal_create_user_pass')}
								</h4>
								<Input
									className={'ph-auth-register-field-input'}
									inputName={'regPassword'}
									inputNameLabel={t(
										'profile.general_users.users_info.modal_create_user_pass'
									)}
									inputNameRule={true}
									inputNameMessage={'Ingresa tu contraseña'}
									inputNameType={'password'}
									inputNameIcon={''}
									inputNameRules={t('field_notifications.normal.rules_password')}
								/>
								<Input
									className={'ph-auth-register-field-input'}
									inputName={'confirm'}
									inputNameLabel={t(
										'profile.general_users.users_info.modal_create_user_confirm_pass'
									)}
									inputNameRule={true}
									inputNameMessage={'Ingresa tu contraseña'}
									inputNameType={'password'}
									inputNameIcon={''}
									dependencies={['password']}
									hasFeedback
									inputNameRules={t('field_notifications.normal.rules_confirm_password')}
								/>
							</Col>
						</Row>
						<Form.Item>
							<div>
								<div className='est-user-modal-button-main-container'>
									<Button
										className='est-user-modal-button-main'
										type='primary'
										htmlType={'submit'}
										loading={isLoading}>
										{t('profile.user_data.modal_edit_user.button')}
									</Button>
								</div>
							</div>
						</Form.Item>
					</div>
				</Form>
			</Modal>
		</>
	)
}
export default CreateUser
