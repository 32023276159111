/** @format */

import React from 'react'

import { useTranslation } from 'react-i18next'

import { Tabs, Row, Col } from 'antd'

import ReviewComment from './components/ReviewComment'

import Image from '../../../../../../src/components/Common/Image'
import ProfileImg from '../../../../../../src/img/detail/profile-example.png'

import './style.css'

export default function Coments(props) {
	const { t } = useTranslation()
	const { TabPane } = Tabs
	return (
		<div className='est-comments-global-container'>
			<div className='est-comments-main-container'>
				<div className='est-comments-tab-global-container'>
					<div className='est-comments-tab-main-container'>
						<Tabs defaultActiveKey='1'>
							<TabPane tab={t('publication.detail.comments.tabs.description_title')} key='1'>
								<div className='tab-main-container'>
									<h3 className='tab-subtitle'>{props.item.description}</h3>
									<h3 className='tab-subtitle'>
										<span className='est-description-subtitle'>
											{t('publication.detail.comments.tabs.amount_people')}
										</span>
										{props.item.people}
									</h3>
									{/* <h3 className='tab-subtitle'>
										<span className='est-description-subtitle'>
											{t('publication.detail.comments.tabs.reserved_with')}
										</span>
										{parseInt(props.item.discount_amount)}%
									</h3> */}
								</div>
							</TabPane>
							<TabPane tab={t('publication.detail.comments.tabs.amenities_title')} key='2'>
								<Row>
									{(props.item.amenidades.agua === '1' ||
										props.item.amenidades.luz === '1' ||
										props.item.amenidades.tocador === '1' ||
										props.item.amenidades.cocinas === '1' ||
										props.item.amenidades.bbq === '1' ||
										props.item.amenidades.fogata === '1') && (
										<div className='est-amenities-container'>
											<h2 className='tab-title'>
												{t('publication.detail.comments.tabs.title_services')}
											</h2>
											{props.item.amenidades.agua === '1' && (
												<h3 className='tab-subtitle'>
													{t('publication.detail.comments.tabs.services_water')}
												</h3>
											)}
											{props.item.amenidades.luz === '1' && (
												<h3 className='tab-subtitle'>
													{t('publication.detail.comments.tabs.services_light')}
												</h3>
											)}
											{props.item.amenidades.tocador === '1' && (
												<h3 className='tab-subtitle'>
													{t('publication.detail.comments.tabs.services_toilet')}
												</h3>
											)}
											{props.item.amenidades.cocinas === '1' && (
												<h3 className='tab-subtitle'>
													{t('publication.detail.comments.tabs.services_kitchen')}
												</h3>
											)}
											{props.item.amenidades.bbq === '1' && (
												<h3 className='tab-subtitle'>BBQ</h3>
											)}
											{props.item.amenidades.fogata === '1' && (
												<h3 className='tab-subtitle'>
													{t('publication.detail.comments.tabs.services_campfire')}
												</h3>
											)}
										</div>
									)}
									{(props.item.amenidades.historico === '1' ||
										props.item.amenidades.ecologia === '1' ||
										props.item.amenidades.agricola === '1' ||
										props.item.amenidades.reactivo_pasivo === '1' ||
										props.item.amenidades.reactivo_activo === '1') && (
										<div className='est-amenities-container'>
											<h2 className='tab-title'>
												{t('publication.detail.comments.tabs.title_site')}
											</h2>
											{props.item.amenidades.historico === '1' && (
												<h3 className='tab-subtitle'>
													{t('publication.detail.comments.tabs.site_history')}
												</h3>
											)}
											{props.item.amenidades.ecologia === '1' && (
												<h3 className='tab-subtitle'>
													{t('publication.detail.comments.tabs.site_reserve')}
												</h3>
											)}
											{props.item.amenidades.agricola === '1' && (
												<h3 className='tab-subtitle'>
													{t('publication.detail.comments.tabs.site_farming')}
												</h3>
											)}
											{props.item.amenidades.reactivo_pasivo === '1' && (
												<h3 className='tab-subtitle'>
													{t('publication.detail.comments.tabs.site_recess_pas')}
												</h3>
											)}
											{props.item.amenidades.reactivo_activo === '1' && (
												<h3 className='tab-subtitle'>
													{t('publication.detail.comments.tabs.site_recess_act')}
												</h3>
											)}
										</div>
									)}
									{(props.item.amenidades.recreacion_piscinas === '1' ||
										props.item.amenidades.recreacion_acuaticas === '1' ||
										props.item.amenidades.recreacion_veredas === '1' ||
										props.item.amenidades.recreacion_espeleologia === '1' ||
										props.item.amenidades.recreacion_kayac_paddle_balsas === '1' ||
										props.item.amenidades.recreacion_cocina === '1' ||
										props.item.amenidades.recreacion_pajaros === '1' ||
										props.item.amenidades.recreacion_alpinismo === '1' ||
										props.item.amenidades.recreacion_zipline === '1' ||
										props.item.amenidades.paracaidas === '1' ||
										props.item.amenidades.recreacion_areas === '1' ||
										props.item.amenidades.recreacion_animales === '1') && (
										<div className='est-amenities-container'>
											<h2 className='tab-title'>
												{t('publication.detail.comments.tabs.title_activity')}
											</h2>
											{props.item.amenidades.recreacion_acuaticas === '1' && (
												<h3 className='tab-subtitle'>
													{t('publication.detail.comments.tabs.activity_aqua')}
												</h3>
											)}
											{props.item.amenidades.recreacion_alpinismo === '1' && (
												<h3 className='tab-subtitle'>
													{t('publication.detail.comments.tabs.activity_climb')}
												</h3>
											)}
											{props.item.amenidades.recreacion_areas === '1' && (
												<h3 className='tab-subtitle'>
													{t('publication.detail.comments.tabs.activity_meeting')}
												</h3>
											)}
											{props.item.amenidades.recreacion_animales === '1' && (
												<h3 className='tab-subtitle'>
													{t('publication.detail.comments.tabs.activity_endemic')}
												</h3>
											)}
											{props.item.amenidades.recreacion_pajaros === '1' && (
												<h3 className='tab-subtitle'>
													{t('publication.detail.comments.tabs.activity_birds')}
												</h3>
											)}
											{props.item.amenidades.recreacion_cocina === '1' && (
												<h3 className='tab-subtitle'>
													{t('publication.detail.comments.tabs.activity_cuisine')}
												</h3>
											)}
											{props.item.amenidades.recreacion_espeleologia === '1' && (
												<h3 className='tab-subtitle'>
													{t('publication.detail.comments.tabs.activity_potho')}
												</h3>
											)}
											{props.item.amenidades.recreacion_kayac_paddle_balsas === '1' && (
												<h3 className='tab-subtitle'>
													{t('publication.detail.comments.tabs.activity_kayak')}
												</h3>
											)}
											{props.item.amenidades.paracaidas === '1' && (
												<h3 className='tab-subtitle'>
													{t('publication.detail.comments.tabs.activity_para')}
												</h3>
											)}
											{props.item.amenidades.recreacion_piscinas === '1' && (
												<h3 className='tab-subtitle'>
													{t('publication.detail.comments.tabs.activity_pool')}
												</h3>
											)}
											{props.item.amenidades.recreacion_veredas === '1' && (
												<h3 className='tab-subtitle'>
													{t('publication.detail.comments.tabs.activity_trails')}
												</h3>
											)}
											{props.item.amenidades.recreacion_zipline === '1' && (
												<h3 className='tab-subtitle'>Zip-line</h3>
											)}
										</div>
									)}
									{(props.item.amenidades.equipos_mesas === '1' ||
										props.item.amenidades.equipos_sillas === '1' ||
										props.item.amenidades.equipos_estufas === '1' ||
										props.item.amenidades.casetas_acampar === '1' ||
										props.item.amenidades.toldos === '1' ||
										props.item.amenidades.estufas_gas === '1' ||
										props.item.amenidades.tanques_gas === '1' ||
										props.item.amenidades.lena === '1' ||
										props.item.amenidades.carbon === '1') && (
										<div className='est-amenities-container'>
											<h2 className='tab-title'>
												{t('publication.detail.comments.tabs.title_equipment')}
											</h2>
											{props.item.amenidades.equipos_mesas === '1' && (
												<h3 className='tab-subtitle'>
													{t('publication.detail.comments.tabs.equipment_table')}
												</h3>
											)}
											{props.item.amenidades.equipos_sillas === '1' && (
												<h3 className='tab-subtitle'>
													{t('publication.detail.comments.tabs.equipment_chair')}
												</h3>
											)}
											{props.item.amenidades.equipos_estufas === '1' && (
												<h3 className='tab-subtitle'>
													{t('publication.detail.comments.tabs.equipment_stove')}
												</h3>
											)}
											{props.item.amenidades.casetas_acampar === '1' && (
												<h3 className='tab-subtitle'>
													{t('publication.detail.comments.tabs.equipment_camping')}
												</h3>
											)}
											{props.item.amenidades.toldos === '1' && (
												<h3 className='tab-subtitle'>
													{t('publication.detail.comments.tabs.equipment_awning')}
												</h3>
											)}
											{props.item.amenidades.estufas_gas === '1' && (
												<h3 className='tab-subtitle'>
													{t('publication.detail.comments.tabs.equipment_gas_stoves')}
												</h3>
											)}
											{props.item.amenidades.tanques_gas === '1' && (
												<h3 className='tab-subtitle'>
													{t('publication.detail.comments.tabs.equipment_gas_tank')}
												</h3>
											)}
											{props.item.amenidades.lena === '1' && (
												<h3 className='tab-subtitle'>
													{t('publication.detail.comments.tabs.equipment_firewood')}
												</h3>
											)}
											{props.item.amenidades.carbon === '1' && (
												<h3 className='tab-subtitle'>
													{t('publication.detail.comments.tabs.equipment_coal')}
												</h3>
											)}
										</div>
									)}

									{(props.item.amenidades.se_admiten_mascotas === '1' ||
										props.item.amenidades.perros_servicios === '1') && (
										<div className='est-amenities-container'>
											<h2 className='tab-title'>
												{t('publication.detail.comments.tabs.title_others')}
											</h2>
											{props.item.amenidades.se_admiten_mascotas === '1' && (
												<h3 className='tab-subtitle'>
													{t('publication.detail.comments.tabs.others_pets')}
												</h3>
											)}
											{props.item.amenidades.perros_servicios === '1' && (
												<h3 className='tab-subtitle'>
													{t('publication.detail.comments.tabs.others_service')}
												</h3>
											)}
										</div>
									)}
								</Row>
							</TabPane>
							{/* <TabPane tab='COMPARTIR' key='3'>
								<div className='tab-main-container'>
									<h2 className='tab-title'>Lorem compartir</h2>
									<h3 className='tab-subtitle'>
										Pellentesque mauris est, pellentesque eget posuere rhoncus, viverra
										sed nibh.
									</h3>
								</div>
							</TabPane> */}
						</Tabs>
					</div>
				</div>

				{/* 	<div className='est-points-global-container'>
					<div className='est-points-main-container'>
						<div className='est-points-content-main-container'>
							<div className='est-points-content'>
								<h2 className='est-points-title'>50</h2>
								<h3 className='est-points-subtitle'>Visitantes</h3>
								<h3 className='est-points-subtitle'>Mensuales</h3>
							</div>

							<div className='est-points-content'>
								<h2 className='est-points-title'>120</h2>
								<h3 className='est-points-subtitle'>Reseñas</h3>
							</div>

							<div className='est-points-content'>
								<h2 className='est-points-title'>200</h2>
								<h3 className='est-points-subtitle'>Comentarios</h3>
								<h3 className='est-points-subtitle'>Positivos</h3>
							</div>
						</div>
					</div>
				</div> */}
				{props.item.detailCalification && (
					<div className='est-comments-global-container'>
						<div className='est-comments-main-container'>
							<div className='est-comments-title-container'>
								<h3 className='est-comments-title'>
									{t('publication.detail.comments.main_title')}
								</h3>
							</div>
							{props.item.detailCalification.length > 0 ? (
								<>
									{props.item.detailCalification.map((item, index) => (
										<Row key={index} className='est-comments-list-main-container'>
											<Col span={6} className='est-comments-img-profile-name-container'>
												<div className='est-comments-img-container'>
													{item.foto ? (
														<Image
															classImg={'est-comments-img'}
															image={item.foto}
															alt={'Profile photo'}
															title={'Profile photo'}
														/>
													) : (
														<Image
															classImg={'est-comments-img'}
															image={ProfileImg}
															alt={'Profile photo'}
															title={'Profile photo'}
														/>
													)}
													{/* 	<Image
														classImg={'est-comments-img'}
														image={item.foto}
														alt={'Profile photo'}
														title={'Profile photo'}
													/> */}
												</div>
												<div className='est-comments-profile-name-container'>
													<h3 className='est-comments-profile-name-title'>
														{item.nombre} {item.apellido}
													</h3>
												</div>
											</Col>
											<Col
												span={18}
												className='est-comments-button-star-global-container'>
												<div className='est-comments-button-star-main-container'>
													<div className='est-comments-star-container'>
														<ReviewComment review={item.calificacion} />
														{/* 
														<h3 className='est-comments-star'>
															<StarFilled />
														</h3>
														<h3 className='est-comments-star'>
															<StarFilled />
														</h3>
														<h3 className='est-comments-star'>
															<StarFilled />
														</h3>
														<h3 className='est-comments-star'>
															<StarOutlined />
														</h3>
														<h3 className='est-comments-star'>
															<StarOutlined />
														</h3> */}
													</div>

													{/* 	
														<Col>
															<div className='est-comments-button-container'>
																<h3 className='est-comments-button'>
																	<LeftSquareFilled />
																</h3>
																<h3 className='est-comments-button'>
																	<RightSquareFilled />
																</h3>
															</div>
														</Col> 
													*/}

													<h4 className='est-review-main-comment'>
														{item.comentario}
													</h4>
												</div>
											</Col>
										</Row>
									))}
								</>
							) : (
								<div>
									<h3 className='est-review-main-comment'>
										{t('publication.detail.comments.main_subtitle')}
									</h3>
								</div>
							)}
						</div>
					</div>
				)}
			</div>
		</div>
	)
}
