/** @format */

import React from 'react'

import { useTranslation } from 'react-i18next'

import { Modal, Button } from 'antd'

import { setGlobal } from 'reactn'

import './style.css'

export default function ModalLogOut(props) {
	const { t } = useTranslation()
	const handleLogOut = () => {
		localStorage.removeItem('userSession')
		setGlobal(() => ({ userEmail: null, userData: null }))
		props.showModalLogOut(false)
		window.location.pathname = '/'
	}
	return (
		<>
			<Modal
				wrapClassName='est-profile-logout-modal-global-container'
				centered
				title={t('header.modal_log_out.title')}
				visible={props.visible}
				onCancel={() => props.showModalLogOut(false)}
				cancelButtonProps={{ style: { display: 'none' } }}
				okButtonProps={{ style: { display: 'none' } }}
				okText=''
				cancelText=''
				footer={false}>
				<p className='est-profile-logout-modal-title'>{t('header.modal_log_out.subtitle')}</p>
				<p className='est-profile-logout-modal-description'>
					{t('header.modal_log_out.description')}
				</p>
				<div className='est-profile-logout-modal-button-container'>
					<Button className='est-profile-logout-modal-button' onClick={() => handleLogOut()}>
						{t('header.modal_log_out.button')}
					</Button>
				</div>
			</Modal>
		</>
	)
}
