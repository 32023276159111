/** @format */

import React from 'react'

import { StarOutlined, StarFilled } from '@ant-design/icons'

import './style.css'

const ReviewComment = (props) => {
	return (
		<h3 className='est-comment-review-starts-icon'>
			{props.review <= 0 && (
				<div className='est-review-stars-no-review-container'>
					<StarFilled />
					<StarFilled />
					<StarFilled />
					<StarFilled />
					<StarFilled />
				</div>
			)}
			{props.review > 0 && props.review < 1 && (
				<>
					<StarOutlined />
					<StarOutlined />
					<StarOutlined />
					<StarOutlined />
					<StarOutlined />
				</>
			)}
			{props.review >= 1 && props.review < 2 && (
				<>
					<StarFilled />
					<StarOutlined />
					<StarOutlined />
					<StarOutlined />
					<StarOutlined />
				</>
			)}
			{props.review >= 2 && props.review < 3 && (
				<>
					<StarFilled />
					<StarFilled />
					<StarOutlined />
					<StarOutlined />
					<StarOutlined />
				</>
			)}
			{props.review >= 3 && props.review < 4 && (
				<>
					<StarFilled />
					<StarFilled />
					<StarFilled />
					<StarOutlined />
					<StarOutlined />
				</>
			)}
			{props.review >= 4 && props.review < 5 && (
				<>
					<StarFilled />
					<StarFilled />
					<StarFilled />
					<StarFilled />
					<StarOutlined />
				</>
			)}
			{props.review >= 5 && (
				<>
					<StarFilled />
					<StarFilled />
					<StarFilled />
					<StarFilled />
					<StarOutlined />
				</>
			)}
		</h3>
	)
}
export default ReviewComment
