/** @format */

import React from 'react'

import { useTranslation } from 'react-i18next'

import HeadDescription from '../../components/Common/HeadDescription'
import MainNavigation from '../../components/Common/MainNavigation'

import './style.css'

export default function TermsConditions() {
	const { t } = useTranslation()
	return (
		<>
			<HeadDescription
				title={`${t('terms_conditions.head_description.title')}`}
				name={'description'}
				content={'Camping Site'}
			/>
			<MainNavigation
				title={t('terms_conditions.main_navigation.title')}
				linkPrevTitle={'Home'}
				linkNextTitle={t('terms_conditions.main_navigation.title')}
				linkPrev={'/'}
				linkNext={'/terms-conditions'}
			/>
			<div className='est-terms-condition-global-container'>
				<div className='est-terms-condition-text-global-container'>
					<div className='est-terms-condition-text-main-container'>
						<h2 className='est-terms-condition-main-title'>
							Terms of Service for Non-European Users
						</h2>
						<h4 className='est-terms-condition-main-text'>Thank you for using PRújula!</h4>
						<h4 className='est-terms-condition-main-text'>
							These Terms of Service (“Terms”) are a binding legal agreement between you and
							PRújula that govern your use of the websites, applications, and other offerings
							from PRújula (collectively, the “PRújula Platform”). When used in these Terms,
							“PRújula,” “we,” “us,” or “our” refers to the PRújula entity set out on
							Schedule 1 with whom you are contracting.
						</h4>
						<h4 className='est-terms-condition-main-text'>
							The PRújula Platform offers an online venue that enables users (“Members”) to
							publish, offer, search for, and book services. Members who publish and offer
							services are “Hosts” and Members who search for, book, or use services are
							“Guests.” Hosts offer accommodations (“Accommodations”), activities,
							excursions, and events (“Experiences”), and a variety of travel and other
							services (collectively, “Host Services,” and each Host Service offering, a
							“Listing”). You must register an account to access and use many features of the
							PRújula Platform, and must keep your account information accurate. As the
							provider of the PRújula Platform, PRújula does not own, control, offer or
							manage any Listings or Host Services. PRújula is not a party to the contracts
							concluded directly between Hosts and Guests, nor is PRújula a real estate
							broker or insurer. PRújula is not acting as an agent in any capacity for any
							Member, except as specified in the Payments Terms of Service (“Payment Terms”).
							To learn more about PRújula’s role see Section 16.
						</h4>
						<h4 className='est-terms-condition-main-text'>
							We maintain other terms and policies that supplement these Terms like our
							Privacy Policy, which describes our collection and use of personal data, and
							our Payments Terms, which govern any payment services provided to Members by
							the PRújula payment entities ("PRújula Payments").
						</h4>
						<h4 className='est-terms-condition-main-text'>
							If you Host, you are responsible for understanding and complying with all laws,
							rules, regulations and contracts with third parties that apply to your Host
							Services.
						</h4>
					</div>
					<div className='est-terms-condition-text-main-container'>
						<h2 className='est-terms-condition-main-title'>Table of Contents</h2>
						<ol className='est-terms-condition-ol-container'>
							<h2 className='est-terms-condition-main-title'>Guest Terms</h2>
							<li className='est-terms-condition-li'>Our Mission. </li>
							<li className='est-terms-condition-li'>Searching and Booking on PRújula.</li>
							<li className='est-terms-condition-li'>
								Cancellations, Travel Issues, Refunds and Booking Modifications.
							</li>
							<li className='est-terms-condition-li'>
								Your Responsibilities and Assumption of Risk.
							</li>
							<h2 className='est-terms-condition-main-title'>Host Terms</h2>
							<li className='est-terms-condition-li'>Hosting on PRújula.</li>
							<li className='est-terms-condition-li'>Managing Your Listing.</li>
							<li className='est-terms-condition-li'>
								Cancellations, Travel Issues, and Booking Modifications.
							</li>
							<li className='est-terms-condition-li'>Taxes.</li>
							<h2 className='est-terms-condition-main-title'>General Terms</h2>
							<li className='est-terms-condition-li'>Reviews.</li>
							<li className='est-terms-condition-li'>Content.</li>
							<li className='est-terms-condition-li'>Fees.</li>
							<li className='est-terms-condition-li'>PRújula Platform Rules.</li>
							<li className='est-terms-condition-li'>
								Termination, Suspension and other Measures.
							</li>
							<li className='est-terms-condition-li'>Modification.</li>
							<li className='est-terms-condition-li'>
								Resolving Complaints and Damage Claims.
							</li>
							<li className='est-terms-condition-li'>PRújula’s Role.</li>
							<li className='est-terms-condition-li'>Member Accounts.</li>
							<li className='est-terms-condition-li'>Disclaimer of Warranties.</li>
							<li className='est-terms-condition-li'>Limitations on Liability.</li>
							<li className='est-terms-condition-li'>Indemnification.</li>
							<li className='est-terms-condition-li'>Contracting Entities.</li>
							<li className='est-terms-condition-li'>
								22. United States Governing Law and Venue.
							</li>
							<li className='est-terms-condition-li'>
								United States Dispute Resolution and Arbitration Agreement.
							</li>
							<li className='est-terms-condition-li'>
								China Governing Law and Dispute Resolution.
							</li>
							<li className='est-terms-condition-li'>
								Rest of World Dispute Resolution, Venue and Forum, and Governing Law.
							</li>
							<li className='est-terms-condition-li'>Miscellaneous.</li>
						</ol>
						<h4 className='est-terms-condition-main-text'>
							Schedule 1 - Contracting Entities
						</h4>
					</div>
					<div className='est-terms-condition-text-main-container'>
						<h2 className='est-terms-condition-main-title'>Guest Terms</h2>
						<h3 className='est-terms-condition-main-subtitle'>1. Our Mission.</h3>
						<h4 className='est-terms-condition-main-text'>
							Our mission is to create a world where you can belong anywhere. From cabins to
							castles to cooking classes, browse through millions of Listings to find the
							ones that fit the way you like to travel. Learn more about a Listing by
							reviewing the description and photos, the Host profile, and Guest reviews. If
							you have questions, just message the Host.
						</h4>
						<h3 className='est-terms-condition-main-subtitle'>
							2. Searching and Booking on PRújula.
						</h3>
						<h4 className='est-terms-condition-main-text'>
							<span className='est-terms-condition-main-text-title'>2.1 Searching.</span> You
							can search for Host Services by using criteria like the type of Host Service,
							travel destination, travel dates, and number of guests. You can also use
							filters to refine your search results. Search results are based on their
							relevance to your search and other criteria. Relevance considers factors like
							price, availability, Reviews, customer service and cancellation history,
							popularity, previous trips and saved Listings, Host requirements (e.g. minimum
							or maximum nights), and more. Learn more about search results in our Help
							Center.
						</h4>
						<h4 className='est-terms-condition-main-text'>
							<span className='est-terms-condition-main-text-title'>2.2 Booking.</span> When
							you book a Listing, you are agreeing to pay all charges for your booking
							including the Listing price, applicable fees like PRújula’s service fee,
							offline fees, taxes, and any other items identified during checkout
							(collectively, “Total Price”). You are also agreeing that PRújula Payments may
							charge and collect any security deposit identified during checkout. When you
							receive the booking confirmation, a contract for Host Services (sometimes
							called a reservation in these Terms) is formed directly between you and the
							Host. The cancellation policy and any other rules, standards, policies, or
							requirements identified in the Listing or during checkout form part of your
							contract with the Host. Be aware that some Hosts work with a co-host or as part
							of a team to provide their Host Services.
						</h4>
						<h4 className='est-terms-condition-main-text'>
							<span className='est-terms-condition-main-text-title'>
								2.3 Accommodation Reservations.
							</span>{' '}
							An Accommodation reservation is a limited license to enter, occupy, and use the
							Accommodation. The Host retains the right to re-enter the Accommodation during
							your stay, to the extent: (i) it is reasonably necessary, (ii) permitted by
							your contract with the Host, and (iii) consistent with applicable law. If you
							stay past checkout, the Host has the right to make you leave in a manner
							consistent with applicable law, including by imposing reasonable overstay
							penalties. You may not exceed the maximum number of allowed Guests.
						</h4>
						<h4 className='est-terms-condition-main-text'>
							<span className='est-terms-condition-main-text-title'>
								2.4 Reservations for Experiences and Other Host Services.
							</span>{' '}
							An Experience or other Host Service reservation entitles you to participate in,
							attend, or use that Host Service. You are responsible for confirming that you,
							and anyone you invite, meet minimum age, proficiency, fitness, or other
							requirements. You are responsible for informing the Host of any medical or
							physical conditions, or other circumstances that may impact your ability to
							participate, attend, or use the Host Service. Except where expressly
							authorized, you may not allow any person to join a Host Service unless they are
							included as an additional guest during the booking process.
						</h4>
						<h3 className='est-terms-condition-main-subtitle'>
							3. Cancellations, Travel Issues, Refunds and Booking Modifications.
						</h3>
						<h4 className='est-terms-condition-main-text'>
							<span className='est-terms-condition-main-text-title'>
								3.1 Cancellations, Travel Issues, and Refunds.
							</span>{' '}
							In general, if as a Guest you cancel a reservation, the amount refunded to you
							is determined by the cancellation policy that applies to that reservation. But,
							in certain situations, other policies take precedence and determine what amount
							is refunded to you. If something outside your control forces you to cancel a
							reservation, you may be eligible for a partial or full refund under our
							Extenuating Circumstances Policy. If the Host cancels, or you experience a
							Travel Issue (as defined in our Guest Refund Policy), you may be eligible for
							rebooking assistance or a partial or full refund under the Guest Refund Policy.
							Different policies apply to certain categories of Listings; for example
							Experiences reservations are governed by the Experiences Guest Refund Policy.
							See each Policy for details about what is covered, and what refund applies in
							each situation.
						</h4>
						<h4 className='est-terms-condition-main-text'>
							<span className='est-terms-condition-main-text-title'>
								3.2 Booking Modifications.
							</span>{' '}
							Guests and Hosts are responsible for any booking modifications they agree to
							make via the PRújula Platform or direct PRújula customer service to make on
							their behalf ("Booking Modifications"), and agree to pay any additional
							amounts, fees, or taxes associated with any Booking Modification.
						</h4>
						<h3 className='est-terms-condition-main-subtitle'>
							4. Your Responsibilities and Assumption of Risk.
						</h3>
						<h4 className='est-terms-condition-main-text'>
							<span className='est-terms-condition-main-text-title'>
								4.1 Your Responsibilities.
							</span>{' '}
							You are responsible and liable for your own acts and omissions and are also
							responsible for the acts and omissions of anyone you invite to join or provide
							access to any Accommodation, Experience or other Host Service. For example,
							this means: (i) you are responsible for leaving an Accommodation (and related
							personal property) in the condition it was in when you arrived, and (ii) you
							must act with integrity, treat others with respect, and comply with applicable
							laws at all times. If you are booking for an additional guest who is a minor or
							if you bring a minor to a Host Service, you must be legally authorized to act
							on behalf of the minor and you are solely responsible for the supervision of
							that minor.
						</h4>
						<h4 className='est-terms-condition-main-text'>
							<span className='est-terms-condition-main-text-title'>
								4.2 Your Assumption of Risk.
							</span>{' '}
							You acknowledge that many activities carry inherent risks and agree that, to
							the maximum extent permitted by applicable law, you assume the entire risk
							arising out of your access to and use of the PRújula Platform and any Content
							(as defined in Section 10), including your stay at any Accommodation,
							participation in any Experience, use of any other Host Service, or any other
							interaction you have with other Members whether in person or online. This means
							it is your responsibility to investigate a Host Service to determine whether it
							is suitable for you. For example, Host Services may carry risk of illness,
							bodily injury, disability, or death, and you freely and willfully assume those
							risks by choosing to participate in those Host Services.
						</h4>
					</div>
					<div className='est-terms-condition-text-main-container'>
						<h2 className='est-terms-condition-main-title'>Host Terms</h2>
						<h3 className='est-terms-condition-main-subtitle'>5. Hosting on PRújula.</h3>
						<h4 className='est-terms-condition-main-text'>
							<span className='est-terms-condition-main-text-title'>5.1 Host.</span> As a
							Host, PRújula offers you the opportunity to share your Accommodation,
							Experience, or other Host Service with our vibrant community of Guests - and
							earn money doing it. It’s easy to create a Listing and you are in control of
							how you host - set your price, availability, and rules for each Listing.
						</h4>
						<h4 className='est-terms-condition-main-text'>
							<span className='est-terms-condition-main-text-title'>
								5.2 Contracting with Guests.
							</span>{' '}
							When you accept a booking request, or receive a booking confirmation through
							the PRújula Platform, you are entering into a contract directly with the Guest,
							and are responsible for delivering your Host Service under the terms and at the
							price specified in your Listing. You are also agreeing to pay applicable fees
							like PRújula’s service fee (and applicable taxes) for each booking. PRújula
							Payments will deduct amounts you owe from your payout unless we and you agree
							to a different method. Any terms, policies or conditions that you include in
							any supplemental contract with Guests must: (i) be consistent with these Terms,
							our Policies, and the information provided in your Listing, and (ii) be
							prominently disclosed in your Listing description.
						</h4>
						<h4 className='est-terms-condition-main-text'>
							<span className='est-terms-condition-main-text-title'>
								5.3 Independence of Hosts.
							</span>{' '}
							Your relationship with PRújula is that of an independent individual or entity
							and not an employee, agent, joint venturer, or partner of PRújula, except that
							PRújula Payments acts as a payment collection agent as described in the
							Payments Terms. PRújula does not direct or control your Host Service, and you
							agree that you have complete discretion whether and when to provide Host
							Services, and at what price and on what terms to offer them.
						</h4>
						<h3 className='est-terms-condition-main-subtitle'>6. Managing Your Listing.</h3>
						<h4 className='est-terms-condition-main-text'>
							<span className='est-terms-condition-main-text-title'>
								6.1 Creating and Managing Your Listing.
							</span>{' '}
							The PRújula Platform provides tools that make it easy for you to set up and
							manage a Listing. Your Listing must include complete and accurate information
							about your Host Service, your price, other charges like cleaning fees, resort
							fees, security deposits, offline fees, and any rules or requirements that apply
							to your Guests or Listing. You are responsible for keeping your Listing
							information (including calendar availability) and content (like photos)
							up-to-date and accurate at all times. We recommend that you obtain appropriate
							insurance for your Host Services and suggest you carefully review policy terms
							and conditions including coverage details and exclusions. You may only maintain
							one Listing per Accommodation, but may have multiple Listings for a single
							property if it has multiple places to stay. Any offer of an Experience is
							subject to our Additional Terms for Experience Hosts.
						</h4>
						<h4 className='est-terms-condition-main-text'>
							<span className='est-terms-condition-main-text-title'>
								6.2 Know Your Legal Obligations.
							</span>{' '}
							You are responsible for understanding and complying with any laws, rules,
							regulations, and contracts with third parties that apply to your Listing or
							Host Services. For example: Some landlords and leases, or homeowner and
							condominium association rules, restrict or prohibit subletting, short-term
							rentals and/or longer-term stays. Some cities have zoning or other laws that
							restrict the short-term rental of residential properties. Some jurisdictions
							require Hosts to register, get a permit, or obtain a license before providing
							certain Host Services (such as short-term rentals, longer-term stays, preparing
							food, serving alcohol for sale, guiding tours, or operating a vehicle). In some
							places, the Host Services you want to offer may be prohibited altogether. Some
							jurisdictions require that you register Guests who stay at your Accommodation.
							Some jurisdictions have laws that create tenancy rights for Guests and
							additional obligations for Hosts. For example, some places have
							landlord-tenant, rent control, and eviction laws that may apply to longer
							stays. Check your local rules to learn what rules apply to the Host Services
							you plan to offer. Information we provide regarding legal requirements is for
							informational purposes only and you should independently confirm your
							obligations. You are responsible for handling and using personal data of Guests
							and others in compliance with applicable privacy laws and these Terms,
							including our Host Privacy Standards. If you have questions about how local
							laws apply you should always seek legal advice.
						</h4>
						<h4 className='est-terms-condition-main-text'>
							<span className='est-terms-condition-main-text-title'>
								6.3 Search Ranking.
							</span>{' '}
							The ranking of Listings in search results on the PRújula Platform depends on a
							variety of factors, including these main parameters:
						</h4>
						<ul>
							<li className='est-terms-condition-li'>
								Guest search parameters (e.g. number of Guests, time and duration of the
								trip, price range),
							</li>
							<li className='est-terms-condition-li'>
								Listing characteristics (e.g. price, calendar availability, number and
								quality of images, Reviews, type of Host Service, Host status, age of the
								Listing, average Guest popularity),
							</li>
							<li className='est-terms-condition-li'>
								Guest booking experience (e.g. customer service and cancellation history of
								the Host, ease of booking),
							</li>
							<li className='est-terms-condition-li'>
								Host requirements (e.g. minimum or maximum nights, booking cut-off time),
								and
							</li>
							<li className='est-terms-condition-li'>
								Guest preferences (e.g. previous trips, saved Listings, location from where
								the Guest is searching).
							</li>
						</ul>
						<h4 className='est-terms-condition-main-text'>
							Search results may appear different on our mobile application than they appear
							on our website. PRújula may allow Hosts to promote their Listings in search or
							elsewhere on the PRújula Platform by paying an additional fee. More information
							about the factors that determine how your Listing appears in search results,
							our current promotional programs (if any), and how we identify promoted Content
							can be found in our Help Center.
						</h4>
						<h4 className='est-terms-condition-main-text'>
							<span className='est-terms-condition-main-text-title'>
								6.4 Your Responsibilities.
							</span>{' '}
							You are responsible and liable for your own acts and omissions and are also
							responsible for the acts and omissions of anyone you allow to participate in
							providing your Host Services. You are responsible for setting your price and
							establishing rules and requirements for your Listing. You must describe any and
							all fees and charges in your Listing description and may not collect any
							additional fees or charges outside the PRújula Platform except those expressly
							authorized by our Offline Fee Policy. Do not encourage Guests to create
							third-party accounts, submit reviews, provide their contact information, or
							take other actions outside the PRújula Platform in violation of our
							Off-Platform Policy.
						</h4>
						<h4 className='est-terms-condition-main-text'>
							<span className='est-terms-condition-main-text-title'>
								6.5 Hosting as a Team or Organization.
							</span>{' '}
							If you work with a co-host or host as part of a team, business, or other
							organization, the entity and each individual who participates in providing Host
							Services is responsible and liable as a Host under these Terms. If you accept
							terms or enter into contracts, you represent and warrant that you are
							authorized to enter into contracts for and bind your team, business or other
							organization, and that each entity you use is in good standing under the laws
							of the place where it is established. If you perform other functions, you
							represent and warrant that you are authorized to perform those functions. If
							you instruct PRújula to transfer a portion of your payout to a co-host or other
							Hosts, or to send payments to someone else, you must be authorized to do so,
							and are responsible and liable for the payment amounts and accuracy of any
							payout information you provide.
						</h4>
						<h4 className='est-terms-condition-main-text'>
							<span className='est-terms-condition-main-text-title'>
								6.6 Your Assumption of Risk.
							</span>{' '}
							You acknowledge that hosting carries inherent risks and agree that you assume
							the entire risk arising out of your access to and use of the PRújula Platform,
							offering Host Services, or any interaction you have with other Members whether
							in person or online. You agree that you have had the opportunity to investigate
							the PRújula Platform and any laws, rules, regulations, or obligations that may
							be applicable to your Listings or Host Services and that you are not relying
							upon any statement of law made by PRújula.
						</h4>
						<h3 className='est-terms-condition-main-subtitle'>
							7. Cancellations, Travel Issues, and Booking Modifications.
						</h3>
						<h4 className='est-terms-condition-main-text'>
							<span className='est-terms-condition-main-text-title'>
								7.1 Cancellations and Travel Issues.
							</span>{' '}
							In general, if a Guest cancels a reservation, the amount paid to you is
							determined by the cancellation policy that applies to that reservation. As a
							host, you should not cancel on a Guest without a valid reason under our
							Extenuating Circumstances Policy or applicable law. If you cancel on a Guest
							without such a valid reason, we may impose a cancellation fee and other
							consequences. If: (i) a Guest experiences a Travel Issue (as defined by the
							Guest Refund Policy), (ii) an Extenuating Circumstance arises, or (iii) a
							reservation is cancelled under Section 13 of these Terms, the amount you are
							paid will be reduced by the amount we refund or otherwise provide to the Guest,
							and by any other reasonable costs we incur as a result of the cancellation. If
							a Guest receives a refund after you have already been paid, or the amount of
							the refund and other costs incurred by PRújula exceeds your payout, PRújula
							(via PRújula Payments) may recover that amount from you, including by
							offsetting the refund against your future payouts. You agree that PRújula’s
							Guest Refund Policy, Extenuating Circumstances Policy, and these Terms preempt
							the cancellation policy you set in situations where they allow for the
							cancellation of a reservation and/or the issuance of refunds to Guests. If we
							reasonably expect to provide a refund to a Guest under one of these policies,
							we may delay release of any payout for that reservation until a refund decision
							is made. If you Host an Experience please note that the Experience Cancellation
							Policy, Experiences Guest Refund Policy and different cancellation fees and
							consequences apply to your reservations. See each Policy for details about what
							is covered, and what your payout will be in each situation.
						</h4>
						<h4 className='est-terms-condition-main-text'>
							<span className='est-terms-condition-main-text-title'>
								7.2 Booking Modifications.
							</span>{' '}
							Hosts and Guests are responsible for any Booking Modifications they agree to
							make via the PRújula Platform or direct PRújula customer service to make on
							their behalf, and agree to pay any additional amounts, fees or taxes associated
							with a Booking Modification.
						</h4>
						<h3 className='est-terms-condition-main-subtitle'>8. Taxes.</h3>
						<h4 className='est-terms-condition-main-text'>
							<span className='est-terms-condition-main-text-title'>8.1 Host Taxes.</span> As
							a host, you are responsible for determining and fulfilling your obligations
							under applicable laws to report, collect, remit, or include in your price any
							applicable VAT or other indirect taxes, occupancy taxes, tourist, income, or
							other taxes ("
							<span className='est-terms-condition-main-text-title'>Taxes</span>").
						</h4>
						<h4 className='est-terms-condition-main-text'>
							<span className='est-terms-condition-main-text-title'>
								8.2 Collection and Remittance by PRújula.
							</span>{' '}
							In jurisdictions where PRújula facilitates the collection and/or remittance of
							Taxes on behalf of Hosts, you instruct and authorize PRújula to collect Taxes
							on your behalf, and/or to remit such Taxes to the relevant Tax authority. Any
							Taxes that are collected and/or remitted by PRújula are identified to Members
							on their transaction records, as applicable. PRújula may seek additional
							amounts from Members (including by deducting such amounts from future payouts)
							in the event that the Taxes collected and/or remitted are insufficient to fully
							discharge that Members’ tax obligations, and you agree that your sole remedy
							for Taxes collected by PRújula is a refund from the applicable Tax authority.
							You acknowledge and agree that we retain the right, with prior notice to
							affected Members, to cease the collection and remittance of Taxes in any
							jurisdiction for any reason.
						</h4>
						<h4 className='est-terms-condition-main-text'>
							<span className='est-terms-condition-main-text-title'>
								8.3 Tax Information.
							</span>{' '}
							In certain jurisdictions, Tax regulations may require that we collect and/or
							report Tax information about you, or withhold Taxes from payouts to you, or
							both. If you fail to provide us with documentation that we determine to be
							sufficient to support any such obligation to withhold Taxes from payouts to
							you, we may withhold payouts up to the amount as required by law, until
							sufficient documentation is provided. You agree that PRújula may issue on your
							behalf invoices or similar documentation for VAT, GST, consumption or other
							Taxes for your Host Services to facilitate accurate tax reporting by you, our
							Guests, and/or their organizations.
						</h4>
					</div>
					<div className='est-terms-condition-text-main-container'>
						<h2 className='est-terms-condition-main-title'>General Terms</h2>
						<h3 className='est-terms-condition-main-subtitle'>9. Reviews.</h3>
						<h4 className='est-terms-condition-main-text'>
							After each Host Service, Guests and Hosts will have an opportunity to review
							each other. Your Review must be accurate and may not contain any
							discriminatory, offensive, defamatory, or other language that violates our
							Content Policy or Review Policy. Reviews are not verified by PRújula for
							accuracy and may be incorrect or misleading.
						</h4>
						<h3 className='est-terms-condition-main-subtitle'>10. Content.</h3>
						<h4 className='est-terms-condition-main-text'>
							Parts of the PRújula Platform enable you to provide feedback, text, photos,
							audio, video, information, and other content (collectively, “Content”). By
							providing Content, in whatever form and through whatever means, you grant
							PRújula a non-exclusive, worldwide, royalty-free, irrevocable, perpetual,
							sub-licensable and transferable license to copy, modify, prepare derivative
							works of, distribute, publish and otherwise exploit, that Content, without
							limitation. If Content includes personal information, our Privacy Policy
							describes how we use that personal information. Where PRújula pays for the
							creation of Content or facilitates its creation, PRújula may own that Content,
							in which case supplemental terms or disclosures will say that. You are solely
							responsible for all Content that you provide and warrant that you either own it
							or are authorized to grant PRújula the rights described in these Terms. You are
							responsible and liable if any of your Content violates or infringes the
							intellectual property or privacy rights of any third party. Content must comply
							with our Content Policy and Nondiscrimination Policy, which prohibit, among
							other things, discriminatory, obscene, harassing, deceptive, violent, and
							illegal content. You agree that PRújula may make available services or
							automated tools to translate Content and that your Content may be translated
							using such services or tools. PRújula does not guarantee the accuracy or
							quality of translations and Members are responsible for confirming the accuracy
							of such translations.
						</h4>
						<h3 className='est-terms-condition-main-subtitle'>11. Fees.</h3>
						<h4 className='est-terms-condition-main-text'>
							PRújula may charge fees (and applicable Taxes) to Hosts and Guests for use of
							the PRújula Platform. More information about when service fees apply and how
							they are calculated can be found on our Service Fees page. Except as otherwise
							provided on the PRújula Platform, service fees are non-refundable. PRújula
							reserves the right to change the service fees at any time, and will provide
							Members notice of any fee changes before they become effective. Fee changes
							will not affect bookings made prior to the effective date of the fee change. If
							you disagree with a fee change you may terminate this agreement at any time
							pursuant to Section 13.2.
						</h4>
						<h3 className='est-terms-condition-main-subtitle'>12. PRújula Platform Rules.</h3>
						<h4 className='est-terms-condition-main-text'>
							<span className='est-terms-condition-main-text-title'>12.1 Rules.</span> You
							must follow these rules and must not help or induce others to break or
							circumvent these rules.
						</h4>
						<ul>
							<li className='est-terms-condition-li'>
								Act with integrity and treat others with respect
							</li>
							<ul>
								<li className='est-terms-condition-li'>
									Do not lie, misrepresent something or someone, or pretend to be someone
									else.
								</li>
								<li className='est-terms-condition-li'>
									Be polite and respectful when you communicate or interact with others.
								</li>
								<li className='est-terms-condition-li'>
									Follow our Nondiscrimination Policy and do not discriminate against or
									harass others.
								</li>
							</ul>
							<li className='est-terms-condition-li'>
								Do not scrape, hack, reverse engineer, compromise or impair the PRújula
								Platform
							</li>
							<ul>
								<li className='est-terms-condition-li'>
									Do not use bots, crawlers, scrapers, or other automated means to access
									or collect data or other content from or otherwise interact with the
									PRújula Platform.
								</li>
								<li className='est-terms-condition-li'>
									Do not hack, avoid, remove, impair, or otherwise attempt to circumvent
									any security or technological measure used to protect the PRújula
									Platform or Content.
								</li>
								<li className='est-terms-condition-li'>
									Do not decipher, decompile, disassemble, or reverse engineer any of the
									software or hardware used to provide the PRújula Platform.
								</li>
								<li className='est-terms-condition-li'>
									Do not take any action that could damage or adversely affect the
									performance or proper functioning of the PRújula Platform.
								</li>
							</ul>
							<li className='est-terms-condition-li'>
								Only use the PRújula Platform as authorized by these Terms or another
								agreement with us
							</li>
							<ul>
								<li className='est-terms-condition-li'>
									You may only use another Member’s personal information as necessary to
									facilitate a transaction using the PRújula Platform as authorized by
									these Terms.
								</li>
								<li className='est-terms-condition-li'>
									Do not use the PRújula Platform, our messaging tools, or Members’
									personal information to send commercial messages without the recipient’s
									express consent.
								</li>
								<li className='est-terms-condition-li'>
									You may use Content made available through the PRújula Platform solely as
									necessary to enable your use of the PRújula Platform as a Guest or Host.
								</li>
								<li className='est-terms-condition-li'>
									Do not use Content unless you have permission from the Content owner or
									the use is authorized by us in these Terms or another agreement you have
									with us.
								</li>
								<li className='est-terms-condition-li'>
									Do not request, make, or accept a booking or any payment outside of the
									PRújula Platform to avoid paying fees, taxes or for any other reason. See
									our Offline Fee Policy for exceptions.
								</li>
								<li className='est-terms-condition-li'>
									Do not require or encourage Guests to open an account, leave a review,
									complete a survey, or otherwise interact, with a third party website,
									application or service before, during or after a reservation, unless
									authorized by PRújula.
								</li>
								<li className='est-terms-condition-li'>
									Do not engage in any practices that are intended to manipulate our search
									algorithm.
								</li>
								<li className='est-terms-condition-li'>
									Do not book Host Services unless you are actually using the Host
									Services.
								</li>
								<li className='est-terms-condition-li'>
									Do not use, copy, display, mirror or frame the PRújula Platform, any
									Content, any PRújula branding, or any page layout or design without our
									consent.
								</li>
							</ul>
							<li className='est-terms-condition-li'>Honor your legal obligations</li>
							<ul>
								<li className='est-terms-condition-li'>
									Understand and follow the laws that apply to you, including privacy, data
									protection, and export laws.
								</li>
								<li className='est-terms-condition-li'>
									If you provide us with someone else’s personal information, you: (i) must
									do so in compliance with applicable law, (ii) must be authorized to do
									so, and (iii) authorize us to process that information under our Privacy
									Policy.
								</li>
								<li className='est-terms-condition-li'>
									Read and follow our Terms, Policies and Standards
								</li>
								<li className='est-terms-condition-li'>
									Do not organize or facilitate unauthorized parties or events. You are
									responsible and liable for any party or event during your reservation
									that violates our rules for parties and events, as incorporated by
									reference herein.
								</li>
								<li className='est-terms-condition-li'>
									Do not use the name, logo, branding, or trademarks of PRújula or others
									without permission.
								</li>
								<li className='est-terms-condition-li'>
									Do not use or register any domain name, social media handle, trade name,
									trademark, branding, logo, or other source identifier that may be
									confused with PRújula branding.
								</li>
								<li className='est-terms-condition-li'>
									Do not offer Host Services that violate the laws or agreements that apply
									to you.
								</li>
								<li className='est-terms-condition-li'>
									Do not offer or solicit prostitution or participate in or facilitate
									human trafficking.
								</li>
							</ul>
						</ul>
						<h4 className='est-terms-condition-main-text'>
							<span className='est-terms-condition-main-text-title'>
								12.2 Reporting Violations.
							</span>{' '}
							If you believe that a Member, Listing or Content poses an imminent risk of harm
							to a person or property, you should immediately contact local authorities
							before contacting PRújula. In addition, if you believe that a Member, Listing
							or Content has violated our Standards, you should report your concerns to
							PRújula. If you reported an issue to local authorities, PRújula may request a
							copy of that report. Except as required by law, you agree that we are not
							obligated to take action in response to any report.
						</h4>
						<h4 className='est-terms-condition-main-text'>
							<span className='est-terms-condition-main-text-title'>
								12.3 Copyright Notifications.
							</span>{' '}
							If you believe that Content on the PRújula Platform infringes copyrights,
							please notify us in accordance with our Copyright Policy.
						</h4>
						<h3 className='est-terms-condition-main-subtitle'>
							13. Termination, Suspension and other Measures.
						</h3>
						<h4 className='est-terms-condition-main-text'>
							<span className='est-terms-condition-main-text-title'>13.1 Term.</span> The
							agreement between you and PRújula reflected by these Terms is effective when
							you access the PRújula Platform (for example to create an account) and remains
							in effect until either you or we terminate the agreement in accordance with
							these Terms.
						</h4>
						<h4 className='est-terms-condition-main-text'>
							<span className='est-terms-condition-main-text-title'>13.2 Termination.</span>{' '}
							You may terminate this agreement at any time by sending us an email or by
							deleting your account. PRújula may terminate this agreement and your account
							for any reason by giving you 30 days’ notice via email or using any other
							contact information you have provided for your account. PRújula may also
							terminate this agreement immediately and without notice and stop providing
							access to the PRújula Platform if you breach these Terms, you violate our
							Policies, you violate applicable laws, or we reasonably believe termination is
							necessary to protect PRújula, its Members, or third parties. If your account
							has been inactive for more than two years, we may terminate your account
							without prior notice.
						</h4>
						<h4 className='est-terms-condition-main-text'>
							<span className='est-terms-condition-main-text-title'>
								13.3 Member Violations.
							</span>{' '}
							If (i) you breach these Terms, our Policies, or our Standards, (ii) you violate
							applicable laws, regulations, or third-party rights, or (iii) PRújula believes
							it is reasonably necessary to protect PRújula, its Members, or third parties;
							PRújula may, with or without prior notice:
						</h4>
						<ul>
							<li className='est-terms-condition-li'>
								suspend or limit your access to or use of the PRújula Platform and/or your
								account;
							</li>
							<li className='est-terms-condition-li'>
								suspend or remove Listings, Reviews, or other Content;
							</li>
							<li className='est-terms-condition-li'>
								cancel pending or confirmed bookings; or
							</li>
							<li className='est-terms-condition-li'>
								suspend or revoke any special status associated with your account.
							</li>
						</ul>
						<h4 className='est-terms-condition-main-text'>
							For minor violations or where otherwise appropriate as PRújula determines in
							its sole discretion, you will be given notice of any intended measure by
							PRújula and an opportunity to resolve the issue. You may appeal actions taken
							by us under this Section by contacting customer service. If a reservation is
							cancelled under this Section, the amount paid to the Host will be reduced by
							the amount we refund or otherwise provide to the Guest, and by any other costs
							we incur as a result of the cancellation.
						</h4>
						<h4 className='est-terms-condition-main-text'>
							<span className='est-terms-condition-main-text-title'>
								13.4 Legal Mandates.
							</span>{' '}
							PRújula may take any action it determines is reasonably necessary to comply
							with applicable law, or the order or request of a court, law enforcement, or
							other administrative agency or governmental body, including the measures
							described above in Section 13.3.
						</h4>
						<h4 className='est-terms-condition-main-text'>
							<span className='est-terms-condition-main-text-title'>
								13.5 Effect of Termination.
							</span>{' '}
							If you are a Host and terminate your PRújula account, any confirmed booking(s)
							will be automatically cancelled and your Guests will receive a full refund. If
							you terminate your account as a Guest, any confirmed booking(s) will be
							automatically cancelled and any refund will depend upon the terms of the
							reservation’s cancellation policy. When this agreement has been terminated, you
							are not entitled to a restoration of your account or any of your Content. If
							your access to or use of the PRújula Platform has been limited, or your PRújula
							account has been suspended, or this agreement has been terminated by us, you
							may not register a new account or access or use the PRújula Platform through an
							account of another Member.
						</h4>
						<h4 className='est-terms-condition-main-text'>
							<span className='est-terms-condition-main-text-title'>13.6 Survival.</span>{' '}
							Parts of these Terms that by their nature survive termination, will survive
							termination of this agreement, including Sections 2 through 26.
						</h4>
						<h3 className='est-terms-condition-main-subtitle'>14. Modification.</h3>
						<h4 className='est-terms-condition-main-text'>
							PRújula may modify these Terms at any time. When we make material changes to
							these Terms, we will post the revised Terms on the PRújula Platform and update
							the “Last Updated” date at the top of these Terms. We will also provide you
							with notice of any material changes by email at least 30 days before the date
							they become effective. If you disagree with the revised Terms, you may
							terminate this agreement immediately as provided in these Terms. If you do not
							terminate your agreement before the date the revised Terms become effective,
							your continued access to or use of the PRújula Platform will constitute
							acceptance of the revised Terms.
						</h4>
						<h3 className='est-terms-condition-main-subtitle'>
							15. Resolving Complaints and Damage Claims.
						</h3>
						<h4 className='est-terms-condition-main-text'>
							If a Member provides evidence that another Member damaged their real or
							personal property ("Damage Claim"), the complaining Member can seek
							compensation through the Resolution Center. If the complaining Member escalates
							a Damage Claim to PRújula, the other Member will be given an opportunity to
							respond. If the responding Member agrees to pay, or PRújula determines in its
							sole discretion that they are responsible for the Damage Claim, PRújula via
							PRújula Payments can collect any sums required to cover the Damage Claim from
							the responding Member and/or against any security deposit. You agree that
							PRújula may seek to recover from you under any insurance policies you maintain
							and that PRújula may also pursue against you any remedies it may have available
							under applicable law. You agree to cooperate in good faith, provide any
							information PRújula requests, execute documents, and take further reasonable
							action, in connection with Damage Claims, Member complaints, claims under
							insurance policies, or other claims related to your provision or use of Host
							Services.
						</h4>
						<h3 className='est-terms-condition-main-subtitle'>16. PRújula’s Role.</h3>
						<h4 className='est-terms-condition-main-text'>
							We offer a platform that enables Members to publish, offer, search for, and
							book Host Services. While we work hard to ensure our Members have great
							experiences using PRújula, we do not and cannot control the conduct of Guests
							and Hosts. You acknowledge that PRújula has the right, but does not have any
							obligation, to monitor the use of the PRújula Platform and verify information
							provided by our Members. For example, we may review, disable access to, remove,
							or edit Content to: (i) operate, secure and improve the PRújula Platform
							(including for fraud prevention, risk assessment, investigation and customer
							support purposes); (ii) ensure Members’ compliance with these Terms; (iii)
							comply with applicable law or the order or requirement of a court, law
							enforcement or other administrative agency or governmental body; (iv) address
							Content that we determine is harmful or objectionable; (v) take actions set out
							in these Terms; and (vi) maintain and enforce any quality or eligibility
							criteria, including by removing Listings that don’t meet quality and
							eligibility criteria. Members acknowledge and agree that PRújula administers
							its Policies (such as our Extenuating Circumstances Policy) and Standards (such
							as basic requirements for hosts), including decisions about whether and how to
							apply them to a particular situation, at its sole discretion. Members agree to
							cooperate with and assist PRújula in good faith, and to provide PRújula with
							such information and take such actions as may be reasonably requested by
							PRújula with respect to any investigation undertaken by PRújula regarding the
							use or abuse of the PRújula Platform. PRújula is not acting as an agent for any
							Member except for where PRújula Payments acts as a collection agent as provided
							in the Payments Terms.
						</h4>
						<h3 className='est-terms-condition-main-subtitle'>17. Member Accounts.</h3>
						<h4 className='est-terms-condition-main-text'>
							You must register an account to access and use many features of the PRújula
							Platform. Registration is only permitted for legal entities, partnerships and
							natural persons who are 18 years or older. You represent and warrant that you
							are not a person or entity barred from using the PRújula Platform under the
							laws of the United States, your place of residence, or any other applicable
							jurisdiction. You must provide accurate, current, and complete information
							during registration and keep your account information up-to-date. You may not
							register more than one account or transfer your account to someone else. You
							are responsible for maintaining the confidentiality and security of your
							account credentials and may not disclose your credentials to any third party.
							You are responsible and liable for activities conducted through your account
							and must immediately notify PRújula if you suspect that your credentials have
							been lost, stolen, or your account is otherwise compromised. If and as
							permitted by applicable law, we may, but have no obligation to (i) ask you to
							provide identification or other information, (ii) undertake checks designed to
							help verify your identity or background, (iii) screen you against third-party
							databases or other sources and request reports from service providers, and (iv)
							obtain reports from public records of criminal convictions or sex offender
							registrations or their local equivalents.
						</h4>
						<h3 className='est-terms-condition-main-subtitle'>
							18. Disclaimer of Warranties.
						</h3>
						<h4 className='est-terms-condition-main-text'>
							<span className='est-terms-condition-main-text-title'>
								We provide the PRújula Platform and all Content “as is” without warranty of
								any kind and we disclaim all warranties, whether express or implied. For
								example: (i) we do not endorse or warrant the existence, conduct,
								performance, safety, quality, legality or suitability of any Guest, Host,
								Host Service, Listing or third party; (ii) we do not warrant the performance
								or non-interruption of the PRújula Platform; and (iii) we do not warrant
								that verification, identity or background checks conducted on Listings or
								Members (if any) will identify past misconduct or prevent future misconduct.
								Any references to a Member or Listing being "verified" (or similar language)
								indicate only that the Member or Listing or PRújula has completed a relevant
								verification or identification process and nothing else. The disclaimers in
								these Terms apply to the maximum extent permitted by law. If you have
								statutory rights or warranties we cannot disclaim, the duration of any such
								statutorily required rights or warranties, will be limited to the maximum
								extent permitted by law.
							</span>
						</h4>
						<h3 className='est-terms-condition-main-subtitle'>
							19. Limitations on Liability.
						</h3>
						<h4 className='est-terms-condition-main-text'>
							<span className='est-terms-condition-main-text-title'>
								Neither PRújula (including its affiliates and personnel) nor any other party
								involved in creating, producing, or delivering the PRújula Platform or any
								Content will be liable for any incidental, special, exemplary or
								consequential damages, including lost profits, loss of data or loss of
								goodwill, service interruption, computer damage or system failure or the
								cost of substitute products or services, or for any damages for personal or
								bodily injury or emotional distress arising out of or in connection with (i)
								these Terms, (ii) the use of or inability to use the PRújula Platform or any
								Content, (iii) any communications, interactions or meetings you may have
								with someone you interact or meet with through, or as a result of, your use
								of the PRújula Platform, or (iv) publishing or booking of a Listing,
								including the provision or use of Host Services, whether based on warranty,
								contract, tort (including negligence), product liability or any other legal
								theory, and whether or not PRújula has been informed of the possibility of
								such damage, even if a limited remedy set out in these Terms is found to
								have failed of its essential purpose. Except for our obligation to transmit
								payments to Hosts under these Terms, or make payments under the PRújula Host
								Guarantee or Japan Host Insurance, in no event will PRújula’s aggregate
								liability for any claim or dispute arising out of or in connection with
								these Terms, your interaction with any Members, or your use of or inability
								to use the PRújula Platform, any Content, or any Host Service, exceed: (A)
								to Guests, the amount you paid as a Guest during the 12-month period prior
								to the event giving rise to the liability, (B) to Hosts, the amount paid to
								you as a Host in the 12-month period prior to the event giving rise to the
								liability, or (C) to anyone else, one hundred U.S. dollars (US$100). These
								limitations of liability and damages are fundamental elements of the
								agreement between you and PRújula. If applicable law does not allow the
								limitations of liability set out in these Terms, the above limitations may
								not apply to you.
							</span>
						</h4>
						<h3 className='est-terms-condition-main-subtitle'>20. Indemnification.</h3>
						<h4 className='est-terms-condition-main-text'>
							<span className='est-terms-condition-main-text-title'>
								To the maximum extent permitted by applicable law, you agree to release,
								defend (at PRújula’s option), indemnify, and hold PRújula (including PRújula
								Payments, other affiliates, and their personnel) harmless from and against
								any claims, liabilities, damages, losses, and expenses, including, without
								limitation, reasonable legal and accounting fees, arising out of or in any
								way connected with: (i) your breach of these Terms (including any
								supplemental or additional terms that apply to a product or feature) or our
								Policies or Standards, (ii) your improper use of the PRújula Platform, (iii)
								your interaction with any Member, stay at an Accommodation, participation in
								an Experience or other Host Service, including without limitation any
								injuries, losses or damages (whether compensatory, direct, incidental,
								consequential or otherwise) of any kind arising in connection with or as a
								result of such interaction, stay, participation or use, (iv) your failure,
								or our failure at your direction, to accurately report, collect or remit
								Taxes, or (v) your breach of any laws, regulations or third party rights
								such as intellectual property or privacy rights.
							</span>
						</h4>
						<h3 className='est-terms-condition-main-subtitle'>21. Contracting Entities.</h3>
						<h4 className='est-terms-condition-main-text'>
							Based on your country of residence or establishment and what you are doing on
							the PRújula Platform, Schedule 1 below sets out the PRújula entity with whom
							you are contracting. If we identify through the PRújula Platform, an PRújula
							entity other than the one set out on Schedule 1 as being responsible for a
							product, feature or transaction, the PRújula entity so identified is your
							contracting entity with respect to that product, feature or transaction. If you
							change your country of residence or establishment, the PRújula company you
							contract with (as set out on Schedule 1) and the applicable version of the
							Terms of Service will be determined by your new country of residence or
							establishment, from the date on which your country of residence or
							establishment changes.
						</h4>
						<h3 className='est-terms-condition-main-subtitle'>
							22. United States Governing Law and Venue.
						</h3>
						<h4 className='est-terms-condition-main-text'>
							If you reside or have your place of establishment in the United States, these
							Terms will be interpreted in accordance with the laws of the State of
							California and the United States of America, without regard to conflict-of-law
							provisions. Judicial proceedings (other than small claims actions) that are
							excluded from the arbitration agreement in Section 23 must be brought in state
							or federal court in San Francisco, California, unless we both agree to some
							other location. You and we both consent to venue and personal jurisdiction in
							San Francisco, California.
						</h4>
						<h3 className='est-terms-condition-main-subtitle'>
							23. United States Dispute Resolution and Arbitration Agreement.
						</h3>
						<h4 className='est-terms-condition-main-text'>
							<span className='est-terms-condition-main-text-title'>23.1 Application.</span>{' '}
							This Arbitration Agreement only applies to you if your country of residence or
							establishment is the United States. If your country of residence or
							establishment is not the United States, and you nevertheless attempt to bring
							any legal claim against PRújula in the United States, this Arbitration
							Agreement will apply for determination of the threshold issue of whether this
							Section 23 applies to you, and all other threshold determinations, including
							residency, arbitrability, venue, and applicable law.
						</h4>
						<h4 className='est-terms-condition-main-text'>
							<span className='est-terms-condition-main-text-title'>
								23.2 Overview of Dispute Resolution Process.
							</span>{' '}
							PRújula is committed to participating in a consumer-friendly dispute resolution
							process. To that end, these Terms provide for a two-part process for
							individuals to whom this Section 23 applies: (1) an informal negotiation
							directly with PRújula’s customer service team (described in paragraph 23.3,
							below), and if necessary (2) a binding arbitration administered by the American
							Arbitration Association (“AAA”). You and PRújula each retain the right to seek
							relief in small claims court as an alternative to arbitration.
						</h4>
						<h4 className='est-terms-condition-main-text'>
							<span className='est-terms-condition-main-text-title'>
								23.3 Mandatory Pre-Arbitration Dispute Resolution and Notification.
							</span>{' '}
							At least 30 days prior to initiating an arbitration, you and PRújula each agree
							to notify the other party of the dispute in writing and attempt in good faith
							to negotiate an informal resolution. You must send your notice of dispute to
							PRújula by mailing it to PRújula’s agent for service:{' '}
							<span className='est-terms-condition-main-text-title'>
								CSC Lawyers Incorporating Service, 2710 Gateway Oaks Drive, Suite 150N,
								Sacramento, California 95833.
							</span>{' '}
							PRújula will send its notice of dispute to the email address associated with
							your PRújula account. A notice of dispute must include: the party’s name and
							preferred contact information, a brief description of the dispute, and the
							relief sought. If the parties are unable to resolve the dispute within the
							30-day period, only then may either party commence arbitration by filing a
							written Demand for Arbitration (available at www.adr.org) with the AAA and
							providing a copy to the other party as specified in the AAA Rules (available at
							www.adr.org).
						</h4>
						<h4 className='est-terms-condition-main-text'>
							<span className='est-terms-condition-main-text-title'>
								23.4 Agreement to Arbitrate. You and PRújula mutually agree that any
								dispute, claim or controversy arising out of or relating to these Terms or
								the applicability, breach, termination, validity, enforcement or
								interpretation thereof, or any use of the PRújula Platform, Host Services,
								or any Content (collectively, “Disputes”) will be settled by binding
								individual arbitration (the “Arbitration Agreement”). If there is a dispute
								about whether this Arbitration Agreement can be enforced or applies to our
								Dispute, you and PRújula agree that the arbitrator will decide that issue.
							</span>
						</h4>
						<h4 className='est-terms-condition-main-text'>
							<span className='est-terms-condition-main-text-title'>
								23.5 Exceptions to Arbitration Agreement.
							</span>{' '}
							You and PRújula each agree that the following causes of action and/or claims
							for relief are exceptions to the Arbitration Agreement and will be brought in a
							judicial proceeding in a court of competent jurisdiction (as defined by Section
							22): (i) any claim or cause of action alleging actual or threatened
							infringement, misappropriation or violation of a party’s copyrights,
							trademarks, trade secrets, patents, or other intellectual property rights; (ii)
							any claim or cause of action seeking emergency injunctive relief based on
							exigent circumstances (e.g., imminent danger or commission of a crime, hacking,
							cyber-attack); or (iii) a request for the remedy of public injunctive relief.
							You and PRújula agree that the remedy of public injunctive relief will proceed
							after the arbitration of all arbitrable claims, remedies, or causes of action,
							and will be stayed pending the outcome of the arbitration pursuant to section 3
							of the Federal Arbitration Act.
						</h4>
						<h4 className='est-terms-condition-main-text'>
							<span className='est-terms-condition-main-text-title'>
								23.6 Arbitration Rules and Governing Law.
							</span>{' '}
							This Arbitration Agreement evidences a transaction in interstate commerce and
							the Federal Arbitration Act governs all substantive and procedural
							interpretation and enforcement of this provision. The arbitration will be
							administered by AAA in accordance with the Consumer Arbitration Rules and/or
							other AAA arbitration rules determined to be applicable by the AAA (the{' '}
							<span className='est-terms-condition-main-text-title'>“AAA Rules“</span>) then
							in effect, except as modified here. The AAA Rules are available at www.adr.org.
							In order to initiate arbitration, a completed written demand (available at
							www.adr.org) must be filed with the AAA and provided to the other party, as
							specified in the AAA rules.
						</h4>
						<h4 className='est-terms-condition-main-text'>
							<span className='est-terms-condition-main-text-title'>
								23.7 Modification to AAA Rules - Arbitration Hearing/Location.
							</span>{' '}
							In order to make the arbitration most convenient to you, PRújula agrees that
							any required arbitration hearing may be conducted, at your option: (a) in the
							U.S. county where you reside; (b) in San Francisco County; (c) via phone or
							video conference; or (d) if all parties agree, by solely the submission of
							documents to the arbitrator.
						</h4>
						<h4 className='est-terms-condition-main-text'>
							<span className='est-terms-condition-main-text-title'>
								23.8 Modification of AAA Rules - Attorney’s Fees and Costs.
							</span>{' '}
							Your arbitration fees and your share of arbitrator compensation shall be
							governed by the AAA Rules and, where appropriate, limited by the AAA Consumer
							Rules. If such costs are determined by the arbitrator to be excessive, PRújula
							will pay all arbitration fees and expenses. Either party may make a request
							that the arbitrator award attorneys’ fees and costs upon proving that the other
							party has asserted a claim, cross-claim or defense that is groundless in fact
							or law, brought in bad faith or for the purpose of harassment, or is otherwise
							frivolous, as allowed by applicable law and the AAA Rules.
						</h4>
						<h4 className='est-terms-condition-main-text'>
							<span className='est-terms-condition-main-text-title'>
								23.9 Arbitrator’s Decision.
							</span>{' '}
							The arbitrator’s decision will include the essential findings and conclusions
							upon which the arbitrator based the award. Judgment on the arbitration award
							may be entered in any court with proper jurisdiction. The arbitrator may award
							any relief allowed by law or the AAA Rules, but declaratory or injunctive
							relief may be awarded only on an individual basis and only to the extent
							necessary to provide relief warranted by the claimant’s individual claim.
						</h4>
						<h4 className='est-terms-condition-main-text'>
							<span className='est-terms-condition-main-text-title'>
								23.10 Jury Trial Waiver.
							</span>{' '}
							You and PRújula acknowledge and agree that we are each waiving the right to a
							trial by jury as to all arbitrable Disputes.
						</h4>
						<h4 className='est-terms-condition-main-text'>
							<span className='est-terms-condition-main-text-title'>
								23.11 No Class Actions or Representative Proceedings.
							</span>{' '}
							You and PRújula acknowledge and agree that, to the fullest extent permitted by
							law, we are each waiving the right to participate as a plaintiff or class
							member in any purported class action lawsuit, class-wide arbitration, private
							attorney general action, or any other representative or consolidated
							proceeding. Unless we agree in writing, the arbitrator may not consolidate more
							than one party’s claims and may not otherwise preside over any form of any
							class or representative proceeding. If there is a final judicial determination
							that applicable law precludes enforcement of the waiver contained in this
							paragraph as to any claim, cause of action or requested remedy, then that
							claim, cause of action or requested remedy, and only that claim, cause of
							action or requested remedy, will be severed from this agreement to arbitrate
							and will be brought in a court of competent jurisdiction. In the event that a
							claim, cause of action or requested remedy is severed pursuant to this
							paragraph, then you and we agree that the claims, causes of action or requested
							remedies that are not subject to arbitration will be stayed until all
							arbitrable claims, causes of action and requested remedies are resolved by the
							arbitrator.
						</h4>
						<h4 className='est-terms-condition-main-text'>
							<span className='est-terms-condition-main-text-title'>
								23.12 Severability.
							</span>{' '}
							Except as provided in Section 23.11, in the event that any portion of this
							Arbitration Agreement is deemed illegal or unenforceable, such provision will
							be severed and the remainder of the Arbitration Agreement will be given full
							force and effect.
						</h4>
						<h4 className='est-terms-condition-main-text'>
							<span className='est-terms-condition-main-text-title'>
								23.13 Changes to Agreement to Arbitrate.
							</span>{' '}
							If PRújula changes this Section 23 after the date you last accepted these Terms
							(or accepted any subsequent changes to these Terms), you may reject that change
							by sending us written notice (including by email) within 30 days of the date
							the change is effective. Rejecting a new change, however, does not revoke or
							alter your prior consent to any earlier agreements to arbitrate any Dispute
							between you and PRújula (or your prior consent to any subsequent changes
							thereto), which will remain in effect and enforceable as to any Dispute between
							you and PRújula.
						</h4>
						<h4 className='est-terms-condition-main-text'>
							<span className='est-terms-condition-main-text-title'>23.14 Survival.</span>{' '}
							Except as provided in Section 23.12 and subject to Section 13.6, this Section
							23 will survive any termination of these Terms and will continue to apply even
							if you stop using the PRújula Platform or terminate your PRújula account.
						</h4>
						<h3 className='est-terms-condition-main-subtitle'>
							24. China Governing Law and Dispute Resolution.
						</h3>
						<h4 className='est-terms-condition-main-text'>
							<span className='est-terms-condition-main-text-title'>
								24.1 China Domestic Transactions.
							</span>{' '}
							If you reside or have your place of establishment in China, and are contracting
							with PRújula China, these Terms and this Section 24.1 are governed by the laws
							of the People’s Republic of China. In this situation, any dispute arising from
							or in connection with these Terms or use of the PRújula Platform shall be
							submitted to the China International Economic and Trade Arbitration Commission
							(<span className='est-terms-condition-main-text-title'>“CIETAC”</span>) for
							arbitration which shall be conducted in accordance with the Commission’s
							arbitration rules in effect at the time of applying for arbitration. The
							arbitral award is final and binding upon both parties. The tribunal shall
							consist of three (3) arbitrators. The seat of the arbitration shall be Beijing.
							The language of the arbitration shall be English.
						</h4>
						<h4 className='est-terms-condition-main-text'>
							<span className='est-terms-condition-main-text-title'>
								24.2 Cross-border Transactions.
							</span>{' '}
							If you reside or have your place of establishment in China, and are contracting
							with PRújula, Inc., PRújula Travel, LLC, PRújula Ireland UC, or any other
							non-China entity, these Terms and this Section 24.2 are governed by the laws of
							Singapore. In this situation, any dispute arising out of or in connection with
							these Terms or use of the PRújula Platform, including any question regarding
							the existence, validity or termination of these Terms, shall be referred to and
							finally resolved by arbitration administered by the Singapore International
							Arbitration Centre (
							<span className='est-terms-condition-main-text-title'>“SIAC”</span>) in
							accordance with the Arbitration Rules of the Singapore International
							Arbitration Centre (
							<span className='est-terms-condition-main-text-title'>“SIAC Rules”</span>) for
							the time being in force, which rules are deemed to be incorporated by reference
							in this clause. The seat of the arbitration shall be Singapore. The Tribunal
							shall consist of three (3) arbitrators. The language of the arbitration shall
							be English.
						</h4>
						<h4 className='est-terms-condition-main-text'>
							<span className='est-terms-condition-main-text-title'>
								24.3 Without Limitation Provisions.
							</span>{' '}
							The above Sections 24.1 and 24.2 are expressed to be without regard to conflict
							of laws provisions and shall not be construed to limit any rights which PRújula
							may have to apply to any court of competent jurisdiction for any order
							requiring you to perform or be prohibited from performing certain acts and
							other provisional relief permitted under the laws of Singapore, the People’s
							Republic of China, or any other laws that may apply to you.
						</h4>
						<h4 className='est-terms-condition-main-text'>
							<span className='est-terms-condition-main-text-title'>
								25. Rest of World Dispute Resolution, Venue and Forum, and Governing Law.
							</span>{' '}
							If you reside or have your place of establishment outside of the United States
							and China, this Section applies to you and these Terms will be interpreted in
							accordance with Irish law. The application of the United Nations Convention on
							Contracts for the International Sale of Goods (CISG) is excluded. The choice of
							law does not impact your rights as a consumer according to the consumer
							protection regulations of your country of residence. If you are acting as a
							consumer, you agree to submit to the non-exclusive jurisdiction of the Irish
							courts. Legal proceedings that you are able to bring against us arising from or
							in connection with these Terms may only be brought in a court located in
							Ireland or a court with jurisdiction in your place of residence. If PRújula
							wishes to enforce any of its rights against you as a consumer, we may do so
							only in the courts of the jurisdiction in which you are a resident. If you are
							acting as a business, you agree to submit to the exclusive jurisdiction of the
							Irish courts.
						</h4>
						<h3 className='est-terms-condition-main-subtitle'>26. Miscellaneous.</h3>
						<h4 className='est-terms-condition-main-text'>
							<span className='est-terms-condition-main-text-title'>
								26.1 Other Terms Incorporated by Reference.
							</span>{' '}
							Our Host Guarantee Terms, Japan Host Insurance Terms, Guest Refund Policy,
							Experiences Guest Refund Policy, Content Policy, Nondiscrimination Policy,
							Extenuating Circumstances Policy, Policies, Standards and other supplemental
							policies and terms linked to in these Terms apply to your use of the PRújula
							Platform, are incorporated by reference, and form part of your agreement with
							PRújula.
						</h4>
						<h4 className='est-terms-condition-main-text'>
							<span className='est-terms-condition-main-text-title'>
								26.2 Interpreting these Terms.
							</span>{' '}
							Except as they may be supplemented by additional terms, conditions, policies,
							guidelines, standards, and in-product disclosures, these Terms (including those
							items incorporated by reference) constitute the entire agreement between
							PRújula and you pertaining to your access to or use of the PRújula Platform and
							supersede any and all prior oral or written understandings or agreements
							between PRújula and you. These Terms do not and are not intended to confer any
							rights or remedies upon anyone other than you and PRújula. If any provision of
							these Terms is held to be invalid or unenforceable, except as otherwise
							indicated in Section 23.11 above, such provision will be struck and will not
							affect the validity and enforceability of the remaining provisions. Where the
							word “will” is used in these Terms it connotes an obligation with the same
							meaning as “shall.”
						</h4>
						<h4 className='est-terms-condition-main-text'>
							<span className='est-terms-condition-main-text-title'>26.3 No Waiver.</span>{' '}
							PRújula’s failure to enforce any right or provision in these Terms will not
							constitute a waiver of such right or provision unless acknowledged and agreed
							to by us in writing. Except as expressly set forth in these Terms, the exercise
							by either party of any of its remedies under these Terms will be without
							prejudice to its other remedies under these Terms or otherwise permitted under
							law.
						</h4>
						<h4 className='est-terms-condition-main-text'>
							<span className='est-terms-condition-main-text-title'>26.4 Assignment.</span>{' '}
							You may not assign, transfer or delegate this agreement or your rights and
							obligations hereunder without PRújula's prior written consent. PRújula may
							without restriction assign, transfer or delegate this agreement and any rights
							and obligations hereunder, at its sole discretion, with 30 days’ prior notice.
						</h4>
						<h4 className='est-terms-condition-main-text'>
							<span className='est-terms-condition-main-text-title'>26.5 Notice.</span>{' '}
							Unless specified otherwise, any notices or other communications to Members
							permitted or required under this agreement, will be provided electronically and
							given by PRújula via email, PRújula Platform notification, messaging service
							(including SMS and WeChat), or any other contact method we enable and you
							provide. If a notification relates to a booking or Listing in Japan, you agree
							and acknowledge that such notifications via electronic means in lieu of a
							written statement, satisfies PRújula’s obligations under Article 59 (1) of the
							Japanese Housing Accommodation Business Act.
						</h4>
						<h4 className='est-terms-condition-main-text'>
							<span className='est-terms-condition-main-text-title'>
								26.6 Third-Party Services.
							</span>{' '}
							The PRújula Platform may contain links to third-party websites, applications,
							services or resources (
							<span className='est-terms-condition-main-text-title'>
								“Third-Party Services”
							</span>
							) that are subject to different terms and privacy practices. PRújula is not
							responsible or liable for any aspect of such Third-Party Services and links to
							such Third-Party Services are not an endorsement.
						</h4>
						<h4 className='est-terms-condition-main-text'>
							<span className='est-terms-condition-main-text-title'>26.7 Google Terms.</span>{' '}
							Some translations on the PRújula Platform are powered by Google. Google
							disclaims all warranties related to the translations, express or implied,
							including any warranties of accuracy, reliability, and any implied warranties
							for merchantability, fitness for a particular purpose and non-infringement.
							Some areas of the PRújula Platform implement Google Maps/Earth mapping
							services, including Google Maps API(s). Your use of Google Maps/Earth is
							subject to the Google Maps/Google Earth Additional Terms of Service.
						</h4>
						<h4 className='est-terms-condition-main-text'>
							<span className='est-terms-condition-main-text-title'>26.8 Apple Terms.</span>{' '}
							If you access or download our application from the Apple App Store, you agree
							to Apple’s Licensed Application End User License Agreement.
						</h4>
						<h4 className='est-terms-condition-main-text'>
							<span className='est-terms-condition-main-text-title'>
								26.9 PRújula Platform Content.
							</span>{' '}
							Content made available through the PRújula Platform may be protected by
							copyright, trademark, and/or other laws of the United States and other
							countries. You acknowledge that all intellectual property rights for that
							Content are the exclusive property of PRújula and/or its licensors and agree
							that you will not remove, alter or obscure any copyright, trademark, service
							mark or other proprietary rights notices. You may not use, copy, adapt, modify,
							prepare derivative works of, distribute, license, sell, transfer, publicly
							display, publicly perform, transmit, broadcast or otherwise exploit any Content
							accessed through the PRújula Platform except to the extent you are the legal
							owner of that Content or as expressly permitted in these Terms. Subject to your
							compliance with these Terms, PRújula grants you a limited, non-exclusive,
							non-sublicensable, revocable, non-transferable license to (i) download and use
							the Application on your personal device(s); and (ii) access and view the
							Content made available on or through the PRújula Platform and accessible to
							you, solely for your personal and non-commercial use.
						</h4>
						<h4 className='est-terms-condition-main-text'>
							<span className='est-terms-condition-main-text-title'>26.10 PRújula.org.</span>{' '}
							PRújula.org is a nonprofit corporation exempt from income taxation under U.S.
							Internal Revenue Code Section 501(c)(3), operating as a public charity.
							PRújula.org is not owned or controlled by PRújula. PRújula.org administers a
							number of charitable programs that benefit our Host and Guest communities and
							the public.
						</h4>
						<h4 className='est-terms-condition-main-text'>
							<span className='est-terms-condition-main-text-title'>
								26.11 Force Majeure.
							</span>{' '}
							PRújula shall not be liable for any delay or failure to perform resulting from
							causes outside its reasonable control, including, but not limited to, acts of
							God, natural disasters, war, terrorism, riots, embargoes, acts of civil or
							military authorities, fire, floods, accidents, pandemics, epidemics or disease,
							strikes or shortages of transportation facilities, fuel, energy, labor or
							materials.
						</h4>
						<h4 className='est-terms-condition-main-text'>
							<span className='est-terms-condition-main-text-title'>
								26.12 Emails and SMS.
							</span>{' '}
							You will receive administrative communications from us using the email address
							or other contact information you provide for your PRújula account. Enrollment
							in additional email subscription programs will not affect the frequency of
							these administrative emails, though you should expect to receive additional
							emails specific to the program(s) to which you have subscribed. You may also
							receive promotional emails from us. No fee is charged for these promotional
							emails, but third-party data rates could apply. You can control whether you
							receive promotional emails using the notification preferences in your account
							settings. Please note that you will not be able to take advantage of certain
							promotions if you disable certain communication settings or do not have an
							PRújula account. In the U.S. if you consent to receive SMS (text messages) from
							us, you will be subject to our SMS Terms.
						</h4>
						<h4 className='est-terms-condition-main-text'>
							<span className='est-terms-condition-main-text-title'>26.13 Contact Us.</span>{' '}
							If you have any questions about these Terms please email us
						</h4>
						<h4 className='est-terms-condition-main-text'>
							<span className='est-terms-condition-main-text-title'>
								Schedule 1 - Contracting Entities
							</span>
						</h4>
					</div>
				</div>
			</div>
		</>
	)
}
