/** @format */

import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'

import Router from './router'

import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import HttpApi from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'

import Loading from './components/Common/Loading'

import 'antd/dist/antd.css'
import './style.css'

/* 
	console.log = function () {}
 */

i18next
	.use(HttpApi)
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		supportedLngs: ['en', 'es'],
		fallbackLng: 'es',
		debug: false,
		detection: {
			order: ['path', 'cookie', 'localstorage', 'htmlTag'],
			caches: ['cookie'],
		},
		backend: {
			loadPath: '/assets/locales/{{lng}}/translation.json',
		},
	})

const loadingMarkup = <Loading />

ReactDOM.render(
	<Suspense fallback={loadingMarkup}>
		<Router />
	</Suspense>,
	document.getElementById('root')
)
