/** @format */

import axios from 'axios'

import notification from 'antd/lib/notification'

import { ENV_CORE } from '../../components/Common/Hooks/Variables/Enviroment'

export default async function CheckReview(item, traduce) {
	let returnResponse
	await axios({
		method: 'POST',
		url: `${ENV_CORE}/api/publication/show-reservation`,
		data: { conRowid: item },
	})
		.then((response) => {
			if (response.data.statusCode === 200) {
				returnResponse = response.data
			} else {
				notification['warning']({
					message: 'Warning:',
					description: 'Service error: REACT_APP_SERVICE_CORE - Review Send',
				})
			}
		})
		.catch(() => {
			notification['error']({
				message: `Error`,
				description: `${traduce.service_global_description}`,
			})
		})
	return returnResponse
}
