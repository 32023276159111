/** @format */
/** @format */

import React, { useState } from 'react'

import { setGlobal } from 'reactn'

import { Row, Col, Button, Modal, Form, Input, notification } from 'antd'

import { EditFilled } from '@ant-design/icons'

import { useTranslation } from 'react-i18next'

import InputNormal from '../../../../../../../../components/Common/Inputs/Normal'

import { rulesValidation } from '../../../../../../../../components/Common/Inputs/Normal/rules'

import { ENV_CORE } from '../../../../../../../../components/Common/Hooks/Variables/Enviroment'

import UploadImage from '../../../../../../../../components/Common/UploadImage'
import Image from '../../../../../../../../components/Common/Image'

import { GetBlogPosts } from '../../services'

import EditBlogPostService from './services'

import './style.css'

const EditBlogPost = (props) => {
	const { t } = useTranslation()
	const [form] = Form.useForm()
	const { TextArea } = Input
	const [isVisible, setVisible] = useState(false)
	const [isLoading, setLoading] = useState(false)
	const [isNewPhoto, setNewPhoto] = useState(null)

	const handleEditBlogPost = async (item) => {
		if (!props.item.image) {
			if (!isNewPhoto) {
				notification['warning']({
					message: 'Advertencia:',
					description: 'Debe subir una imagen para el Post del Blog.',
				})
				return
			} else {
				item.image = isNewPhoto
			}
		} else {
			if (isNewPhoto) {
				item.image = isNewPhoto
			} else {
				item.image = props.item.image
			}
		}
		const data = {
			id: props.item.id,
			id_user: props.item.id_user,
			tags: item.tags,
			image: item.image,
			info_es: {
				title: item.title_es,
				content: item.content_es,
			},
			info_en: {
				title: item.title_en,
				content: item.content_en,
			},
		}
		setLoading(true)

		const traduce = {
			service_success_message: t(
				'profile.manage_blog.service_edit_blog.service_success_message'
			),
			service_success_description: t(
				'profile.manage_blog.service_edit_blog.service_success_description'
			),
			service_warning_message: t(
				'profile.manage_blog.service_edit_blog.service_warning_message'
			),
			service_warning_description: t(
				'profile.manage_blog.service_edit_blog.service_warning_description'
			),
			service_error_description: t(
				'profile.manage_blog.service_edit_blog.service_error_description'
			),
		}

		await EditBlogPostService(data, traduce).then((response) => {
			if (response) {
				GetBlogPosts().then((responseBlog) => {
					setGlobal({
						allBlogPosts: responseBlog,
					})
					form.resetFields()
					setNewPhoto(null)
				})
			}
		})
		setLoading(false)
		setVisible(false)
	}

	const handleAddProfileImage = (item) => {
		setNewPhoto(`${ENV_CORE}/${item.file}`)
	}

	const handleDeleteProfileImage = (item) => {
		setNewPhoto(null)
	}

	return (
		<>
			<Button onClick={() => setVisible(true)} className='est-general-edit-blog-post-button'>
				<EditFilled />
			</Button>
			<Modal
				forceRender
				wrapClassName='est-user-modal-container'
				maskClosable={false}
				width={1200}
				centered
				visible={isVisible}
				onCancel={() => setVisible()}
				okText='Confirmar'
				cancelButtonProps={{ style: { display: 'none' } }}
				okButtonProps={{ style: { display: 'none' } }}>
				<h3 className='est-auth-edit-profile-modal-title'>
					{t('profile.manage_blog.modal_edit_blog.modal_title')}
				</h3>

				<Form
					form={form}
					name='user_create'
					onFinish={handleEditBlogPost}
					initialValues={{
						tags: props.item.tags,
						title_es: props.item.info_es ? props.item.info_es.title : '',
						content_es: props.item.info_es ? props.item.info_es.content : '',
						title_en: props.item.info_en ? props.item.info_en.title : '',
						content_en: props.item.info_en ? props.item.info_en.content : '',
					}}>
					<div className='est-auth-login-form-container'>
						<Row>
							<Col span={24} className='est-login-form-text-container'>
								<h4 className='est-login-form-text'>Tags</h4>
								<InputNormal
									className={'est-auth-login-field-input'}
									inputName={'tags'}
									inputNameLabel={'Tags'}
									inputNameRule={true}
									inputNameMessage={'Tag es obligatorio.'}
									inputNameType={'text'}
									inputNameIcon={''}
									inputNameRules={t('field_notifications.normal.rules_required')}
								/>
							</Col>
							<Col span={24} className='est-login-form-text-container'>
								<h4 className='est-login-form-text'>
									{t('profile.manage_blog.modal_edit_blog.input_title_es')}
								</h4>
								<InputNormal
									className={'est-auth-login-field-input'}
									inputName={'title_es'}
									inputNameLabel={t('profile.manage_blog.modal_edit_blog.input_title_es')}
									inputNameRule={true}
									inputNameMessage={'Titulo es obligatorio.'}
									inputNameType={'text'}
									inputNameIcon={''}
									inputNameRules={t('field_notifications.normal.rules_required')}
									disabled={false}
								/>
								<h4 className='est-login-form-text'>
									{t('profile.manage_blog.modal_edit_blog.input_content_title_es')}
								</h4>
								<Form.Item
									name={'content_es'}
									rules={rulesValidation[t('field_notifications.normal.rules_required')]}>
									<TextArea
										className='est-ads-basics-info-field-input'
										size='large'
										type={'text'}
										placeholder={t(
											'profile.manage_blog.modal_edit_blog.input_content_title_es'
										)}
										rows={15}
									/>
								</Form.Item>
								<h4 className='est-login-form-text'>
									{t('profile.manage_blog.modal_edit_blog.input_title_en')}
								</h4>
								<InputNormal
									className={'est-auth-login-field-input'}
									inputName={'title_en'}
									inputNameLabel={t('profile.manage_blog.modal_edit_blog.input_title_en')}
									inputNameRule={true}
									inputNameMessage={'Titulo es obligatorio.'}
									inputNameType={'text'}
									inputNameIcon={''}
									inputNameRules={t('field_notifications.normal.rules_required')}
									disabled={false}
								/>
								<h4 className='est-login-form-text'>
									{t('profile.manage_blog.modal_edit_blog.input_content_title_en')}
								</h4>
								<Form.Item
									name={'content_en'}
									rules={rulesValidation[t('field_notifications.normal.rules_required')]}>
									<TextArea
										className='est-ads-basics-info-field-input'
										size='large'
										type={'text'}
										placeholder={t(
											'profile.manage_blog.modal_edit_blog.input_content_title_en'
										)}
										rows={15}
									/>
								</Form.Item>
							</Col>
						</Row>
						<Row>
							<Col span={12} className='est-general-edit-blog-post-image-upload'>
								<Form.Item name='dragger' valuePropName='fileList' noStyle>
									<UploadImage
										imageDelete={(data) => handleDeleteProfileImage(data)}
										addItemImage={(data) => handleAddProfileImage(data)}
									/>
								</Form.Item>
								<div className='est-profile-edit-modal-image-text-container'>
									{props.item.image && (
										<>
											<h4 className='est-profile-edit-modal-image-text'>
												{t('profile.manage_blog.modal_edit_blog.actual_photo_title')}
											</h4>
											<div className='est-edit-post-modal-image-container'>
												<Image
													classImg={'est-edit-post-modal-image'}
													image={props.item.image}
													alt={'Imagen Profile'}
													title={'Imagen Profile'}
												/>
											</div>
										</>
									)}
								</div>
							</Col>
						</Row>
						<Form.Item>
							<div>
								<div className='est-user-modal-button-main-container'>
									<Button
										className='est-user-modal-button-main'
										type='primary'
										htmlType={'submit'}
										loading={isLoading}>
										{t('profile.user_data.modal_edit_user.button')}
									</Button>
								</div>
							</div>
						</Form.Item>
					</div>
				</Form>
			</Modal>
		</>
	)
}
export default EditBlogPost
