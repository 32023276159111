/** @format */

import React, { useEffect, useState } from 'react'

import { useParams, useHistory, Link } from 'react-router-dom'

import { useTranslation } from 'react-i18next'

import { Form, Row, Col, Radio, Input, Button, Modal } from 'antd'

import Loading from '../../components/Common/Loading'
import Image from '../../components/Common/Image'
import MainNavigation from '../../components/Common/MainNavigation'
import { rulesValidation } from '../../components/Common/Inputs/Normal/rules'

import MainLogoImg from '../../img/footer-top/main-logo-colorfull.png'

import SendReview from '../../components/Common/Hooks/Functions/ReviewSend'

import CheckReview from './services'

import './style.css'

const ReviewSend = () => {
	const [edit_form] = Form.useForm()
	const { t } = useTranslation()
	const history = useHistory()
	const token = useParams()
	const { TextArea } = Input

	const [isLoading, setLoading] = useState(false)
	const [isData, setData] = useState(null)
	const [isModalConfirmation, setModalConfirmation] = useState(false)

	const [isTraduce] = useState({
		service_global_description: t('global_service.service_global_description'),
	})

	const handleReviewSend = (item) => {
		let answer_1
		let answer_2
		let answer_4
		let answer_5

		if (item.item_1 === 1) {
			answer_1 = 'Por debajo del Nivel'
		} else if (item.item_1 === 3) {
			answer_1 = 'Al nivel esperado'
		} else if (item.item_1 === 5) {
			answer_1 = 'Por encima del nivel'
		}

		if (item.item_2 === 1) {
			answer_2 = 'No'
		} else if (item.item_2 === 5) {
			answer_2 = 'Si'
		}

		if (item.item_4 === 1) {
			answer_4 = 'Localizacion(lugares cercanos para disfrutar)'
		} else if (item.item_4 === 2) {
			answer_4 = 'Servicios(agua, luz, banios, cocinas, etc)'
		} else if (item.item_4 === 3) {
			answer_4 = 'Actividades para realizar en el lugar(piscina, zip-line, jacuzzi)'
		} else if (item.item_4 === 4) {
			answer_4 = 'Atractivo visual(paisaje)'
		} else if (item.item_4 === 5) {
			answer_4 = 'Recursos naturales para disfrutar en el lugar(rio, cueva, playa)'
		}

		if (item.item_5 === 1) {
			answer_5 = 'Accesos(estacionamiento lejano)'
		} else if (item.item_5 === 2) {
			answer_5 = 'Actividades para realizar en el lugar(piscina, zip-line, jacuzzi)'
		} else if (item.item_5 === 3) {
			answer_5 = 'Cuidado de la Finca(limpieza, concervacion, informacion disponible)'
		} else if (item.item_5 === 4) {
			answer_5 = 'Servicios(agua, luz, banios, cocinas, etc)'
		} else if (item.item_5 === 5) {
			answer_5 = 'Todo me gusto'
		}

		const rating = (item.item_1 + item.item_2 + item.item_4 + item.item_5) / 4
		const info = {
			idAnuncio: isData.id_anuncio,
			idReservacion: isData.id,
			idUser: isData.id_user,
			ratingUser: rating.toString(),
			commentary: item.item_3,
			answers: [
				{
					question:
						'¿Hasta qué punto tu experiencia en nuestra finca superó tus expectativas?',
					answer: answer_1,
				},
				{
					question: '¿Nos recomendarías a tus amigos o familiares?',
					answer: answer_2,
				},
				{
					question: '¿Por qué sí o por qué no nos recomendarías?',
					answer: item.item_3,
				},
				{
					question: '¿Qué fue lo más que te gustó de la finca?',
					answer: answer_4,
				},
				{
					question: '¿Qué fue lo menos que te gustó de la finca?',
					answer: answer_5,
				},
			],
		}

		setLoading(true)

		const traduce = {
			service_success_title: t('profile.user_reservations.tabs.review.service_success_title'),
			service_success_description: t(
				'profile.user_reservations.tabs.review.service_success_description'
			),
			service_warning_title: t('profile.user_reservations.tabs.review.service_warning_title'),
			service_warning_description: t(
				'profile.user_reservations.tabs.review.service_warning_description'
			),
			service_error_description: t(
				'profile.user_reservations.tabs.review.service_error_description'
			),
		}

		SendReview(info, traduce).then((response) => {
			edit_form.resetFields()
			setLoading(false)
			if (response) {
				setModalConfirmation(true)
			}
		})
	}

	useEffect(() => {
		CheckReview(token.token, isTraduce).then((response) => {
			if (response.calificado === 0) {
				setData(response.infoReserv)
			} else {
				history.push('/')
			}
		})
	}, [token.token, history, isTraduce])

	if (isData) {
		return (
			<>
				<MainNavigation
					title={t('profile.user_reservations.tabs.review.modal_main_title')}
					linkPrevTitle={'Home'}
					linkNextTitle={t('profile.user_reservations.tabs.review.modal_main_title')}
					linkPrev={'/'}
					linkNext={''}
				/>
				<div className='est-profile-publication-review-global-container'>
					<Form onFinish={handleReviewSend} form={edit_form}>
						<Row>
							<Col span={24}>
								<p className='est-profile-publication-review-global-title'>
									{t('profile.user_reservations.tabs.review.modal_subtitle_one')}
									<span className='review-modal-global-title-span'>
										{t('profile.user_reservations.tabs.review.modal_subtitle_two')}
									</span>
									{t('profile.user_reservations.tabs.review.modal_subtitle_three')}
								</p>
							</Col>
							<Col span={24}>
								<h4 className='est-profile-publication-review-title'>
									{t('profile.user_reservations.tabs.review.modal_question_one')}
								</h4>
								<Form.Item
									name={'item_1'}
									rules={rulesValidation[t('field_notifications.normal.rules_required')]}
									className='est-profile-publication-review-value-container'>
									<Radio.Group>
										<Radio value={5}>
											{t(
												'profile.user_reservations.tabs.review.modal_question_one_option_one'
											)}
										</Radio>
										<Radio value={3}>
											{t(
												'profile.user_reservations.tabs.review.modal_question_one_option_two'
											)}
										</Radio>
										<Radio value={1}>
											{t(
												'profile.user_reservations.tabs.review.modal_question_one_option_three'
											)}
										</Radio>
									</Radio.Group>
								</Form.Item>
							</Col>
							<Col span={24}>
								<h4 className='est-profile-publication-review-title'>
									{t('profile.user_reservations.tabs.review.modal_question_two')}
								</h4>
								<Form.Item
									name={'item_2'}
									rules={rulesValidation[t('field_notifications.normal.rules_required')]}
									className='est-profile-publication-review-value-container'>
									<Radio.Group>
										<Radio value={5}>
											{t(
												'profile.user_reservations.tabs.review.modal_question_two_option_one'
											)}
										</Radio>
										<Radio value={1}>No</Radio>
									</Radio.Group>
								</Form.Item>
							</Col>
							<Col span={24}>
								<h4 className='est-profile-publication-review-title'>
									{t('profile.user_reservations.tabs.review.modal_question_three')}
								</h4>
								<Form.Item
									name={'item_3'}
									rules={rulesValidation[t('field_notifications.normal.rules_required')]}
									className='est-profile-publication-review-text-area-container'>
									<TextArea
										className='est-profile-publication-review-modal-field-input'
										size='large'
										type={'text'}
										placeholder={t(
											'publication.create.basic_info.placeholder_description'
										)}
										rows={4}
									/>
								</Form.Item>
							</Col>
							<Col span={24}>
								<h4 className='est-profile-publication-review-title'>
									{t('profile.user_reservations.tabs.review.modal_question_four')}
								</h4>
								<Form.Item
									name={'item_4'}
									rules={rulesValidation[t('field_notifications.normal.rules_required')]}
									className='est-profile-publication-review-value-container'>
									<Radio.Group>
										<Row>
											<Col span={24}>
												<Radio value={2}>
													{t(
														'profile.user_reservations.tabs.review.modal_question_four_option_one'
													)}
												</Radio>
											</Col>
											<Col span={24}>
												<Radio value={5}>
													{t(
														'profile.user_reservations.tabs.review.modal_question_four_option_two'
													)}
												</Radio>
											</Col>
											<Col span={24}>
												<Radio value={4}>
													{t(
														'profile.user_reservations.tabs.review.modal_question_four_option_three'
													)}
												</Radio>
											</Col>
											<Col span={24}>
												<Radio value={1}>
													{t(
														'profile.user_reservations.tabs.review.modal_question_four_option_four'
													)}
												</Radio>
											</Col>
											<Col span={24}>
												<Radio value={3}>
													{t(
														'profile.user_reservations.tabs.review.modal_question_four_option_five'
													)}
												</Radio>
											</Col>
										</Row>
									</Radio.Group>
								</Form.Item>
							</Col>
							<Col span={24}>
								<h4 className='est-profile-publication-review-title'>
									{t('profile.user_reservations.tabs.review.modal_question_five')}
								</h4>
								<Form.Item
									name={'item_5'}
									rules={rulesValidation[t('field_notifications.normal.rules_required')]}
									className='est-profile-publication-review-value-container'>
									<Radio.Group>
										<Row>
											<Col span={24}>
												<Radio value={4}>
													{t(
														'profile.user_reservations.tabs.review.modal_question_five_option_one'
													)}
												</Radio>
											</Col>
											<Col span={24}>
												<Radio value={1}>
													{t(
														'profile.user_reservations.tabs.review.modal_question_five_option_two'
													)}
												</Radio>
											</Col>
											<Col span={24}>
												<Radio value={2}>
													{t(
														'profile.user_reservations.tabs.review.modal_question_five_option_three'
													)}
												</Radio>
											</Col>
											<Col span={24}>
												<Radio value={3}>
													{t(
														'profile.user_reservations.tabs.review.modal_question_five_option_four'
													)}
												</Radio>
											</Col>
											<Col span={24}>
												<Radio value={5}>
													{t(
														'profile.user_reservations.tabs.review.modal_question_five_option_five'
													)}
												</Radio>
											</Col>
										</Row>
									</Radio.Group>
								</Form.Item>
							</Col>
							<Col span={24}>
								<div className='est-profile-publication-edit-modal-button-container'>
									<Button
										className='est-profile-publication-edit-modal-button'
										htmlType={'submit'}
										loading={isLoading}>
										{t('profile.user_reservations.tabs.review.review_final_button')}
									</Button>
								</div>
							</Col>
						</Row>
					</Form>
				</div>
				<Modal
					wrapClassName='est-payment-modal'
					centered
					title={'Review'}
					visible={isModalConfirmation}
					cancelButtonProps={{ style: { display: 'none' } }}
					okButtonProps={{ style: { display: 'none' } }}
					okText=''
					cancelText=''
					width={350}
					header={false}
					footer={false}>
					<Image
						classImg={'est-payment-modal-image'}
						image={MainLogoImg}
						alt={'Prujula Logo'}
						title={'Prujula Logo'}
					/>
					<h3 className='est-payment-modal-error-title-1'>Gracias por Calificarnos</h3>
					<h3 className='est-payment-modal-error-title-2'>Te esperamos de vuelta.</h3>
					<Link to='/' className='est-profile-payment-modal-button'>
						Regresar
					</Link>
				</Modal>
			</>
		)
	} else {
		return <Loading />
	}
}
export default ReviewSend
