/** @format */

import React, { useState } from 'react'

import { Row, Col, Button, Modal } from 'antd'

import { useTranslation } from 'react-i18next'

import Image from '../../../../../../../../components/Common/Image'

import './style.css'

export default function PreviewDetailBlog(props) {
	const { t } = useTranslation()
	const [isVisible, setVisible] = useState(false)
	const [isModalInfo, setModalInfo] = useState(null)
	const handleManageUser = (item) => {
		setVisible(true)
		setModalInfo(props.item)
	}

	const handleCloseManageUser = () => {
		setModalInfo(null)
		setVisible(false)
	}

	return (
		<>
			<Button
				onClick={() => handleManageUser(props.item)}
				className='est-general-preview-blog-post-button'>
				{t('profile.manage_blog.modal_preview_blog_button')}
			</Button>
			<Modal
				wrapClassName='est-manage-users-modal-global-container'
				centered
				width={1200}
				title={t('profile.manage_blog.modal_preview_blog.modal_title')}
				visible={isVisible}
				onCancel={() => handleCloseManageUser()}
				cancelButtonProps={{ style: { display: 'none' } }}
				okButtonProps={{ style: { display: 'none' } }}
				okText=''
				cancelText=''
				footer={false}>
				{isModalInfo && (
					<Row>
						<Col span={24}>
							<div className='est-general-preview-blog-post-modal-image-container'>
								<Image
									classImg={'est-general-preview-blog-post-modal-image'}
									image={isModalInfo.image}
									alt={isModalInfo.title}
									title={isModalInfo.title}
								/>
							</div>
							<div className='est-general-preview-blog-post-modal-text-container'>
								<h4 className='est-general-preview-blog-post-modal-title'>
									<span className='est-manage-users-modal-title-span'>ID:</span>{' '}
									{isModalInfo.id}
								</h4>
								{isModalInfo.info_es && (
									<>
										<h4 className='est-general-preview-blog-post-modal-title'>
											<span className='est-manage-users-modal-title-span'>
												{t('profile.manage_blog.modal_preview_blog.post_title_es')}
											</span>{' '}
											{isModalInfo.info_es.title}
										</h4>
										<h4 className='est-general-preview-blog-post-modal-content'>
											<span className='est-manage-users-modal-title-span'>
												{t('profile.manage_blog.modal_preview_blog.post_content_es')}
											</span>{' '}
											{isModalInfo.info_es.content}
										</h4>
									</>
								)}
								{isModalInfo.info_en && (
									<>
										<h4 className='est-general-preview-blog-post-modal-title'>
											<span className='est-manage-users-modal-title-span'>
												{t('profile.manage_blog.modal_preview_blog.post_title_en')}
											</span>{' '}
											{isModalInfo.info_en.title}
										</h4>
										<h4 className='est-general-preview-blog-post-modal-content'>
											<span className='est-manage-users-modal-title-span'>
												{t('profile.manage_blog.modal_preview_blog.post_content_en')}
											</span>{' '}
											{isModalInfo.info_en.content}
										</h4>
									</>
								)}
								<h4 className='est-general-preview-blog-post-modal-title'>
									<span className='est-manage-users-modal-title-span'>
										{t('profile.manage_blog.modal_preview_blog.post_date')}
									</span>{' '}
									{isModalInfo.date_create}
								</h4>
								<h4 className='est-general-preview-blog-post-modal-title'>
									<span className='est-manage-users-modal-title-span'>
										{t('profile.manage_blog.modal_preview_blog.post_author')}
									</span>{' '}
									{isModalInfo.name} {isModalInfo.last_name}
								</h4>

								<h4 className='est-general-preview-blog-post-modal-title'>
									<span className='est-manage-users-modal-title-span'>Tags:</span>{' '}
									{isModalInfo.tags}
								</h4>
								<h4 className='est-general-preview-blog-post-modal-title'>
									<span className='est-manage-users-modal-title-span'>
										{t('profile.manage_blog.modal_preview_blog.post_id_user')}
									</span>{' '}
									{isModalInfo.id_user}
								</h4>
							</div>
						</Col>
					</Row>
				)}
				<div className='est-manage-users-modal-button-container'>
					<Button
						className='est-manage-users-modal-button'
						onClick={() => handleCloseManageUser()}>
						{t('profile.manage_blog.modal_preview_blog.modal_close_button')}
					</Button>
				</div>
			</Modal>
		</>
	)
}
