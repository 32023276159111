/** @format */

import axios from 'axios'

import notification from 'antd/lib/notification'

import {
	ENV_CORE,
	EZ_PAYMENTS,
	CORS_BYPASS,
} from '../../../../components/Common/Hooks/Variables/Enviroment'

const servicesPayment = {
	async SendPayment(item, traduceOne) {
		let returnResponse
		let headers = new Headers()

		headers.append('Content-Type', 'application/json')
		headers.append('Accept', 'application/json')
		//headers.append('Authorization', 'Basic ' + base64.encode(username + ':' + password))
		//headers.append('Origin', BASE)

		await fetch(`${CORS_BYPASS}/${EZ_PAYMENTS}`, {
			method: 'POST', // *GET, POST, PUT, DELETE, etc.
			mode: 'cors', // no-cors, *cors, same-origin
			//cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'same-origin', // include, *same-origin, omit
			headers: headers,
			//redirect: 'follow', // manual, *follow, error
			//referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
			body: JSON.stringify(item), // body data type must match "Content-Type" header
		})
			.then((response) => {
				if (response.ok) {
					return response.json()
				} else {
					notification['error']({
						message: 'Error:',
						description: `${traduceOne.service_error_description_one}`,
					})
				}
			})
			.then((response) => {
				if (response.ReturnCode !== '1') {
					notification['error']({
						message: 'Error:',
						duration: 8,
						description: `${traduceOne.service_error_description_two} ${response.ReasonDescription}`,
					})
				} else {
					returnResponse = response
				}
			})
			.catch((error) => {
				notification['error']({
					message: 'Error:',
					description: `${error}`,
				})
			})
			.finally(() => {})
		return returnResponse
	},
	async ConfirmPayment(item, data, traduceTwo) {
		data = {
			id_reservacion: item,
			confirmation_number: data,
		}
		let returnResponse
		await axios({
			method: 'POST',
			url: `${ENV_CORE}/api/payments/confirm-payments`,
			data: data,
		})
			.then((response) => {
				if (response.data.statusCode === 200) {
					returnResponse = response.data
				} else {
					notification['warning']({
						message: `${traduceTwo.service_error_description_one}`,
						duration: 8,
						description: `${traduceTwo.service_error_description_one}`,
					})
				}
			})
			.catch((error) => {
				console.error('error request:', error)
			})
		return returnResponse
	},
}
export default servicesPayment
