/** @format */

export const ENV_CORE = `${process.env.REACT_APP_SERVICE_CORE}`
export const ENV_KEY_GOOGLE = `${process.env.REACT_APP_KEY_GOOGLE}`
export const ENV_API_GOOGLE = `${process.env.REACT_APP_API_GOOGLE}`
export const ENV_API_FACEBOOK = `${process.env.REACT_APP_KEY_FACEBOOK}`
export const EZ_PAYMENTS = `${process.env.REACT_APP_EZ_PAYMENTS}`
export const CORS_BYPASS = `${process.env.REACT_APP_CORS_BYPASS}`
export const ENV_ALGOLIA_KEY = `${process.env.REACT_APP_ALGOLIA_KEY}`
export const ENV_ALGOLIA_ID = `${process.env.REACT_APP_ALGOLIA_ID}`
