/** @format */

import React from 'react'

import { Link } from 'react-router-dom'

import { useTranslation } from 'react-i18next'

import HeadDescription from '../../../../components/Common/HeadDescription'

import MainNavigation from '../../../../components/Common/MainNavigation'

//import Carrousel from './components/Carrousel'

import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
//import Form from 'antd/lib/form'
//import Button from 'antd/lib/button'
//import Input from 'antd/lib/input'

//import InputCustom from '../../../../components/Common/Inputs/Normal'

import {
	FolderOutlined,
	WechatOutlined,
	HeartOutlined,
	//FacebookFilled,
	//TwitterSquareFilled,
	//InstagramOutlined,
	PlusCircleFilled,
} from '@ant-design/icons'

//import RightSearch from '../RightSearch'
import Image from '../../../../components/Common/Image'
import ArticleTwoMain from '../../../../img/blog/articulo2.jpg'
import ArticleOneMain from '../../../../img/blog/articulo1/principal.jpg'
import ArticleThreeMain from '../../../../img/blog/articulo3.jpg'
import ArticleFiveMain from '../../../../img/blog/articulo5.jpg'

//import CommentProfilePhoto from '../../../../../src/img/detail/profile-example.png'

import './style.css'

export default function ArticleTwo() {
	const { t } = useTranslation()
	//const { TextArea } = Input
	return (
		<>
			<HeadDescription
				title={'Prujula - Actículo'}
				name={'description'}
				content={'Camping Site'}
			/>
			<div className='est-blog-article-two-global-container'>
				<MainNavigation
					title={t('blog_detail.main_navigation.title')}
					linkPrevTitle={'Home'}
					linkNextTitle={'Blog'}
					linkPrev={'/'}
					linkNext={'/blog'}
				/>
				<div className='est-blog-article-two-main-container'>
					<Row>
						<Col
							span={24}
							/* xs={24}
							sm={24}
							md={24}
							lg={18}
							xl={18} */
							className='est-blog-article-two-left-global-container'>
							<div className='est-blog-article-two-title-img-container'>
								<h3 className='est-blog-article-two-title'>
									Puerto Rico: un paraíso para proteger y disfrutar
								</h3>
								<h3 className='est-blog-article-two-subtitle'>Autor: Estelí Capote</h3>
								<div className='est-blog-article-two-subtitle-container'>
									<h4 className='est-blog-article-two-category-text'>
										<FolderOutlined /> {t('blog_detail.article_category')}
									</h4>
									<h4 className='est-blog-article-two-category-icon'>
										<WechatOutlined /> 12
									</h4>
									<h4 className='est-blog-article-two-category-icon'>
										<HeartOutlined /> 6
									</h4>
								</div>
								<div className='est-blog-article-two-img-container'>
									<Image
										classImg={'est-blog-main-img'}
										image={ArticleTwoMain}
										alt={'Profile photo'}
										title={'Profile photo'}
									/>
									<div className='est-blog-article-two-img-date'>
										<h3 className='est-blog-article-two-img-date-title'>17</h3>
										<h3 className='est-blog-article-two-img-date-subtitle'>07</h3>
									</div>
								</div>
							</div>
							<div className='est-blog-article-two-text-container'>
								<p className='est-blog-article-two-text'>
									Desde los años de la administración de Luis Muñoz Marín, Puerto Rico se
									ha vislumbrado como un destino turístico sin igual. Ciertamente, nuestro
									clima es propicio para realizar actividades de exterior durante todo el
									año. A ello se le añade la variedad de ecosistemas, flora y fauna que
									coexisten en un solo archipiélago. Sin embargo, se ha apostado por un
									turismo de negocios que poco aporta a nuestra economía y que ha
									demostrado ser un nicho volátil que depende (1) del desarrollo de nuevos
									negocios y (2) de condiciones - cada vez más cambiantes - que permitan la
									visita de turistas del exterior.
									<br></br>
									<br></br>
									En tiempos relativamente recientes la Compañía de Turismo, que tuvo al
									Plan. José 'Tato' Rivera Santana entre su plantilla, comenzó a
									desarrollar una visión de turismo más parecida a lo que puede ser una
									visión compartida de turismo ecológico. Este proyecto comienza por
									nutrirse de turismo interno donde las y los puertorriqueños disfrutamos
									de nuestro patrimonio natural. Apostamos a que esta visión de turismo
									tiene el potencial de garantizar el turismo como modelo de desarrollo
									económico siempre y cuando se cumplan con una serie de parámetros.
									<br></br>
									<br></br>
									Primero este turismo debe ser sustentable. Debe respetar la ecología de
									lugar y no atentar contra ella. Ello exisigrá de los dueños de fincas,
									así como de las agencias, fiscalización para garantizar el bueno uso,
									manejo y conservación de los recursos. Segundo, este turismo debe ser
									nacional. De nada sirve que los sitios de interés estén en manos
									extranjeras, seguiría siendo capital local que se extrae del país como lo
									fue en su época la industria de la caña y el café. Ello requiere que se
									eliminen las Leyes 20, 22 y 60 que ponen en clara desventaja el
									desarrollo de negocios locales frente a inversores de capital extranjero.
									Tercero, desarrollo y cumplimiento con guías de desarrollo ecoturísticos.
									La Compañía de Turismo ha desarrollado una serie de guías respecto a este
									particular. Es importante que se divulguen las mismas, se establezcan
									modelos, e incluso se certifique alguna entidad para que certifique los
									sitios turísticos que quieran certificarse como tal.
									<br></br>
									<br></br>
									Prújula se compromete a fomentar el desarrollo y promoción de actividades
									de ocio ecológico y más aún promover el turismo interno y externo de foma
									sostenible. Más adelante continuaremos escribiendo sobre este particular.
								</p>

								<p className='est-blog-article-two-text'>
									Para más información puede accesar:
								</p>
								<a href='https://www.prtourism.com/dnn/Portals/_default/Turismo/Documents/Sustainable%20Tourism/CatalogoVerde2017.pdf'>
									Link 1
								</a>
								<br></br>
								<a href='https://www.prtourism.com/Portals/0/Turismo%20Sostenible/Ecoturismo/Gu%C3%ADas%20de%20Ecoturismo%20Enero%202021.pdf?ver=2021-02-15-083054-253'>
									Link 2
								</a>
								<br></br>
								<a href='https://www.prtourism.com/dnn/Portals/_default/Turismo/Documents/Guia%20Agroturismo.pdf'>
									Link 3
								</a>
								<br></br>
								<a href='https://www.prtourism.com/dnn/Portals/_default/Turismo/Documents/Gui%CC%81a%20Operacional%20para%20Instalaciones%20Ecoturi%CC%81sticas%20y%20Turismo%20Sostenible%202014%20PDF.pdf'>
									Link 4
								</a>
								<br></br>
								<a href='https://www.prtourism.com/dnn/Portals/_default/Turismo/Documents/Gui%CC%81as%20de%20Disen%CC%83o%20para%20Instalaciones%20Ecoturi%CC%81sticas%20y%20Turismo%20Sostenible.pdf'>
									Link 5
								</a>
								<br></br>
								<a href='https://www.prtourism.com/dnn/Prensa/PID/1514/TagID/195/TagName/Turismo-Sostenible'>
									Link 6
								</a>
							</div>
							<hr className='est-blog-article-two-spacer'></hr>
							{/* <div className='est-blog-article-two-carrousel-container'>
								<Carrousel />
							</div> 
							<hr className='est-blog-article-two-spacer'></hr>
							<div className='est-blog-article-two-icon-container'>
								<h3 className='est-blog-article-two-rrss'>{t('blog_detail.share')}</h3>
								<h3 className='est-blog-article-two-icon'>
									<FacebookFilled />
								</h3>
								<h3 className='est-blog-article-two-icon'>
									<TwitterSquareFilled />
								</h3>
								<h3 className='est-blog-article-two-icon'>
									<InstagramOutlined />
								</h3>
							</div>
							<div className='est-blog-article-two-comments-container'>
								<h3 className='est-blog-article-two-comments-title'>
									3 {t('blog_detail.comments.title')}
								</h3>

								<div className='est-blog-article-two-comments-main-container'>
									<div className='est-blog-article-two-comments-img-container'>
										<Image
											classImg={'est-blog-article-two-comments-img'}
											image={CommentProfilePhoto}
											alt={'Profile photo'}
											title={'Profile photo'}
										/>
									</div>
									<div className='est-blog-article-two-comments-text-container'>
										<div className='est-blog-article-two-comments-title-subtittle-container'>
											<h3 className='est-blog-article-two-comments-title'>Barney</h3>
											<h3 className='est-blog-article-two-comments-subtitle'>
												Abril 7, 2021 1:33 am
											</h3>
										</div>
										<h3 className='est-blog-article-two-comments-text'>
											Has maiorum habemus detraxit at. Timeam fabulas splendide et his.
											Facilisi aliquando sea ad, vel ne consetetur adversarium. Integre
											admodum et his,
										</h3>
										<h4 className='est-blog-article-two-comments-text-link'>
											{t('blog_detail.comments.answer')}
										</h4>
									</div>
								</div>

								<hr className='est-blog-article-two-comments-response-hr'></hr>

								<div className='est-blog-article-two-comments-main-container'>
									<div className='est-blog-article-two-comments-img-container'>
										<Image
											classImg={'est-blog-article-two-comments-img'}
											image={CommentProfilePhoto}
											alt={'Profile photo'}
											title={'Profile photo'}
										/>
									</div>
									<div className='est-blog-article-two-comments-text-container'>
										<div className='est-blog-article-two-comments-title-subtittle-container'>
											<h3 className='est-blog-article-two-comments-title'>Lyly</h3>
											<h3 className='est-blog-article-two-comments-subtitle'>
												Abril 7, 2021 1:33 am
											</h3>
										</div>
										<h3 className='est-blog-article-two-comments-text'>
											Has maiorum habemus detraxit at. Timeam fabulas splendide et his.
											Facilisi aliquando sea ad, vel ne consetetur adversarium. Integre
											admodum et his,
										</h3>
										<h4 className='est-blog-article-two-comments-text-link'>
											{t('blog_detail.comments.answer')}
										</h4>
									</div>
								</div>

								<div className='est-blog-article-two-comments-main-container'>
									<div className='est-blog-article-two-comments-img-container'>
										<Image
											classImg={'est-blog-article-two-comments-img'}
											image={CommentProfilePhoto}
											alt={'Profile photo'}
											title={'Profile photo'}
										/>
									</div>
									<div className='est-blog-article-two-comments-text-container'>
										<div className='est-blog-article-two-comments-title-subtittle-container'>
											<h3 className='est-blog-article-two-comments-title'>Barney</h3>
											<h3 className='est-blog-article-two-comments-subtitle'>
												Abril 7, 2021 1:33 am
											</h3>
										</div>
										<h3 className='est-blog-article-two-comments-text'>
											Has maiorum habemus detraxit at. Timeam fabulas splendide et his.
											Facilisi aliquando sea ad, vel ne consetetur adversarium. Integre
											admodum et his,
										</h3>
										<h4 className='est-blog-article-two-comments-text-link'>
											{t('blog_detail.comments.answer')}
										</h4>
									</div>
								</div>
							</div>
							<hr className='est-blog-article-two-spacer'></hr>
							<div className='est-blog-article-two-comments-form-container'>
								<h2 className='est-blog-article-two-comments-form-title'>
									{t('blog_detail.form.title')}
								</h2>
								<h3 className='est-blog-article-two-comments-form-subtitle'>
									{t('blog_detail.form.subtitle')} <span>*</span>
								</h3>
								<Form name='blog'>
									<Row className='est-blog-article-two-form-global-container'>
										<Col
											xs={24}
											sm={24}
											md={12}
											lg={12}
											xl={12}
											className='est-blog-article-two-form-main-container-one'>
											<InputCustom
												className={'est-blog-article-two-form-input'}
												inputName={'first_name'}
												inputNameLabel={t('blog_detail.form.input_name')}
												inputNameRule={true}
												inputNameMessage={t('blog_detail.form.placeholder_name')}
												inputNameType={'text'}
												inputNameIcon={''}
												inputNameRules={'rulesFirstName'}
											/>
											<InputCustom
												className={'est-blog-article-two-form-input'}
												inputName={'email'}
												inputNameLabel={t('blog_detail.form.input_email')}
												inputNameRule={true}
												inputNameMessage={t('blog_detail.form.placeholder_email')}
												inputNameType={'text'}
												inputNameIcon={''}
												inputNameRules={'rulesEmail'}
											/>
										</Col>
										<Col
											xs={24}
											sm={24}
											md={12}
											lg={12}
											xl={12}
											className='est-blog-article-two-form-main-container-two'>
											<Form.Item name={'message'}>
												<TextArea
													className='est-home-contact-field-input'
													size='large'
													type={'text'}
													placeholder={t('blog_detail.form.text_area')}
													rows={3}
												/>
											</Form.Item>
										</Col>
										<Col span={24} className='est-blog-article-two-form-button-container'>
											<Button
												className='est-blog-article-two-form-button'
												htmlType={'submit'}>
												{t('blog_detail.form.button')}
											</Button>
										</Col>
									</Row>
								</Form>
							</div>*/}

							<div className='est-blog-article-two-see-more-global-container'>
								<h3 className='est-blog-article-two-see-more-title'>
									{t('blog_detail.another_articles.title')}
								</h3>

								<Row>
									<Col
										xs={24}
										sm={24}
										md={24}
										lg={8}
										xl={8}
										className='est-blog-article-two-see-more-main-container'>
										<Link to='/blog/article-one'>
											<Image
												classImg={'est-blog-article-two-see-more-img'}
												image={ArticleOneMain}
												alt={'Profile photo'}
												title={'Profile photo'}
											/>
											<h3 className='est-blog-article-two-see-more-article-title'>
												SENDERISMO AL ALCANCE DE TODOS
											</h3>
											<h3 className='est-blog-article-two-see-more-subtitle'>
												Por Ricardo Martínez, Julio 16 2021
											</h3>
											<h3 className='est-blog-article-two-see-more-text'>
												Más que nunca me convenzo de lo importante y necesario que es
												para nuestra salud mental y física separar...
											</h3>
											<h3 className='est-blog-article-two-see-more-link'>
												<PlusCircleFilled className='est-blog-article-two-see-more-icon' />
												{t('blog_detail.another_articles.link')}
											</h3>
										</Link>
									</Col>
									<Col
										xs={24}
										sm={24}
										md={24}
										lg={8}
										xl={8}
										className='est-blog-article-two-see-more-main-container'>
										<Link to='/blog/article-three'>
											<Image
												classImg={'est-blog-article-one-see-more-img'}
												image={ArticleThreeMain}
												alt={'Profile photo'}
												title={'Profile photo'}
											/>
											<h3 className='est-blog-article-one-see-more-article-title'>
												La semilla de PRUJULA
											</h3>
											<h3 className='est-blog-article-one-see-more-subtitle'>
												Por Estelí Capote, Julio 15 2021
											</h3>
											<h3 className='est-blog-article-one-see-more-text'>
												Desde que era una pioja, y gracias a mi papá, aprendí a amar mi
												país disfrutándolo, acampando en cada monte o playa que nos
												encontrábamos.
											</h3>
											<h3 className='est-blog-article-one-see-more-link'>
												<PlusCircleFilled className='est-blog-article-one-see-more-icon' />
												{t('blog_detail.another_articles.link')}
											</h3>
										</Link>
									</Col>
									<Col
										xs={24}
										sm={24}
										md={24}
										lg={8}
										xl={8}
										className='est-blog-article-two-see-more-main-container'>
										<Link to='/blog/article-five'>
											<Image
												classImg={'est-blog-article-two-see-more-img'}
												image={ArticleFiveMain}
												alt={'Profile photo'}
												title={'Profile photo'}
											/>
											<h3 className='est-blog-article-two-see-more-article-title'>
												Para campistas, camperos y fanáticos
											</h3>
											<h3 className='est-blog-article-two-see-more-subtitle'>
												Por ECM, Julio 20 2021
											</h3>
											<h3 className='est-blog-article-two-see-more-text'>
												Más que un artículo o blog esto es mi “cheat sheet” para
												acampar....
											</h3>
											<h3 className='est-blog-article-two-see-more-link'>
												<PlusCircleFilled className='est-blog-article-two-see-more-icon' />
												{t('blog_detail.another_articles.link')}
											</h3>
										</Link>
									</Col>
								</Row>
							</div>
						</Col>
						{/* 	<Col span={6} className='est-blog-article-two-right-global-container'>
							<RightSearch />
						</Col> */}
					</Row>
				</div>
			</div>
		</>
	)
}
