/** @format */

import React from 'react'

import { useTranslation } from 'react-i18next'

import MainNavigation from '../../components/Common/MainNavigation'
import HeadDescription from '../../components/Common/HeadDescription'
import Algolia from '../../components/Common/Algolia'

import './style.css'

export default function Categories(props) {
	const { t } = useTranslation()
	return (
		<>
			<HeadDescription
				title={`Prujula - ${props.match.params.categories}`}
				name={'description'}
				content={'Camping Site'}
			/>
			<MainNavigation
				title={`${props.match.params.categories}`}
				linkPrevTitle={'Home'}
				linkNextTitle={t('categories.main_navigation.title')}
				linkPrev={'/'}
				linkNext={'/categories'}
			/>
			<Algolia params={props.match.params} />
		</>
	)
}
