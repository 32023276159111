/** @format */

import React, { useState, useEffect } from 'react'

import { useGlobal, setGlobal } from 'reactn'

import { useTranslation } from 'react-i18next'

import MainNavigation from '../../../../components/Common/MainNavigation'

import HeadDescription from '../../../../components/Common/HeadDescription'
import Loading from '../../../../components/Common/Loading'

import MainMenu from '../../components/MainMenu'
import DataInfo from './components/DataInfo'

import { GetUserInfo } from '../../../../components/Common/Hooks/Functions/GetUserInfo'

import './style.css'

export default function ProfileMetrics() {
	const { t } = useTranslation()
	const [isUser] = useState(JSON.parse(localStorage.getItem('userSession')))
	const [isDataProfile] = useGlobal('DataProfile')

	useEffect(() => {
		GetUserInfo(isUser.id).then((response) => {
			if (response) {
				setGlobal({
					DataProfile: response,
				})
			} else {
				setTimeout(() => {
					window.location.pathname = '/'
				}, 5000)
			}
		})
	}, [isUser.id])

	if (!isDataProfile) {
		return <Loading />
	} else {
		return (
			<>
				<HeadDescription
					title={`${t('profile.data_metrics.head_description.title')} - ${isUser.id}`}
					name={'description'}
					content={'Camping Site'}
				/>

				<MainNavigation
					title={t('profile.data_metrics.main_navigation.title')}
					linkPrevTitle={'Home'}
					linkNextTitle={t('profile.data_metrics.main_navigation.title')}
					linkPrev={'/'}
					linkNext={'/'}
				/>

				<div className='est-prujula-detail-profile-global-container'>
					<div className='est-prujula-main-container'>
						<div className='est-prujula-detail-profile-main-title-container'>
							<h2 className='est-prujula-detail-profile-main-title'>
								{t('profile.data_metrics.user_info')}
							</h2>
						</div>

						<div className='est-profile-global-container'>
							<div className='est-main-menu-global-container'>
								<MainMenu user={isDataProfile} />
							</div>
							<div className='est-profile-info-container'>
								<DataInfo isUserDetailInfo={isUser} />
							</div>
						</div>
					</div>
				</div>
			</>
		)
	}
}
