/** @format */

import React from 'react'

import { Link } from 'react-router-dom'

import { SortBy, Stats, connectHits } from 'react-instantsearch-dom'

import { useTranslation } from 'react-i18next'

import { Button } from 'antd'

import { BarsOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons'

import ReviewStars from '../../../ReviewStars'

import './style.css'

export default function RightContent() {
	const { t } = useTranslation()
	const MyHits = connectHits(({ hits }) => {
		const hs = hits.map((hit) => <HitComponent key={hit.objectID} hit={hit} />)
		return <div id='hits'>{hs}</div>
	})

	function HitComponent({ hit }) {
		return (
			<div className='est-algolia-hits-global-container'>
				<div className='est-algolia-hits-img-container'>
					<img className='est-algolia-hits-img' alt={hit.title} src={hit.picture_url} />
				</div>

				<div className='est-algolia-hits-main-container'>
					<div className='est-algolia-hits-title-review-container'>
						<div className='est-algolia-hits-title-price-container'>
							<h3 className='est-algolia-hits-title'>{hit.title}</h3>
							<div className='est-algolia-hits-price'>$ {hit.price}</div>
							{/* <img className='profile' alt={hit.user.nombre} src={hit.user.foto} /> */}
						</div>
						<div className='est-algolia-hits-review-global-container'>
							<ReviewStars review={hit.calificacion} />
							<h4 className='est-algolia-hits-review'>
								( {t('algolia.results.reviews_title')} )
							</h4>
						</div>
					</div>

					<div className='est-algolia-hits-info-global-container'>
						<h4 className='est-algolia-hits-info-description'>{hit.description}</h4>
						{/* <h4 className='est-algolia-hits-info-city'>{hit.completeAddress.city}</h4>
					<h4 className='est-algolia-hits-info-country'>{hit.completeAddress.country}</h4> */}
					</div>

					<div className='est-algolia-hits-button-container'>
						<Link
							to={`/publication/detail/${hit.objectID}`}
							className='est-algolia-hits-button'>
							<PlusOutlined /> {t('algolia.results.see_more_button')}
						</Link>
						<Button className='est-algolia-hits-search-button'>
							<SearchOutlined />
						</Button>
					</div>
				</div>
			</div>
		)
	}

	return (
		<div className='container-fluid' id='results'>
			<div className='est-results-global-container'>
				<div className='est-results-sort-global-container'>
					<div className='est-results-info-area-container'>
						<h3 className='est-results-info-area-icon'>
							<BarsOutlined />
						</h3>
						<Stats />
					</div>
					{/* 
					<div id='searchbox'>
						<HitsPerPage
							defaultRefinement={3}
							items={[{ value: 3 }, { value: 12 }, { value: 24 }, { value: 48 }]}
						/>
					</div>
					 */}
					<div className='est-results-sort-container'>
						<h3 className='est-results-sort-title'>
							{t('algolia.results.sort_by_main_title')}
						</h3>
						<SortBy
							defaultRefinement='publications'
							items={[
								{
									label: `${t('algolia.results.sort_by_option_one')}`,
									value: 'publications',
								},
								{
									label: `${t('algolia.results.sort_by_option_two')}`,
									value: 'publications_price_asc',
								},
								{
									label: `${t('algolia.results.sort_by_option_three')}`,
									value: 'publications_price_desc',
								},
							]}
						/>
					</div>
				</div>
				<hr className='est-results-spacer'></hr>
				<MyHits />
			</div>
		</div>
	)
}
