/** @format */

import React from 'react'

import { Link } from 'react-router-dom'

import { useTranslation } from 'react-i18next'

import HeadDescription from '../../../../components/Common/HeadDescription'

import MainNavigation from '../../../../components/Common/MainNavigation'

//import Carrousel from './components/Carrousel'

import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
//import Form from 'antd/lib/form'
//import Button from 'antd/lib/button'
//import Input from 'antd/lib/input'

//import InputCustom from '../../../../components/Common/Inputs/Normal'

import {
	FolderOutlined,
	WechatOutlined,
	HeartOutlined,
	//FacebookFilled,
	//TwitterSquareFilled,
	//InstagramOutlined,
	PlusCircleFilled,
} from '@ant-design/icons'

//import RightSearch from '../RightSearch'
import Image from '../../../../components/Common/Image'
import ArticleFourMain from '../../../../img/blog/articulo4.jpg'
import ArticleOneMain from '../../../../img/blog/articulo1/principal.jpg'
import ArticleThreeMain from '../../../../img/blog/articulo3.jpg'
import ArticleFiveMain from '../../../../img/blog/articulo5.jpg'

//import CommentProfilePhoto from '../../../../../src/img/detail/profile-example.png'

import './style.css'

export default function ArticleFour() {
	const { t } = useTranslation()
	//const { TextArea } = Input
	return (
		<>
			<HeadDescription
				title={'Prujula - Actículo'}
				name={'description'}
				content={'Camping Site'}
			/>
			<div className='est-blog-article-four-global-container'>
				<MainNavigation
					title={t('blog_detail.main_navigation.title')}
					linkPrevTitle={'Home'}
					linkNextTitle={'Blog'}
					linkPrev={'/'}
					linkNext={'/blog'}
				/>
				<div className='est-blog-article-four-main-container'>
					<Row>
						<Col
							span={24}
							/* xs={24}
							sm={24}
							md={24}
							lg={18}
							xl={18} */
							className='est-blog-article-four-left-global-container'>
							<div className='est-blog-article-four-title-img-container'>
								<h3 className='est-blog-article-four-title'>¿Camping de Mochila o Baúl?</h3>
								<h3 className='est-blog-article-four-subtitle'>Autor: ECM</h3>
								<div className='est-blog-article-four-subtitle-container'>
									<h4 className='est-blog-article-four-category-text'>
										<FolderOutlined /> {t('blog_detail.article_category')}
									</h4>
									<h4 className='est-blog-article-four-category-icon'>
										<WechatOutlined /> 12
									</h4>
									<h4 className='est-blog-article-four-category-icon'>
										<HeartOutlined /> 6
									</h4>
								</div>
								<div className='est-blog-article-four-img-container'>
									<Image
										classImg={'est-blog-main-img'}
										image={ArticleFourMain}
										alt={'Profile photo'}
										title={'Profile photo'}
									/>
									<div className='est-blog-article-four-img-date'>
										<h3 className='est-blog-article-four-img-date-title'>19</h3>
										<h3 className='est-blog-article-four-img-date-subtitle'>07</h3>
									</div>
								</div>
							</div>
							<div className='est-blog-article-four-text-container'>
								<p className='est-blog-article-four-text'>
									Creo que había acampado más de veinte veces ocasiones cuando hablaba de
									nuestra próxima aventura con mis amigos Carlos y Diana, campistas de a
									verduras, cuando carlos me pregunta - ¿es de mochila o de baúl?- . Acto
									seguido le pregunto a Carlos a qué se refería y me explica que existen
									dos categorías mínimas para definir el tipo de campismo que se realiza.
									<br></br>
									<br></br>
									Aunque en la práctica una planifica el tipo de equipo que se lleva
									dependendiedo del lugar en el que se acampa, no se me había ocurrido que
									tenían un nombre: Camping de baúl o de mochila. ¡Exacto! Las categorías
									se establecen en relación a la distancia que existe entre el lugar de
									acampar y el estacionamiento del vehículo. Si el estacionamiento ubica a
									más o menos 100 metros de distancia entre el carro (donde se carga el
									equipo de camping) y el área de acampar se le conoce como camping de
									baúl. Es un camping cómodo donde básicamente puedes llevar todo lo que te
									quepa en el baúl del carro.
									<br></br>
									<br></br>
									Ahora bien, para el camping de mochila debes estar un poco más pro.
									Porque el lugar donde te estacionas se convierte en el punto de partida
									para la travesía hacia el área de acampar. En el caso del camping de
									mochila, la travesía a pie es parte de la aventura y requiere que el
									equipaje sea tan liviano como sea posible. Por lo que este tipo de
									camping requiere un nivel de experiencia mayor.
									<br></br>
									<br></br>
									Más adelante publicaremos un artículo sobre el equipo mínimo para camping
									de baúl y otro los para camping de mochila. ¡Pendientes a que nuestros
									expert@s campistas publiquen!
								</p>
							</div>
							<hr className='est-blog-article-four-spacer'></hr>
							{/* <div className='est-blog-article-four-carrousel-container'>
								<Carrousel />
							</div> 
							<hr className='est-blog-article-four-spacer'></hr>
							<div className='est-blog-article-four-icon-container'>
								<h3 className='est-blog-article-four-rrss'>{t('blog_detail.share')}</h3>
								<h3 className='est-blog-article-four-icon'>
									<FacebookFilled />
								</h3>
								<h3 className='est-blog-article-four-icon'>
									<TwitterSquareFilled />
								</h3>
								<h3 className='est-blog-article-four-icon'>
									<InstagramOutlined />
								</h3>
							</div>
							<div className='est-blog-article-four-comments-container'>
								<h3 className='est-blog-article-four-comments-title'>
									3 {t('blog_detail.comments.title')}
								</h3>

								<div className='est-blog-article-four-comments-main-container'>
									<div className='est-blog-article-four-comments-img-container'>
										<Image
											classImg={'est-blog-article-four-comments-img'}
											image={CommentProfilePhoto}
											alt={'Profile photo'}
											title={'Profile photo'}
										/>
									</div>
									<div className='est-blog-article-four-comments-text-container'>
										<div className='est-blog-article-four-comments-title-subtittle-container'>
											<h3 className='est-blog-article-four-comments-title'>Barney</h3>
											<h3 className='est-blog-article-four-comments-subtitle'>
												Abril 7, 2021 1:33 am
											</h3>
										</div>
										<h3 className='est-blog-article-four-comments-text'>
											Has maiorum habemus detraxit at. Timeam fabulas splendide et his.
											Facilisi aliquando sea ad, vel ne consetetur adversarium. Integre
											admodum et his,
										</h3>
										<h4 className='est-blog-article-four-comments-text-link'>
											{t('blog_detail.comments.answer')}
										</h4>
									</div>
								</div>

								<hr className='est-blog-article-four-comments-response-hr'></hr>

								<div className='est-blog-article-four-comments-main-container'>
									<div className='est-blog-article-four-comments-img-container'>
										<Image
											classImg={'est-blog-article-four-comments-img'}
											image={CommentProfilePhoto}
											alt={'Profile photo'}
											title={'Profile photo'}
										/>
									</div>
									<div className='est-blog-article-four-comments-text-container'>
										<div className='est-blog-article-four-comments-title-subtittle-container'>
											<h3 className='est-blog-article-four-comments-title'>Lyly</h3>
											<h3 className='est-blog-article-four-comments-subtitle'>
												Abril 7, 2021 1:33 am
											</h3>
										</div>
										<h3 className='est-blog-article-four-comments-text'>
											Has maiorum habemus detraxit at. Timeam fabulas splendide et his.
											Facilisi aliquando sea ad, vel ne consetetur adversarium. Integre
											admodum et his,
										</h3>
										<h4 className='est-blog-article-four-comments-text-link'>
											{t('blog_detail.comments.answer')}
										</h4>
									</div>
								</div>

								<div className='est-blog-article-four-comments-main-container'>
									<div className='est-blog-article-four-comments-img-container'>
										<Image
											classImg={'est-blog-article-four-comments-img'}
											image={CommentProfilePhoto}
											alt={'Profile photo'}
											title={'Profile photo'}
										/>
									</div>
									<div className='est-blog-article-four-comments-text-container'>
										<div className='est-blog-article-four-comments-title-subtittle-container'>
											<h3 className='est-blog-article-four-comments-title'>Barney</h3>
											<h3 className='est-blog-article-four-comments-subtitle'>
												Abril 7, 2021 1:33 am
											</h3>
										</div>
										<h3 className='est-blog-article-four-comments-text'>
											Has maiorum habemus detraxit at. Timeam fabulas splendide et his.
											Facilisi aliquando sea ad, vel ne consetetur adversarium. Integre
											admodum et his,
										</h3>
										<h4 className='est-blog-article-four-comments-text-link'>
											{t('blog_detail.comments.answer')}
										</h4>
									</div>
								</div>
							</div>
							<hr className='est-blog-article-four-spacer'></hr>
							<div className='est-blog-article-four-comments-form-container'>
								<h2 className='est-blog-article-four-comments-form-title'>
									{t('blog_detail.form.title')}
								</h2>
								<h3 className='est-blog-article-four-comments-form-subtitle'>
									{t('blog_detail.form.subtitle')} <span>*</span>
								</h3>
								<Form name='blog'>
									<Row className='est-blog-article-four-form-global-container'>
										<Col
											xs={24}
											sm={24}
											md={12}
											lg={12}
											xl={12}
											className='est-blog-article-four-form-main-container-one'>
											<InputCustom
												className={'est-blog-article-four-form-input'}
												inputName={'first_name'}
												inputNameLabel={t('blog_detail.form.input_name')}
												inputNameRule={true}
												inputNameMessage={t('blog_detail.form.placeholder_name')}
												inputNameType={'text'}
												inputNameIcon={''}
												inputNameRules={'rulesFirstName'}
											/>
											<InputCustom
												className={'est-blog-article-four-form-input'}
												inputName={'email'}
												inputNameLabel={t('blog_detail.form.input_email')}
												inputNameRule={true}
												inputNameMessage={t('blog_detail.form.placeholder_email')}
												inputNameType={'text'}
												inputNameIcon={''}
												inputNameRules={'rulesEmail'}
											/>
										</Col>
										<Col
											xs={24}
											sm={24}
											md={12}
											lg={12}
											xl={12}
											className='est-blog-article-four-form-main-container-two'>
											<Form.Item name={'message'}>
												<TextArea
													className='est-home-contact-field-input'
													size='large'
													type={'text'}
													placeholder={t('blog_detail.form.text_area')}
													rows={3}
												/>
											</Form.Item>
										</Col>
										<Col
											span={24}
											className='est-blog-article-four-form-button-container'>
											<Button
												className='est-blog-article-four-form-button'
												htmlType={'submit'}>
												{t('blog_detail.form.button')}
											</Button>
										</Col>
									</Row>
								</Form>
							</div>*/}

							<div className='est-blog-article-four-see-more-global-container'>
								<h3 className='est-blog-article-four-see-more-title'>
									{t('blog_detail.another_articles.title')}
								</h3>

								<Row>
									<Col
										xs={24}
										sm={24}
										md={24}
										lg={8}
										xl={8}
										className='est-blog-article-four-see-more-main-container'>
										<Link to='/blog/article-five'>
											<Image
												classImg={'est-blog-article-two-see-more-img'}
												image={ArticleFiveMain}
												alt={'Profile photo'}
												title={'Profile photo'}
											/>
											<h3 className='est-blog-article-two-see-more-article-title'>
												Para campistas, camperos y fanáticos
											</h3>
											<h3 className='est-blog-article-two-see-more-subtitle'>
												Por ECM, Julio 20 2021
											</h3>
											<h3 className='est-blog-article-two-see-more-text'>
												Más que un artículo o blog esto es mi “cheat sheet” para
												acampar....
											</h3>
											<h3 className='est-blog-article-two-see-more-link'>
												<PlusCircleFilled className='est-blog-article-two-see-more-icon' />
												{t('blog_detail.another_articles.link')}
											</h3>
										</Link>
									</Col>
									<Col
										xs={24}
										sm={24}
										md={24}
										lg={8}
										xl={8}
										className='est-blog-article-four-see-more-main-container'>
										<Link to='/blog/article-one'>
											<Image
												classImg={'est-blog-article-two-see-more-img'}
												image={ArticleOneMain}
												alt={'Profile photo'}
												title={'Profile photo'}
											/>
											<h3 className='est-blog-article-two-see-more-article-title'>
												SENDERISMO AL ALCANCE DE TODOS
											</h3>
											<h3 className='est-blog-article-two-see-more-subtitle'>
												Por Ricardo Martínez, Julio 16 2021
											</h3>
											<h3 className='est-blog-article-two-see-more-text'>
												Más que nunca me convenzo de lo importante y necesario que es
												para nuestra salud mental y física separar...
											</h3>
											<h3 className='est-blog-article-two-see-more-link'>
												<PlusCircleFilled className='est-blog-article-two-see-more-icon' />
												{t('blog_detail.another_articles.link')}
											</h3>
										</Link>
									</Col>
									<Col
										xs={24}
										sm={24}
										md={24}
										lg={8}
										xl={8}
										className='est-blog-article-four-see-more-main-container'>
										<Link to='/blog/article-three'>
											<Image
												classImg={'est-blog-article-one-see-more-img'}
												image={ArticleThreeMain}
												alt={'Profile photo'}
												title={'Profile photo'}
											/>
											<h3 className='est-blog-article-one-see-more-article-title'>
												La semilla de PRUJULA
											</h3>
											<h3 className='est-blog-article-one-see-more-subtitle'>
												Por Estelí Capote, Julio 15 2021
											</h3>
											<h3 className='est-blog-article-one-see-more-text'>
												Desde que era una pioja, y gracias a mi papá, aprendí a amar mi
												país disfrutándolo, acampando en cada monte o playa que nos
												encontrábamos.
											</h3>
											<h3 className='est-blog-article-one-see-more-link'>
												<PlusCircleFilled className='est-blog-article-one-see-more-icon' />
												{t('blog_detail.another_articles.link')}
											</h3>
										</Link>
									</Col>
								</Row>
							</div>
						</Col>
						{/* <Col span={6} className='est-blog-article-four-right-global-container'>
							<RightSearch />
						</Col> */}
					</Row>
				</div>
			</div>
		</>
	)
}
