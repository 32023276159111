/** @format */

import React, { useState, useEffect } from 'react'

import { useTranslation } from 'react-i18next'

import { Link } from 'react-router-dom'

import MainNavigation from '../../../../../components/Common/MainNavigation'

import Row from 'antd/lib/row'
import Form from 'antd/lib/form'
import Col from 'antd/lib/col'
import Checkbox from 'antd/lib/checkbox'
import Button from 'antd/lib/button'

import Spacer from '../../../../../components/Common/Spacer'

import CreateAd from './services'

import './style.css'

export default function Amenities() {
	const { t } = useTranslation()
	const [isInfoAmenities] = useState(JSON.parse(localStorage.getItem('info-photos')))
	const [isLoading, setLoading] = useState(false)

	const handleAmenities = async (item) => {
		let info = isInfoAmenities
		info.regAmenities = item
		setLoading(true)

		const traduce = {
			service_warning_title: t('publication.create.amenities.service_warning_title'),
			service_warning_description: t('publication.create.amenities.service_warning_description'),
			service_error_description: t('publication.create.amenities.service_error_description'),
		}

		CreateAd(info, traduce).then((response) => {
			if (response) {
				setLoading(false)
				localStorage.setItem('success', JSON.stringify(response))
				window.location.pathname = 'publication/create/success'
			} else {
				setLoading(false)
				window.location.pathname = 'publication/create/error'
			}
		})
	}

	useEffect(() => {
		if (!isInfoAmenities) {
			window.location.pathname = '/'
		}
	}, [isInfoAmenities])
	return (
		<>
			<MainNavigation
				title={t('publication.create.amenities.main_navigation.title')}
				linkPrevTitle={'Home'}
				linkNextTitle={t('publication.create.amenities.main_navigation.title')}
				linkPrev={'/'}
				linkNext={'/publication/create/amenities'}
			/>
			<div className='est-ads-amenities-global-container'>
				<h1 className='est-ads-amenities-global-title'>
					{t('publication.create.amenities.title')}
				</h1>
				<h4 className='est-ads-amenities-global-subtitle'>
					{t('publication.create.amenities.subtitle')}
				</h4>
				<Form
					className='est-ads-amenities-form-container'
					name='basic_info'
					onFinish={handleAmenities}
					initialValues={{
						agua: false,
						luz: false,
						tocador: false,
						cocinas: false,
						bbq: false,
						fogata: false,
						historico: false,
						ecologia: false,
						agricola: false,
						reactivo_pasivo: false,
						reactivo_activo: false,
						recreacion_piscinas: false,
						recreacion_acuaticas: false,
						recreacion_veredas: false,
						recreacion_espeleologia: false,
						recreacion_kayac_paddle_balsas: false,
						recreacion_cocina: false,
						recreacion_pajaros: false,
						recreacion_alpinismo: false,
						recreacion_zipline: false,
						paracaidas: false,
						recreacion_areas: false,
						recreacion_animales: false,
						equipos_mesas: false,
						equipos_sillas: false,
						equipos_estufas: false,
						casetas_acampar: false,
						toldos: false,
						estufas_gas: false,
						tanques_gas: false,
						lena: false,
						carbon: false,
						se_admiten_mascotas: false,
						perros_servicios: false,
					}}>
					<Row className='est-ads-amenities-main-container'>
						<Col span={24} className='est-amenities-checkbox-container'>
							<h4 className='est-amenities-main-title'>
								{t('publication.create.amenities.title_services')}
							</h4>
							<Row>
								<Col xs={12} sm={12} md={8} lg={8} xl={8}>
									<Form.Item name='agua' valuePropName='checked'>
										<Checkbox>
											{t('publication.create.amenities.checkbox_services_water')}
										</Checkbox>
									</Form.Item>
								</Col>
								<Col xs={12} sm={12} md={8} lg={8} xl={8}>
									<Form.Item name='luz' valuePropName='checked'>
										<Checkbox>
											{t('publication.create.amenities.checkbox_services_light')}
										</Checkbox>
									</Form.Item>
								</Col>
								<Col xs={12} sm={12} md={8} lg={8} xl={8}>
									<Form.Item name='tocador' valuePropName='checked'>
										<Checkbox>
											{t('publication.create.amenities.checkbox_services_toilet')}
										</Checkbox>
									</Form.Item>
								</Col>
								<Col xs={12} sm={12} md={8} lg={8} xl={8}>
									<Form.Item name='cocinas' valuePropName='checked'>
										<Checkbox>
											{t('publication.create.amenities.checkbox_services_kitchen')}
										</Checkbox>
									</Form.Item>
								</Col>
								<Col xs={12} sm={12} md={8} lg={8} xl={8}>
									<Form.Item name='bbq' valuePropName='checked'>
										<Checkbox>BBQ</Checkbox>
									</Form.Item>
								</Col>
								<Col xs={12} sm={12} md={8} lg={8} xl={8}>
									<Form.Item name='fogata' valuePropName='checked'>
										<Checkbox>
											{t('publication.create.amenities.checkbox_services_campfire')}
										</Checkbox>
									</Form.Item>
								</Col>
							</Row>
						</Col>
						<Col span={24} className='est-amenities-checkbox-container'>
							<h4 className='est-amenities-main-title'>
								{t('publication.create.amenities.title_site')}
							</h4>
							<Row>
								<Col xs={12} sm={12} md={8} lg={8} xl={8}>
									<Form.Item name='historico' valuePropName='checked'>
										<Checkbox>
											{t('publication.create.amenities.checkbox_site_history')}
										</Checkbox>
									</Form.Item>
								</Col>
								<Col xs={12} sm={12} md={8} lg={8} xl={8}>
									<Form.Item name='ecologia' valuePropName='checked'>
										<Checkbox>
											{t('publication.create.amenities.checkbox_site_reserve')}
										</Checkbox>
									</Form.Item>
								</Col>
								<Col xs={12} sm={12} md={8} lg={8} xl={8}>
									<Form.Item name='agricola' valuePropName='checked'>
										<Checkbox>
											{t('publication.create.amenities.checkbox_site_farming')}
										</Checkbox>
									</Form.Item>
								</Col>
								<Col xs={12} sm={12} md={8} lg={8} xl={8}>
									<Form.Item name='reactivo_pasivo' valuePropName='checked'>
										<Checkbox>
											{t('publication.create.amenities.checkbox_site_recess_pas')}
										</Checkbox>
									</Form.Item>
								</Col>
								<Col xs={12} sm={12} md={8} lg={8} xl={8}>
									<Form.Item name='reactivo_activo' valuePropName='checked'>
										<Checkbox>
											{t('publication.create.amenities.checkbox_site_recess_act')}
										</Checkbox>
									</Form.Item>
								</Col>
							</Row>
						</Col>
						<Col span={24} className='est-amenities-checkbox-container'>
							<h4 className='est-amenities-main-title'>
								{t('publication.create.amenities.title_activity')}
							</h4>
							<Row>
								<Col xs={12} sm={12} md={12} lg={12} xl={12}>
									<Form.Item name='recreacion_acuaticas' valuePropName='checked'>
										<Checkbox>
											{t('publication.create.amenities.checkbox_activity_aqua')}
										</Checkbox>
									</Form.Item>
								</Col>
								<Col xs={12} sm={12} md={12} lg={12} xl={12}>
									<Form.Item name='recreacion_alpinismo' valuePropName='checked'>
										<Checkbox>
											{t('publication.create.amenities.checkbox_activity_climb')}
										</Checkbox>
									</Form.Item>
								</Col>
								<Col xs={24} sm={12} md={12} lg={12} xl={12}>
									<Form.Item name='recreacion_areas' valuePropName='checked'>
										<Checkbox>
											{t('publication.create.amenities.checkbox_activity_meeting')}
										</Checkbox>
									</Form.Item>
								</Col>
								<Col xs={24} sm={12} md={12} lg={12} xl={12}>
									<Form.Item name='recreacion_animales' valuePropName='checked'>
										<Checkbox>
											{t('publication.create.amenities.checkbox_activity_endemic')}
										</Checkbox>
									</Form.Item>
								</Col>
								<Col xs={12} sm={12} md={12} lg={12} xl={12}>
									<Form.Item name='recreacion_pajaros' valuePropName='checked'>
										<Checkbox>
											{t('publication.create.amenities.checkbox_activity_birds')}
										</Checkbox>
									</Form.Item>
								</Col>
								<Col xs={12} sm={12} md={12} lg={12} xl={12}>
									<Form.Item name='recreacion_cocina' valuePropName='checked'>
										<Checkbox>
											{t('publication.create.amenities.checkbox_activity_cuisine')}
										</Checkbox>
									</Form.Item>
								</Col>
								<Col xs={12} sm={12} md={12} lg={12} xl={12}>
									<Form.Item name='recreacion_espeleologia' valuePropName='checked'>
										<Checkbox>
											{t('publication.create.amenities.checkbox_activity_potho')}
										</Checkbox>
									</Form.Item>
								</Col>
								<Col xs={12} sm={12} md={12} lg={12} xl={12}>
									<Form.Item name='recreacion_kayac_paddle_balsas' valuePropName='checked'>
										<Checkbox>
											{t('publication.create.amenities.checkbox_activity_kayak')}
										</Checkbox>
									</Form.Item>
								</Col>
								<Col xs={12} sm={12} md={12} lg={12} xl={12}>
									<Form.Item name='paracaidas' valuePropName='checked'>
										<Checkbox>
											{t('publication.create.amenities.checkbox_activity_para')}
										</Checkbox>
									</Form.Item>
								</Col>
								<Col xs={12} sm={12} md={12} lg={12} xl={12}>
									<Form.Item name='recreacion_piscinas' valuePropName='checked'>
										<Checkbox>
											{t('publication.create.amenities.checkbox_activity_pool')}
										</Checkbox>
									</Form.Item>
								</Col>
								<Col xs={12} sm={12} md={12} lg={12} xl={12}>
									<Form.Item name='recreacion_veredas' valuePropName='checked'>
										<Checkbox>
											{t('publication.create.amenities.checkbox_activity_trails')}
										</Checkbox>
									</Form.Item>
								</Col>
								<Col xs={12} sm={12} md={12} lg={12} xl={12}>
									<Form.Item name='recreacion_zipline' valuePropName='checked'>
										<Checkbox>Zip-line</Checkbox>
									</Form.Item>
								</Col>
							</Row>
						</Col>
						<Col span={24} className='est-amenities-checkbox-container'>
							<h4 className='est-amenities-main-title'>
								{t('publication.create.amenities.title_equipment')}
							</h4>
							<Row>
								<Col xs={12} sm={12} md={8} lg={8} xl={8}>
									<Form.Item name='equipos_mesas' valuePropName='checked'>
										<Checkbox>
											{t('publication.create.amenities.checkbox_equipment_table')}
										</Checkbox>
									</Form.Item>
								</Col>
								<Col xs={12} sm={12} md={8} lg={8} xl={8}>
									<Form.Item name='equipos_sillas' valuePropName='checked'>
										<Checkbox>
											{t('publication.create.amenities.checkbox_equipment_chair')}
										</Checkbox>
									</Form.Item>
								</Col>
								<Col xs={12} sm={12} md={8} lg={8} xl={8}>
									<Form.Item name='equipos_estufas' valuePropName='checked'>
										<Checkbox>
											{t('publication.create.amenities.checkbox_equipment_stove')}
										</Checkbox>
									</Form.Item>
								</Col>
								<Col xs={12} sm={12} md={8} lg={8} xl={8}>
									<Form.Item name='casetas_acampar' valuePropName='checked'>
										<Checkbox>
											{t('publication.create.amenities.checkbox_equipment_camping')}
										</Checkbox>
									</Form.Item>
								</Col>
								<Col xs={12} sm={12} md={8} lg={8} xl={8}>
									<Form.Item name='toldos' valuePropName='checked'>
										<Checkbox>
											{t('publication.create.amenities.checkbox_equipment_awning')}
										</Checkbox>
									</Form.Item>
								</Col>
								<Col xs={12} sm={12} md={8} lg={8} xl={8}>
									<Form.Item name='estufas_gas' valuePropName='checked'>
										<Checkbox>
											{t('publication.create.amenities.checkbox_equipment_gas_stoves')}
										</Checkbox>
									</Form.Item>
								</Col>
								<Col xs={12} sm={12} md={8} lg={8} xl={8}>
									<Form.Item name='tanques_gas' valuePropName='checked'>
										<Checkbox>
											{t('publication.create.amenities.checkbox_equipment_gas_tank')}
										</Checkbox>
									</Form.Item>
								</Col>
								<Col xs={12} sm={12} md={8} lg={8} xl={8}>
									<Form.Item name='lena' valuePropName='checked'>
										<Checkbox>
											{t('publication.create.amenities.checkbox_equipment_firewood')}
										</Checkbox>
									</Form.Item>
								</Col>
								<Col xs={12} sm={12} md={8} lg={8} xl={8}>
									<Form.Item name='carbon' valuePropName='checked'>
										<Checkbox>
											{t('publication.create.amenities.checkbox_equipment_coal')}
										</Checkbox>
									</Form.Item>
								</Col>
							</Row>
						</Col>
						<Col span={24} className='est-amenities-checkbox-container'>
							<h4 className='est-amenities-main-title'>
								{t('publication.create.amenities.title_others')}
							</h4>
							<Row>
								<Col xs={12} sm={12} md={8} lg={8} xl={8}>
									<Form.Item name='se_admiten_mascotas' valuePropName='checked'>
										<Checkbox>
											{t('publication.create.amenities.checkbox_others_pets')}
										</Checkbox>
									</Form.Item>
								</Col>
								<Col xs={12} sm={12} md={8} lg={8} xl={8}>
									<Form.Item name='perros_servicios' valuePropName='checked'>
										<Checkbox>
											{t('publication.create.amenities.checkbox_others_service')}
										</Checkbox>
									</Form.Item>
								</Col>
							</Row>
						</Col>
					</Row>
					<Row>
						<Col span={24} className='est-ads-amenities-button-container'>
							<Link className='est-ads-amenities-button' to='/publication/create/photos'>
								{t('publication.create.amenities.prev_button')}
							</Link>
							<Spacer />
							<Button
								className='est-ads-amenities-button'
								htmlType={'submit'}
								loading={isLoading}>
								{t('publication.create.amenities.next_button')}
							</Button>
						</Col>
					</Row>
				</Form>
			</div>
		</>
	)
}
