/** @format */

import React, { useState, useEffect } from 'react'

import { useTranslation } from 'react-i18next'

import { useGlobal, setGlobal } from 'reactn'

import { Link, useLocation } from 'react-router-dom'

import Drawer from 'antd/lib/drawer'
import Button from 'antd/lib/button'
import Menu from 'antd/lib/menu'

import {
	HomeOutlined,
	PieChartOutlined,
	ReconciliationOutlined,
	ScheduleOutlined,
	TeamOutlined,
	FormOutlined,
	CarryOutOutlined,
	UserOutlined,
	BookOutlined,
} from '@ant-design/icons'

import Image from '../../../../components/Common/Image'
import ProfileImg from '../../../../img/detail/profile-example.png'

import './style.css'

export default function MainMenu(props) {
	let location = useLocation()
	const [current, setCurrent] = useState(
		location.pathname === '/' || location.pathname === '' ? '/dashboard' : location.pathname
	)
	const { t } = useTranslation()
	const [isCollapse, setCollapse] = useState(false)
	const [isVisible, setVisible] = useGlobal('useDrawer')

	const toggleCollapsed = () => {
		if (isCollapse) {
			setCollapse(false)
		} else {
			setCollapse(true)
		}
	}

	const onClose = () => {
		setVisible(false)
	}

	useEffect(() => {
		if (location) {
			if (current !== location.pathname) {
				setCurrent(location.pathname)
			}
		}
	}, [location, current])

	return (
		<>
			<div className='est-main-menu-collapse-container'>
				<Button
					type='primary'
					onClick={() => toggleCollapsed()}
					className={`${isCollapse ? '' : 'est-main-menu-button-shown'} est-main-menu-button`}>
					{t('profile.main_menu.menu_button')}
				</Button>
				<Menu selectedKeys={[current]} mode='inline' inlineCollapsed={isCollapse}>
					<Menu.Item key='/profile-image' className='est-detail-profile-name-img'>
						<div className='est-profile-img-container'>
							{props.user.foto ? (
								<Image
									classImg={`${
										isCollapse ? 'est-profile-img-unshown' : 'est-profile-img-shown'
									} est-profile-img`}
									image={props.user.foto}
									alt={'Profile photo'}
									title={'Profile photo'}
								/>
							) : (
								<Image
									classImg={`${
										isCollapse ? 'est-profile-img-unshown' : 'est-profile-img-shown'
									} est-profile-img`}
									image={ProfileImg}
									alt={'Profile photo'}
									title={'Profile photo'}
								/>
							)}
						</div>

						<h3
							className={`${
								isCollapse ? 'est-main-menu-name-unshown' : ''
							} est-main-menu-name`}>
							{props.user.nombre} {props.user.apellido}
						</h3>
					</Menu.Item>

					{props.user.perfil === '1' && (
						<>
							<Menu.Item key='/profile/metrics' icon={<PieChartOutlined />}>
								<Link to='/profile/metrics' className='est-main-menu-link'>
									{t('profile.main_menu.metrics')}
								</Link>
							</Menu.Item>
							<Menu.Item key='/profile/general-users' icon={<TeamOutlined />}>
								<Link to='/profile/general-users' className='est-main-menu-link'>
									{t('profile.main_menu.general_user')}
								</Link>
							</Menu.Item>
							<Menu.Item key='/profile/general-publications' icon={<FormOutlined />}>
								<Link to='/profile/general-publications' className='est-main-menu-link'>
									{t('profile.main_menu.general_announcements')}
								</Link>
							</Menu.Item>
							<Menu.Item key='/profile/general-reservations' icon={<CarryOutOutlined />}>
								<Link to='/profile/general-reservations' className='est-main-menu-link'>
									{t('profile.main_menu.general_reservations')}
								</Link>
							</Menu.Item>
							<Menu.Item key='/profile/manage-blog' icon={<BookOutlined />}>
								<Link to='/profile/manage-blog' className='est-main-menu-link'>
									{t('profile.main_menu.manage_blog')}
								</Link>
							</Menu.Item>
						</>
					)}
					<Menu.Item key='/profile/user-data' icon={<UserOutlined />}>
						<Link to='/profile/user-data' className='est-main-menu-link'>
							{t('profile.main_menu.personal_info')}
						</Link>
					</Menu.Item>
					<Menu.Item key='/profile/user-publications' icon={<ReconciliationOutlined />}>
						<Link to='/profile/user-publications' className='est-main-menu-link'>
							{t('profile.main_menu.ads')}
						</Link>
					</Menu.Item>
					<Menu.Item key='/profile/user-reservations' icon={<ScheduleOutlined />}>
						<Link to='/profile/user-reservations' className='est-main-menu-link'>
							{t('profile.main_menu.reservation')}
						</Link>
					</Menu.Item>
				</Menu>
			</div>

			<div className='est-main-menu-collapse-responsive-container'>
				<Drawer
					title={t('profile.main_menu.menu_button')}
					placement={'left'}
					closable={true}
					onClose={onClose}
					visible={isVisible}>
					<Menu selectedKeys={[current]} mode='inline' inlineCollapsed={isCollapse}>
						<Menu.Item key='/profile-image' className='est-detail-profile-name-img'>
							<div className='est-profile-img-container'>
								{props.user.foto ? (
									<Image
										classImg={`${
											isCollapse ? 'est-profile-img-unshown' : 'est-profile-img-shown'
										} est-profile-img`}
										image={props.user.foto}
										alt={'Profile photo'}
										title={'Profile photo'}
									/>
								) : (
									<Image
										classImg={`${
											isCollapse ? 'est-profile-img-unshown' : 'est-profile-img-shown'
										} est-profile-img`}
										image={ProfileImg}
										alt={'Profile photo'}
										title={'Profile photo'}
									/>
								)}
							</div>

							<h3 className={'est-main-menu-name'}>
								{props.user.nombre} {props.user.apellido}
							</h3>
						</Menu.Item>

						{props.user.perfil === '1' && (
							<>
								<Menu.Item key='/profile/metrics' icon={<HomeOutlined />}>
									<Link
										to='/profile/metrics'
										className='est-main-menu-link'
										onClick={() =>
											setGlobal({
												useDrawer: false,
											})
										}>
										{t('profile.main_menu.metrics')}
									</Link>
								</Menu.Item>
								<Menu.Item key='/profile/general-users' icon={<TeamOutlined />}>
									<Link
										to='/profile/general-users'
										className='est-main-menu-link'
										onClick={() =>
											setGlobal({
												useDrawer: false,
											})
										}>
										{t('profile.main_menu.general_user')}
									</Link>
								</Menu.Item>
								<Menu.Item key='/profile/general-publications' icon={<FormOutlined />}>
									<Link
										to='/profile/general-publications'
										className='est-main-menu-link'
										onClick={() =>
											setGlobal({
												useDrawer: false,
											})
										}>
										{t('profile.main_menu.general_announcements')}
									</Link>
								</Menu.Item>
								<Menu.Item key='/profile/general-reservations' icon={<CarryOutOutlined />}>
									<Link
										to='/profile/general-reservations'
										className='est-main-menu-link'
										onClick={() =>
											setGlobal({
												useDrawer: false,
											})
										}>
										{t('profile.main_menu.general_reservations')}
									</Link>
								</Menu.Item>
								<Menu.Item key='/profile/manage-blog' icon={<BookOutlined />}>
								<Link to='/profile/manage-blog' className='est-main-menu-link'>
									{t('profile.main_menu.manage_blog')}
								</Link>
							</Menu.Item>
							</>
						)}

						<Menu.Item key='/profile/user-data' icon={<UserOutlined />}>
							<Link
								to='/profile/user-data'
								className='est-main-menu-link'
								onClick={() =>
									setGlobal({
										useDrawer: false,
									})
								}>
								{t('profile.main_menu.personal_info')}
							</Link>
						</Menu.Item>

						<Menu.Item key='/profile/user-publications' icon={<ReconciliationOutlined />}>
							<Link
								to='/profile/user-publications'
								className='est-main-menu-link'
								onClick={() =>
									setGlobal({
										useDrawer: false,
									})
								}></Link>
							{t('profile.main_menu.ads')}
						</Menu.Item>

						<Menu.Item key='/profile/user-reservations' icon={<ScheduleOutlined />}>
							<Link
								to='/profile/user-reservations'
								className='est-main-menu-link'
								onClick={() =>
									setGlobal({
										useDrawer: false,
									})
								}></Link>
							{t('profile.main_menu.reservation')}
						</Menu.Item>
					</Menu>
				</Drawer>
			</div>
		</>
	)
}
