/** @format */

import React, { useState } from 'react'

import { useLocation, useHistory } from 'react-router-dom'

import cookies from 'js-cookie'

import i18next from 'i18next'

import './style.css'

const LanguajeSelector = () => {
	const history = useHistory()
	const location = useLocation()
	const [isLanguaje, setLanguaje] = useState(cookies.get('i18next') || 'en')

	const handleChangeLanguaje = (item) => {
		i18next.changeLanguage(item)
		setLanguaje(item)
		if (location.pathname.match(/blog-detail/) || location.pathname.match(/blog/)) {
			setTimeout(() => {
				history.go(0)
			}, 500)
		}
	}

	return (
		<h3 className='est-main-header-title-1'>
			<span
				className={`${isLanguaje === 'es' ? 'est-main-header-languaje-seleted' : ''}`}
				onClick={() => handleChangeLanguaje('es')}>
				ES
			</span>{' '}
			|{' '}
			<span
				className={`${isLanguaje === 'en' ? 'est-main-header-languaje-seleted' : ''}`}
				onClick={() => handleChangeLanguaje('en')}>
				EN
			</span>
		</h3>
	)
}
export default LanguajeSelector
