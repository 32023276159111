/** @format */

import React, { useState, useEffect } from 'react'

import { setGlobal, useGlobal } from 'reactn'

import { useTranslation } from 'react-i18next'

import { Row, Col, Input, Button } from 'antd'

import { DeleteFilled } from '@ant-design/icons'

import Loading from '../../../../../../components/Common/Loading'

import ModalCreateBlog from './components/ModalCreateBlog'
import ModalPreviewPost from './components/ModalPreviewPost'
import ModalEditBlog from './components/ModalEditBlog'

import { GetBlogPosts, DeleteBlogPosts } from './services'

import './style.css'

export default function ManageBlogPublications() {
	const { t } = useTranslation()
	const [isMobile, setMobile] = useState(false)
	const [isAllBlogPosts, setAllBlogPosts] = useGlobal('allBlogPosts')
	const [isAllBlogPostsService, setAllBlogPostsService] = useState(true)
	const [isFilterList, setFilterList] = useState(null)
	const [isDeleteBlogPost, setDeleteBlogPost] = useState(false)

	const handleSearchList = (item) => {
		const filter = item.target.value
		let filterList = isFilterList.filter((data) => {
			data.email = data.title.toLowerCase()
			return data.title.indexOf(filter) !== -1
		})
		setAllBlogPosts(filterList)
	}

	const handleDeleteBlogPost = async (item) => {
		setDeleteBlogPost(true)
		const idPost = {
			id: item,
		}

		const traduceTwo = {
			service_success_message: t(
				'profile.manage_blog.service_delete_blog.service_success_message'
			),
			service_success_description: t(
				'profile.manage_blog.service_delete_blog.service_success_description'
			),
			service_warning_message: t(
				'profile.manage_blog.service_delete_blog.service_success_message'
			),
			service_warning_description: t(
				'profile.manage_blog.service_delete_blog.service_success_description'
			),
			service_global_description: t('global_service.service_global_description'),
		}

		await DeleteBlogPosts(idPost, traduceTwo).then((response) => {
			if (response) {
				const traduceOne = {
					service_warning_message: t(
						'profile.manage_blog.service_get_blog.service_warning_message'
					),
					service_warning_description: t(
						'profile.manage_blog.service_get_blog.service_warning_description'
					),
					service_global_description: t('global_service.service_global_description'),
				}

				GetBlogPosts(traduceOne).then((responseBlog) => {
					if (responseBlog) {
						setAllBlogPosts(responseBlog)
						setFilterList(responseBlog)
					} else {
						setAllBlogPostsService(false)
					}
				})
			}
		})
		setDeleteBlogPost(false)
	}

	useEffect(() => {
		if (window.innerWidth < 576) {
			setMobile(true)
		}
		GetBlogPosts().then((response) => {
			if (response) {
				setAllBlogPosts(response)
				setFilterList(response)
			} else {
				setAllBlogPostsService(false)
			}
		})
	}, [setAllBlogPosts])

	if (!isAllBlogPostsService) {
		return (
			<h3 className='est-user-reservations-list-tab-main-subtitle'>
				{t('profile.user_reservations.tabs.subtitle')}
			</h3>
		)
	} else {
		if (!isAllBlogPosts) {
			return <Loading />
		} else {
			return (
				<>
					{isMobile && (
						<Row className='est-general-list-users-banner-container-responsive'>
							<Col xs={12}>
								<Button
									className='est-general-blog-post-responsive-button'
									type='primary'
									onClick={() =>
										setGlobal({
											useDrawer: true,
										})
									}>
									{t('profile.general_users.menu_resp_button')}
								</Button>
							</Col>
							<Col xs={12} className='est-general-blog-create-post-container'>
								<ModalCreateBlog />
							</Col>
							<Col xs={24} className='est-general-blog-post-banner-search-container'>
								<Input
									type='text'
									onChange={(item) => handleSearchList(item)}
									placeholder={t('profile.general_users.search_placeholder')}
									className='est-general-blog-post-banner-search'
								/>
							</Col>
						</Row>
					)}

					<Row className='est-general-list-users-banner-container'>
						<Col xs={24} sm={4} md={4} lg={0} xl={0}>
							<Button
								className='est-general-blog-post-responsive-button'
								type='primary'
								onClick={() =>
									setGlobal({
										useDrawer: true,
									})
								}>
								{t('profile.general_users.menu_resp_button')}
							</Button>
						</Col>
						<Col
							xs={24}
							sm={12}
							md={12}
							lg={12}
							xl={12}
							className='est-general-blog-post-banner-search-container'>
							<Input
								type='text'
								onChange={(item) => handleSearchList(item)}
								placeholder={t('profile.general_users.search_placeholder')}
								className='est-general-blog-post-banner-search'
							/>
						</Col>
						<Col
							xs={24}
							sm={4}
							md={4}
							lg={12}
							xl={12}
							className='est-general-blog-create-post-container'>
							<ModalCreateBlog />
						</Col>
					</Row>

					<Row className='est-profile-general-blog-posts-main-title-container'>
						<Col md={3} lg={3} xl={3}>
							<h4 className='est-profile-general-blog-posts-main-title'>
								{t('profile.manage_blog.post_title')}
							</h4>
						</Col>
						<Col md={4} lg={4} xl={4}>
							<h4 className='est-profile-general-blog-posts-main-title'>
								{t('profile.manage_blog.user_title')}
							</h4>
						</Col>

						<Col md={7} lg={7} xl={7}>
							<h4 className='est-profile-general-blog-posts-main-title'>
								{t('profile.manage_blog.author_title')}
							</h4>
						</Col>
						<Col md={5} lg={5} xl={5}>
							<h4 className='est-profile-general-blog-posts-main-title'>
								{t('profile.manage_blog.date_title')}
							</h4>
						</Col>
						<Col md={5} lg={5} xl={5}>
							<h4 className='est-profile-general-blog-posts-main-title'>
								{t('profile.manage_blog.actions_title')}
							</h4>
						</Col>
					</Row>
					<div className='est-profile-general-blog-posts-list-container'>
						{isAllBlogPosts.map((item, index) => (
							<Row className='est-general-blog-posts-list-container' key={index}>
								<Col
									className='est-general-blog-posts-list-text-container'
									xs={24}
									sm={24}
									md={3}
									lg={3}
									xl={3}>
									<h3 className='est-profile-general-blog-posts-list-main-title'>
										<span className='est-profile-general-blog-posts-main-title-responsive'>
											{t('profile.manage_blog.post_title_resp')}
										</span>
										{item.id}
									</h3>
								</Col>
								<Col
									className='est-general-blog-posts-list-text-container'
									xs={24}
									sm={24}
									md={4}
									lg={4}
									xl={4}>
									<h3 className='est-profile-general-blog-posts-list-main-title'>
										<span className='est-profile-general-blog-posts-main-title-responsive'>
											{t('profile.manage_blog.user_title_resp')}
										</span>
										{item.id_user}
									</h3>
								</Col>

								<Col
									className='est-general-blog-posts-list-text-container'
									xs={24}
									sm={24}
									md={7}
									lg={7}
									xl={7}>
									<h3 className='est-profile-general-blog-posts-list-main-title'>
										<span className='est-profile-general-blog-posts-main-title-responsive'>
											{t('profile.manage_blog.author_title_resp')}
										</span>
										{item.name} {item.last_name}
									</h3>
								</Col>
								<Col
									className='est-general-blog-posts-list-text-container'
									xs={24}
									sm={24}
									md={5}
									lg={5}
									xl={5}>
									<h3 className='est-profile-general-blog-posts-list-main-title'>
										<span className='est-profile-general-blog-posts-main-title-responsive'>
											{t('profile.manage_blog.date_title_resp')}
										</span>
										{item.date_create}
									</h3>
								</Col>
								<Col
									className='est-general-blog-posts-list-text-container'
									xs={24}
									sm={24}
									md={5}
									lg={5}
									xl={5}>
									<h3 className='est-profile-general-blog-posts-list-main-title'>
										<span className='est-profile-general-blog-posts-main-title-responsive'>
											{t('profile.manage_blog.actions_title_resp')}
										</span>
										<Row className='est-users-manage-blog-button-container'>
											<Col>
												<ModalPreviewPost item={item} />
											</Col>
											<Col>
												<ModalEditBlog item={item} />
											</Col>
											<Col>
												<Button
													loading={isDeleteBlogPost}
													icon={<DeleteFilled />}
													className='est-users-manage-list-button-active-user-button'
													onClick={() => handleDeleteBlogPost(item.id)}></Button>
											</Col>
										</Row>
									</h3>
								</Col>
							</Row>
						))}
					</div>
				</>
			)
		}
	}
}
