/** @format */

import React, { useState } from 'react'

import { useTranslation } from 'react-i18next'

import Form from 'antd/lib/form'
import Button from 'antd/lib/button'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import Input from 'antd/lib/input'
import Select from 'antd/lib/select'

import { rulesValidation } from '../../../../components/Common/Inputs/Normal/rules'

import Spacer from '../../../../../src/components/Common/Spacer'

import InputCustom from '../../../../components/Common/Inputs/Normal'

import Contact from './services'

import './style.css'

export default function ContactUs() {
	const [contact_form] = Form.useForm()
	const { t } = useTranslation()
	const { Option } = Select
	const { TextArea } = Input
	const [isContactLoading, setContactLoading] = useState(false)
	const handleContact = async (item) => {
		setContactLoading(true)

		const traduce = {
			success_message: t('home.contact.services.success_message'),
			success_description: t('home.contact.services.success_description'),
			service_global_description: t('global_service.service_global_description'),
		}

		Contact(item, traduce).then(() => {
			contact_form.resetFields()
			setContactLoading(false)
		})
	}
	return (
		<div id='prujula-home-contact' className='est-contact-us-global-container'>
			<div className='est-contact-us-main-container'>
				<div className='est-contact-us-container'>
					<h2 className='est-contact-us-title-primary'>{t('home.contact.title')}</h2>
					<h2 className='est-contact-us-title-secondary'>{t('home.contact.subtitle')}</h2>
				</div>
				<Row className='est-contact-us-form-container'>
					<Col className='est-main-contact-input-container'>
						<Form
							className='est-main-contact-global-container'
							name='form_contact'
							onFinish={handleContact}
							form={contact_form}>
							<Row>
								<Col
									xs={24}
									sm={24}
									md={8}
									lg={8}
									xl={8}
									className='est-main-contact-input-container'>
									<InputCustom
										className={'est-home-contact-field-input'}
										inputName={'regName'}
										inputNameLabel={t('home.contact.placeholder_name')}
										inputNameRule={true}
										inputNameMessage={t('home.contact.input_name')}
										inputNameType={'text'}
										inputNameIcon={''}
										inputNameRules={t('field_notifications.normal.rules_required')}
									/>
								</Col>
								<Spacer />
								<Col
									xs={24}
									sm={24}
									md={8}
									lg={8}
									xl={8}
									className='est-main-contact-input-container-middle'>
									<InputCustom
										className={'est-home-contact-field-input'}
										inputName={'regEmail'}
										inputNameLabel={t('home.contact.placeholder_email')}
										inputNameRule={true}
										inputNameMessage={t('home.contact.input_email')}
										inputNameType={'text'}
										inputNameIcon={''}
										inputNameRules={t('field_notifications.normal.rules_required')}
									/>
								</Col>
								<Spacer />
								<Col
									xs={24}
									sm={24}
									md={8}
									lg={8}
									xl={8}
									className='est-main-contact-input-container-right'>
									<Form.Item
										name='regRequirement'
										rules={
											rulesValidation[t('field_notifications.normal.rules_required')]
										}>
										<Select
											className='est-home-contact-field-select'
											placeholder={t('home.contact.input_request')}
											size='large'
											style={{ width: '100%' }}>
											<Option value='support'>Soporte</Option>
											<Option value='requirement'>Requerimiento</Option>
											<Option value='issue'>Queja</Option>
										</Select>
									</Form.Item>
								</Col>
								<Col
									xs={24}
									sm={24}
									md={24}
									lg={24}
									xl={24}
									className='est-main-contact-input-container'>
									<Form.Item
										name={'regMessage'}
										rules={
											rulesValidation[t('field_notifications.normal.rules_required')]
										}>
										<TextArea
											className='est-home-contact-field-input'
											size='large'
											type={'text'}
											placeholder={t('home.contact.input_text_area')}
											rows={4}
										/>
									</Form.Item>
								</Col>
							</Row>
							<Row className='est-contact-us-button-container'>
								<Col
									xs={24}
									sm={24}
									md={24}
									lg={24}
									xl={24}
									className='est-main-contact-input-button-container'>
									<Button
										className='est-main-form-main-button'
										htmlType={'submit'}
										loading={isContactLoading}>
										{t('home.contact.button')}
									</Button>
								</Col>
							</Row>
						</Form>
					</Col>
				</Row>
			</div>
		</div>
	)
}
