/** @format */

import React from 'react'

import { Link } from 'react-router-dom'

import { useTranslation } from 'react-i18next'

import { CheckCircleOutlined } from '@ant-design/icons'

import MainNavigation from '../../components/Common/MainNavigation'

import './style.css'

export default function ReservationSuccess() {
	const { t } = useTranslation()
	return (
		<>
			<MainNavigation
				title={t('reservation_success.main_navigation.title')}
				linkPrevTitle={'Home'}
				linkNextTitle={t('reservation_success.main_navigation.title')}
				linkPrev={'/'}
				linkNext={'/reservation_success'}
			/>
			<div className='est-reservation-success-global-container'>
				<div className='est-reservation-success-main-container'>
					<h3 className='est-reservation-success-icon'>
						<CheckCircleOutlined />
					</h3>
					<h3 className='est-reservation-success-title'>{t('reservation_success.title')}</h3>
					<h3 className='est-reservation-success-subtitle'>
						{t('reservation_success.subtitle')}
					</h3>

					<div className='est-reservation-success-button-container'>
						<Link to='/' className='est-reservation-success-button'>
							{t('reservation_success.button')}
						</Link>
					</div>
				</div>
			</div>
		</>
	)
}
