/** @format */

import React, { useEffect, useState } from 'react'

import { setGlobal, useGlobal } from 'reactn'

import { Row, Col, Button, Modal, Form, Input } from 'antd'

import { PoweroffOutlined } from '@ant-design/icons'

import { useTranslation } from 'react-i18next'

import InputNormal from '../../../../../../components/Common/Inputs/Normal'

import Loading from '../../../../../../components/Common/Loading'
import Image from '../../../../../../components/Common/Image'

import ModalEditUser from './components/ModalEditUser'
import ModalCreateUser from './components/ModalCreateUser'

import './style.css'

import { GetAllUsers, ActivateUser, UpdatePassword } from './services'

const Usersinfo = () => {
	const { t } = useTranslation()
	const [isMobile, setMobile] = useState(false)
	const [update_password] = Form.useForm()
	const [isVisible, setVisible] = useState(false)
	const [isAllUsers, setAllUsers] = useGlobal('allUsers')
	const [isModalInfo, setModalInfo] = useState(null)
	const [isUpdateUser, setUpdateUser] = useState(false)
	const [isLoading, setLoading] = useState(false)
	const [isFilterList, setFilterList] = useState(null)

	const [isGetAllUsers] = useState({
		service_global_description: t('global_service.service_global_description'),
	})

	const handleManageUser = (item) => {
		setVisible(true)
		setModalInfo(item)
	}

	const handleCloseManageUser = () => {
		setModalInfo(null)
		setVisible(false)
	}

	const handleUpdatePassWord = async (item) => {
		setLoading(true)
		const updatePassword = {
			updEmailEncriptado: isModalInfo.email_encriptado,
			updNewPassword: item.regPassword,
		}

		const traduceTwo = {
			service_success_message: t(
				'profile.general_users.service_update_password.service_success_message'
			),
			service_success_description: t(
				'profile.general_users.service_update_password.service_success_description'
			),
			service_global_description: t('global_service.service_global_description'),
		}

		await UpdatePassword(updatePassword, traduceTwo).then(() => {})
		setLoading(false)
		update_password.resetFields()
	}

	const handleActivateuser = async (item) => {
		setUpdateUser(true)
		let actionUser = {
			idUser: item.id,
			allDelete: 'no',
		}
		if (item.estado === '1') {
			actionUser.estado = '0'
		} else if (item.estado === '0') {
			actionUser.estado = '1'
		}

		const traduceOne = {
			service_success_message: t(
				'profile.general_users.service_activate_user.service_success_message'
			),
			service_success_description: t(
				'profile.general_users.service_activate_user.service_success_description'
			),
			service_warning_title: t(
				'profile.general_users.service_activate_user.service_warning_title'
			),
			service_global_description: t('global_service.service_global_description'),
		}

		await ActivateUser(actionUser, traduceOne).then((response) => {
			if (response) {
				GetAllUsers(isGetAllUsers).then((response) => {
					if (response) {
						setAllUsers(response)
					}
				})
			}
		})
		setUpdateUser(false)
	}

	const handleSearchList = (item) => {
		const filter = item.target.value
		let filterList = isFilterList.filter((data) => {
			data.email = data.email.toLowerCase()
			return data.email.indexOf(filter) !== -1
		})
		setAllUsers(filterList)
	}

	useEffect(() => {
		if (window.innerWidth < 576) {
			setMobile(true)
		}
		GetAllUsers(isGetAllUsers).then((response) => {
			if (response) {
				setAllUsers(response)
				setFilterList(response)
			}
		})
	}, [setAllUsers, isGetAllUsers])

	if (!isAllUsers) {
		return <Loading />
	} else
		return (
			<>
				{isMobile && (
					<Row className='est-general-list-users-banner-container-responsive'>
						<Col xs={12}>
							<Button
								className='est-general-list-users-responsive-button'
								type='primary'
								onClick={() =>
									setGlobal({
										useDrawer: true,
									})
								}>
								{t('profile.general_users.menu_resp_button')}
							</Button>
						</Col>
						<Col xs={12} className='est-general-list-create-user-responsive-button'>
							<ModalCreateUser />
						</Col>
						<Col xs={24} className='est-general-list-users-banner-search-container'>
							<Input
								type='text'
								onChange={(item) => handleSearchList(item)}
								placeholder={t('profile.general_users.search_placeholder')}
								className='est-general-list-users-banner-search'
							/>
						</Col>
					</Row>
				)}

				<Row className='est-general-list-users-banner-container'>
					<Col xs={24} sm={4} md={4} lg={0} xl={0}>
						<Button
							className='est-general-list-users-responsive-button'
							type='primary'
							onClick={() =>
								setGlobal({
									useDrawer: true,
								})
							}>
							{t('profile.general_users.menu_resp_button')}
						</Button>
					</Col>
					<Col
						xs={24}
						sm={12}
						md={12}
						lg={12}
						xl={12}
						className='est-general-list-users-banner-search-container'>
						<Input
							type='text'
							onChange={(item) => handleSearchList(item)}
							placeholder={t('profile.general_users.search_placeholder')}
							className='est-general-list-users-banner-search'
						/>
					</Col>
					<Col
						xs={24}
						sm={4}
						md={4}
						lg={12}
						xl={12}
						className='est-general-list-create-user-container'>
						<ModalCreateUser />
					</Col>
				</Row>

				<Row className='est-general-list-users-list-main-title-container'>
					<Col md={2} lg={2} xl={2}>
						<h3 className='est-general-list-users-list-main-title'>
							{t('profile.general_users.status_title')}
						</h3>
					</Col>
					<Col md={2} lg={2} xl={2}>
						<h3 className='est-general-list-users-list-main-title'>
							{t('profile.general_users.id_title')}
						</h3>
					</Col>
					<Col md={6} lg={6} xl={6}>
						<h3 className='est-general-list-users-list-main-title'>
							{t('profile.general_users.email_title')}
						</h3>
					</Col>
					<Col md={6} lg={6} xl={6}>
						<h3 className='est-general-list-users-list-main-title'>
							{t('profile.general_users.name_title')}
						</h3>
					</Col>
					<Col md={3} lg={3} xl={3}>
						<h3 className='est-general-list-users-list-main-title'>
							{t('profile.general_users.mode_title')}
						</h3>
					</Col>
					<Col md={5} lg={5} xl={5}>
						<h3 className='est-general-list-users-list-main-title'>
							{t('profile.general_users.action_title')}
						</h3>
					</Col>
				</Row>
				<div className='est-general-list-users-list-main-container'>
					{isAllUsers.map((item, index) => (
						<Row key={index} className='est-general-list-users-list-container'>
							<Col
								className='est-general-list-users-list-text-container'
								xs={24}
								sm={24}
								md={2}
								lg={2}
								xl={2}>
								<h4 className='est-general-list-users-title'>
									<span className='est-general-list-users-list-main-title-responsive'>
										{t('profile.general_users.status_title_resp')}
									</span>
									<span
										className={
											item.estado === '1'
												? 'est-general-list-users-title-state-active'
												: 'est-general-list-users-title-state-disable'
										}></span>
								</h4>
							</Col>
							<Col
								className='est-general-list-users-list-text-container'
								xs={24}
								sm={24}
								md={2}
								lg={2}
								xl={2}>
								<h4 className='est-general-list-users-title'>
									<span className='est-general-list-users-list-main-title-responsive'>
										{t('profile.general_users.id_title_resp')}
									</span>
									{item.id}
								</h4>
							</Col>
							<Col
								className='est-general-list-users-list-text-container'
								xs={24}
								sm={24}
								md={6}
								lg={6}
								xl={6}>
								<h4 className='est-general-list-users-title'>
									<span className='est-general-list-users-list-main-title-responsive'>
										{t('profile.general_users.email_title_resp')}
									</span>
									{item.email}
								</h4>
							</Col>
							<Col
								className='est-general-list-users-list-text-container'
								xs={24}
								sm={24}
								md={6}
								lg={6}
								xl={6}>
								<h4 className='est-general-list-users-title'>
									<span className='est-general-list-users-list-main-title-responsive'>
										{t('profile.general_users.name_title_resp')}{' '}
									</span>
									{item.nombre} {item.apellido}
								</h4>
							</Col>
							<Col
								className='est-general-list-users-list-text-container'
								xs={24}
								sm={24}
								md={3}
								lg={3}
								xl={3}>
								<h4 className='est-general-list-users-title'>
									<span className='est-general-list-users-list-main-title-responsive'>
										{t('profile.general_users.mode_title_resp')}
									</span>
									{item.modo}
								</h4>
							</Col>
							<Col
								xs={24}
								sm={24}
								md={5}
								lg={5}
								xl={5}
								className='est-general-list-users-list-text-container'>
								<h4 className='est-general-list-users-title'>
									<span className='est-general-list-users-list-main-title-responsive'>
										{t('profile.general_users.action_title_resp')}
									</span>
									<Row className='est-users-manage-list-button-container'>
										<Col>
											<Button
												className='est-users-manage-list-button'
												onClick={() => handleManageUser(item)}>
												{t('profile.general_users.management_users_button')}
											</Button>
										</Col>
										<Col>
											<ModalEditUser item={item} />
										</Col>
										<Col>
											<Button
												loading={isUpdateUser}
												icon={<PoweroffOutlined />}
												className='est-users-manage-list-button-disable-user-button'
												onClick={() => handleActivateuser(item)}></Button>
										</Col>
									</Row>
								</h4>
							</Col>
						</Row>
					))}
				</div>
				<Modal
					wrapClassName='est-manage-users-modal-global-container'
					centered
					title={t('profile.general_users.users_info.modal_title')}
					visible={isVisible}
					onCancel={() => handleCloseManageUser()}
					cancelButtonProps={{ style: { display: 'none' } }}
					okButtonProps={{ style: { display: 'none' } }}
					okText=''
					cancelText=''
					footer={false}>
					{isModalInfo && (
						<Row>
							<Col
								xs={24}
								sm={24}
								md={isModalInfo.modo === 'directo' ? 12 : 24}
								lg={isModalInfo.modo === 'directo' ? 12 : 24}
								xl={isModalInfo.modo === 'directo' ? 12 : 24}
								className='est-manage-users-modal-main-container'>
								<div className='est-manage-users-modal-profile-image-container'>
									<Image
										classImg={'est-manage-users-modal-profile-image'}
										image={isModalInfo.foto}
										alt={isModalInfo.nombre}
										title={isModalInfo.nombre}
									/>
								</div>

								<div
									className={`${
										isModalInfo.modo !== 'directo'
											? 'est-manage-users-modal-direct-title-container'
											: ''
									} est-manage-users-modal-title-container`}>
									<h4 className='est-manage-users-modal-title'>
										<span className='est-manage-users-modal-title-span'>ID:</span>{' '}
										{isModalInfo.id}
									</h4>
									<h4 className='est-manage-users-modal-title'>
										<span className='est-manage-users-modal-title-span'>
											{t('profile.general_users.users_info.modal_user_email')}
										</span>{' '}
										{isModalInfo.email}
									</h4>
									<h4 className='est-manage-users-modal-title'>
										<span className='est-manage-users-modal-title-span'>
											{t('profile.general_users.users_info.modal_user_name')}
										</span>{' '}
										{isModalInfo.nombre} {isModalInfo.apellido}
									</h4>
									<h4 className='est-manage-users-modal-title'>
										<span className='est-manage-users-modal-title-span'>
											{t('profile.general_users.users_info.modal_user_create')}
										</span>{' '}
										{isModalInfo.fecha_creacion}
									</h4>
									<h4 className='est-manage-users-modal-title'>
										<span className='est-manage-users-modal-title-span'>
											{t('profile.general_users.users_info.modal_user_login_mode')}
										</span>{' '}
										{isModalInfo.modo}
									</h4>
									<h4 className='est-manage-users-modal-title'>
										<span className='est-manage-users-modal-title-span'>
											{t('profile.general_users.users_info.modal_user_account_status')}
										</span>{' '}
										{isModalInfo.estado === '1'
											? `${t(
													'profile.general_users.users_info.modal_user_state.active_title'
											  )}`
											: `${t(
													'profile.general_users.users_info.modal_user_state.inactive_title'
											  )}`}
									</h4>
									<h4 className='est-manage-users-modal-title'>
										<span className='est-manage-users-modal-title-span'>
											{t('profile.general_users.users_info.modal_user_profile')}
										</span>{' '}
										{isModalInfo.perfil === '1'
											? `${t(
													'profile.general_users.users_info.modal_user_type_profile.admin_title'
											  )}`
											: `${t(
													'profile.general_users.users_info.modal_user_type_profile.regular_user_title'
											  )}`}
									</h4>
									<h4 className='est-manage-users-modal-title'>
										<span className='est-manage-users-modal-title-span'>
											{t('profile.general_users.users_info.modal_user_phone')}
										</span>{' '}
										{isModalInfo.telefono}
									</h4>
									<h4 className='est-manage-users-modal-title'>
										<span className='est-manage-users-modal-title-span'>
											{t('profile.general_users.users_info.modal_user_last_login')}
										</span>{' '}
										{isModalInfo.ultimo_login}
									</h4>
								</div>
							</Col>

							{isModalInfo.modo === 'directo' && (
								<Col
									xs={24}
									sm={24}
									md={12}
									lg={12}
									xl={12}
									className='est-manage-users-modal-password-global-container'>
									<div className='est-manage-users-modal-password-main-container'>
										<h4 className='est-manage-users-modal-password-title'>
											{t('profile.general_users.users_info.modal_user_pass_title')}
										</h4>
										<Form
											form={update_password}
											name='user_update_password'
											onFinish={handleUpdatePassWord}>
											<InputNormal
												className={'est-manage-users-modal-password-input'}
												inputName={'regPassword'}
												inputNameLabel={t(
													'profile.general_users.users_info.modal_user_pass_placholder'
												)}
												inputNameRule={true}
												inputNameMessage={t(
													'profile.general_users.users_info.modal_user_pass_message'
												)}
												inputNameType={'password'}
												inputNameIcon={''}
												inputNameRules={t('field_notifications.normal.rules_password')}
											/>
											<InputNormal
												className={'est-manage-users-modal-password-input'}
												inputName={'confirm'}
												inputNameLabel={t(
													'profile.general_users.users_info.modal_user_pass_confirm_placeholder'
												)}
												inputNameRule={true}
												inputNameMessage={t(
													'profile.general_users.users_info.modal_user_pass_message'
												)}
												inputNameType={'password'}
												inputNameIcon={''}
												dependencies={['password']}
												hasFeedback
												inputNameRules={t(
													'field_notifications.normal.rules_confirm_password'
												)}
											/>
											<Button
												className='est-manage-users-modal-password-button'
												htmlType={'submit'}
												loading={isLoading}>
												{t('profile.general_users.users_info.modal_user_pass_title')}
											</Button>
										</Form>
									</div>
								</Col>
							)}
						</Row>
					)}
					<div className='est-manage-users-modal-button-container'>
						<Button
							className='est-manage-users-modal-button'
							onClick={() => handleCloseManageUser()}>
							{t('profile.general_users.users_info.modal_user_close_button')}
						</Button>
					</div>
				</Modal>
			</>
		)
}
export default Usersinfo
