/** @format */

import React, { useState } from 'react'

import './style.css'

export default function FooterBottom() {
	const [isDate] = useState(new Date().getFullYear())

	return (
		<>
			<div className='est-footer-bottom-global-container'>
				<div className='est-footer-bottom-main-container'>
					<div className='est-footer-bottom-container'>
						<h3 className='est-footer-bottom-title'>© Copyright PRújula {isDate}</h3>
					</div>
				</div>
			</div>
		</>
	)
}
