/** @format */

import React, { useState, useEffect } from 'react'

import { useTranslation } from 'react-i18next'

import { useGlobal } from 'reactn'

import { Link } from 'react-router-dom'

import MainNavigation from '../../../../../components/Common/MainNavigation'

import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import Input from 'antd/lib/input'
import Button from 'antd/lib/button'
import Form from 'antd/lib/form'
import notification from 'antd/lib/notification'

import Spacer from '../../../../../components/Common/Spacer'

import { rulesValidation } from '../../../../../components/Common/Inputs/Normal/rules'

import InputMask from '../../../../../components/Common/Inputs/InputMask'
import { rulesValidationMask } from '../../../../../components/Common/Inputs/InputMask/rules'

import MapLeaft from '../../../../../components/Common/MapLeaft'

import Address from './components/Address'

import './style.css'

export default function AdsLocation() {
	const { t } = useTranslation()
	const [isBasicInfo] = useState(JSON.parse(localStorage.getItem('info')))
	const [isUserAddress, SetUserAddress] = useGlobal('SelectedAddress')
	const { TextArea } = Input

	const handleLocationInfo = async (item) => {
		if (isUserAddress) {
			if (isUserAddress.default) {
				notification['warning']({
					message: t('publication.create.location.warning_message'),
					description: t('publication.create.location.warning_description'),
				})
				return
			}
		}
		let info = JSON.parse(localStorage.getItem('info'))
		let regAddress = {
			completeAddress: isUserAddress.complete_address,
			country: isUserAddress.country,
			countryCode: isUserAddress.country_code,
			county: isUserAddress.route,
			city: isUserAddress.locality,
			municipality: isUserAddress.sublocality,
			state: isUserAddress.administrative_area_level_1,
			lat: isUserAddress.geo_location.lat,
			lng: isUserAddress.geo_location.lng,
		}
		info.regAddress = regAddress
		info.regAddressDescription = item.regAddressDescription
		info.regPhone = item.regPhone.replace(/[+()/\s/]/g, '')
		info.regImageGallery = []
		localStorage.setItem('info-location', JSON.stringify(info))
		window.location.pathname = 'publication/create/photos'
	}
	useEffect(() => {
		SetUserAddress({
			geo_location: [18.4655394, -66.1057355],
			complete_address: 'San Juan, Puerto Rico',
			default: true,
		})
		if (!isBasicInfo) {
			window.location.pathname = '/'
		}
	}, [isBasicInfo, SetUserAddress])

	return (
		<>
			<MainNavigation
				title={t('publication.create.location.main_navigation.title')}
				linkPrevTitle={'Home'}
				linkNextTitle={t('publication.create.location.main_navigation.title')}
				linkPrev={'/'}
				linkNext={'/publication/create/location'}
			/>
			<div className='est-ads-location-info-global-container'>
				<h2 className='est-ads-location-info-global-title'>
					{t('publication.create.location.title')}
				</h2>
				<h3 className='est-ads-location-info-global-subtitle'>
					{t('publication.create.location.subtitle')}
				</h3>
				<Form name='location' onFinish={handleLocationInfo}>
					<Row className='est-ads-location-info-main-container'>
						<Col
							xs={24}
							sm={24}
							md={12}
							lg={12}
							xl={16}
							className='est-ads-location-info-input-container'>
							<Row>
								<Col xs={24} sm={24} md={24} lg={24} xl={24}>
									<h4 className='est-ads-location-info-main-title'>
										{t('publication.create.location.address_title')}
									</h4>
									<Address />
									<br />
									<br />
									<h4 className='est-ads-location-info-main-title'>
										{t('publication.create.location.text_area_title')}
									</h4>
									<Form.Item
										name={'regAddressDescription'}
										rules={
											rulesValidation[t('field_notifications.normal.rules_required')]
										}>
										<TextArea
											className='est-ads-location-field-input'
											size='large'
											type={'text'}
											placeholder={t(
												'publication.create.location.text_area_placeholder'
											)}
											rows={4}
										/>
									</Form.Item>
									<h4 className='est-ads-location-info-main-title'>
										{t('publication.create.location.phone_title')}
									</h4>
									<Form.Item
										name={'regPhone'}
										rules={
											rulesValidationMask[
												t('field_notifications.normal.rules_mask_phone')
											]
										}>
										<InputMask
											maskstyle={'est-ads-location-input-main-phone'}
											mask='+(1) 999 999 9999'
											placeholder='+(1) 999 999 9999'
										/>
									</Form.Item>
									<h4 className='est-ads-location-info-main-title'>
										{t('publication.create.location.chosen_address_title')}
									</h4>
									{isUserAddress && (
										<>
											{isUserAddress.default ? (
												<h3 className='est-ads-location-info-main-subtitle'>
													{t('publication.create.location.chosen_address_subtitle')}
												</h3>
											) : (
												<h3>{isUserAddress.complete_address}</h3>
											)}
										</>
									)}
								</Col>
							</Row>
						</Col>
						<hr className='est-ads-location-info-spacer'></hr>
						<Col
							xs={24}
							sm={24}
							md={12}
							lg={12}
							xl={8}
							className='est-ads-location-map-container'>
							{isUserAddress && (
								<>
									<h4 className='est-ads-location-map-title'>
										{t('publication.create.location.selected_address_title')}
									</h4>
									{isUserAddress && (
										<>
											{isUserAddress.default ? (
												<h4 className='est-ads-location-map-subtitle'>
													{t('publication.create.location.selected_address_subtitle')}
												</h4>
											) : (
												<h4 className='est-ads-location-map-subtitle'>
													{isUserAddress.complete_address}
												</h4>
											)}
										</>
									)}
									<div className='est-address-box-container'>
										<MapLeaft
											center={isUserAddress.geo_location}
											zoom={12}
											title={'User Current'}
											description={'Address.'}
										/>
									</div>
								</>
							)}
						</Col>
					</Row>
					<Row>
						<Col span={24} className='est-ads-location-info-button-container'>
							<Link
								className='est-ads-location-info-button'
								to='/publication/create/basic-info'>
								{t('publication.create.location.prev_button')}
							</Link>
							<Spacer />
							<Button className='est-ads-location-info-button' htmlType={'submit'}>
								{t('publication.create.location.next_button')}
							</Button>
						</Col>
					</Row>
				</Form>
			</div>
		</>
	)
}
