/** @format */

import React, { useState } from 'react'

import { setGlobal, useGlobal } from 'reactn'

import { Row, Col, Button, Modal, Form, Input, notification } from 'antd'

import { useTranslation } from 'react-i18next'

import InputNormal from '../../../../../../../../components/Common/Inputs/Normal'

import { rulesValidation } from '../../../../../../../../components/Common/Inputs/Normal/rules'

import { ENV_CORE } from '../../../../../../../../components/Common/Hooks/Variables/Enviroment'

import UploadImage from '../../../../../../../../components/Common/UploadImage'

import { GetBlogPosts } from '../../services'

import CreateBlogPostService from './services'

import './style.css'

const CreateBlogPost = () => {
	const { t } = useTranslation()
	const [form] = Form.useForm()
	const { TextArea } = Input
	const [isVisible, setVisible] = useState(false)
	const [isLoading, setLoading] = useState(false)
	const [isNewPhoto, setNewPhoto] = useState(null)
	const [isDataProfile] = useGlobal('DataProfile')

	const handleCreateBlogPost = async (item) => {
		if (!isNewPhoto) {
			notification['warning']({
				message: t('profile.manage_blog.notification_create_blog.notification_warning_message'),
				description: t(
					'profile.manage_blog.notification_create_blog.notification_warning_description'
				),
			})
			return
		}
		delete item.dragger
		item.id_user = isDataProfile.id
		item.image = isNewPhoto
		setLoading(true)

		const data = {
			id_user: isDataProfile.id,
			tags: item.tags,
			image: item.image,
			info_es: {
				title: item.title_es,
				content: item.content_es,
			},
			info_en: {
				title: item.title_en,
				content: item.content_en,
			},
		}

		const traduce = {
			service_success_message: t(
				'profile.manage_blog.service_create_blog.service_success_message'
			),
			service_success_description: t(
				'profile.manage_blog.service_create_blog.service_success_description'
			),
			service_warning_message: t(
				'profile.manage_blog.service_create_blog.service_warning_message'
			),
			service_warning_description: t(
				'profile.manage_blog.service_create_blog.service_warning_description'
			),
			service_error_description: t(
				'profile.manage_blog.service_create_blog.service_error_description'
			),
		}
		await CreateBlogPostService(data, traduce).then((response) => {
			if (response) {
				GetBlogPosts().then((responseBlog) => {
					setGlobal({
						allBlogPosts: responseBlog,
					})
					form.resetFields()
					setNewPhoto(null)
				})
			}
		})
		setLoading(false)
		setVisible(false)
	}

	const handleAddProfileImage = (item) => {
		setNewPhoto(`${ENV_CORE}/${item.file}`)
	}

	const handleDeleteProfileImage = (item) => {
		setNewPhoto(null)
	}

	return (
		<>
			<Button onClick={() => setVisible(true)} className='est-general-create-blog-post-button'>
				{t('profile.manage_blog.modal_create_blog_button')}
			</Button>
			<Modal
				forceRender
				wrapClassName='est-user-modal-container'
				maskClosable={false}
				width='1000px'
				centered
				visible={isVisible}
				onCancel={() => setVisible()}
				okText='Confirmar'
				cancelButtonProps={{ style: { display: 'none' } }}
				okButtonProps={{ style: { display: 'none' } }}>
				<h3 className='est-auth-edit-profile-modal-title'>
					{t('profile.manage_blog.modal_create_blog.modal_title')}
				</h3>

				<Form form={form} name='user_create' onFinish={handleCreateBlogPost}>
					<div className='est-auth-login-form-container'>
						<Row>
							<Col span={12} className='est-login-form-text-container'>
								<h4 className='est-login-form-text'>Tags</h4>
								<InputNormal
									className={'est-auth-login-field-input'}
									inputName={'tags'}
									inputNameLabel={'Tags'}
									inputNameRule={true}
									inputNameMessage={'Tag es obligatorio.'}
									inputNameType={'text'}
									inputNameIcon={''}
									inputNameRules={t('field_notifications.normal.rules_required')}
								/>
							</Col>
							<Col span={24} className='est-login-form-text-container'>
								<h4 className='est-login-form-text'>
									{t('profile.manage_blog.modal_create_blog.input_title_es')}
								</h4>
								<InputNormal
									className={'est-auth-login-field-input'}
									inputName={'title_es'}
									inputNameLabel={t(
										'profile.manage_blog.modal_create_blog.input_title_es'
									)}
									inputNameRule={true}
									inputNameMessage={'Titulo es obligatorio.'}
									inputNameType={'text'}
									inputNameIcon={''}
									inputNameRules={t('field_notifications.normal.rules_required')}
									disabled={false}
								/>
								<h4 className='est-login-form-text'>
									{t('profile.manage_blog.modal_create_blog.input_content_title_es')}
								</h4>
								<Form.Item
									name={'content_es'}
									rules={rulesValidation[t('field_notifications.normal.rules_required')]}>
									<TextArea
										className='est-ads-basics-info-field-input'
										size='large'
										type={'text'}
										placeholder={t(
											'profile.manage_blog.modal_create_blog.input_content_title_es'
										)}
										rows={15}
									/>
								</Form.Item>
								<h4 className='est-login-form-text'>
									{t('profile.manage_blog.modal_create_blog.input_title_en')}
								</h4>
								<InputNormal
									className={'est-auth-login-field-input'}
									inputName={'title_en'}
									inputNameLabel={t(
										'profile.manage_blog.modal_create_blog.input_title_en'
									)}
									inputNameRule={true}
									inputNameMessage={'Titulo es obligatorio.'}
									inputNameType={'text'}
									inputNameIcon={''}
									inputNameRules={t('field_notifications.normal.rules_required')}
									disabled={false}
								/>
								<h4 className='est-login-form-text'>
									{t('profile.manage_blog.modal_create_blog.input_content_title_en')}
								</h4>
								<Form.Item
									name={'content_en'}
									rules={rulesValidation[t('field_notifications.normal.rules_required')]}>
									<TextArea
										className='est-ads-basics-info-field-input'
										size='large'
										type={'text'}
										placeholder={t(
											'profile.manage_blog.modal_create_blog.input_content_title_en'
										)}
										rows={15}
									/>
								</Form.Item>
							</Col>
						</Row>
						<Row>
							<Col span={12} className='est-general-create-blog-post-image-upload'>
								<Form.Item name='dragger' valuePropName='fileList' noStyle>
									<UploadImage
										imageDelete={(data) => handleDeleteProfileImage(data)}
										addItemImage={(data) => handleAddProfileImage(data)}
									/>
								</Form.Item>
							</Col>
						</Row>
						<Form.Item>
							<div>
								<div className='est-user-modal-button-main-container'>
									<Button
										className='est-user-modal-button-main'
										type='primary'
										htmlType={'submit'}
										loading={isLoading}>
										{t('profile.user_data.modal_edit_user.button')}
									</Button>
								</div>
							</div>
						</Form.Item>
					</div>
				</Form>
			</Modal>
		</>
	)
}
export default CreateBlogPost
