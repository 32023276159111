/** @format */

import React, { useState } from 'react'

import { useTranslation } from 'react-i18next'

import notification from 'antd/lib/notification'

import Button from 'antd/lib/button'
import Slider from 'antd/lib/slider'
import Modal from 'antd/lib/modal'

import moment from 'moment'

//import Image from '../../../../../../src/components/Common/Image'
//import SupportImg from '../../../../../img/detail/Support.png'
//import SaleImg from '../../../../../../src/img/detail/sale.png'

import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import { DatePicker } from 'antd'

import AdsReservation from './services'

import './style.css'

export default function Aside(props) {
	const { t } = useTranslation()
	const [isSubtotal, setSubtotal] = useState(props.item.price)
	const [isPercentage, setPercentage] = useState(((isSubtotal * 5) / 100).toFixed(2))
	const [isPercentageImp, setPercentageImp] = useState(((isSubtotal * 7) / 100).toFixed(2))
	const [isTotal, setTotal] = useState(
		(parseFloat(isSubtotal) + parseFloat(isPercentage) + parseFloat(isPercentageImp)).toFixed(2)
	)
	const [isDisabledDates] = useState(props.item.disable_dates)
	const [isPersons, setPersons] = useState(1)
	const [isNights, setNights] = useState(null)
	const [isDateStart, setDateStart] = useState(null)
	const [isDateEnd, setDateEnd] = useState(null)
	const [isReservationLoading, setReservationLoading] = useState(false)
	const [isModalReservation, setModalReservation] = useState(false)
	const { RangePicker } = DatePicker
	const format = 'YYYY-MM-DD'
	/* 
	const disabledDates = [
		{
			start: '23-06-2021',
			end: '25-06-2021',
		},
		{
			start: '01-06-2021',
			end: '03-06-2021',
		},
	] */

	const handlePersons = (item) => {
		let subTotal = item * props.item.price
		let percentaje = item * ((props.item.price * 5) / 100)
		let percentajeImp = item * ((props.item.price * 7) / 100)
		if (isNights) {
			setSubtotal((subTotal * isNights).toFixed(2))
			setPercentage((percentaje * isNights).toFixed(2))
			setPercentageImp((percentajeImp * isNights).toFixed(2))
			setTotal(
				(
					(parseFloat(subTotal) + parseFloat(percentaje) + parseFloat(percentajeImp)) *
					isNights
				).toFixed(2)
			)
		} else {
			setSubtotal(subTotal.toFixed(2))
			setPercentage(percentaje.toFixed(2))
			setPercentageImp(percentajeImp.toFixed(2))
			setTotal(
				(parseFloat(subTotal) + parseFloat(percentaje) + parseFloat(percentajeImp)).toFixed(2)
			)
		}
		setPersons(item)
	}

	const handleReservation = async () => {
		setReservationLoading(true)
		let userData = JSON.parse(localStorage.getItem('userSession'))
		let data = {
			idAnuncio: props.item.id,
			idUser: userData.id,
			cantPersonas: isPersons,
			cantDias: isNights,
			fechaInicio: isDateStart,
			fechaFin: isDateEnd,
			precioXNoche: isSubtotal,
			comision: isPercentage,
			impuesto: isPercentageImp,
			descuento: props.item.price_offer,
			total: isTotal,
		}

		const traduce = {
			service_error_description_one: t('publication.detail.aside.service_error_description_one'),
			service_error_description_two: t('publication.detail.aside.service_error_description_two'),
		}

		await AdsReservation(data, traduce).then((response) => {
			if (response) {
				window.location.href = '/reservation-success'
			}
		})
		setReservationLoading(false)
	}

	const handleSelectedDates = (item) => {
		if (item) {
			let date_start = item[0].format('YYYY-MM-DD')
			let date_end = item[1].format('YYYY-MM-DD')
			let days = item[1].diff(item[0], 'days') + 1
			setSubtotal((days * props.item.price * isPersons).toFixed(2))
			setPercentage(((days * props.item.price * isPersons * 5) / 100).toFixed(2))
			setPercentageImp(((days * props.item.price * isPersons * 7) / 100).toFixed(2))
			setNights(days)
			setDateStart(date_start)
			setDateEnd(date_end)
			setTotal(
				(
					(parseFloat(days * props.item.price) +
						parseFloat((days * props.item.price * 5) / 100) +
						parseFloat((days * props.item.price * 7) / 100)) *
					isPersons
				).toFixed(2)
			)
		} else {
			setSubtotal(props.item.price * isPersons)
			setPercentage((((props.item.price * 5) / 100) * isPersons).toFixed(2))
			setPercentageImp((((props.item.price * 7) / 100) * isPersons).toFixed(2))
			setTotal(
				(
					(parseFloat(props.item.price) +
						parseFloat((props.item.price * 5) / 100) +
						parseFloat((props.item.price * 7) / 100)) *
					isPersons
				).toFixed(2)
			)
			setNights(null)
		}
	}

	const handleModalReservation = () => {
		let validReservation = true
		let userData = JSON.parse(localStorage.getItem('userSession'))
		if (!userData) {
			notification['warning']({
				message: t('publication.detail.aside.warning'),
				description: t('publication.detail.aside.message_one'),
			})
			return
		}
		if (!isNights) {
			notification['warning']({
				message: t('publication.detail.aside.warning'),
				description: t('publication.detail.aside.message_two'),
			})
			return
		}
		let dateArray = []
		let currentDate = moment(isDateStart)
		let stopDate = moment(isDateEnd)
		while (currentDate <= stopDate) {
			dateArray.push(moment(currentDate).format('YYYY-MM-DD'))
			currentDate = moment(currentDate).add(1, 'days')
		}
		if (props.item.disable_dates) {
			props.item.disable_dates.forEach((item) => {
				dateArray.forEach((data) => {
					if (item.start === data || item.end === data) {
						validReservation = false
					}
				})
			})
		}
		if (!validReservation) {
			notification['warning']({
				message: t('publication.detail.aside.warning'),
				duration: 6,
				description: t('publication.detail.aside.message_three'),
			})
			return
		}
		setModalReservation(true)
	}

	return (
		<>
			<div className='est-aside-global-container'>
				<div className='est-aside-main-container'>
					<div className='est-aside-main-title-container'>
						<h2 className='est-aside-title-primary'>{props.item.completeAddress.address}</h2>
					</div>

					<div className='est-aside-detail-gobal-container'>
						<div className='est-aside-detail-main-container'>
							<h2 className='est-aside-detail-main-title'>{props.item.title}</h2>
							<h3 className='est-aside-detail-main-category'>
								{props.item.nombre_categoria}
							</h3>
							<h3 className='est-aside-detail-main-price'>
								{t('publication.detail.aside.price_title')}
							</h3>
							<h3 className='est-aside-detail-main-price'>${props.item.price}</h3>
							<div className='est-detail-reservation-amount-people-container'>
								<h4 className='est-detail-reservation-amount-people-title'>
									{t('publication.detail.aside.people_subtitle')} {isPersons}
								</h4>
								<Slider
									min={1}
									max={parseInt(props.item.people)}
									onChange={handlePersons}
									value={typeof isPersons === 'number' ? isPersons : 0}
								/>
							</div>

							<div className='est-detail-reservation-date-picker-container'>
								<h4 className='est-detail-reservation-date-picker-title'>
									{t('publication.detail.aside.nights_subtitle')}{' '}
									{isNights ? isNights : t('publication.detail.aside.price_title')}
								</h4>
								<RangePicker
									format='DD/MM/YYYY HH:mm'
									onChange={handleSelectedDates}
									//defaultValue={[moment(isDate, dateFormat), moment(isDate, dateFormat)]}
									defaultValue={''}
									disabledDate={(current) => {
										if (isDisabledDates) {
											return isDisabledDates.some(
												(date) =>
													current < moment().startOf('day') ||
													current.isBetween(
														moment(date['start'], format),
														moment(date['end'], format).endOf('day')
													)
											)
										} else {
											return current < moment().startOf('day')
										}
									}}
								/>
							</div>

							<div className='est-detail-reservation-inner-container'>
								<Row>
									<Col span={24} className='est-detail-reservation-taxes'>
										<Row className='est-detail-reservation-payment-data-container'>
											<h3 className='amenities-title'>Subtotal</h3>
											<div className='est-detail-reservation-payment-data-spacer' />
											<h3 className='amenities-price'>${isSubtotal}</h3>
										</Row>
									</Col>
									<Col span={24} className='est-detail-reservation-taxes'>
										<Row className='est-detail-reservation-payment-data-container'>
											<h3 className='amenities-title'>
												{t('publication.detail.aside.commission_title')}
											</h3>
											<div className='est-detail-reservation-payment-data-spacer' />
											<h3 className='amenities-price'>${isPercentage}</h3>
										</Row>
									</Col>
									<Col span={24} className='est-detail-reservation-taxes'>
										<Row className='est-detail-reservation-payment-data-container'>
											<h3 className='amenities-title'>
												{t('publication.detail.aside.taxes_title')}
											</h3>
											<div className='est-detail-reservation-payment-data-spacer' />
											<h3 className='amenities-price'>${isPercentageImp}</h3>
										</Row>
									</Col>
								</Row>
							</div>

							<div className='est-detail-total-title-container'>
								<h2 className='est-detail-total-title'>Total ${isTotal}</h2>
							</div>

							<div className='est-detail-total-button-global-container'>
								<div className='est-detail-total-button-main-container'>
									<Button
										onClick={() => handleModalReservation()}
										className='est-detail-total-button'>
										{t('publication.detail.aside.button_confirm')}
									</Button>
								</div>
							</div>
						</div>
					</div>

					{/* 	<div className='est-detail-support-global-container'>
						<div className='est-detail-support-main-container'>
							<div className='est-detail-support-img-container'>
								<Image
									classImg={'est-detail-support-img'}
									image={SupportImg}
									alt={'Support'}
									title={'Support'}
								/>
							</div>
							<div className='est-detail-support-text-container'>
								<h3 className='est-detail-support-title'>Soporte 24 hr</h3>
								<h3 className='est-detail-support-subtitle'>
									Lorem ipsum is simply a dummy text
								</h3>
							</div>
						</div>
					</div>

					<div className='est-detail-sale-global-container'>
						<div className='est-detail-sale-main-container'>
							<div className='est-detail-sale-img-container'>
								<Image
									classImg={'est-detail-sale-img'}
									image={SaleImg}
									alt={'Sale'}
									title={'Sale'}
								/>
							</div>
							<div className='est-detail-sale-text-container'>
								<h3 className='est-detail-sale-title'>Descuentos</h3>
								<h3 className='est-detail-sale-subtitle'>
									Lorem ipsum is simply a dummy text
								</h3>
							</div>
						</div>
					</div> */}
				</div>
			</div>
			<Modal
				title={t('publication.detail.aside.modal_title')}
				wrapClassName='est-reservation-modal-global-container'
				maskClosable={true}
				width='550px'
				centered
				visible={isModalReservation}
				onCancel={() => setModalReservation(false)}
				cancelButtonProps={{ style: { display: 'none' } }}
				okButtonProps={{ style: { display: 'none' } }}>
				<h3 className='est-reservation-modal-title'>
					{t('publication.detail.aside.modal_subtitle')}
				</h3>
				{isPersons && (
					<h4 className='est-reservation-modal-subtitle'>
						<span className='est-reservation-modal-subtitle-span-title'>
							{t('publication.detail.aside.people')}
						</span>{' '}
						{isPersons}
					</h4>
				)}
				{isNights && (
					<h4 className='est-reservation-modal-subtitle'>
						<span className='est-reservation-modal-subtitle-span-title'>
							{t('publication.detail.aside.nights')}
						</span>{' '}
						{isNights}
					</h4>
				)}
				{isDateStart && (
					<h4 className='est-reservation-modal-subtitle'>
						<span className='est-reservation-modal-subtitle-span-title'>
							{t('publication.detail.aside.date_start')}
						</span>{' '}
						{isDateStart}
					</h4>
				)}
				{isDateEnd && (
					<h4 className='est-reservation-modal-subtitle'>
						<span className='est-reservation-modal-subtitle-span-title'>
							{t('publication.detail.aside.date_end')}
						</span>{' '}
						{isDateEnd}
					</h4>
				)}
				{isSubtotal && (
					<h4 className='est-reservation-modal-subtitle'>
						<span className='est-reservation-modal-subtitle-span-title'>Subtotal:</span> $
						{isSubtotal}
					</h4>
				)}
				{isPercentage && (
					<h4 className='est-reservation-modal-subtitle'>
						<span className='est-reservation-modal-subtitle-span-title'>
							{t('publication.detail.aside.commission_modal_title')}
						</span>{' '}
						${isPercentage}
					</h4>
				)}
				{isPercentageImp && (
					<h4 className='est-reservation-modal-subtitle'>
						<span className='est-reservation-modal-subtitle-span-title'>
							{t('publication.detail.aside.taxes_modal_title')}
						</span>{' '}
						${isPercentageImp}
					</h4>
				)}
				{isTotal && (
					<h4 className='est-reservation-modal-subtitle'>
						<span className='est-reservation-modal-subtitle-span-title'>Total:</span> $
						{isTotal}
					</h4>
				)}

				<div className='est-reservation-modal-footer-global-container'>
					<h4 className='est-reservation-modal-footer-title'>
						{t('publication.detail.aside.time_out_one')}{' '}
						<span className='est-reservation-modal-footer-subtitle'>
							5 {t('publication.detail.aside.time_out_two')}
						</span>{' '}
						{t('publication.detail.aside.time_out_three')}
					</h4>
				</div>

				<div className='est-reservation-modal-button-container'>
					<Button
						className='est-reservation-modal-button'
						loading={isReservationLoading}
						onClick={() => handleReservation()}>
						{t('publication.detail.aside.modal_button')}
					</Button>
				</div>
			</Modal>
		</>
	)
}
