/** @format */

import React, { useState, useEffect } from 'react'
import { setGlobal } from 'reactn'

import { useTranslation } from 'react-i18next'

import { useCookies } from 'react-cookie'

import { Row, Col, Button } from 'antd'

import Spacer from '../../../../../../components/Common/Spacer'

import DataBars from './components/DataBars'
import DataLine from './components/DataLine'

import { GetMetricsReservationsPay, GetMetricsReservationsAproved } from './services'

import './style.css'

export default function DataInfo() {
	const { t } = useTranslation()
	const [cookies] = useCookies(['i18next'])
	const [isDataMetricsPay, setDataMetricsPay] = useState(null)
	const [isDataMetricsApproved, setDataMetricsApproved] = useState(null)
	const [isMobile, setMobile] = useState(false)

	const [isGetMetricsReservationsPay] = useState({
		service_global_description: t('global_service.service_global_description'),
	})
	const [isGetMetricsReservationsAproved] = useState({
		service_global_description: t('global_service.service_global_description'),
	})

	const FormatPrice = (item) => {
		let locale
		if (cookies.i18next === 'en') {
			locale = 'en-US'
		} else if (cookies.i18next === 'es') {
			locale = 'es-ES'
		}
		return item.toLocaleString(locale, {
			style: 'currency',
			currency: 'USD',
		})
	}

	useEffect(() => {
		if (window.innerWidth < 575) {
			setMobile(true)
		}
		GetMetricsReservationsPay(cookies.i18next, isGetMetricsReservationsPay).then((response) => {
			if (response) {
				setDataMetricsPay(response)
			}
		})
		GetMetricsReservationsAproved(cookies.i18next, isGetMetricsReservationsAproved).then(
			(response) => {
				if (response) {
					setDataMetricsApproved(response)
				}
			}
		)
	}, [cookies.i18next, isGetMetricsReservationsAproved, isGetMetricsReservationsPay])

	return (
		<div className='est-profile-data-info-global-container'>
			<Button
				className='est-profile-data-info-responsive-button'
				type='primary'
				onClick={() =>
					setGlobal({
						useDrawer: true,
					})
				}>
				{t('profile.user_data.profile_info.resp_button')}
			</Button>
			<div className='est-profile-data-info-main-container'>
				<Row>
					{isDataMetricsPay && (
						<>
							<Col
								xs={24}
								sm={24}
								md={12}
								lg={12}
								xl={12}
								className='est-profile-data-info-box'>
								<div className='est-profile-data-info-box-inner'>
									<h3 className='est-profile-data-info-title-one'>
										{t('profile.data_metrics.left_info_box.main_title')}
									</h3>
									<h4 className='est-profile-data-info-text-one'>
										{t('profile.data_metrics.left_info_box.subtitle')}
									</h4>
									<h3 className='est-profile-data-info-text-two'>
										{FormatPrice(isDataMetricsPay.total)}
									</h3>
								</div>
							</Col>
							<Spacer />
						</>
					)}
					{isDataMetricsApproved && (
						<Col
							xs={24}
							sm={24}
							md={12}
							lg={12}
							xl={12}
							className='est-profile-data-info-box'>
							<div className='est-profile-data-info-box-inner'>
								<h3 className='est-profile-data-info-title-one'>
									{t('profile.data_metrics.right_info_box.main_title')}
								</h3>
								<h4 className='est-profile-data-info-text-one'>
									{t('profile.data_metrics.right_info_box.subtitle')}
								</h4>
								<h3 className='est-profile-data-info-text-two'>
									{FormatPrice(isDataMetricsApproved.total)}
								</h3>
							</div>
						</Col>
					)}
					{/* 		<Spacer />
					<Col xs={24} sm={24} md={24} lg={6} xl={6} className='est-profile-data-info-box'>
						<h3 className='est-profile-data-info-title-one'>Lorem Ipsum</h3>
						<h4 className='est-profile-data-info-text-one'>Lorem Ipsum</h4>
						<h3 className='est-profile-data-info-text-two'>2.45</h3>
					</Col> */}
				</Row>
				<Row>
					<Col span={24} className='est-data-metrics-bar-container'>
						{isDataMetricsPay && <DataBars data={isDataMetricsPay} mobile={isMobile} />}
					</Col>
				</Row>
				<Row>
					<Col span={24} className='est-data-metrics-bar-container'>
						{isDataMetricsApproved && (
							<DataLine data={isDataMetricsApproved} mobile={isMobile} />
						)}
					</Col>
				</Row>
				{/* 		<Row>
					<Col span={12}>
						<h2>Ejemplo de titulo 2</h2>
						<Doughnut data={isDataDonnut} />
					</Col>
				</Row> */}
			</div>
		</div>
	)
}
