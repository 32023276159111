/** @format */

import React from 'react'

import { useTranslation } from 'react-i18next'

import { Link } from 'react-router-dom'

import { ExclamationCircleOutlined } from '@ant-design/icons'

import './style.css'

export default function PageNotFound() {
	const { t } = useTranslation()
	return (
		<div className='est-not-found-global-container'>
			<div className='est-not-found-main-container'>
				<h3 className='est-not-found-icon'>
					<ExclamationCircleOutlined />
				</h3>
				<h3 className='est-not-found-title'>{t('not_found.title')}</h3>
				<h3 className='est-not-found-subtitle'>{t('not_found.subtitle')}</h3>

				<div className='est-not-found-button-container'>
					<Link to='/' className='est-not-found-button'>
						{t('not_found.button')}
					</Link>
				</div>
			</div>
		</div>
	)
}
