/** @format */

import React, { useState, useEffect } from 'react'

import { useTranslation } from 'react-i18next'

import { Link } from 'react-router-dom'

import MainNavigation from '../../../../../components/Common/MainNavigation'

import Row from 'antd/lib/row'
import Form from 'antd/lib/form'
import Col from 'antd/lib/col'
import Input from 'antd/lib/input'
import Button from 'antd/lib/button'
import Radio from 'antd/lib/radio'
//import Switch from 'antd/lib/switch'
//import Select from 'antd/lib/select'
import notification from 'antd/lib/notification'
import Checkbox from 'antd/lib/checkbox'

import Spacer from '../../../../../components/Common/Spacer'

import Loading from '../../../../../components/Common/Loading'

import { rulesValidation } from '../../../../../components/Common/Inputs/Normal/rules'

import InputCustom from '../../../../../components/Common/Inputs/Normal'

import { GetCategories } from '../../../../../components/Common/Hooks/Functions/GetCategories'

import './style.css'

export default function AdsBasicInfo() {
	const { t } = useTranslation()
	const [isUser] = useState(JSON.parse(localStorage.getItem('userSession')))
	const [isCategories, setCategories] = useState(false)
	const [isDiscountPrice] = useState(false)
	//const [isDiscountPrice, setDiscountPrice] = useState(false)
	const { TextArea } = Input
	//const { Option } = Select

	const handleBasicInfo = async (item) => {
		item.regIdUser = isUser.id
		if (!item.regOffer) {
			item.regOffer = false
		}
		if (!item.regPriceOffer) {
			item.regPriceOffer = '0'
		}
		if (parseInt(item.regPriceOffer) > parseInt(item.regPrice)) {
			notification['success']({
				message: t('publication.create.basic_info.message'),
				description: t('publication.create.basic_info.description'),
			})
			return
		}
		localStorage.setItem('info', JSON.stringify(item))
		window.location.pathname = 'publication/create/location'
	}

	/* 	const handleDiscountPrice = async () => {
		if (isDiscountPrice) {
			setDiscountPrice(false)
		} else {
			setDiscountPrice(true)
		}
	} */

	useEffect(() => {
		localStorage.removeItem('success')
		localStorage.removeItem('info')
		localStorage.removeItem('info-location')
		localStorage.removeItem('info-photos')
		GetCategories().then((response) => {
			if (response) {
				let info_categories = []
				let info_type_view = []
				response.category.forEach((item) => {
					info_categories.push({ label: item.nombre, value: item.nombre })
				})
				response.type_view.forEach((item) => {
					info_type_view.push({ label: item.nombre, value: item.nombre })
				})
				setCategories({ categories: info_categories, type_view: info_type_view })
			}
		})
	}, [])

	if (!isCategories) {
		return <Loading />
	} else {
		return (
			<>
				<MainNavigation
					title={t('publication.create.basic_info.main_navigation.title')}
					linkPrevTitle={'Home'}
					linkNextTitle={t('publication.create.basic_info.main_navigation.title')}
					linkPrev={'/'}
					linkNext={'/publication/create/basic-info'}
				/>
				<div className='est-ads-basics-info-global-container'>
					<h2 className='est-ads-basics-info-global-title'>
						{t('publication.create.basic_info.main_title')}
					</h2>
					<h3 className='est-ads-basics-info-global-subtitle'>
						{t('publication.create.basic_info.main_subtitle')}
					</h3>
					<Form
						name='basic_info'
						onFinish={handleBasicInfo}
						initialValues={{
							regHalf: 'default',
							regDiscountAmount: 100,
						}}>
						<Row className='est-ads-basics-info-main-container'>
							<Col
								xs={24}
								sm={isDiscountPrice ? 8 : 12}
								md={isDiscountPrice ? 8 : 12}
								lg={isDiscountPrice ? 8 : 12}
								xl={isDiscountPrice ? 8 : 12}
								className='est-ads-basics-info-container'>
								<h4 className='est-ads-basics-info-title'>
									{t('publication.create.basic_info.input_name')}
								</h4>
								<InputCustom
									className={'est-ads-basics-info-field-input'}
									inputName={'regTitle'}
									inputNameLabel={t('publication.create.basic_info.placeholder_name')}
									inputNameRule={true}
									inputNameMessage={' '}
									inputNameType={'text'}
									inputNameIcon={''}
									inputNameRules={t('field_notifications.normal.rules_required')}
								/>
							</Col>
							<Col
								xs={24}
								sm={isDiscountPrice ? 8 : 12}
								md={isDiscountPrice ? 8 : 12}
								lg={isDiscountPrice ? 8 : 12}
								xl={isDiscountPrice ? 8 : 12}
								className='est-ads-basics-info-container'>
								<h4 className='est-ads-basics-info-title'>
									{t('publication.create.basic_info.input_price')}
								</h4>
								<InputCustom
									className={'est-ads-basics-info-field-input'}
									inputName={'regPrice'}
									inputNameLabel={t('publication.create.basic_info.placeholder_price')}
									inputNameRule={true}
									inputNameMessage={' '}
									inputNameType={'number'}
									inputNameIcon={''}
									inputNameRules={t('field_notifications.normal.rules_required')}
								/>
							</Col>
							{isDiscountPrice && (
								<Col
									xs={24}
									sm={8}
									md={8}
									lg={8}
									xl={8}
									className='est-ads-basics-info-container'>
									<h4 className='est-ads-basics-info-title'>
										{t('publication.create.basic_info.input_offer_price')}
									</h4>
									<InputCustom
										className={'est-ads-basics-info-field-input'}
										inputName={'regPriceOffer'}
										inputNameLabel={t(
											'publication.create.basic_info.placeholder_offer_price'
										)}
										inputNameRule={true}
										inputNameMessage={' '}
										inputNameType={'number'}
										inputNameIcon={''}
										inputNameRules={t('field_notifications.normal.rules_required')}
									/>
								</Col>
							)}
							<Col span={24} className='est-ads-basics-info-container'>
								<h4 className='est-ads-basics-info-title'>
									{t('publication.create.basic_info.input_description')}
								</h4>
								<Form.Item
									name={'regDescription'}
									rules={rulesValidation[t('field_notifications.normal.rules_required')]}>
									<TextArea
										className='est-ads-basics-info-field-input'
										size='large'
										type={'text'}
										placeholder={t(
											'publication.create.basic_info.placeholder_description'
										)}
										rows={4}
									/>
								</Form.Item>
							</Col>
							<div className='field-hidden'>
								<InputCustom
									className={'est-ads-basics-info-field-input'}
									inputName={'regHalf'}
									inputNameLabel={'Medio'}
									inputNameRule={true}
									inputNameMessage={' '}
									inputNameType={'text'}
									inputNameIcon={''}
									inputNameRules={'rulesRequired'}
								/>
							</div>
							<Col
								xs={24}
								sm={24}
								md={8}
								lg={8}
								xl={8}
								className='est-ads-basics-info-container'>
								<h4 className='est-ads-basics-info-title'>
									{t('publication.create.basic_info.input_people')}
								</h4>
								<InputCustom
									className={'est-ads-basics-info-field-input'}
									inputName={'regPeople'}
									inputNameLabel={t('publication.create.basic_info.placeholder_people')}
									inputNameRule={true}
									inputNameMessage={' '}
									inputNameType={'number'}
									inputNameIcon={''}
									inputNameRules={t('field_notifications.normal.rules_people')}
								/>
							</Col>
							<Col
								xs={24}
								sm={24}
								md={8}
								lg={8}
								xl={8}
								className='est-ads-basics-info-container'>
								<h4 className='est-ads-basics-info-title'>
									{t('publication.create.basic_info.placeholder_category')}
								</h4>
								<Form.Item
									name='regIdCategory'
									valuePropName='checked'
									rules={[
										{
											required: true,
											message: t('publication.create.basic_info.category_message'),
										},
									]}>
									<Checkbox.Group options={isCategories.categories} />
								</Form.Item>
							</Col>
							<Col
								xs={24}
								sm={24}
								md={8}
								lg={8}
								xl={8}
								className='est-ads-basics-info-container'>
								<h4 className='est-ads-basics-info-title'>
									{t('publication.create.basic_info.placeholder_type_view')}
								</h4>
								<Form.Item
									name='regIdCategory2'
									valuePropName='checked'
									rules={[
										{
											required: true,
											message: t('publication.create.basic_info.type_view_message'),
										},
									]}>
									<Checkbox.Group options={isCategories.type_view} />
								</Form.Item>
							</Col>
							{/* 	<Col
								xs={6}
								sm={5}
								md={5}
								lg={4}
								xl={4}
								className='est-ads-basics-info-container'>
								<h4 className='est-ads-basics-info-title'>
									{t('publication.create.basic_info.input_discount')}
								</h4>
								<Form.Item name='regOffer' valuePropName='checked'>
									<Switch onClick={() => handleDiscountPrice()} />
								</Form.Item>
							</Col> */}
							<Col
								xs={17}
								sm={10}
								md={8}
								lg={7}
								xl={5}
								className='est-ads-basics-info-container display-none'>
								<h4 className='est-ads-basics-info-title'>
									{t('publication.create.basic_info.radio_reserv')}
								</h4>
								<Form.Item
									name={'regDiscountAmount'}
									rules={rulesValidation.rulesRequired}
									className='est-ads-basics-info-value-container'>
									<Radio.Group>
										<Radio value={100}>100%</Radio>
										<Radio value={50}>50%</Radio>
										<Radio value={25}>25%</Radio>
									</Radio.Group>
								</Form.Item>
							</Col>
						</Row>
						<Row>
							<Col span={24} className='est-ads-basics-info-button-container'>
								<Link className='est-ads-basics-info-button' to='/'>
									{t('publication.create.basic_info.prev_button')}
								</Link>
								<Spacer />
								<Button className='est-ads-basics-info-button' htmlType={'submit'}>
									{t('publication.create.basic_info.next_button')}
								</Button>
							</Col>
						</Row>
					</Form>
				</div>
			</>
		)
	}
}
