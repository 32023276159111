/** @format */

import React, { useState, useEffect } from 'react'

import { useTranslation } from 'react-i18next'

import { useGlobal, setGlobal } from 'reactn'
//import { useHistory } from 'react-router-dom'

import MainNavigation from '../../../../components/Common/MainNavigation'

import moment from 'moment'

import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import Button from 'antd/lib/button'
//import Modal from 'antd/lib/modal'
//import Checkbox from 'antd/lib/checkbox'
//import Form from 'antd/lib/form'

import Tabs from 'antd/lib/tabs'

import HeadDescription from '../../../../components/Common/HeadDescription'
import Loading from '../../../../components/Common/Loading'

import { GetUserInfo } from '../../../../components/Common/Hooks/Functions/GetUserInfo'
//import { CheckReservation } from '../../../../components/Common/Hooks/Functions/CheckReservation'

import MainMenu from '../../components/MainMenu'
import LoadingData from '../../components/LoadingData'

import Reviews from './components/Reviews'
//import EditReservation from './components/EditReservation'

import { GeneralReservations, UpdateReservations } from './services'

import './style.css'

export default function UserListPublications() {
	//const [form] = Form.useForm()
	const { t } = useTranslation()
	//const history = useHistory()
	const [isUser] = useState(JSON.parse(localStorage.getItem('userSession')))
	const [isDataProfile] = useGlobal('DataProfile')
	const [isUserReservations, setUserReservations] = useGlobal('GeneralReservations')
	const [isUserReservationsLoading, setUserReservationsLoading] = useState(true)
	//const [isPaymentLoading, setPaymentLoading] = useState(false)
	//const [isVibleModalContract, setVibleModalContract] = useState(false)
	//const [isRowIdPayment, setRowIdPayment] = useState(null)
	//const [isLoadingModalPaymentButton, setLoadingModalPaymentButton] = useState(false)
	//const [isModalClosable, setModalClosable] = useState(true)

	const [isUpdateLoading, setUpdateLoading] = useState(false)
	const { TabPane } = Tabs

	const [isTraduceGeneralReservations] = useState({
		service_global_description: t('global_service.service_global_description'),
	})

	useEffect(() => {
		GeneralReservations(isTraduceGeneralReservations).then((response) => {
			if (response) {
				setUserReservations(response)
			} else {
				setUserReservationsLoading(true)
			}
		})
		GetUserInfo(isUser.id).then((response) => {
			if (response) {
				setGlobal({
					DataProfile: response,
				})
			} else {
				setTimeout(() => {
					window.location.pathname = '/'
				}, 5000)
			}
		})
	}, [isUser.id, setUserReservations, isTraduceGeneralReservations])

	/* 	const handleCancelationDate = (item) => {
		let days
		if (item) {
			let todayDate = new Date()
			todayDate =
				todayDate.getFullYear() +
				'-' +
				0 +
				(todayDate.getMonth() + 1) +
				'-' +
				todayDate.getDate()
			todayDate = moment(todayDate)
			let cancellationDate = moment(item)
			days =
				'*(' +
				cancellationDate.diff(todayDate, 'days') +
				')' +
				t('profile.user_reservations.data_day')
		}
		return days
	} */

	const handleReviewsStartDate = (item) => {
		let days
		if (item) {
			let todayDate = new Date()
			todayDate =
				todayDate.getFullYear() +
				'-' +
				0 +
				(todayDate.getMonth() + 1) +
				'-' +
				todayDate.getDate()
			todayDate = moment(todayDate)
			let cancellationDate = moment(item)
			days = cancellationDate.diff(todayDate, 'days')
		}
		return days
	}

	/* 	const handleOpenModalPayment = (item) => {
		setVibleModalContract(true)
		setRowIdPayment(item)
	} */

	/* 	const handleCloseModalPayment = () => {
		setRowIdPayment(null)
		setVibleModalContract(false)
	} */

	/* 	const handlePayment = async () => {
		setModalClosable(false)
		setLoadingModalPaymentButton(true)
		setPaymentLoading(true)
		await CheckReservation(isRowIdPayment).then((response) => {
			setModalClosable(true)
			setPaymentLoading(false)
			setLoadingModalPaymentButton(false)
			if (response) {
				history.push(`/profile/payment/${isRowIdPayment}`)
			}
		})
	} */

	const handleUpdateReservation = async (item, data) => {
		setUpdateLoading(true)

		const traduce = {
			service_success_title: t('profile.user_publication.service_success_title'),
			service_success_description: t('profile.user_publication.service_success_description'),
			service_warning_title: t('profile.user_publication.service_warning_title'),
			service_warning_description: t('profile.user_publication.service_warning_description'),
			service_error_description: t('profile.user_publication.service_error_description'),
		}

		await UpdateReservations(item, data, traduce).then((response) => {
			if (response) {
				GeneralReservations(isTraduceGeneralReservations).then((response) => {
					if (response) {
						setUserReservations(response)
					} else {
						setUserReservationsLoading(true)
					}
				})
			}
		})
		setUpdateLoading(false)
	}

	if (!isDataProfile) {
		return <Loading />
	} else {
		return (
			<>
				<HeadDescription
					title={`${t('profile.general_reservations.head_description.title')} - ${isUser.id}`}
					name={'description'}
					content={'Camping Site'}
				/>

				<MainNavigation
					title={t('profile.general_reservations.main_navigation.title')}
					linkPrevTitle={'Home'}
					linkNextTitle={t('profile.general_reservations.main_navigation.title')}
					linkPrev={'/'}
					linkNext={'/'}
				/>

				<div className='est-user-reservations-global-container'>
					<div className='est-user-reservations-main-container'>
						<div className='est-user-reservations-main-title-container'>
							<h2 className='est-user-reservations-main-title'>
								{t('profile.general_reservations.user_info')}
							</h2>
						</div>

						<div className='est-profile-user-reservations-global-container'>
							<div className='est-profile-user-reservations-aside-container'>
								<MainMenu user={isDataProfile} />
							</div>
							<div className='est-profile-reservations-list-global-container'>
								<div className='est-profile-reservations-list-main-container'>
									<div className='est-profile-reservations-list-header-global-container'>
										<div className='est-profile-reservations-responsive-button-container'>
											<Button
												className='est-profile-reservations-responsive-button'
												type='primary'
												onClick={() =>
													setGlobal({
														useDrawer: true,
													})
												}>
												{t('profile.user_reservations.menu_resp_button')}
											</Button>
										</div>
										<h2 className='est-profile-reservations-list-title'>
											{t('profile.user_reservations.title')}
										</h2>
									</div>
									<div className='est-profile-reservations-list-collapse-container'>
										{isUserReservationsLoading ? (
											<>
												{isUserReservations ? (
													<div className='est-general-reservations-list-global-container'>
														<Tabs defaultActiveKey='1'>
															<TabPane
																tab={t(
																	'profile.user_reservations.tabs.pending_title'
																)}
																key='1'>
																{isUserReservations.userReservationPending.length >
																0 ? (
																	<>
																		{isUserReservations.userReservationPending.map(
																			(item, index) => (
																				<div
																					key={index}
																					className='est-user-reservations-list-main-container'>
																					<Row>
																						<Col
																							xs={24}
																							sm={24}
																							md={8}
																							lg={8}
																							xl={8}>
																							<h4 className='est-user-reservations-list-main-title'>
																								<span className='est-user-reservations-list-main-span-title'>
																									{t(
																										'profile.user_reservations.tabs.main_id'
																									)}{' '}
																								</span>
																								{item.id}
																							</h4>
																						</Col>
																						<Col
																							xs={24}
																							sm={24}
																							md={8}
																							lg={8}
																							xl={8}>
																							<h4 className='est-user-reservations-list-main-title'>
																								<span className='est-user-reservations-list-main-span-title'>
																									{t(
																										'profile.user_reservations.tabs.id'
																									)}{' '}
																								</span>
																								{item.id_anuncio}
																							</h4>
																						</Col>
																						<Col
																							xs={24}
																							sm={24}
																							md={8}
																							lg={8}
																							xl={8}>
																							<h4 className='est-user-reservations-list-main-title'>
																								<span className='est-user-reservations-list-main-span-title'>
																									{t(
																										'profile.user_reservations.tabs.title'
																									)}{' '}
																								</span>
																								{item.title}
																							</h4>
																						</Col>
																						<Col
																							xs={24}
																							sm={24}
																							md={8}
																							lg={8}
																							xl={8}>
																							<h4 className='est-user-reservations-list-main-title'>
																								<span className='est-user-reservations-list-main-span-title'>
																									{t(
																										'profile.user_reservations.tabs.applicant'
																									)}{' '}
																								</span>
																								{item.nombre}{' '}
																								{item.apellido}
																							</h4>
																						</Col>
																						<Col
																							xs={24}
																							sm={24}
																							md={8}
																							lg={8}
																							xl={8}>
																							<h4 className='est-user-reservations-list-main-title'>
																								<span className='est-user-reservations-list-main-span-title'>
																									{t(
																										'profile.user_reservations.tabs.number_days'
																									)}{' '}
																								</span>
																								{item.cantidad_dias}
																							</h4>
																						</Col>
																						<Col
																							xs={24}
																							sm={24}
																							md={8}
																							lg={8}
																							xl={8}>
																							<h4 className='est-user-reservations-list-main-title'>
																								<span className='est-user-reservations-list-main-span-title'>
																									{t(
																										'profile.user_reservations.tabs.amount_people'
																									)}{' '}
																								</span>
																								{item.cantidad_personas}
																							</h4>
																						</Col>
																						<Col
																							xs={24}
																							sm={24}
																							md={8}
																							lg={8}
																							xl={8}>
																							<h4 className='est-user-reservations-list-main-title'>
																								<span className='est-user-reservations-list-main-span-title'>
																									{t(
																										'profile.user_reservations.tabs.start_date'
																									)}{' '}
																								</span>
																								{item.fecha_desde}
																							</h4>
																						</Col>
																						<Col
																							xs={24}
																							sm={24}
																							md={8}
																							lg={8}
																							xl={8}>
																							<h4 className='est-user-reservations-list-main-title'>
																								<span className='est-user-reservations-list-main-span-title'>
																									{t(
																										'profile.user_reservations.tabs.end_date'
																									)}{' '}
																								</span>
																								{item.fecha_hasta}
																							</h4>
																						</Col>
																						<Col
																							xs={24}
																							sm={24}
																							md={8}
																							lg={8}
																							xl={8}>
																							<h4 className='est-user-reservations-list-main-title'>
																								<span className='est-user-reservations-list-main-span-title'>
																									Subtotal:{' '}
																								</span>
																								${item.precio}
																							</h4>
																						</Col>
																						<Col
																							xs={24}
																							sm={24}
																							md={8}
																							lg={8}
																							xl={8}>
																							<h4 className='est-user-reservations-list-main-title'>
																								<span className='est-user-reservations-list-main-span-title'>
																									{t(
																										'profile.user_reservations.tabs.taxes'
																									)}{' '}
																								</span>
																								${item.impuesto}
																							</h4>
																						</Col>
																						<Col
																							xs={24}
																							sm={24}
																							md={8}
																							lg={8}
																							xl={8}>
																							<h4 className='est-user-reservations-list-main-title'>
																								<span className='est-user-reservations-list-main-span-title'>
																									Total:{' '}
																								</span>
																								${item.total}
																							</h4>
																						</Col>
																						{/* 	<Col span={24}>
																							<div className='est-user-reservations-date-left'>
																								{handleCancelationDate(
																									item.fecha_vencimiento
																								)}
																							</div>
																						</Col> */}
																						<Col
																							span={24}
																							className='est-user-publications-list-reservations-button-container'>
																							<Button
																								className='est-user-publications-list-reservations-button'
																								loading={isUpdateLoading}
																								onClick={() =>
																									handleUpdateReservation(
																										'1',
																										item.id
																									)
																								}>
																								{t(
																									'profile.general_reservations.button_pending_1'
																								)}
																							</Button>
																							<Button
																								className='est-user-publications-list-reservations-button'
																								loading={isUpdateLoading}
																								onClick={() =>
																									handleUpdateReservation(
																										'2',
																										item.id
																									)
																								}>
																								{t(
																									'profile.general_reservations.button_pending_2'
																								)}
																							</Button>
																							{/* 	<EditReservation item={item} /> */}
																						</Col>
																					</Row>
																				</div>
																			)
																		)}
																	</>
																) : (
																	<h3 className='est-user-reservations-list-tab-main-subtitle'>
																		{t('profile.user_reservations.tabs.subtitle')}
																	</h3>
																)}
															</TabPane>
															<TabPane
																tab={t('profile.user_reservations.tabs.pass_title')}
																key='2'>
																{isUserReservations.userReservationApproved.length >
																0 ? (
																	<>
																		{isUserReservations.userReservationApproved.map(
																			(item, index) => (
																				<div
																					key={index}
																					className='est-user-reservations-list-main-container'>
																					<Row>
																						<Col
																							xs={24}
																							sm={24}
																							md={8}
																							lg={8}
																							xl={8}>
																							<h4 className='est-user-reservations-list-main-title'>
																								<span className='est-user-reservations-list-main-span-title'>
																									ID de reservación:{' '}
																								</span>
																								{item.id}
																							</h4>
																						</Col>
																						<Col
																							xs={24}
																							sm={24}
																							md={8}
																							lg={8}
																							xl={8}>
																							<h4 className='est-user-reservations-list-main-title'>
																								<span className='est-user-reservations-list-main-span-title'>
																									{t(
																										'profile.user_reservations.tabs.id'
																									)}{' '}
																								</span>
																								{item.id_anuncio}
																							</h4>
																						</Col>
																						<Col
																							xs={24}
																							sm={24}
																							md={8}
																							lg={8}
																							xl={8}>
																							<h4 className='est-user-reservations-list-main-title'>
																								<span className='est-user-reservations-list-main-span-title'>
																									{t(
																										'profile.user_reservations.tabs.title'
																									)}{' '}
																								</span>
																								{item.title}
																							</h4>
																						</Col>
																						<Col
																							xs={24}
																							sm={24}
																							md={8}
																							lg={8}
																							xl={8}>
																							<h4 className='est-user-reservations-list-main-title'>
																								<span className='est-user-reservations-list-main-span-title'>
																									{t(
																										'profile.user_reservations.tabs.applicant'
																									)}{' '}
																								</span>
																								{item.nombre}{' '}
																								{item.apellido}
																							</h4>
																						</Col>
																						<Col
																							xs={24}
																							sm={24}
																							md={8}
																							lg={8}
																							xl={8}>
																							<h4 className='est-user-reservations-list-main-title'>
																								<span className='est-user-reservations-list-main-span-title'>
																									{t(
																										'profile.user_reservations.tabs.number_days'
																									)}{' '}
																								</span>
																								{item.cantidad_dias}
																							</h4>
																						</Col>
																						<Col
																							xs={24}
																							sm={24}
																							md={8}
																							lg={8}
																							xl={8}>
																							<h4 className='est-user-reservations-list-main-title'>
																								<span className='est-user-reservations-list-main-span-title'>
																									{t(
																										'profile.user_reservations.tabs.amount_people'
																									)}{' '}
																								</span>
																								{item.cantidad_personas}
																							</h4>
																						</Col>
																						<Col
																							xs={24}
																							sm={24}
																							md={8}
																							lg={8}
																							xl={8}>
																							<h4 className='est-user-reservations-list-main-title'>
																								<span className='est-user-reservations-list-main-span-title'>
																									{t(
																										'profile.user_reservations.tabs.start_date'
																									)}{' '}
																								</span>
																								{item.fecha_desde}
																							</h4>
																						</Col>
																						<Col
																							xs={24}
																							sm={24}
																							md={8}
																							lg={8}
																							xl={8}>
																							<h4 className='est-user-reservations-list-main-title'>
																								<span className='est-user-reservations-list-main-span-title'>
																									{t(
																										'profile.user_reservations.tabs.end_date'
																									)}{' '}
																								</span>
																								{item.fecha_hasta}
																							</h4>
																						</Col>
																						<Col
																							xs={24}
																							sm={24}
																							md={8}
																							lg={8}
																							xl={8}>
																							<h4 className='est-user-reservations-list-main-title'>
																								<span className='est-user-reservations-list-main-span-title'>
																									Subtotal:{' '}
																								</span>
																								${item.precio}
																							</h4>
																						</Col>
																						<Col
																							xs={24}
																							sm={24}
																							md={8}
																							lg={8}
																							xl={8}>
																							<h4 className='est-user-reservations-list-main-title'>
																								<span className='est-user-reservations-list-main-span-title'>
																									{t(
																										'profile.user_reservations.tabs.taxes'
																									)}{' '}
																								</span>
																								${item.impuesto}
																							</h4>
																						</Col>
																						<Col
																							xs={24}
																							sm={24}
																							md={8}
																							lg={8}
																							xl={8}>
																							<h4 className='est-user-reservations-list-main-title'>
																								<span className='est-user-reservations-list-main-span-title'>
																									Total:{' '}
																								</span>
																								${item.total}
																							</h4>
																						</Col>
																						{item.calificado === '0' && (
																							<>
																								{handleReviewsStartDate(
																									item.fecha_hasta
																								) <= 0 &&
																									item.pagado === '1' && (
																										<Col span={24}>
																											<Reviews
																												data={{
																													id_anoucement:
																														item.id_anuncio,
																													id_user:
																														item.id_user,
																													id_reservation:
																														item.id,
																												}}
																											/>
																										</Col>
																									)}
																							</>
																						)}
																						<Col span={24}>
																							{item.pagado === '1' && (
																								<div className='est-user-reservations-payment-done'>
																									{t(
																										'profile.user_reservations.tabs.payment_success'
																									)}
																								</div>
																							)}
																							{/* 	{item.pagado === '0' &&
																								handleReviewsStartDate(
																									item.fecha_hasta
																								) >= 0 && (
																									<Button
																										loading={
																											isPaymentLoading
																										}
																										className='est-user-publications-list-reservations-button'
																										onClick={() =>
																											handleOpenModalPayment(
																												item.rowid
																											)
																										}>
																										{t(
																											'profile.user_reservations.tabs.payment_button'
																										)}
																									</Button>
																								)} */}
																						</Col>
																						<Col
																							span={24}
																							className='est-user-publications-list-reservations-button-container'>
																							<Button
																								className='est-user-publications-list-reservations-button'
																								loading={isUpdateLoading}
																								onClick={() =>
																									handleUpdateReservation(
																										'0',
																										item.id
																									)
																								}>
																								{t(
																									'profile.general_reservations.button_pending_3'
																								)}
																							</Button>
																							<Button
																								className='est-user-publications-list-reservations-button'
																								loading={isUpdateLoading}
																								onClick={() =>
																									handleUpdateReservation(
																										'2',
																										item.id
																									)
																								}>
																								{t(
																									'profile.general_reservations.button_pending_4'
																								)}
																							</Button>
																						</Col>
																					</Row>
																				</div>
																			)
																		)}
																	</>
																) : (
																	<h3 className='est-user-reservations-list-tab-main-subtitle'>
																		{t('profile.user_reservations.tabs.subtitle')}
																	</h3>
																)}
															</TabPane>
															<TabPane
																tab={t(
																	'profile.user_reservations.tabs.cancel_title'
																)}
																key='3'>
																{isUserReservations.userReservationCancel.length >
																0 ? (
																	<>
																		{isUserReservations.userReservationCancel.map(
																			(item, index) => (
																				<div
																					key={index}
																					className='est-user-reservations-list-main-container'>
																					<Row>
																						<Col
																							xs={24}
																							sm={24}
																							md={8}
																							lg={8}
																							xl={8}>
																							<h4 className='est-user-reservations-list-main-title'>
																								<span className='est-user-reservations-list-main-span-title'>
																									ID de reservación
																								</span>
																								{item.id}
																							</h4>
																						</Col>
																						<Col
																							xs={24}
																							sm={24}
																							md={8}
																							lg={8}
																							xl={8}>
																							<h4 className='est-user-reservations-list-main-title'>
																								<span className='est-user-reservations-list-main-span-title'>
																									{t(
																										'profile.user_reservations.tabs.id'
																									)}{' '}
																								</span>
																								{item.id_anuncio}
																							</h4>
																						</Col>
																						<Col
																							xs={24}
																							sm={24}
																							md={8}
																							lg={8}
																							xl={8}>
																							<h4 className='est-user-reservations-list-main-title'>
																								<span className='est-user-reservations-list-main-span-title'>
																									{t(
																										'profile.user_reservations.tabs.title'
																									)}{' '}
																								</span>
																								{item.title}
																							</h4>
																						</Col>
																						<Col
																							xs={24}
																							sm={24}
																							md={8}
																							lg={8}
																							xl={8}>
																							<h4 className='est-user-reservations-list-main-title'>
																								<span className='est-user-reservations-list-main-span-title'>
																									{t(
																										'profile.user_reservations.tabs.applicant'
																									)}{' '}
																								</span>
																								{item.nombre}{' '}
																								{item.apellido}
																							</h4>
																						</Col>
																						<Col
																							xs={24}
																							sm={24}
																							md={8}
																							lg={8}
																							xl={8}>
																							<h4 className='est-user-reservations-list-main-title'>
																								<span className='est-user-reservations-list-main-span-title'>
																									{t(
																										'profile.user_reservations.tabs.number_days'
																									)}{' '}
																								</span>
																								{item.cantidad_dias}
																							</h4>
																						</Col>
																						<Col
																							xs={24}
																							sm={24}
																							md={8}
																							lg={8}
																							xl={8}>
																							<h4 className='est-user-reservations-list-main-title'>
																								<span className='est-user-reservations-list-main-span-title'>
																									{t(
																										'profile.user_reservations.tabs.amount_people'
																									)}{' '}
																								</span>
																								{item.cantidad_personas}
																							</h4>
																						</Col>
																						<Col
																							xs={24}
																							sm={24}
																							md={8}
																							lg={8}
																							xl={8}>
																							<h4 className='est-user-reservations-list-main-title'>
																								<span className='est-user-reservations-list-main-span-title'>
																									{t(
																										'profile.user_reservations.tabs.start_date'
																									)}{' '}
																								</span>
																								{item.fecha_desde}
																							</h4>
																						</Col>
																						<Col
																							xs={24}
																							sm={24}
																							md={8}
																							lg={8}
																							xl={8}>
																							<h4 className='est-user-reservations-list-main-title'>
																								<span className='est-user-reservations-list-main-span-title'>
																									{t(
																										'profile.user_reservations.tabs.end_date'
																									)}{' '}
																								</span>
																								{item.fecha_hasta}
																							</h4>
																						</Col>
																						<Col
																							xs={24}
																							sm={24}
																							md={8}
																							lg={8}
																							xl={8}>
																							<h4 className='est-user-reservations-list-main-title'>
																								<span className='est-user-reservations-list-main-span-title'>
																									Subtotal:{' '}
																								</span>
																								${item.precio}
																							</h4>
																						</Col>
																						<Col
																							xs={24}
																							sm={24}
																							md={8}
																							lg={8}
																							xl={8}>
																							<h4 className='est-user-reservations-list-main-title'>
																								<span className='est-user-reservations-list-main-span-title'>
																									{t(
																										'profile.user_reservations.tabs.taxes'
																									)}{' '}
																								</span>
																								${item.impuesto}
																							</h4>
																						</Col>
																						<Col
																							xs={24}
																							sm={24}
																							md={8}
																							lg={8}
																							xl={8}>
																							<h4 className='est-user-reservations-list-main-title'>
																								<span className='est-user-reservations-list-main-span-title'>
																									Total:{' '}
																								</span>
																								${item.total}
																							</h4>
																						</Col>
																						<Col
																							span={24}
																							className='est-user-publications-list-reservations-button-container'>
																							<Button
																								className='est-user-publications-list-reservations-button'
																								loading={isUpdateLoading}
																								onClick={() =>
																									handleUpdateReservation(
																										'0',
																										item.id
																									)
																								}>
																								{t(
																									'profile.general_reservations.button_pending_5'
																								)}
																							</Button>
																							<Button
																								className='est-user-publications-list-reservations-button'
																								loading={isUpdateLoading}
																								onClick={() =>
																									handleUpdateReservation(
																										'1',
																										item.id
																									)
																								}>
																								{t(
																									'profile.general_reservations.button_pending_6'
																								)}
																							</Button>
																						</Col>
																					</Row>
																				</div>
																			)
																		)}
																	</>
																) : (
																	<h3 className='est-user-reservations-list-tab-main-subtitle'>
																		{t('profile.user_reservations.tabs.subtitle')}
																	</h3>
																)}
															</TabPane>
														</Tabs>
													</div>
												) : (
													<LoadingData />
												)}
											</>
										) : (
											<h2 className='est-user-profile-reservations-list-main-title'>
												{t('profile.user_reservations.tabs.no_post_title')}{' '}
											</h2>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* 	<Modal
					width={500}
					maskClosable={false}
					closable={isModalClosable}
					wrapClassName='est-profile-reservation-agreement-modal-global-container'
					centered
					title={''}
					visible={isVibleModalContract}
					onCancel={() => handleCloseModalPayment()}
					cancelButtonProps={{ style: { display: 'none' } }}
					okButtonProps={{ style: { display: 'none' } }}
					okText=''
					cancelText=''
					footer={false}>
					<p className='est-profile-reservation-agreement-modal-title'>
						{t('profile.user_reservations.terms_conditions_modal_title')}
					</p>
					<p className='est-profile-reservation-agreement-modal-description'>
						{t('profile.user_reservations.terms_conditions_modal_subtitle_one')}
					</p>
					<div className='est-profile-reservation-agreement-modal-button-container'>
						<Form form={form} name='user_agreement_contract' onFinish={handlePayment}>
							<div className='est-profile-reservation-agreement-modal-check-container'>
								<Form.Item
									name='agreement_check'
									valuePropName='checked'
									rules={[
										{
											validator: (_, value) =>
												value
													? Promise.resolve()
													: Promise.reject(
															t(
																'profile.user_reservations.terms_conditions_modal_rules'
															)
													  ),
										},
									]}>
									<Checkbox></Checkbox>
								</Form.Item>
								<h4 className='est-profile-reservation-agreement-modal-data-agree-title'>
									{t('profile.user_reservations.terms_conditions_modal_subtitle_two')}
									<a
										className='est-profile-reservation-agreement-modal-data-agree-link'
										href='/terms-conditions'
										target='_blank'>
										{' '}
										{t('profile.user_reservations.terms_conditions_modal_subtitle_three')}
									</a>{' '}
									{t('profile.user_reservations.terms_conditions_modal_subtitle_four')}
								</h4>
							</div>
							<Button
								htmlType={'submit'}
								loading={isLoadingModalPaymentButton}
								className='est-profile-reservation-agreement-modal-button'>
								{t('header.modal_log_out.button')}
							</Button>
						</Form>
					</div>
				</Modal> */}
			</>
		)
	}
}
