/** @format */

import React, { useEffect, useState } from 'react'

import { Link } from 'react-router-dom'

import { useTranslation } from 'react-i18next'

import Loading from '../../components/Common/Loading'

import MainNavigation from '../../components/Common/MainNavigation'

import VerifyAccount from './services'

import { CheckCircleOutlined } from '@ant-design/icons'
import { CloseCircleOutlined } from '@ant-design/icons'

import './style.css'

export default function RegisterSuccess(props) {
	const { t } = useTranslation()
	const [isVisible, setVisible] = useState(true)
	const [isSuccess, setSuccess] = useState(null)
	useEffect(() => {
		const servicesSuccessTranslate = {
			service_1_title: t('register_success.success_title'),
			service_1_description: t('register_success.success_subtitle'),
			service_2_title: t('register_success.error_title'),
			service_2_description: t('register_success.error_subtitle'),
			service_global_description: t('global_service.service_global_description'),
		}

		VerifyAccount(props.match.params.id, servicesSuccessTranslate).then((response) => {
			if (response) {
				setSuccess(response)
				if (!response) {
					setVisible(false)
				}
			} else {
				setVisible(false)
			}
		})
	}, [props.match.params.id, t])
	if (isVisible) {
		if (!isSuccess) {
			return <Loading />
		} else {
			return (
				<>
					<MainNavigation
						title={t('register_success.main_navigation.title')}
						linkPrevTitle={'Home'}
						linkNextTitle={t('register_success.main_navigation.title')}
						linkPrev={'/'}
						linkNext={'/register_success'}
					/>
					<div className='est-register-success-global-container'>
						<div className='est-register-success-main-container'>
							<h3 className='est-register-success-icon'>
								<CheckCircleOutlined />
							</h3>
							<h3 className='est-register-success-title'>
								{t('register_success.success_title')}
							</h3>
							<h3 className='est-register-success-subtitle'>
								{t('register_success.success_subtitle')}
							</h3>

							<div className='est-register-success-button-container'>
								<Link to='/' className='est-register-success-button'>
									{t('register_success.button')}
								</Link>
							</div>
						</div>
					</div>
				</>
			)
		}
	} else {
		return (
			<div className='est-register-success-global-container'>
				<div className='est-register-success-main-container'>
					<h3 className='est-register-success-icon'>
						<CloseCircleOutlined />
					</h3>
					<h3 className='est-register-success-title'>{t('register_success.error_title')}</h3>
					<h3 className='est-register-success-subtitle'>
						{t('register_success.error_subtitle')}
					</h3>

					<div className='est-register-success-button-container'>
						<Link to='/' className='est-register-success-button'>
							{t('register_success.button')}
						</Link>
					</div>
				</div>
			</div>
		)
	}
}
