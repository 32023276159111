/** @format */

import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import Layout from '../components/Layout/'

import Init from '../pages/home'
import Verify from '../pages/register-verify'
import Success from '../pages/register-success'
import AdsBasicInfo from '../pages/publication/create/Pages/basic-info'
import AdsLocation from '../pages/publication/create/Pages/location'
import AdsPhotos from '../pages/publication/create/Pages/photos'
import AdsAmenities from '../pages/publication/create/Pages/amenities'
import AdsSuccess from '../pages/publication/create/Pages/success'
import AdsError from '../pages/publication/create/Pages/error'
import AdsDetail from '../pages/publication/detail'
import Profile from '../pages/profile/pages/user-data'
import PaymentForm from '../pages/profile/pages/payment'
import Metrics from '../pages/profile/pages/data-metrics'
import UserPublications from '../pages/profile/pages/user-publications'
import UserReservations from '../pages/profile/pages/user-reservations'
import NotFound from '../pages/not-found'
import Blog from '../pages/blog'
import BlogDetail from '../pages/blog-detail'
import ArticleOne from '../pages/blog-detail/components/ArticleOne'
import ReservationSuccess from '../pages/reservation-success'
import TermConditions from '../pages/terms-conditions'
import ReviewSend from '../pages/review-send'

import PrivateLogin from './components/PrivateLogin'

import Test from '../pages/test'
import Search from '../pages/search'
import Categories from '../pages/categories'
import CategoriesID from '../pages/categories'
import ArticleTwo from '../pages/blog-detail/components/ArticleTwo'
import ArticleThree from '../pages/blog-detail/components/ArticleThree'
import ArticleFour from '../pages/blog-detail/components/ArticleFour'
import ArticleFive from '../pages/blog-detail/components/ArticleFive'

import GeneralUsers from '../pages/profile/pages/general-users'
import GeneralAnnouncements from '../pages/profile/pages/general-publications'
import GeneralReservations from '../pages/profile/pages/general-reservations'
import ManageBlog from '../pages/profile/pages/manage-blog'

const Routers = () => (
	<BrowserRouter>
		<Layout>
			<Switch>
				<Route exact path='/' component={Init} />
				<Route exact path='/register-verify' component={Verify} />
				<Route exact path='/publication/detail/:id' component={AdsDetail} />
				<Route path='/auth/register-sucess/:id' component={Success} />
				<Route exact path='/test' component={Test} />
				<Route exact path='/views/:views?/:id?' component={Search} />
				<Route exact path='/categories/:categories?' component={Categories} />
				<Route exact path='/search/:id?' component={Search} />
				<Route exact path='/search/:categories?/:views?/:id?' component={Search} />

				<Route exact path='/categories/:categories/:id' component={CategoriesID} />
				<Route exact path='/blog' component={Blog} />
				<Route exact path='/blog-detail/:id' component={BlogDetail} />
				<Route exact path='/terms-conditions' component={TermConditions} />
				<Route exact path='/blog/article-one' component={ArticleOne} />
				<Route exact path='/blog/article-two' component={ArticleTwo} />
				<Route exact path='/blog/article-three' component={ArticleThree} />
				<Route exact path='/blog/article-four' component={ArticleFour} />
				<Route exact path='/blog/article-five' component={ArticleFive} />
				<Route exact path='/reviews/send/:token' component={ReviewSend} />
				<Route exact path='/reservation-success' component={ReservationSuccess} />
				<Route exact path='/profile/payment/:id' component={PaymentForm} />
				<PrivateLogin exact path='/profile/user-data' component={Profile} />
				<PrivateLogin exact path='/profile/user-publications' component={UserPublications} />
				<PrivateLogin exact path='/profile/user-reservations' component={UserReservations} />
				<PrivateLogin exact path='/profile/metrics' component={Metrics} />
				<PrivateLogin exact path='/publication/create/basic-info' component={AdsBasicInfo} />
				<PrivateLogin exact path='/publication/create/location' component={AdsLocation} />
				<PrivateLogin exact path='/publication/create/photos' component={AdsPhotos} />
				<PrivateLogin exact path='/publication/create/amenities' component={AdsAmenities} />
				<PrivateLogin exact path='/publication/create/success' component={AdsSuccess} />
				<PrivateLogin exact path='/publication/create/error' component={AdsError} />

				<PrivateLogin exact path='/profile/general-users' component={GeneralUsers} />
				<PrivateLogin
					exact
					path='/profile/general-publications'
					component={GeneralAnnouncements}
				/>
				<PrivateLogin
					exact
					path='/profile/general-reservations'
					component={GeneralReservations}
				/>
				<PrivateLogin exact path='/profile/manage-blog' component={ManageBlog} />
				<Route path='*' component={NotFound} />
			</Switch>
		</Layout>
	</BrowserRouter>
)

export default Routers
