/** @format */

import React, { useState } from 'react'
import AwesomeSwiper from 'react-awesome-swiper'

import Modal from 'antd/lib/modal'

import Image from '../Image'

import './style.css'

export default function CarouselGallery(props) {
	const [isModalVisible, setIsModalVisible] = useState(false)
	const [isPreviewImg, setPreviewImg] = useState('')
	const [isOptions] = useState({
		spaceBetween: 0,
		loop: false,
		autoplay: false,
		preloadImages: false,
		lazy: true,
		speed: 500,
		navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev',
		},
		breakpoints: {
			1024: {
				slidesPerView: props.carouselItems[0],
				spaceBetween: 0,
			},
			768: {
				slidesPerView: props.carouselItems[1],
				spaceBetween: 10,
			},
			640: {
				slidesPerView: props.carouselItems[2],
				spaceBetween: 0,
			},
			575: {
				slidesPerView: props.carouselItems[3],
				spaceBetween: 0,
			},
			320: {
				slidesPerView: props.carouselItems[4],
				spaceBetween: 0,
			},
			250: {
				slidesPerView: props.carouselItems[5],
				spaceBetween: 0,
			},
		},
	})
	const showModal = (setImg) => {
		setPreviewImg(setImg)
		setIsModalVisible(true)
	}
	const handleOk = () => {
		setIsModalVisible(false)
	}
	const handleCancel = () => {
		setIsModalVisible(false)
	}

	return (
		<>
			<AwesomeSwiper ref={(ref) => ref} config={isOptions}>
				<div className='swiper-wrapper'>
					{props.banners.map(function (item, i) {
						return (
							<div
								className='est-carousel-gallery swiper-slide'
								key={i}
								onClick={() => showModal(item.image)}>
								<Image
									classImg={'est-banner-image'}
									image={item.image}
									//alt={item.tipo}
									//title={item.tipo}
								/>
							</div>
						)
					})}
				</div>
				<div className='swiper-button-prev ph-carousel-prev'></div>
				<div className='swiper-button-next ph-carousel-next'></div>
			</AwesomeSwiper>

			<Modal
				width={992}
				wrapClassName='est-publication-detail-modal-img'
				title='Vista previa'
				visible={isModalVisible}
				onOk={handleOk}
				onCancel={handleCancel}
				footer={null}>
				{isPreviewImg && <img alt='example' style={{ width: '100%' }} src={isPreviewImg} />}
			</Modal>
		</>
	)
}
