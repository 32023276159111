/** @format */
import React, { useState, useEffect } from 'react'

import { useTranslation } from 'react-i18next'

import { useGlobal, setGlobal } from 'reactn'

//import FacebookLogin from 'react-facebook-login'
import GoogleLogin from 'react-google-login'

import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import Modal from 'antd/lib/modal'
import Form from 'antd/lib/form'
import Button from 'antd/lib/button'

import Input from '../../../Inputs/Normal'

//import { ENV_KEY_GOOGLE, ENV_API_FACEBOOK } from '../../../Hooks/Variables/Enviroment'

import { ENV_KEY_GOOGLE } from '../../../Hooks/Variables/Enviroment'

import servicesLogin from './services'

import Image from '../../../Image'
import GoogleLoginImg from '../../../../../img/login/LogoGoogle.png'
//import FacebookLoginImg from '../../../../../img/login/LogoFb.png'

import './style.css'

export default function ModalLoginUser() {
	const { t } = useTranslation()
	const [form] = Form.useForm()
	const [isModalUser, setModalUser] = useGlobal('ModalUser')
	const [isUserLogin] = useGlobal('UserLogin')
	const [isUserRegister] = useGlobal('UserRegister')
	const [isUserRecover] = useGlobal('UserRecover')
	const [isLoading, setLoading] = useState(false)
	const [setLoginGoogle] = useState(false)
	const [setLoginFacebook] = useState(false)
	const [isSocialLogin, setSocialLogin] = useState('null')

	const handleLoginUser = async (item) => {
		if (isSocialLogin === 'google') {
			//desde aqui no me funciona cuando actualizo el isSocialLogin
			setLoginGoogle(true)

			const traduceGoogle = {
				service_login_google_success_message: t(
					'header.modal_user.service_login_google_success_message'
				),
				service_login_google_success_description: t(
					'header.modal_user.service_login_google_success_description'
				),
				service_global_description: t('global_service.service_global_description'),
			}

			await servicesLogin.LoginGoogle(item, traduceGoogle).then((response) => {
				if (response) {
					setLoginGoogle(false)
					handleCloseModal()
				}
			})
		} else if (isSocialLogin === 'facebook') {
			setLoginFacebook(true)

			const traduceFacebook = {
				service_login_fb_success_message: t(
					'header.modal_user.service_login_fb_success_message'
				),
				service_login_fb_success_description: t(
					'header.modal_user.service_login_fb_success_description'
				),
				service_global_description: t('global_service.service_global_description'),
			}
			await servicesLogin.LoginFacebook(item, traduceFacebook).then((response) => {
				if (response) {
					setLoginFacebook(false)
					handleCloseModal()
				}
			})
		} else {
			setLoading(true)
			if (isUserLogin) {
				const traduceOne = {
					service_login_success_message: t('header.modal_user.service_login_success_message'),
					service_login_success_description: t(
						'header.modal_user.service_login_success_description'
					),
					service_login_error_message: t('header.modal_user.service_login_error_message'),
					service_login_error_description: t(
						'header.modal_user.service_login_error_description'
					),
					service_global_description: t('global_service.service_global_description'),
				}
				await servicesLogin.Login(item, traduceOne).then((response) => {
					if (response) {
						setLoading(false)
						handleCloseModal()
					}
				})
				setLoading(false)
			} else if (isUserRegister) {
				const traduceTwo = {
					service_register_success_message: t(
						'header.modal_user.service_register_success_message'
					),
					service_register_error_message: t(
						'header.modal_user.service_register_error_message'
					),
					service_global_description: t('global_service.service_global_description'),
				}
				await servicesLogin.Register(item, traduceTwo).then((response) => {
					setLoading(false)
					handleCloseModal()
					if (response) {
						window.location.href = '/register-verify'
					}
				})
			} else if (isUserRecover) {
				const traduceThree = {
					service_recover_success_message: t(
						'header.modal_user.service_register_success_message'
					),
					service_recover_success_description: t(
						'header.modal_user.service_register_error_message'
					),
					service_recover_warning_message: t(
						'header.modal_user.service_register_success_message'
					),
					service_recover_warning_description: t(
						'header.modal_user.service_register_success_message'
					),
					service_global_description: t('global_service.service_global_description'),
				}
				await servicesLogin.Recover(item, traduceThree).then((response) => {
					setLoading(false)
					handleCloseModal()
					if (response) {
						window.location.href = '/register-verify'
					}
				})
			}
		}
	}

	const handleCloseModal = () => {
		setModalUser(false)
		setGlobal({
			UserLogin: false,
			UserRegister: false,
			UserRecover: false,
		})
	}

	const handleActionsModalUser = (item) => {
		if (item === 'login') {
			setGlobal({
				UserLogin: true,
				UserRegister: false,
				UserRecover: false,
			})
		} else if (item === 'register') {
			setGlobal({
				UserLogin: false,
				UserRegister: true,
				UserRecover: false,
			})
		} else if (item === 'recover') {
			setGlobal({
				UserLogin: false,
				UserRegister: false,
				UserRecover: true,
			})
		}
	}

	const handleResponseGoogle = async (response) => {
		const traduceGoogle = {
			service_login_google_success_message: t(
				'header.modal_user.service_login_google_success_message'
			),
			service_login_google_success_description: t(
				'header.modal_user.service_login_google_success_description'
			),
			service_global_description: t('global_service.service_global_description'),
		}
		setSocialLogin('google')
		if (response.profileObj !== undefined) {
			await servicesLogin.LoginGoogle(response.profileObj, traduceGoogle).then((response) => {
				if (response) {
					handleCloseModal()
				}
			})
		}
	}

	/* 	const handleResponsefacebook = async (response) => {
		if (response.status !== 'unknown') {
			setSocialLogin('facebook')
			await servicesLogin.LoginFacebook(response).then((response) => {
				if (response) {
					handleCloseModal()
				}
			})
		}
	} */

	useEffect(() => {
		form.resetFields()
	}, [form, isModalUser])

	return (
		<>
			<Modal
				forceRender
				wrapClassName='est-user-login-modal-global-container'
				maskClosable={true}
				width='700px'
				centered
				visible={isModalUser}
				onCancel={() => handleCloseModal()}
				okText='Confirmar'
				cancelButtonProps={{ style: { display: 'none' } }}
				okButtonProps={{ style: { display: 'none' } }}>
				{isUserLogin && (
					<h3 className='est-user-login-modal-title'>{t('header.modal_user.user_login')}</h3>
				)}
				{isUserRegister && (
					<h3 className='est-user-login-modal-title'>
						{t('header.modal_user.user_register')}
					</h3>
				)}
				{isUserRecover && (
					<h3 className='est-user-login-modal-title'>{t('header.modal_user.user_recover')}</h3>
				)}
				<Form
					form={form}
					initialValues={{
						first_name: '',
						last_name: '',
						email: '',
						confirmEmail: '',
						password: '',
						confirm: '',
					}}
					name='user_login'
					onFinish={handleLoginUser}>
					<div className='est-auth-login-form-container'>
						{isUserRegister && (
							<>
								<Input
									className={'est-auth-register-field-input'}
									inputName={'first_name'}
									inputNameLabel={t('header.modal_user.input_name')}
									inputNameRule={true}
									inputNameMessage={t('header.modal_user.placeholder_name')}
									inputNameType={'text'}
									inputNameIcon={''}
									inputNameRules={t('field_notifications.normal.rules_first_name')}
								/>
								<Input
									className={'est-auth-register-field-input'}
									inputName={'last_name'}
									inputNameLabel={t('header.modal_user.input_last_name')}
									inputNameRule={true}
									inputNameMessage={t('header.modal_user.placeholder_last_name')}
									inputNameType={'text'}
									inputNameIcon={''}
									inputNameRules={t('field_notifications.normal.rules_last_name')}
								/>
							</>
						)}
						<Input
							className={'est-auth-login-field-input'}
							inputName={'email'}
							inputNameLabel={t('header.modal_user.input_email')}
							inputNameRule={true}
							inputNameMessage={t('header.modal_user.placeholder_email')}
							inputNameType={'text'}
							inputNameIcon={''}
							inputNameRules={t('field_notifications.normal.rules_email')}
						/>
						{isUserRegister && (
							<Input
								className={'est-auth-register-field-input'}
								inputName={'confirmEmail'}
								inputNameLabel={t('header.modal_user.input_email_confirm')}
								inputNameRule={true}
								inputNameMessage={t('header.modal_user.placeholder_email_confirm')}
								inputNameType={'text'}
								inputNameIcon={''}
								inputNameRules={t('field_notifications.normal.rules_confirm_email')}
							/>
						)}
						{(isUserRegister || isUserLogin) && (
							<Input
								className={'est-auth-login-field-input'}
								inputName={'password'}
								inputNameLabel={t('header.modal_user.input_password')}
								inputNameRule={true}
								inputNameMessage={t('header.modal_user.placeholder_password')}
								inputNameType={'password'}
								inputNameIcon={''}
								inputNameRules={t('field_notifications.normal.rules_password')}
							/>
						)}
						{isUserRegister && (
							<Input
								className={'est-auth-register-field-input'}
								inputName={'confirm'}
								inputNameLabel={t('header.modal_user.input_password_confirm')}
								inputNameRule={true}
								inputNameMessage={t('header.modal_user.placeholder_password_confirm')}
								inputNameType={'password'}
								inputNameIcon={''}
								dependencies={['password']}
								hasFeedback
								inputNameRules={t('field_notifications.normal.rules_confirm_password')}
							/>
						)}
					</div>
					<Form.Item>
						<div>
							<div className='est-user-login-modal-button-container'>
								<Button
									onClick={() => setSocialLogin(null)}
									className='est-user-login-modal-button'
									type='primary'
									htmlType={'submit'}
									loading={isLoading}>
									{isUserRecover && t('header.modal_user.button_recover')}
									{isUserRegister && t('header.modal_user.button_register')}
									{isUserLogin && t('header.modal_user.button_login')}
								</Button>
							</div>

							{(isUserRegister || isUserLogin) && (
								<Row className='est-user-login-modal-social-button-container'>
									<Col
										xs={24}
										sm={24}
										md={24}
										lg={24}
										xl={24}
										className='est-modal-user-social-button-global-container'>
										<div className='est-modal-user-social-g-button-main-container'>
											<div className='est-modal-user-social-button-img-container'>
												<Image
													classImg={'est-modal-user-social-button-img'}
													image={GoogleLoginImg}
													alt={'Profile photo'}
													title={'Profile photo'}
												/>
											</div>

											<GoogleLogin
												clientId={`${ENV_KEY_GOOGLE}`}
												onSuccess={handleResponseGoogle}
												onFailure={handleResponseGoogle}
												className='est-modal-user-social-g-button'
												icon={false}>
												<span>{t('header.modal_user.button_google')}</span>
											</GoogleLogin>
										</div>
									</Col>
									{/* 	<Col
										xs={24}
										sm={24}
										md={12}
										lg={12}
										xl={12}
										className='est-modal-user-social-button-global-container'>
										<div className='est-modal-user-social-fb-button-main-container'>
											<div className='est-modal-user-social-button-img-container'>
												<Image
													classImg={'est-modal-user-social-button-img'}
													image={FacebookLoginImg}
													alt={'Profile photo'}
													title={'Profile photo'}
												/>
											</div>

											<FacebookLogin
												appId={`${ENV_API_FACEBOOK}`}
												autoLoad={false}
												fields='name,email,picture'
												textButton={t('header.modal_user.button_fb')}
												cssClass='est-modal-user-social-fb-button'
												callback={handleResponsefacebook}
											/>
										</div>
									</Col> */}
								</Row>
							)}

							<div className='est-user-login-modal-actions-global-container'>
								{!isUserRecover && (
									<div onClick={() => handleActionsModalUser('recover')}>
										<h3 className='est-user-login-modal-main-title'>
											{t('header.modal_user.forgot_password')}
										</h3>
									</div>
								)}
								{!isUserRegister && (
									<div onClick={() => handleActionsModalUser('register')}>
										<h3 className='est-user-login-modal-main-title'>
											{t('header.modal_user.register')}
										</h3>
									</div>
								)}
								{!isUserLogin && (
									<div onClick={() => handleActionsModalUser('login')}>
										<h3 className='est-user-login-modal-main-title'>
											{t('header.modal_user.login')}
										</h3>
									</div>
								)}
							</div>
						</div>
					</Form.Item>
				</Form>
			</Modal>
		</>
	)
}
