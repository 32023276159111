/** @format */
import React from 'react'

import MainNavigation from '../../components/Common/MainNavigation'
import HeadDescription from '../../components/Common/HeadDescription'
import Algolia from '../../components/Common/Algolia'

import { useTranslation } from 'react-i18next'

import './style.css'

export default function SearchResults(props) {
	const { t } = useTranslation()
	return (
		<>
			<HeadDescription title={`Prujula -`} name={'description'} content={'Camping Site'} />
			<MainNavigation
				title={`${t('algolia.main_navigation.title')} : ${
					props.match.params.id ? props.match.params.id : ''
				}`}
				linkPrevTitle={'Home'}
				linkNextTitle={t('algolia.main_navigation.subtitle')}
				linkPrev={'/'}
				linkNext={'/'}
			/>
			<Algolia params={props.match.params} />
		</>
	)
}
