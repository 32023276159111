/** @format */

import React, { useState } from 'react'

import { usePlacesWidget } from 'react-google-autocomplete'

import { useTranslation } from 'react-i18next'

import Form from 'antd/lib/form'
import Button from 'antd/lib/button'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import Select from 'antd/lib/select'
//import notification from 'antd/lib/notification'

import Input from '../../../../components/Common/Inputs/Normal'

import { ENV_API_GOOGLE } from '../../../../components/Common/Hooks/Variables/Enviroment'

import './style.css'

export default function MainSearch(props) {
	const { t } = useTranslation()
	const [isLocationGoogle, setLocationGoogle] = useState('')
	const { Option } = Select

	const { ref } = usePlacesWidget({
		apiKey: ENV_API_GOOGLE,
		options: {
			componentRestrictions: { country: 'pr' },
			types: ['address'],
		},

		onPlaceSelected: (place) => {
			setLocationGoogle(place.formatted_address)
		},
	})

	const handleSearchProduct = async (item) => {
		if (!item.title && !item.category && !isLocationGoogle && !item.type_view) {
			window.location.href = `/search`
		} else if (item.title && !item.category && !isLocationGoogle && !item.type_view) {
			window.location.href = `/search/${item.title}`
		} else if (!item.title && item.category && !isLocationGoogle && !item.type_view) {
			window.location.href = `/categories/${item.category}`
		} else if (!item.title && !item.category && isLocationGoogle && !item.type_view) {
			window.location.href = `/search/${isLocationGoogle}`
		} else if (item.title && !item.category && isLocationGoogle && !item.type_view) {
			window.location.href = `/search/${item.title + ' ' + isLocationGoogle}`
		} else if (!item.title && item.category && isLocationGoogle && !item.type_view) {
			window.location.href = `/categories/${item.category}/${isLocationGoogle}`
		} else if (item.title && item.category && !isLocationGoogle && !item.type_view) {
			window.location.href = `/categories/${item.category}/${item.title}`
		} else if (item.title && item.category && isLocationGoogle && !item.type_view) {
			window.location.href = `/categories/${item.category}/${
				item.title + ' ' + isLocationGoogle
			}`
		} else if (!item.title && !item.category && !isLocationGoogle && item.type_view) {
			window.location.href = `/views/${item.type_view}`
		} else if (item.title && !item.category && !isLocationGoogle && item.type_view) {
			window.location.href = `/views/${item.type_view}/${item.title}`
		} else if (!item.title && item.category && !isLocationGoogle && item.type_view) {
			window.location.href = `/search/${item.category}/${item.type_view}`
		} else if (!item.title && !item.category && isLocationGoogle && item.type_view) {
			window.location.href = `/views/${item.type_view}/${isLocationGoogle}`
		} else if (item.title && !item.category && isLocationGoogle && item.type_view) {
			window.location.href = `/views/${item.type_view}/${item.title + ' ' + isLocationGoogle}`
		} else if (!item.title && item.category && isLocationGoogle && item.type_view) {
			window.location.href = `/search/${item.category}/${item.type_view}/${isLocationGoogle}`
		} else if (item.title && item.category && !isLocationGoogle && item.type_view) {
			window.location.href = `/search/${item.category}/${item.type_view}/${item.title}`
		} else if (item.title && item.category && isLocationGoogle && item.type_view) {
			window.location.href = `/search/${item.category}/ ${item.type_view}/${
				item.title + ' ' + isLocationGoogle
			}`
		}
	}

	return (
		<Form
			className='est-main-search-global-container'
			name='main_search'
			onFinish={handleSearchProduct}>
			<h3 className='est-main-search-main-title'>{t('home.main_search.title')}</h3>
			<Row>
				<Col className='est-main-search-input-container' xs={24} sm={24} md={6} lg={6} xl={6}>
					<Form.Item name='category'>
						<Select
							placeholder={t('home.main_search.input_category')}
							size='large'
							style={{ width: '100%' }}
							allowClear={true}>
							{props.isCategories.category.map((item, index) => (
								<Option value={item.nombre} key={index}>
									{item.nombre}
								</Option>
							))}
						</Select>
					</Form.Item>
				</Col>
				<Col className='est-main-search-input-container' xs={24} sm={24} md={6} lg={6} xl={6}>
					<Form.Item name='type_view'>
						<Select
							placeholder={t('home.main_search.input_view')}
							size='large'
							style={{ width: '100%' }}
							allowClear={true}>
							{props.isCategories.type_view.map((item, index) => (
								<Option value={item.nombre} key={index}>
									{item.nombre}
								</Option>
							))}
						</Select>
					</Form.Item>
				</Col>
				<Col className='est-main-search-input-container' xs={24} sm={24} md={6} lg={6} xl={6}>
					<input
						className='est-main-search-location-input'
						ref={ref}
						onChange={(event) => setLocationGoogle(event.target.value)}
						placeholder={t('home.main_search.input_search')}
					/>
				</Col>
				<Col className='est-main-search-input-container' xs={24} sm={24} md={6} lg={6} xl={6}>
					<Input
						className={'est-auth-login-field-input'}
						inputName={'title'}
						inputNameLabel={t('home.main_search.input_keyword')}
						inputNameRule={true}
						inputNameType={'text'}
						inputNameIcon={''}
					/>
				</Col>
			</Row>
			<Row>
				<Col className='est-main-search-input-button-container' span={24}>
					<Button className='est-main-search-input-button' htmlType={'submit'}>
						{t('home.main_search.button')}
					</Button>
				</Col>
			</Row>
		</Form>
	)
}
