/** @format */

import React from 'react'

import { Link } from 'react-router-dom'

import { useTranslation } from 'react-i18next'

import HeadDescription from '../../../../components/Common/HeadDescription'

import MainNavigation from '../../../../components/Common/MainNavigation'

import Carrousel from './components/Carrousel'

import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
//import Form from 'antd/lib/form'
//import Button from 'antd/lib/button'
//import Input from 'antd/lib/input'

//import InputCustom from '../../../../components/Common/Inputs/Normal'

import {
	FolderOutlined,
	WechatOutlined,
	HeartOutlined,
	//FacebookFilled,
	//TwitterSquareFilled,
	//InstagramOutlined,
	PlusCircleFilled,
} from '@ant-design/icons'

//import RightSearch from '../RightSearch'
import Image from '../../../../components/Common/Image'
import ArticleOneMain from '../../../../img/blog/articulo1/principal.jpg'
import ArticleTwoMain from '../../../../img/blog/articulo2.jpg'
import ArticleThreeMain from '../../../../img/blog/articulo3.jpg'
import ArticleFourMain from '../../../../img/blog/articulo4.jpg'

//import CommentProfilePhoto from '../../../../../src/img/detail/profile-example.png'

import './style.css'

export default function ArticleOne() {
	const { t } = useTranslation()
	//const { TextArea } = Input
	return (
		<>
			<HeadDescription
				title={'Prujula - Actículo'}
				name={'description'}
				content={'Camping Site'}
			/>
			<div className='est-blog-article-one-global-container'>
				<MainNavigation
					title={t('blog_detail.main_navigation.title')}
					linkPrevTitle={'Home'}
					linkNextTitle={'Blog'}
					linkPrev={'/'}
					linkNext={'/blog'}
				/>
				<div className='est-blog-article-one-main-container'>
					<Row>
						<Col
							span={24}
							/* xs={24}
							sm={24}
							md={24}
							lg={18}
							xl={18} */
							className='est-blog-article-one-left-global-container'>
							<div className='est-blog-article-one-title-img-container'>
								<h3 className='est-blog-article-one-title'>
									SENDERISMO AL ALCANCE DE TODOS
								</h3>
								<h3 className='est-blog-article-one-subtitle'>Autor: Ricardo Martínez</h3>
								<div className='est-blog-article-one-subtitle-container'>
									<h4 className='est-blog-article-one-category-text'>
										<FolderOutlined /> {t('blog_detail.article_category')}
									</h4>
									<h4 className='est-blog-article-one-category-icon'>
										<WechatOutlined /> 12
									</h4>
									<h4 className='est-blog-article-one-category-icon'>
										<HeartOutlined /> 6
									</h4>
								</div>
								<div className='est-blog-article-one-img-container'>
									<Image
										classImg={'est-blog-main-img'}
										image={ArticleOneMain}
										alt={'Profile photo'}
										title={'Profile photo'}
									/>
									<div className='est-blog-article-one-img-date'>
										<h3 className='est-blog-article-one-img-date-title'>16</h3>
										<h3 className='est-blog-article-one-img-date-subtitle'>07</h3>
									</div>
								</div>
							</div>
							<div className='est-blog-article-one-text-container'>
								<p className='est-blog-article-one-text'>
									Más que nunca me convenzo de lo importante y necesario que es para
									nuestra salud mental y física separar tiempos de esparcimiento en la
									naturaleza. Entiendo que hay una relación clara entre las actividades al
									aire libre y menos visitas al médico. En ese sentido, el senderismo es la
									mejor "pastilla". Y no se diga el efecto positivo en la formación
									personal balanceada de lo niños cuando lo practican con sus padres y
									parientes.
								</p>
								<p className='est-blog-article-one-next-title'>Próximos eventos:</p>
								<p className='est-blog-article-one-subtite-one'>
									PUERTO RICO NATURA -"Outdoors Activities"
								</p>
								<p className='est-blog-article-one-subtite-two'>*****Senderismo****</p>
								<p className='est-blog-article-one-subtite-three'>
									CALENDARIO - AGOSTO - 2021
								</p>
								<ul className='est-blog-article-one-ul'>
									<li className='est-blog-article-one-list-item'>
										Domingo 1<br></br>
										Ruta Playera: Las Piscinas, Palmas - Posa Las Mujeres - Cueva
										Golondrinas - Manatí
									</li>
									<li className='est-blog-article-one-list-item'>
										Sábado 7<br></br>
										La Zanja (playa), Fajardo
									</li>
									<li className='est-blog-article-one-list-item'>
										Domingo 8<br></br>
										Cerro Morales, Utuado
									</li>
									<li className='est-blog-article-one-list-item'>
										Domingo 15<br></br>
										Toro Negro: Torre de Observación y Cerro de Punta - Orocovis / Jayuya
									</li>
									<li className='est-blog-article-one-list-item'>
										Sábado 21<br></br>
										Cueva del Arco, Tanamá, Arecibo
									</li>
									<li className='est-blog-article-one-list-item'>
										Sábado 28<br></br>
										Cerro Mime y Cascada Los Calderos, Orocovis
									</li>
									<li className='est-blog-article-one-list-item'>
										Domingo 29<br></br>
										Ruta Playera: Las Piscinas, Palmas - Posa Las Mujeres - Cueva
										Golondrinas - Manatí
									</li>
								</ul>
								<p className='est-blog-article-one-text'>
									Para ir a otros lugares, entre semana, fuera del calendario, pueden
									comunicarse y acordamos el plan.<br></br>
									Gracias por su apoyo.
								</p>
							</div>
							<div className='est-blog-article-one-carrousel-container'>
								<Carrousel />
							</div>
							<hr className='est-blog-article-one-spacer'></hr>
							{/* <div className='est-blog-article-one-icon-container'>
								<h3 className='est-blog-article-one-rrss'>{t('blog_detail.share')}</h3>
								<h3 className='est-blog-article-one-icon'>
									<FacebookFilled />
								</h3>
								<h3 className='est-blog-article-one-icon'>
									<TwitterSquareFilled />
								</h3>
								<h3 className='est-blog-article-one-icon'>
									<InstagramOutlined />
								</h3>
							</div> 

							 <div className='est-blog-article-one-comments-container'>
								<h3 className='est-blog-article-one-comments-title'>
									3 {t('blog_detail.comments.title')}
								</h3>

								<div className='est-blog-article-one-comments-main-container'>
									<div className='est-blog-article-one-comments-img-container'>
										<Image
											classImg={'est-blog-article-one-comments-img'}
											image={CommentProfilePhoto}
											alt={'Profile photo'}
											title={'Profile photo'}
										/>
									</div>
									<div className='est-blog-article-one-comments-text-container'>
										<div className='est-blog-article-one-comments-title-subtittle-container'>
											<h3 className='est-blog-article-one-comments-title'>Barney</h3>
											<h3 className='est-blog-article-one-comments-subtitle'>
												Abril 7, 2021 1:33 am
											</h3>
										</div>
										<h3 className='est-blog-article-one-comments-text'>
											Has maiorum habemus detraxit at. Timeam fabulas splendide et his.
											Facilisi aliquando sea ad, vel ne consetetur adversarium. Integre
											admodum et his,
										</h3>
										<h4 className='est-blog-article-one-comments-text-link'>
											{t('blog_detail.comments.answer')}
										</h4>
									</div>
								</div>

								<hr className='est-blog-article-one-comments-response-hr'></hr>

								<div className='est-blog-article-one-comments-main-container'>
									<div className='est-blog-article-one-comments-img-container'>
										<Image
											classImg={'est-blog-article-one-comments-img'}
											image={CommentProfilePhoto}
											alt={'Profile photo'}
											title={'Profile photo'}
										/>
									</div>
									<div className='est-blog-article-one-comments-text-container'>
										<div className='est-blog-article-one-comments-title-subtittle-container'>
											<h3 className='est-blog-article-one-comments-title'>Lyly</h3>
											<h3 className='est-blog-article-one-comments-subtitle'>
												Abril 7, 2021 1:33 am
											</h3>
										</div>
										<h3 className='est-blog-article-one-comments-text'>
											Has maiorum habemus detraxit at. Timeam fabulas splendide et his.
											Facilisi aliquando sea ad, vel ne consetetur adversarium. Integre
											admodum et his,
										</h3>
										<h4 className='est-blog-article-one-comments-text-link'>
											{t('blog_detail.comments.answer')}
										</h4>
									</div>
								</div>

								<div className='est-blog-article-one-comments-main-container'>
									<div className='est-blog-article-one-comments-img-container'>
										<Image
											classImg={'est-blog-article-one-comments-img'}
											image={CommentProfilePhoto}
											alt={'Profile photo'}
											title={'Profile photo'}
										/>
									</div>
									<div className='est-blog-article-one-comments-text-container'>
										<div className='est-blog-article-one-comments-title-subtittle-container'>
											<h3 className='est-blog-article-one-comments-title'>Barney</h3>
											<h3 className='est-blog-article-one-comments-subtitle'>
												Abril 7, 2021 1:33 am
											</h3>
										</div>
										<h3 className='est-blog-article-one-comments-text'>
											Has maiorum habemus detraxit at. Timeam fabulas splendide et his.
											Facilisi aliquando sea ad, vel ne consetetur adversarium. Integre
											admodum et his,
										</h3>
										<h4 className='est-blog-article-one-comments-text-link'>
											{t('blog_detail.comments.answer')}
										</h4>
									</div>
								</div>
							</div>
							<hr className='est-blog-article-one-spacer'></hr> 
							<div className='est-blog-article-one-comments-form-container'>
								<h2 className='est-blog-article-one-comments-form-title'>
									{t('blog_detail.form.title')}
								</h2>
								<h3 className='est-blog-article-one-comments-form-subtitle'>
									{t('blog_detail.form.subtitle')} <span>*</span>
								</h3>
								<Form name='blog'>
									<Row className='est-blog-article-one-form-global-container'>
										<Col
											xs={24}
											sm={24}
											md={12}
											lg={12}
											xl={12}
											className='est-blog-article-one-form-main-container-one'>
											<InputCustom
												className={'est-blog-article-one-form-input'}
												inputName={'first_name'}
												inputNameLabel={t('blog_detail.form.input_name')}
												inputNameRule={true}
												inputNameMessage={t('blog_detail.form.placeholder_name')}
												inputNameType={'text'}
												inputNameIcon={''}
												inputNameRules={'rulesFirstName'}
											/>
											<InputCustom
												className={'est-blog-article-one-form-input'}
												inputName={'email'}
												inputNameLabel={t('blog_detail.form.input_email')}
												inputNameRule={true}
												inputNameMessage={t('blog_detail.form.placeholder_email')}
												inputNameType={'text'}
												inputNameIcon={''}
												inputNameRules={'rulesEmail'}
											/>
										</Col>
										<Col
											xs={24}
											sm={24}
											md={12}
											lg={12}
											xl={12}
											className='est-blog-article-one-form-main-container-two'>
											<Form.Item name={'message'}>
												<TextArea
													className='est-home-contact-field-input'
													size='large'
													type={'text'}
													placeholder={t('blog_detail.form.text_area')}
													rows={3}
												/>
											</Form.Item>
										</Col>
										<Col span={24} className='est-blog-article-one-form-button-container'>
											<Button
												className='est-blog-article-one-form-button'
												htmlType={'submit'}>
												{t('blog_detail.form.button')}
											</Button>
										</Col>
									</Row>
								</Form>
							</div>*/}

							<div className='est-blog-article-one-see-more-global-container'>
								<h3 className='est-blog-article-one-see-more-title'>
									{t('blog_detail.another_articles.title')}
								</h3>

								<Row>
									<Col
										xs={24}
										sm={24}
										md={24}
										lg={8}
										xl={8}
										className='est-blog-article-one-see-more-main-container'>
										<Link to='/blog/article-two'>
											<Image
												classImg={'est-blog-article-one-see-more-img'}
												image={ArticleTwoMain}
												alt={'Profile photo'}
												title={'Profile photo'}
											/>
											<h3 className='est-blog-article-one-see-more-article-title'>
												Puerto Rico: un paraíso para proteger y disfrutar
											</h3>
											<h3 className='est-blog-article-one-see-more-subtitle'>
												Por Estelí Capote, Julio 17 2021
											</h3>
											<h3 className='est-blog-article-one-see-more-text'>
												Desde los años de la administración de Luis Muñoz Marín, Puerto
												Rico se ha vislumbrado como un destino turístico...
											</h3>
											<h3 className='est-blog-article-one-see-more-link'>
												<PlusCircleFilled className='est-blog-article-one-see-more-icon' />
												{t('blog_detail.another_articles.link')}
											</h3>
										</Link>
									</Col>
									<Col
										xs={24}
										sm={24}
										md={24}
										lg={8}
										xl={8}
										className='est-blog-article-one-see-more-main-container'>
										<Link to='/blog/article-three'>
											<Image
												classImg={'est-blog-article-one-see-more-img'}
												image={ArticleThreeMain}
												alt={'Profile photo'}
												title={'Profile photo'}
											/>
											<h3 className='est-blog-article-one-see-more-article-title'>
												La semilla de PRUJULA
											</h3>
											<h3 className='est-blog-article-one-see-more-subtitle'>
												Por Estelí Capote, Julio 15 2021
											</h3>
											<h3 className='est-blog-article-one-see-more-text'>
												Desde que era una pioja, y gracias a mi papá, aprendí a amar mi
												país disfrutándolo, acampando en cada monte o playa que nos
												encontrábamos.
											</h3>
											<h3 className='est-blog-article-one-see-more-link'>
												<PlusCircleFilled className='est-blog-article-one-see-more-icon' />
												{t('blog_detail.another_articles.link')}
											</h3>
										</Link>
									</Col>
									<Col
										xs={24}
										sm={24}
										md={24}
										lg={8}
										xl={8}
										className='est-blog-article-one-see-more-main-container'>
										<Link to='/blog/article-four'>
											<Image
												classImg={'est-blog-article-one-see-more-img'}
												image={ArticleFourMain}
												alt={'Profile photo'}
												title={'Profile photo'}
											/>
											<h3 className='est-blog-article-one-see-more-article-title'>
												¿Camping de Mochila o Baúl?
											</h3>
											<h3 className='est-blog-article-one-see-more-subtitle'>
												Por ECM, Julio 19 2021
											</h3>
											<h3 className='est-blog-article-one-see-more-text'>
												Creo que había acampado más de veinte veces ocasiones cuando
												hablaba de nuestra próxima aventura con mis amigos Carlos y
												Diana...
											</h3>
											<h3 className='est-blog-article-one-see-more-link'>
												<PlusCircleFilled className='est-blog-article-one-see-more-icon' />
												{t('blog_detail.another_articles.link')}
											</h3>
										</Link>
									</Col>
								</Row>
							</div>
						</Col>
						{/* 	<Col span={6} className='est-blog-article-one-right-global-container'>
							<RightSearch />
						</Col> */}
					</Row>
				</div>
			</div>
		</>
	)
}
