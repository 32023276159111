/** @format */

import React, { useState } from 'react'

import { useTranslation } from 'react-i18next'

import axios from 'axios'

import Upload from 'antd/lib/upload'
import Modal from 'antd/lib/modal'
import Progress from 'antd/lib/progress'
import message from 'antd/lib/message'

import { CloudUploadOutlined } from '@ant-design/icons'

import { ENV_CORE } from '../../../../../../../components/Common/Hooks/Variables/Enviroment'

import './style.css'

export default function UploadImage(props) {
	const { t } = useTranslation()
	const [isPreviewModal, setPreviewModal] = useState(false)
	const [isFileList, setFileList] = useState([])
	const [isPreviewImg, setPreviewImg] = useState('')
	const [isProgress, setProgress] = useState(0)

	const handleImageGalleryDelete = async (item) => {
		props.imageDelete(props.type)
	}

	const handleOnChangeImage = ({ fileList }) => {
		setFileList(fileList)
	}

	const handleUploadImage = async (options) => {
		const { onSuccess, onError, file, onProgress } = options
		const data = new FormData()
		data.append('imagen[]', file)
		const config = {
			headers: { 'Content-Type': 'multipart/form-data' },
			onUploadProgress: (event) => {
				const percent = Math.floor((event.loaded / event.total) * 100)
				setProgress(percent)
				if (percent === 100) {
					setTimeout(() => setProgress(0), 1000)
				}
				onProgress({ percent: (event.loaded / event.total) * 100 })
			},
		}
		try {
			const response = await axios.post(`${ENV_CORE}/api/upload/upload-image`, data, config)
			if (response.data.statusCode === 200) {
				props.addItemImage(response.data.imageInfo[0], props.type)
				onSuccess('Ok')
				message.success(t('publication.create.photos.message_success'))
			} else {
				message.error(t('publication.create.photos.message_error_one'))
			}
		} catch (err) {
			onError({ err })
			message.error(t('publication.create.photos.message_error_one'))
		}
	}

	const handlePreview = async (item) => {
		setPreviewModal(true)
		setPreviewImg(item.thumbUrl)
	}

	return (
		<div className='est-upload-image-container'>
			<Upload
				accept='image/*'
				customRequest={handleUploadImage}
				onChange={handleOnChangeImage}
				onPreview={handlePreview}
				onRemove={handleImageGalleryDelete}
				listType='picture-card'
				className='image-upload-grid'>
				{isFileList.length >= 1 ? null : (
					<div className='image-upload-grid-text-container'>
						<h3 className='image-upload-grid-title'>
							{t('publication.create.photos.upload_image.placeholder_one')}
							{props.title}
						</h3>
						<div className='image-upload-grid-icon-container'>
							<span>
								<CloudUploadOutlined />
							</span>
						</div>
					</div>
				)}
			</Upload>
			{isProgress > 0 ? <Progress percent={isProgress} /> : null}
			<Modal
				wrapClassName='est-upload-image-modal-container'
				visible={isPreviewModal}
				title={t('publication.create.photos.modal_title')}
				footer={null}
				onCancel={() => setPreviewModal(false)}>
				{isPreviewImg && <img alt='example' style={{ width: '100%' }} src={isPreviewImg} />}
			</Modal>
		</div>
	)
}
