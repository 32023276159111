/** @format */

import React, { useState, useEffect } from 'react'

import { useLocation, Link } from 'react-router-dom'

import { useGlobal } from 'reactn'

import { useTranslation } from 'react-i18next'

import Avatar from 'antd/lib/avatar'
import Dropdown from 'antd/lib/dropdown'

import { UserOutlined } from '@ant-design/icons'

import Spacer from '../Spacer'
import Image from '../Image'

import MainLogoImg from '../../../img/header/main-logo.png'

import DropMenu from './components/DropMenu'
import LanguajeSelector from './components/LanguajeSelector'

import './style.css'

export default function MainHeader(props) {
	const { t } = useTranslation()
	const [isMobile, setMobile] = useState(false)
	let location = useLocation()
	const [isSession] = useState(JSON.parse(localStorage.getItem('userSession')))
	const [isUserModeLogin, setUserModeLogin] = useGlobal('userData')
	const [isEmail, setEmail] = useGlobal('userEmail')
	const [isDropdown, setDropdown] = useState(false)
	const handleCloseMenu = (item) => {
		setDropdown(item)
	}
	useEffect(() => {
		if (window.innerWidth < 426) {
			setMobile(true)
		}
		if (isSession) {
			setEmail(`${isSession.nombre} ${isSession.apellido}`)
			setUserModeLogin(isSession)
		}
	}, [setUserModeLogin, setEmail, isSession])
	return (
		<div
			className={`${
				location.pathname === '/'
					? 'est-main-header-color-transparent'
					: 'est-main-header-color-estandar'
			} est-main-header-sticky`}>
			<div className='est-main-header-main-container'>
				<div className='est-main-header-container'>
					<LanguajeSelector />
					<Spacer />
					<a href='/'>
						<Image
							classImg={`${
								location.pathname === '/'
									? 'est-main-header-logo-transparent'
									: 'est-main-header-logo-estandar'
							} est-main-logo-image-sticky`}
							image={MainLogoImg}
							alt={'Prujula Logo'}
							title={'Prujula Logo'}
						/>
					</a>
					<Spacer />
					{props.location[2] === 'register-sucess' ? (
						<Link to='/' className='est-main-form-main-button'>
							Inicio
						</Link>
					) : (
						<Dropdown
							overlayClassName={`${
								location.pathname === '/'
									? 'est-main-header-menu-container'
									: 'est-main-header-menu-container-estandar'
							}`}
							overlay={
								<DropMenu
									modeLogin={isUserModeLogin}
									email={isEmail}
									CloseMenu={(item) => handleCloseMenu(item)}
								/>
							}
							visible={isDropdown}
							onVisibleChange={handleCloseMenu}
							trigger={['click']}>
							<h3 className='est-main-header-title-2'>
								<Avatar
									className='est-main-header-avatar-container'
									icon={<UserOutlined />}
								/>
								{!isMobile && (
									<>{isEmail ? `${isEmail}` : `${t('header.drop_menu.profile')}`}</>
								)}
							</h3>
						</Dropdown>
					)}
				</div>
			</div>
		</div>
	)
}
