/** @format */

import React, { useState } from 'react'

import { setGlobal } from 'reactn'

import { useTranslation } from 'react-i18next'

import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import Button from 'antd/lib/button'
import Modal from 'antd/lib/modal'
import Form from 'antd/lib/form'
import Input from 'antd/lib/input'
import Radio from 'antd/lib/radio'

import { rulesValidation } from '../../../../../../components/Common/Inputs/Normal/rules'

import UserReservations from '../../services'

import SendReview from './services'

import './style.css'

export default function Reviews({ data }) {
	const [edit_form] = Form.useForm()
	const { TextArea } = Input
	const { t } = useTranslation()
	const [isVisible, setVisible] = useState(false)
	const [isLoading, setLoading] = useState(false)

	const handleReviewModal = () => {
		setVisible(true)
	}

	const handleReviewSend = (item) => {
		let answer_1
		let answer_2
		let answer_4
		let answer_5

		if (item.item_1 === 1) {
			answer_1 = 'Por debajo del Nivel'
		} else if (item.item_1 === 3) {
			answer_1 = 'Al nivel esperado'
		} else if (item.item_1 === 5) {
			answer_1 = 'Por encima del nivel'
		}

		if (item.item_2 === 1) {
			answer_2 = 'No'
		} else if (item.item_2 === 5) {
			answer_2 = 'Si'
		}

		if (item.item_4 === 1) {
			answer_4 = 'Localizacion(lugares cercanos para disfrutar)'
		} else if (item.item_4 === 2) {
			answer_4 = 'Servicios(agua, luz, banios, cocinas, etc)'
		} else if (item.item_4 === 3) {
			answer_4 = 'Actividades para realizar en el lugar(piscina, zip-line, jacuzzi)'
		} else if (item.item_4 === 4) {
			answer_4 = 'Atractivo visual(paisaje)'
		} else if (item.item_4 === 5) {
			answer_4 = 'Recursos naturales para disfrutar en el lugar(rio, cueva, playa)'
		}

		if (item.item_5 === 1) {
			answer_5 = 'Accesos(estacionamiento lejano)'
		} else if (item.item_5 === 2) {
			answer_5 = 'Actividades para realizar en el lugar(piscina, zip-line, jacuzzi)'
		} else if (item.item_5 === 3) {
			answer_5 = 'Cuidado de la Finca(limpieza, concervacion, informacion disponible)'
		} else if (item.item_5 === 4) {
			answer_5 = 'Servicios(agua, luz, banios, cocinas, etc)'
		} else if (item.item_5 === 5) {
			answer_5 = 'Todo me gusto'
		}

		const rating = (item.item_1 + item.item_2 + item.item_4 + item.item_5) / 4
		const info = {
			idAnuncio: data.id_anoucement,
			idReservacion: data.id_reservation,
			idUser: data.id_user,
			ratingUser: rating.toString(),
			commentary: item.item_3,
			answers: [
				{
					question:
						'¿Hasta qué punto tu experiencia en nuestra finca superó tus expectativas?',
					answer: answer_1,
				},
				{
					question: '¿Nos recomendarías a tus amigos o familiares?',
					answer: answer_2,
				},
				{
					question: '¿Por qué sí o por qué no nos recomendarías?',
					answer: item.item_3,
				},
				{
					question: '¿Qué fue lo más que te gustó de la finca?',
					answer: answer_4,
				},
				{
					question: '¿Qué fue lo menos que te gustó de la finca?',
					answer: answer_5,
				},
			],
		}

		setLoading(true)

		const traduce = {
			service_success_title: t('profile.user_reservations.tabs.review.service_success_title'),
			service_success_description: t(
				'profile.user_reservations.tabs.review.service_success_description'
			),
			service_warning_title: t('profile.user_reservations.tabs.review.service_warning_title'),
			service_warning_description: t(
				'profile.user_reservations.tabs.review.service_warning_description'
			),
			service_error_description: t(
				'profile.user_reservations.tabs.review.service_error_description'
			),
		}

		SendReview(info, traduce).then((response) => {
			edit_form.resetFields()
			setLoading(false)
			setVisible(false)
			if (response) {
				UserReservations(data.id_user).then((responseReservations) => {
					setGlobal({
						userReservations: responseReservations,
					})
				})
			}
		})
	}

	return (
		<>
			<Button
				className='est-user-publications-list-reservations-button'
				onClick={() => handleReviewModal()}>
				{t('profile.user_reservations.tabs.review.review_main_button')}
			</Button>
			<Modal
				width={900}
				wrapClassName='est-profile-publication-review-modal-container'
				centered
				title={t('profile.user_reservations.tabs.review.modal_main_title')}
				visible={isVisible}
				onCancel={() => setVisible(false)}
				cancelButtonProps={{ style: { display: 'none' } }}
				okButtonProps={{ style: { display: 'none' } }}
				okText=''
				cancelText=''
				footer={false}>
				<Form onFinish={handleReviewSend} form={edit_form}>
					<Row>
						<Col span={24}>
							<p className='est-profile-publication-review-modal-global-title'>
								{t('profile.user_reservations.tabs.review.modal_subtitle_one')}
								<span className='review-modal-global-title-span'>
									{t('profile.user_reservations.tabs.review.modal_subtitle_two')}
								</span>
								{t('profile.user_reservations.tabs.review.modal_subtitle_three')}
							</p>
						</Col>
						<Col span={24}>
							<h4 className='est-profile-publication-review-modal-title'>
								{t('profile.user_reservations.tabs.review.modal_question_one')}
							</h4>
							<Form.Item
								name={'item_1'}
								rules={rulesValidation[t('field_notifications.normal.rules_required')]}
								className='est-profile-publication-review-modal-value-container'>
								<Radio.Group>
									<Radio value={5}>
										{t(
											'profile.user_reservations.tabs.review.modal_question_one_option_one'
										)}
									</Radio>
									<Radio value={3}>
										{t(
											'profile.user_reservations.tabs.review.modal_question_one_option_two'
										)}
									</Radio>
									<Radio value={1}>
										{t(
											'profile.user_reservations.tabs.review.modal_question_one_option_three'
										)}
									</Radio>
								</Radio.Group>
							</Form.Item>
						</Col>
						<Col span={24}>
							<h4 className='est-profile-publication-review-modal-title'>
								{t('profile.user_reservations.tabs.review.modal_question_two')}
							</h4>
							<Form.Item
								name={'item_2'}
								rules={rulesValidation[t('field_notifications.normal.rules_required')]}
								className='est-profile-publication-review-modal-value-container'>
								<Radio.Group>
									<Radio value={5}>
										{t(
											'profile.user_reservations.tabs.review.modal_question_two_option_one'
										)}
									</Radio>
									<Radio value={1}>No</Radio>
								</Radio.Group>
							</Form.Item>
						</Col>
						<Col span={24}>
							<h4 className='est-profile-publication-review-modal-title'>
								{t('profile.user_reservations.tabs.review.modal_question_three')}
							</h4>
							<Form.Item
								name={'item_3'}
								rules={rulesValidation[t('field_notifications.normal.rules_required')]}
								className='est-profile-publication-review-modal-text-area-container'>
								<TextArea
									className='est-profile-publication-review-modal-field-input'
									size='large'
									type={'text'}
									placeholder={t('publication.create.basic_info.placeholder_description')}
									rows={4}
								/>
							</Form.Item>
						</Col>
						<Col span={24}>
							<h4 className='est-profile-publication-review-modal-title'>
								{t('profile.user_reservations.tabs.review.modal_question_four')}
							</h4>
							<Form.Item
								name={'item_4'}
								rules={rulesValidation[t('field_notifications.normal.rules_required')]}
								className='est-profile-publication-review-modal-value-container'>
								<Radio.Group>
									<Row>
										<Col span={24}>
											<Radio value={2}>
												{t(
													'profile.user_reservations.tabs.review.modal_question_four_option_one'
												)}
											</Radio>
										</Col>
										<Col span={24}>
											<Radio value={5}>
												{t(
													'profile.user_reservations.tabs.review.modal_question_four_option_two'
												)}
											</Radio>
										</Col>
										<Col span={24}>
											<Radio value={4}>
												{t(
													'profile.user_reservations.tabs.review.modal_question_four_option_three'
												)}
											</Radio>
										</Col>
										<Col span={24}>
											<Radio value={1}>
												{t(
													'profile.user_reservations.tabs.review.modal_question_four_option_four'
												)}
											</Radio>
										</Col>
										<Col span={24}>
											<Radio value={3}>
												{t(
													'profile.user_reservations.tabs.review.modal_question_four_option_five'
												)}
											</Radio>
										</Col>
									</Row>
								</Radio.Group>
							</Form.Item>
						</Col>
						<Col span={24}>
							<h4 className='est-profile-publication-review-modal-title'>
								{t('profile.user_reservations.tabs.review.modal_question_five')}
							</h4>
							<Form.Item
								name={'item_5'}
								rules={rulesValidation[t('field_notifications.normal.rules_required')]}
								className='est-profile-publication-review-modal-value-container'>
								<Radio.Group>
									<Row>
										<Col span={24}>
											<Radio value={4}>
												{t(
													'profile.user_reservations.tabs.review.modal_question_five_option_one'
												)}
											</Radio>
										</Col>
										<Col span={24}>
											<Radio value={1}>
												{t(
													'profile.user_reservations.tabs.review.modal_question_five_option_two'
												)}
											</Radio>
										</Col>
										<Col span={24}>
											<Radio value={2}>
												{t(
													'profile.user_reservations.tabs.review.modal_question_five_option_three'
												)}
											</Radio>
										</Col>
										<Col span={24}>
											<Radio value={3}>
												{t(
													'profile.user_reservations.tabs.review.modal_question_five_option_four'
												)}
											</Radio>
										</Col>
										<Col span={24}>
											<Radio value={5}>
												{t(
													'profile.user_reservations.tabs.review.modal_question_five_option_five'
												)}
											</Radio>
										</Col>
									</Row>
								</Radio.Group>
							</Form.Item>
						</Col>
						<Col span={24}>
							<div className='est-profile-publication-edit-modal-button-container'>
								<Button
									className='est-profile-publication-edit-modal-button'
									htmlType={'submit'}
									loading={isLoading}>
									{t('profile.user_reservations.tabs.review.review_final_button')}
								</Button>
							</div>
						</Col>
					</Row>
				</Form>
			</Modal>
		</>
	)
}
