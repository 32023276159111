/** @format */

import React from 'react'

import { Link } from 'react-router-dom'

import Row from 'antd/lib/row'
import Col from 'antd/lib/col'

import './style.css'

export default function MainNavigation(props) {
	return (
		<div className='est-detail-publication-banner-global-container'>
			<Row className='est-detail-publication-banner-main-container'>
				<Col span={24}>
					<h3 className='est-detail-publication-banner-title'>{props.title}</h3>
				</Col>
				<Col className='est-detail-publication-banner-link-container'>
					<Link to={props.linkPrev} className='est-detail-publication-banner-link'>
						{props.linkPrevTitle}
					</Link>
					<h3 className='est-detail-publication-banner-slash'>/</h3>
					<Link to={props.linkNext} className='est-detail-publication-banner-link'>
						{props.linkNextTitle}
					</Link>
				</Col>
			</Row>
		</div>
	)
}
