/** @format */

import React, { useEffect } from 'react'

import { Map, TileLayer, Marker, Popup } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'

import './style.css'

export default function LeafletMap(props) {
	useEffect(() => {
		const markerIcon = require('leaflet')
		delete markerIcon.Icon.Default.prototype._getIconUrl
		markerIcon.Icon.Default.mergeOptions({
			iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png').default,
			iconUrl: require('leaflet/dist/images/marker-icon.png').default,
			shadowUrl: require('leaflet/dist/images/marker-shadow.png').default,
		})
	}, [])
	return (
		<>
			<Map center={props.center} zoom={props.zoom}>
				<TileLayer url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png' />
				<Marker position={props.center}>
					<Popup>
						{props.title} <br /> <h4 className='ph-map-marker-pharol'>{props.description}</h4>
					</Popup>
				</Marker>
			</Map>
		</>
	)
}
