/** @format */

import axios from 'axios'

import notification from 'antd/lib/notification'

import { ENV_CORE } from '../../../../components/Common/Hooks/Variables/Enviroment'

const servicesPublications = {
	async GeneralPublications() {
		let returnResponse
		await axios({
			method: 'POST',
			url: `${ENV_CORE}/api/publication/all-admin`,
		})
			.then((response) => {
				if (response.data.statusCode === 200) {
					returnResponse = response.data.adsInfo
				} else if (response.data.statusCode === 400) {
					returnResponse = false
				} else {
					notification['warning']({
						message: 'Warning:',
						description: `Service error: REACT_APP_SERVICE_CORE - User Publications`,
					})
				}
			})
			.catch(() => {
				notification['error']({
					message: `Error`,
					description: 'Check your internet connection',
				})
			})
		return returnResponse
	},
	async UpdatePublication(item, data, traduceOne) {
		let info = {
			updId: data,
			updEstatus: item,
		}
		let returnResponse
		await axios({
			method: 'POST',
			url: `${ENV_CORE}/api/publication/update-publication`,
			data: info,
		})
			.then((response) => {
				if (response.data.statusCode === 200) {
					returnResponse = response
					notification['success']({
						message: `${traduceOne.service_success_title}`,
						description: `${traduceOne.service_success_description}`,
					})
				} else {
					notification['warning']({
						message: `${traduceOne.service_warning_title}`,
						description: `${traduceOne.service_warning_description}`,
					})
				}
			})
			.catch(() => {
				notification['error']({
					message: `Error`,
					description: `${traduceOne.service_error_description}`,
				})
			})
		return returnResponse
	},
	async ChangeStatusPublication(item, traduceTwo) {
		let returnResponse
		await axios({
			method: 'POST',
			url: `${ENV_CORE}/api/publication/update-estado-publication`,
			data: item,
		})
			.then((response) => {
				if (response.data.statusCode === 200) {
					returnResponse = response
					notification['success']({
						message: `${traduceTwo.service_success_title}`,
						description: `${traduceTwo.service_success_description}`,
					})
				} else {
					notification['warning']({
						message: `Error`,
						description: `${traduceTwo.service_error_message}`,
					})
				}
			})
			.catch(() => {
				notification['error']({
					message: `Error`,
					description: `${traduceTwo.service_error_description}`,
				})
			})
		return returnResponse
	},
}
export default servicesPublications
