/** @format */

import React, { useState } from 'react'

import { useTranslation } from 'react-i18next'

import { Bar } from 'react-chartjs-2'

import './style.css'

export default function DataBars(props) {
	const { t } = useTranslation()
	const [isDataBars] = useState({
		labels: props.data.labels,
		datasets: [
			{
				label: `${t('profile.data_metrics.metric_bar.label_title')}`,
				backgroundColor: 'rgba(36,102,84,0.2)',
				borderColor: 'rgba(61,150,102,1)',
				borderWidth: 1,
				hoverBackgroundColor: 'rgba(10,189,89,0.4)',
				hoverBorderColor: 'rgba(61,50,102,1)',
				data: props.data.data,
			},
		],
	})
	return (
		<>
			<h2 className='est-data-metrics-bar-title'>
				{t('profile.data_metrics.metric_bar.main_title')}
			</h2>
			<Bar
				data={isDataBars}
				width={100}
				height={props.mobile ? 100 : 20}
				options={{
					responsive: true,
					maintainAspectRatio: true,
				}}
			/>
		</>
	)
}
