/** @format */

import React, { useEffect, useState } from 'react'

import { Link } from 'react-router-dom'

import moment from 'moment/min/moment-with-locales'

import Moment from 'react-moment'

//import moment from 'moment'
//import momment from 'moment/locale/en'
//import 'moment/locale/es'

import { useTranslation } from 'react-i18next'

import { useCookies } from 'react-cookie'

import MainNavigation from '../../components/Common/MainNavigation'

import { Row, Col } from 'antd'

//import Form from 'antd/lib/form'
//import Button from 'antd/lib/button'

//import InputCustom from '../../../src/components/Common/Inputs/Normal'

import {
	//FolderOutlined,
	//WechatOutlined,
	//HeartOutlined,
	//FacebookFilled,
	//TwitterSquareFilled,
	//InstagramOutlined,
	PlusCircleFilled,
} from '@ant-design/icons'

//import RightSearch from './components/RightSearch'
import Image from '../../components/Common/Image'
import Loading from '../../components/Common/Loading'
//import BlogMainImage from '../../../src/img/blog/youngcouple-blog-simple.png'
//import CommentProfilePhoto from '../../../src/img/detail/profile-example.png'

import { GetDetailBlog, BlogLastestPosts } from './services'

import './style.css'

export default function Blog(props) {
	Moment.globalMoment = moment
	const [cookies] = useCookies(['i18next'])
	const [isLanguaje, setLanguaje] = useState(null)
	const [isBlogInfo, setBloginfo] = useState(null)
	const [isBlogLatest, setBloglatest] = useState(null)
	const { t } = useTranslation()
	//const { TextArea } = Input

	useEffect(() => {
		GetDetailBlog(props.match.params.id).then((response) => {
			if (response) {
				setBloginfo(response)
				if (cookies.i18next === 'es') {
					setLanguaje('info_es')
				} else if (cookies.i18next === 'en') {
					setLanguaje('info_en')
				}
			}
		})
		BlogLastestPosts().then((response) => {
			if (response) {
				setBloglatest(response)
			}
		})
	}, [cookies.i18next, props.match.params.id])

	if (!isBlogInfo) {
		return <Loading />
	} else {
		return (
			<div className='est-blog-detail-global-container'>
				<MainNavigation
					title={isBlogInfo[isLanguaje] ? isBlogInfo[isLanguaje].title : ''}
					linkPrevTitle={'Home'}
					linkNextTitle={'Blog'}
					linkPrev={'/'}
					linkNext={'/blog'}
				/>
				<div className='est-blog-detail-main-container'>
					<Row>
						<Col span={24} className='est-blog-detail-left-global-container'>
							<div className='est-blog-detail-title-img-container'>
								<h3 className='est-blog-detail-title'>
									{isBlogInfo[isLanguaje] ? isBlogInfo[isLanguaje].title : ''}
								</h3>
								<div className='est-blog-detail-subtitle-container'>
									<h4 className='est-blog-detail-category-text'>
										{t('blog.author_title')} {isBlogInfo.name} {isBlogInfo.last_name},{' '}
										<Moment locale={cookies.i18next} format='LL'>
											{isBlogInfo.date_create}
										</Moment>
									</h4>
								</div>
								<div className='est-blog-detail-img-container'>
									<Image
										classImg={'est-blog-main-img'}
										image={isBlogInfo.image}
										alt={'Profile photo'}
										title={'Profile photo'}
									/>
									<div className='est-blog-detail-img-date'>
										<h3 className='est-blog-detail-img-date-title'>
											<Moment locale={cookies.i18next} format='DD'>
												{isBlogInfo.date_create}
											</Moment>
										</h3>
										<h3 className='est-blog-detail-img-date-subtitle'>
											{' '}
											<Moment locale={cookies.i18next} format='MM'>
												{isBlogInfo.date_create}
											</Moment>
										</h3>
									</div>
								</div>
							</div>
							<div className='est-blog-detail-text-container'>
								<p className='est-blog-detail-text'>
									{isBlogInfo[isLanguaje] ? isBlogInfo[isLanguaje].content : ''}
								</p>
							</div>
							<hr className='est-blog-detail-spacer'></hr>
							{/* 	<div className='est-blog-detail-icon-container'>
							<h3 className='est-blog-detail-rrss'>{t('blog_detail.share')}</h3>
							<h3 className='est-blog-detail-icon'>
								<FacebookFilled />
							</h3>
							<h3 className='est-blog-detail-icon'>
								<TwitterSquareFilled />
							</h3>
							<h3 className='est-blog-detail-icon'>
								<InstagramOutlined />
							</h3>
						</div>
						<div className='est-blog-detail-comments-container'>
							<h3 className='est-blog-detail-comments-title'>
								3 {t('blog_detail.comments.title')}
							</h3>

							<div className='est-blog-detail-comments-main-container'>
								<div className='est-blog-detail-comments-img-container'>
									<Image
										classImg={'est-blog-detail-comments-img'}
										image={CommentProfilePhoto}
										alt={'Profile photo'}
										title={'Profile photo'}
									/>
								</div>
								<div className='est-blog-detail-comments-text-container'>
									<div className='est-blog-detail-comments-title-subtittle-container'>
										<h3 className='est-blog-detail-comments-title'>Barney</h3>
										<h3 className='est-blog-detail-comments-subtitle'>
											Abril 7, 2021 1:33 am
										</h3>
									</div>
									<h3 className='est-blog-detail-comments-text'>
										Has maiorum habemus detraxit at. Timeam fabulas splendide et his.
										Facilisi aliquando sea ad, vel ne consetetur adversarium. Integre
										admodum et his,
									</h3>
									<h4 className='est-blog-detail-comments-text-link'>
										{t('blog_detail.comments.answer')}
									</h4>
								</div>
							</div>

							<hr className='est-blog-detail-comments-response-hr'></hr>

							<div className='est-blog-detail-comments-main-container'>
								<div className='est-blog-detail-comments-img-container'>
									<Image
										classImg={'est-blog-detail-comments-img'}
										image={CommentProfilePhoto}
										alt={'Profile photo'}
										title={'Profile photo'}
									/>
								</div>
								<div className='est-blog-detail-comments-text-container'>
									<div className='est-blog-detail-comments-title-subtittle-container'>
										<h3 className='est-blog-detail-comments-title'>Lyly</h3>
										<h3 className='est-blog-detail-comments-subtitle'>
											Abril 7, 2021 1:33 am
										</h3>
									</div>
									<h3 className='est-blog-detail-comments-text'>
										Has maiorum habemus detraxit at. Timeam fabulas splendide et his.
										Facilisi aliquando sea ad, vel ne consetetur adversarium. Integre
										admodum et his,
									</h3>
									<h4 className='est-blog-detail-comments-text-link'>
										{t('blog_detail.comments.answer')}
									</h4>
								</div>
							</div>

							<div className='est-blog-detail-comments-main-container'>
								<div className='est-blog-detail-comments-img-container'>
									<Image
										classImg={'est-blog-detail-comments-img'}
										image={CommentProfilePhoto}
										alt={'Profile photo'}
										title={'Profile photo'}
									/>
								</div>
								<div className='est-blog-detail-comments-text-container'>
									<div className='est-blog-detail-comments-title-subtittle-container'>
										<h3 className='est-blog-detail-comments-title'>Barney</h3>
										<h3 className='est-blog-detail-comments-subtitle'>
											Abril 7, 2021 1:33 am
										</h3>
									</div>
									<h3 className='est-blog-detail-comments-text'>
										Has maiorum habemus detraxit at. Timeam fabulas splendide et his.
										Facilisi aliquando sea ad, vel ne consetetur adversarium. Integre
										admodum et his,
									</h3>
									<h4 className='est-blog-detail-comments-text-link'>
										{t('blog_detail.comments.answer')}
									</h4>
								</div>
							</div>
						</div>
						<hr className='est-blog-detail-spacer'></hr>
						<div className='est-blog-detail-comments-form-container'>
							<h2 className='est-blog-detail-comments-form-title'>
								{t('blog_detail.form.title')}
							</h2>
							<h3 className='est-blog-detail-comments-form-subtitle'>
								{t('blog_detail.form.subtitle')}
							</h3>
							<Form name='blog'>
								<Row className='est-blog-detail-form-global-container'>
									<Col span={12} className='est-blog-detail-form-main-container-one'>
										<InputCustom
											className={'est-blog-detail-form-input'}
											inputName={'first_name'}
											inputNameLabel={t('blog_detail.form.input_name')}
											inputNameRule={true}
											inputNameMessage={t('blog_detail.form.placeholder_name')}
											inputNameType={'text'}
											inputNameIcon={''}
											inputNameRules={t('field_notifications.normal.rules_first_name')}
										/>
										<InputCustom
											className={'est-blog-detail-form-input'}
											inputName={'email'}
											inputNameLabel={t('blog_detail.form.input_email')}
											inputNameRule={true}
											inputNameMessage={t('blog_detail.form.placeholder_email')}
											inputNameType={'text'}
											inputNameIcon={''}
											inputNameRules={t('field_notifications.normal.rules_email')}
										/>
									</Col>
									<Col span={12} className='est-blog-detail-form-main-container-two'>
										<Form.Item name={'message'}>
											<TextArea
												className='est-home-contact-field-input'
												size='large'
												type={'text'}
												placeholder={t('blog_detail.form.text_area')}
												rows={3}
											/>
										</Form.Item>
									</Col>
									<Col span={24} className='est-blog-detail-form-button-container'>
										<Button className='est-blog-detail-form-button' htmlType={'submit'}>
											{t('blog_detail.form.button')}
										</Button>
									</Col>
								</Row>
							</Form>
						</div> */}

							<div className='est-blog-detail-see-more-global-container'>
								<h3 className='est-blog-detail-see-more-title'>
									{t('blog_detail.another_articles.title')}
								</h3>
								{isBlogLatest && (
									<Row>
										{isBlogLatest.map((item, index) => (
											<Col xs={24} sm={24} md={8} lg={8} xl={8} key={index}>
												<div className='est-card-blog-global-container'>
													<div className='est-card-blog-main-container'>
														<Link to={`/blog-detail/${item.id}`}>
															<div className='est-card-blog-img-main-container'>
																<Image
																	classImg={'est-card-blog-img'}
																	image={item.image}
																	alt={
																		item[isLanguaje] ? item[isLanguaje].title : ''
																	}
																	title={
																		item[isLanguaje] ? item[isLanguaje].title : ''
																	}
																/>
															</div>
															<h3 className='card-blog-name'>
																{item[isLanguaje] ? item[isLanguaje].title : ''}
															</h3>
															<h3 className='card-blog-subtitle'>
																{t('blog.author_title')} {item.name}{' '}
																{item.last_name}
															</h3>
															<h3 className='card-blog-description'>
																{item[isLanguaje] ? item[isLanguaje].content : ''}
															</h3>
															<h3 className='card-blog-link'>
																<PlusCircleFilled className='card-blog-link-icon' />
																{t('blog.see_more_button')}
															</h3>
														</Link>
													</div>
												</div>
											</Col>
										))}
										{/* <Col span={8} className='est-blog-detail-see-more-main-container'>
											<Image
												classImg={'est-blog-detail-see-more-img'}
												image={BlogMainImage}
												alt={'Profile photo'}
												title={'Profile photo'}
											/>
											<h3 className='est-blog-detail-see-more-article-title'>
												Lorem Ipsum is simply dummy text of the printing.
											</h3>
											<h3 className='est-blog-detail-see-more-subtitle'>
												Por Catherine, Abril 15 2021
											</h3>
											<h3 className='est-blog-detail-see-more-text'>
												Has been the industrys standard dummy text ever since the 1500s,
												when an unknown printer took.
											</h3>
											<h3 className='est-blog-detail-see-more-link'>
												<PlusCircleFilled className='est-blog-detail-see-more-icon' />
												{t('blog_detail.another_articles.link')}
											</h3>
										</Col>
										<Col span={8} className='est-blog-detail-see-more-main-container'>
											<Image
												classImg={'est-blog-detail-see-more-img'}
												image={BlogMainImage}
												alt={'Profile photo'}
												title={'Profile photo'}
											/>
											<h3 className='est-blog-detail-see-more-article-title'>
												Lorem Ipsum is simply dummy text of the printing.
											</h3>
											<h3 className='est-blog-detail-see-more-subtitle'>
												Por Catherine, Abril 15 2021
											</h3>
											<h3 className='est-blog-detail-see-more-text'>
												Has been the industrys standard dummy text ever since the 1500s,
												when an unknown printer took.
											</h3>
											<h3 className='est-blog-detail-see-more-link'>
												<PlusCircleFilled className='est-blog-detail-see-more-icon' />
												{t('blog_detail.another_articles.link')}
											</h3>
										</Col>
										<Col span={8} className='est-blog-detail-see-more-main-container'>
											<Image
												classImg={'est-blog-detail-see-more-img'}
												image={BlogMainImage}
												alt={'Profile photo'}
												title={'Profile photo'}
											/>
											<h3 className='est-blog-detail-see-more-article-title'>
												Lorem Ipsum is simply dummy text of the printing.
											</h3>
											<h3 className='est-blog-detail-see-more-subtitle'>
												Por Catherine, Abril 15 2021
											</h3>
											<h3 className='est-blog-detail-see-more-text'>
												Has been the industrys standard dummy text ever since the 1500s,
												when an unknown printer took.
											</h3>
											<h3 className='est-blog-detail-see-more-link'>
												<PlusCircleFilled className='est-blog-detail-see-more-icon' />
												{t('blog_detail.another_articles.link')}
											</h3>
										</Col> */}
									</Row>
								)}
							</div>
						</Col>

						{/* 	<Col span={6} className='est-blog-detail-right-global-container'>
						<RightSearch />
					</Col> */}
					</Row>
				</div>
			</div>
		)
	}
}
