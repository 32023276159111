/** @format */

import React from 'react'

import { Link } from 'react-router-dom'

import { useTranslation } from 'react-i18next'

import HeadDescription from '../../../../components/Common/HeadDescription'

import MainNavigation from '../../../../components/Common/MainNavigation'

//import Carrousel from './components/Carrousel'

import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
//import Form from 'antd/lib/form'
//import Button from 'antd/lib/button'
//import Input from 'antd/lib/input'

//import InputCustom from '../../../../components/Common/Inputs/Normal'

import {
	FolderOutlined,
	WechatOutlined,
	HeartOutlined,
	//FacebookFilled,
	//TwitterSquareFilled,
	//InstagramOutlined,
	PlusCircleFilled,
} from '@ant-design/icons'

//import RightSearch from '../RightSearch'
import Image from '../../../../components/Common/Image'
import ArticleFiveMain from '../../../../img/blog/articulo5.jpg'
import ArticleOneMain from '../../../../img/blog/articulo1/principal.jpg'
import ArticleTwoMain from '../../../../img/blog/articulo2.jpg'
import ArticleThreeMain from '../../../../img/blog/articulo3.jpg'

//import CommentProfilePhoto from '../../../../../src/img/detail/profile-example.png'

import './style.css'

export default function ArticleFive() {
	const { t } = useTranslation()
	//const { TextArea } = Input
	return (
		<>
			<HeadDescription
				title={'Prujula - Actículo'}
				name={'description'}
				content={'Camping Site'}
			/>
			<div className='est-blog-article-five-global-container'>
				<MainNavigation
					title={t('blog_detail.main_navigation.title')}
					linkPrevTitle={'Home'}
					linkNextTitle={'Blog'}
					linkPrev={'/'}
					linkNext={'/blog'}
				/>
				<div className='est-blog-article-five-main-container'>
					<Row>
						<Col
							span={24}
							/* xs={24}
							sm={24}
							md={24}
							lg={18}
							xl={18} */
							className='est-blog-article-five-left-global-container'>
							<div className='est-blog-article-five-title-img-container'>
								<h3 className='est-blog-article-five-title'>
									Para campistas, camperos y fanáticos
								</h3>
								<h3 className='est-blog-article-five-subtitle'>Autor: ECM</h3>
								<div className='est-blog-article-five-subtitle-container'>
									<h4 className='est-blog-article-five-category-text'>
										<FolderOutlined /> {t('blog_detail.article_category')}
									</h4>
									<h4 className='est-blog-article-five-category-icon'>
										<WechatOutlined /> 12
									</h4>
									<h4 className='est-blog-article-five-category-icon'>
										<HeartOutlined /> 6
									</h4>
								</div>
								<div className='est-blog-article-five-img-container'>
									<Image
										classImg={'est-blog-main-img'}
										image={ArticleFiveMain}
										alt={'Profile photo'}
										title={'Profile photo'}
									/>
									<div className='est-blog-article-five-img-date'>
										<h3 className='est-blog-article-five-img-date-title'>20</h3>
										<h3 className='est-blog-article-five-img-date-subtitle'>07</h3>
									</div>
								</div>
							</div>
							<div className='est-blog-article-five-text-container'>
								<p className='est-blog-article-five-text'>
									Más que un artículo o blog esto es mi “cheat sheet” para acampar:
								</p>
								<ul className='est-blog-article-five-ul'>
									<li className='est-blog-article-five-list-item'>
										Si estás comenzando, suscríbete a la Asociación de Acampadores de
										Puerto Rico, ADAPRI. Es una organización con muuuchos años de
										existencia, conocen muchos lugares y puedes hacer actividades en
										compañía de personas y familias con iguales intereses. Se reúnen
										mensualmente, tienen varioa capítulos a lo largo y ancho de Puerto
										Rico. Su correo es{' '}
										<a href='boletinadapri@gmail.com'>boletinadapri@gmail.com</a>
									</li>
									<li className='est-blog-article-five-list-item'>
										En Puerto Rico existe una tienda de efectospara campismo, su dueño y
										su equipo de trabajo son acampadores experimentados por par de
										décadas. De hecho, algunos de elloa también se ofrecen como guías para
										excursiones. La tienda tiene efectos de campismo de las mejores marcas
										y el equipo se asegura que lo que necesitas. Mis sandalias son de ahí.
										La tienda se llama ACAMPA, ubica en el 517 Ave. Andalucía, Puerto
										Nuevo. Su teléfono es 787.706.0695 y tienen página web:{' '}
										<a href='https://www.acampapr.com/'>https://www.acampapr.com/</a>
									</li>
									<li className='est-blog-article-five-list-item'>
										Una página social que sigo mucho en Facebook es Camping en Puerto
										Rico. Síguelos{' '}
										<a href='https://www.facebook.com/groups/391767924318304/?ref=share'>
											https://www.facebook.com/groups/391767924318304/?ref=share
										</a>
									</li>
								</ul>
								<p className='est-blog-article-five-text'>
									Si tienes más preguntas solo escribe a{' '}
									<a href='prujula@outlook.com'>prujula@outlook.com</a> y con mucho gusto
									te responderé. ¡Cuéntanos como te va en tu próximo camping!
								</p>

								<hr className='est-blog-article-five-spacer'></hr>
								{/* <div className='est-blog-article-five-carrousel-container'>
									<Carrousel />
								</div> */}
							</div>
							{/*  
							<hr className='est-blog-article-five-spacer'></hr>
							<div className='est-blog-article-five-icon-container'>
								<h3 className='est-blog-article-five-rrss'>{t('blog_detail.share')}</h3>
								<h3 className='est-blog-article-five-icon'>
									<FacebookFilled />
								</h3>
								<h3 className='est-blog-article-five-icon'>
									<TwitterSquareFilled />
								</h3>
								<h3 className='est-blog-article-five-icon'>
									<InstagramOutlined />
								</h3>
							</div>
							<div className='est-blog-article-five-comments-container'>
								<h3 className='est-blog-article-five-comments-title'>
									3 {t('blog_detail.comments.title')}
								</h3>

								<div className='est-blog-article-five-comments-main-container'>
									<div className='est-blog-article-five-comments-img-container'>
										<Image
											classImg={'est-blog-article-five-comments-img'}
											image={CommentProfilePhoto}
											alt={'Profile photo'}
											title={'Profile photo'}
										/>
									</div>
									<div className='est-blog-article-five-comments-text-container'>
										<div className='est-blog-article-five-comments-title-subtittle-container'>
											<h3 className='est-blog-article-five-comments-title'>Barney</h3>
											<h3 className='est-blog-article-five-comments-subtitle'>
												Abril 7, 2021 1:33 am
											</h3>
										</div>
										<h3 className='est-blog-article-five-comments-text'>
											Has maiorum habemus detraxit at. Timeam fabulas splendide et his.
											Facilisi aliquando sea ad, vel ne consetetur adversarium. Integre
											admodum et his,
										</h3>
										<h4 className='est-blog-article-five-comments-text-link'>
											{t('blog_detail.comments.answer')}
										</h4>
									</div>
								</div>

								<hr className='est-blog-article-five-comments-response-hr'></hr>

								<div className='est-blog-article-five-comments-main-container'>
									<div className='est-blog-article-five-comments-img-container'>
										<Image
											classImg={'est-blog-article-five-comments-img'}
											image={CommentProfilePhoto}
											alt={'Profile photo'}
											title={'Profile photo'}
										/>
									</div>
									<div className='est-blog-article-five-comments-text-container'>
										<div className='est-blog-article-five-comments-title-subtittle-container'>
											<h3 className='est-blog-article-five-comments-title'>Lyly</h3>
											<h3 className='est-blog-article-five-comments-subtitle'>
												Abril 7, 2021 1:33 am
											</h3>
										</div>
										<h3 className='est-blog-article-five-comments-text'>
											Has maiorum habemus detraxit at. Timeam fabulas splendide et his.
											Facilisi aliquando sea ad, vel ne consetetur adversarium. Integre
											admodum et his,
										</h3>
										<h4 className='est-blog-article-five-comments-text-link'>
											{t('blog_detail.comments.answer')}
										</h4>
									</div>
								</div>

								<div className='est-blog-article-five-comments-main-container'>
									<div className='est-blog-article-five-comments-img-container'>
										<Image
											classImg={'est-blog-article-five-comments-img'}
											image={CommentProfilePhoto}
											alt={'Profile photo'}
											title={'Profile photo'}
										/>
									</div>
									<div className='est-blog-article-five-comments-text-container'>
										<div className='est-blog-article-five-comments-title-subtittle-container'>
											<h3 className='est-blog-article-five-comments-title'>Barney</h3>
											<h3 className='est-blog-article-five-comments-subtitle'>
												Abril 7, 2021 1:33 am
											</h3>
										</div>
										<h3 className='est-blog-article-five-comments-text'>
											Has maiorum habemus detraxit at. Timeam fabulas splendide et his.
											Facilisi aliquando sea ad, vel ne consetetur adversarium. Integre
											admodum et his,
										</h3>
										<h4 className='est-blog-article-five-comments-text-link'>
											{t('blog_detail.comments.answer')}
										</h4>
									</div>
								</div>
							</div>
							<hr className='est-blog-article-five-spacer'></hr>
							<div className='est-blog-article-five-comments-form-container'>
								<h2 className='est-blog-article-five-comments-form-title'>
									{t('blog_detail.form.title')}
								</h2>
								<h3 className='est-blog-article-five-comments-form-subtitle'>
									{t('blog_detail.form.subtitle')} <span>*</span>
								</h3>
								<Form name='blog'>
									<Row className='est-blog-article-five-form-global-container'>
										<Col
											xs={24}
											sm={24}
											md={12}
											lg={12}
											xl={12}
											className='est-blog-article-five-form-main-container-one'>
											<InputCustom
												className={'est-blog-article-five-form-input'}
												inputName={'first_name'}
												inputNameLabel={t('blog_detail.form.input_name')}
												inputNameRule={true}
												inputNameMessage={t('blog_detail.form.placeholder_name')}
												inputNameType={'text'}
												inputNameIcon={''}
												inputNameRules={'rulesFirstName'}
											/>
											<InputCustom
												className={'est-blog-article-five-form-input'}
												inputName={'email'}
												inputNameLabel={t('blog_detail.form.input_email')}
												inputNameRule={true}
												inputNameMessage={t('blog_detail.form.placeholder_email')}
												inputNameType={'text'}
												inputNameIcon={''}
												inputNameRules={'rulesEmail'}
											/>
										</Col>
										<Col
											xs={24}
											sm={24}
											md={12}
											lg={12}
											xl={12}
											className='est-blog-article-five-form-main-container-two'>
											<Form.Item name={'message'}>
												<TextArea
													className='est-home-contact-field-input'
													size='large'
													type={'text'}
													placeholder={t('blog_detail.form.text_area')}
													rows={3}
												/>
											</Form.Item>
										</Col>
										<Col
											span={24}
											className='est-blog-article-five-form-button-container'>
											<Button
												className='est-blog-article-five-form-button'
												htmlType={'submit'}>
												{t('blog_detail.form.button')}
											</Button>
										</Col>
									</Row>
								</Form>
							</div> */}

							<div className='est-blog-article-five-see-more-global-container'>
								<h3 className='est-blog-article-five-see-more-title'>
									{t('blog_detail.another_articles.title')}
								</h3>

								<Row>
									<Col
										xs={24}
										sm={24}
										md={24}
										lg={8}
										xl={8}
										className='est-blog-article-five-see-more-main-container'>
										<Link to='/blog/article-one'>
											<Image
												classImg={'est-blog-article-two-see-more-img'}
												image={ArticleOneMain}
												alt={'Profile photo'}
												title={'Profile photo'}
											/>
											<h3 className='est-blog-article-two-see-more-article-title'>
												SENDERISMO AL ALCANCE DE TODOS
											</h3>
											<h3 className='est-blog-article-two-see-more-subtitle'>
												Por Ricardo Martínez, Julio 16 2021
											</h3>
											<h3 className='est-blog-article-two-see-more-text'>
												Más que nunca me convenzo de lo importante y necesario que es
												para nuestra salud mental y física separar...
											</h3>
											<h3 className='est-blog-article-two-see-more-link'>
												<PlusCircleFilled className='est-blog-article-two-see-more-icon' />
												{t('blog_detail.another_articles.link')}
											</h3>
										</Link>
									</Col>
									<Col
										xs={24}
										sm={24}
										md={24}
										lg={8}
										xl={8}
										className='est-blog-article-five-see-more-main-container'>
										<Link to='/blog/article-three'>
											<Image
												classImg={'est-blog-article-one-see-more-img'}
												image={ArticleThreeMain}
												alt={'Profile photo'}
												title={'Profile photo'}
											/>
											<h3 className='est-blog-article-one-see-more-article-title'>
												La semilla de PRUJULA
											</h3>
											<h3 className='est-blog-article-one-see-more-subtitle'>
												Por Estelí Capote, Julio 15 2021
											</h3>
											<h3 className='est-blog-article-one-see-more-text'>
												Desde que era una pioja, y gracias a mi papá, aprendí a amar mi
												país disfrutándolo, acampando en cada monte o playa que nos
												encontrábamos.
											</h3>
											<h3 className='est-blog-article-one-see-more-link'>
												<PlusCircleFilled className='est-blog-article-one-see-more-icon' />
												{t('blog_detail.another_articles.link')}
											</h3>
										</Link>
									</Col>
									<Col
										xs={24}
										sm={24}
										md={24}
										lg={8}
										xl={8}
										className='est-blog-article-five-see-more-main-container'>
										<Link to='/blog/article-two'>
											<Image
												classImg={'est-blog-article-one-see-more-img'}
												image={ArticleTwoMain}
												alt={'Profile photo'}
												title={'Profile photo'}
											/>
											<h3 className='est-blog-article-one-see-more-article-title'>
												Puerto Rico: un paraíso para proteger y disfrutar
											</h3>
											<h3 className='est-blog-article-one-see-more-subtitle'>
												Por Estelí Capote, Julio 17 2021
											</h3>
											<h3 className='est-blog-article-one-see-more-text'>
												Desde los años de la administración de Luis Muñoz Marín, Puerto
												Rico se ha vislumbrado como un destino turístico...
											</h3>
											<h3 className='est-blog-article-one-see-more-link'>
												<PlusCircleFilled className='est-blog-article-one-see-more-icon' />
												{t('blog_detail.another_articles.link')}
											</h3>
										</Link>
									</Col>
								</Row>
							</div>
						</Col>
						{/* <Col span={6} className='est-blog-article-five-right-global-container'>
							<RightSearch />
						</Col> */}
					</Row>
				</div>
			</div>
		</>
	)
}
