/** @format */

import axios from 'axios'

import { setGlobal } from 'reactn'

import notification from 'antd/lib/notification'

import { ENV_CORE } from '../../../Hooks/Variables/Enviroment'

const servicesLogin = {
	async Login(item, traduceOne) {
		let returnResponse
		let data = {
			conEmail: item.email,
			conPassword: item.password,
			conModo: 'directo',
		}
		await axios({
			method: 'POST',
			url: `${ENV_CORE}/api/auth/login`,
			data: data,
		})
			.then((response) => {
				if (response.data.statusCode === 200) {
					notification['success']({
						message: `${traduceOne.service_login_success_message}`,
						description: `${traduceOne.service_login_success_description}`,
					})
					returnResponse = {
						nombre: response.data.userInfo.nombre,
						apellido: response.data.userInfo.apellido,
						id: response.data.userInfo.id,
						modo: response.data.userInfo.modo,
						email: response.data.userInfo.email,
					}
					localStorage.setItem('userSession', JSON.stringify(returnResponse))
					setGlobal(() => ({
						userEmail: `${returnResponse.nombre} ${returnResponse.apellido}`,
						userData: returnResponse,
					}))
				} else {
					notification['warning']({
						message: `${traduceOne.service_login_error_message}`,
						description: `${traduceOne.service_login_error_description}`,
					})
				}
			})
			.catch(() => {
				notification['error']({
					message: `Error`,
					description: `${traduceOne.service_global_description}`,
				})
			})
		return returnResponse
	},
	async Register(item, traduceTwo) {
		let returnResponse
		let data = {
			regName: item.first_name,
			regLast: item.last_name,
			regEmail: item.email,
			regPassword: item.password,
			regModo: 'directo',
			regPerfil: 0,
			regVerificacion: 1,
		}
		await axios({
			method: 'POST',
			url: `${ENV_CORE}/api/auth/user-register`,
			data: data,
		})
			.then((response) => {
				if (response.data.statusCode === 200) {
					notification['success']({
						message: `${traduceTwo.service_register_success_message}`,
						description: `${response.data.mensaje}`,
					})
					returnResponse = response
				} else {
					notification['error']({
						message: `${traduceTwo.service_register_error_message} ${response.data.statusCode}`,
						description: `${response.data.mensaje}`,
					})
				}
			})
			.catch(() => {
				notification['error']({
					message: `Error`,
					description: `${traduceTwo.service_global_description}`,
				})
			})
		return returnResponse
	},
	async Recover(item, traduceThree) {
		let returnResponse
		let data = {
			conEmail: item.email,
		}
		await axios({
			method: 'POST',
			url: `${ENV_CORE}/api/auth/restore-password`,
			data: data,
		})
			.then((response) => {
				if (response.data.statusCode === 200) {
					notification['success']({
						message: `${traduceThree.service_recover_success_message}`,
						description: `${traduceThree.service_recover_success_description}`,
					})
					returnResponse = response
				} else {
					notification['warning']({
						message: `${traduceThree.service_recover_warning_message}`,
						description: `${traduceThree.service_recover_warning_description}`,
					})
				}
			})
			.catch(() => {
				notification['error']({
					message: `Error`,
					description: `${traduceThree.service_global_description}`,
				})
			})
		return returnResponse
	},
	async LoginGoogle(item, translateGoogle) {
		let returnResponse
		let data = {
			conEmail: item.email,
			conFoto: item.imageUrl,
			conNombre: item.givenName,
			conApellido: item.familyName,
			conModo: 'google',
		}

		await axios({
			method: 'POST',
			url: `${ENV_CORE}/api/auth/social-login`,
			data: data,
		})
			.then((response) => {
				if (response.data.statusCode === 200) {
					notification['success']({
						message: `${translateGoogle.service_login_google_success_message}`,
						description: `${translateGoogle.service_login_google_success_description}`,
					})
					returnResponse = {
						nombre: response.data.userInfo.nombre,
						apellido: response.data.userInfo.apellido,
						id: response.data.userInfo.id,
						modo: response.data.userInfo.modo,
						email: response.data.userInfo.email,
					}

					localStorage.setItem('userSession', JSON.stringify(returnResponse))

					setGlobal(() => ({
						userEmail: returnResponse.email,
						userData: returnResponse,
					}))
				} else {
					notification['warning']({
						message: `Warning: ${response.data.statusCode}`,
						description: `${response.data.mensaje}.`,
					})
				}
			})
			.catch(() => {
				notification['error']({
					message: `Error`,
					description: `${translateGoogle.service_global_description}`,
				})
			})
		return returnResponse
	},
	async LoginFacebook(item, traduceFacebook) {
		let returnResponse
		let data = {
			conEmail: item.email ? item.email : false,
			conFoto: item.picture.data.url,
			conNombre: item.name,
			conApellido: '',
			conModo: 'facebook',
		}
		if (!data.conEmail) {
			notification['warning']({
				message: 'Aviso:',
				duration: 10,
				description:
					'No se obtuvieron los permisos necesarios de facebook para iniciar sesión, inténtelo nuevamente o use los otros métodos de Inicio de Sesión, disculpe las molestias ocasionadas..',
			})
		} else {
			await axios({
				method: 'POST',
				url: `${ENV_CORE}/api/auth/social-login`,
				data: data,
			})
				.then((response) => {
					if (response.data.statusCode === 200) {
						notification['success']({
							message: `${traduceFacebook.service_login_fb_success_message}`,
							description: `${traduceFacebook.service_login_fb_success_description}`,
						})
						returnResponse = {
							nombre: response.data.userInfo.nombre,
							apellido: response.data.userInfo.apellido,
							id: response.data.userInfo.id,
							modo: response.data.userInfo.modo,
							email: response.data.userInfo.email,
						}
						localStorage.setItem('userSession', JSON.stringify(returnResponse))
						setGlobal(() => ({
							userEmail: returnResponse.email,
							userData: returnResponse,
						}))
					} else {
						notification['warning']({
							message: `Warning: ${response.data.statusCode}`,
							description: `${response.data.mensaje}.`,
						})
					}
				})
				.catch(() => {
					notification['error']({
						message: `Error`,
						description: `${traduceFacebook.service_global_description}`,
					})
				})
		}
		return returnResponse
	},
}
export default servicesLogin
